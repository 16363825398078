import { generatePath } from "ui-kit";

export const paths = {
  listAll: "/bookings",
  list: "/:groupId/bookings",
  view: "/:groupId/bookings/:bookingId",
};

export const generators = {
  listAll: () => generatePath(paths.listAll),
  list: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.list, { groupId }),
  view: ({ groupId, bookingId }: Record<"groupId" | "bookingId", string>) =>
    generatePath(paths.view, { groupId, bookingId }),
};
