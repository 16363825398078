import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

import { paths } from "../paths/bookings";

export const routes: RouteType[] = [
  {
    path: paths.listAll,
    component: lazy(() => import("../pages/bookings/List/AllGroups")),
  },
  {
    path: paths.list,
    component: lazy(() => import("../pages/bookings/List")),
  },
  {
    path: paths.view,
    component: lazy(() => import("../pages/bookings/View")),
  },
];
