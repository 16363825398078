import { useCallback, useEffect, useIntl, useMemo, useState } from "ui-kit";

import { OrderBy, useUserGroupsQuery } from "../../../../graphql/__generated__";

interface Props {
  groupId?: string;
  groupName?: string;
}

const LIMIT = 100;

export const useGroupFilter = ({ groupId, groupName }: Props) => {
  const { formatMessage } = useIntl();

  const [searchValue, setSearchValue] = useState("");
  const [offset, setOffset] = useState(0);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  const { data, loading, error } = useUserGroupsQuery({
    variables: {
      search: searchValue
        ? {
            display_name: {
              _ilike: `%${searchValue.trim()}%`,
            },
          }
        : {},
      orderBy: {
        name: OrderBy.Asc,
      },
      offset: offset * LIMIT,
      limit: LIMIT,
    },
  });

  const dataLength = data?.user_groups_aggregate.aggregate?.count || 0;

  if (error) throw error;

  const allGroupsOption = useMemo(
    () => ({
      label: formatMessage({ id: "general.allGroups" }),
      value: "",
    }),
    [formatMessage]
  );

  useEffect(() => {
    const opts = (() => {
      if (data?.user_groups) {
        return (
          data?.user_groups.map(({ id, display_name }) => ({
            value: id as string,
            label: display_name,
          })) || []
        );
      }
      return [];
    })();
    if (offset === 0) {
      setOptions(opts);
    } else {
      setOptions((prev) => prev.concat(opts));
    }
  }, [data, offset, loading]);

  const optionValue = useMemo(() => {
    if (!groupId) return allGroupsOption;
    return {
      label: groupName || "",
      value: groupId,
    };
  }, [groupName, groupId, allGroupsOption]);

  const setInputValue = useCallback((input: string) => {
    setSearchValue(input);
    setOffset(0);
  }, []);

  const fetchNext = useCallback(() => setOffset(offset + 1), [offset]);

  const hasMore = useMemo(
    () => options.length < dataLength,
    [options.length, dataLength]
  );

  const allOptions = useMemo(() => {
    if (options.length === 0) return [];
    if (!searchValue) return [allGroupsOption, ...options];
    return options;
  }, [searchValue, options, allGroupsOption]);

  return {
    dataLength,
    optionValue,
    options: allOptions,
    loading,
    setInputValue,
    fetchNext,
    hasMore,
  };
};
