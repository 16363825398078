import { generatePath } from "ui-kit";

export const paths = {
  list: "/:groupId/campaigns",
  create: "/:groupId/campaigns/create",
  view: "/:groupId/campaigns/:campaignId",
  edit: "/:groupId/campaigns/:campaignId/edit",
};

export const generators = {
  list: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.list, { groupId }),
  create: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.create, { groupId }),
  edit: ({ groupId, campaignId }: Record<"groupId" | "campaignId", string>) =>
    generatePath(paths.edit, { groupId, campaignId }),
  view: ({ groupId, campaignId }: Record<"groupId" | "campaignId", string>) =>
    generatePath(paths.view, { groupId, campaignId }),
};
