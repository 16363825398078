import { ApolloProvider } from "@apollo/client";
import {
  AppProvider,
  React,
  RenderRoutes,
  useCallback,
  useEffect,
  useState,
} from "ui-kit";

import AuthErrorBoundary from "./components/AuthErrorBoundary";
import SuspenseLayout from "./components/SuspenseLayout";
import config from "./config";
import { LogoutUrlContext } from "./contexts/LogoutUrlContext";
import { TutorialVisitedContext } from "./contexts/TutorialVisitedContext";
import { useFirstTimeLoggedIn } from "./hooks/useFirstTimeLoggedIn";
import routes from "./routing";

const { DEBUG, GRAPHQL_URL } = config;

export default function App() {
  const [logoutUrlSet, setLogoutUrlSet] = useState(new Set<string>());
  const { isFirstTimeLoggedIn, isLoading } = useFirstTimeLoggedIn();
  const [isVisitTutorial, setIsVisitTutorial] = useState(false);

  useEffect(() => {
    if (isLoading) return;
    setIsVisitTutorial(!isFirstTimeLoggedIn);
  }, [isFirstTimeLoggedIn, isLoading]);

  const addLogoutUrl = useCallback(
    (url: string) => setLogoutUrlSet((prev) => prev.add(url)),
    [setLogoutUrlSet]
  );

  const getLogoutUrls = useCallback(() => {
    const arr: string[] = [];
    logoutUrlSet.forEach((v) => arr.push(v));
    return arr;
  }, [logoutUrlSet]);

  return (
    <AppProvider
      uri={GRAPHQL_URL}
      debug={DEBUG}
      name="admin"
      ApolloProvider={ApolloProvider}
    >
      <TutorialVisitedContext.Provider
        value={{ isVisitTutorial, setIsVisitTutorial }}
      >
        <LogoutUrlContext.Provider value={{ getLogoutUrls, addLogoutUrl }}>
          <AuthErrorBoundary>
            <RenderRoutes
              routes={routes}
              FallbackLayoutComponent={SuspenseLayout}
            />
          </AuthErrorBoundary>
        </LogoutUrlContext.Provider>
      </TutorialVisitedContext.Provider>
    </AppProvider>
  );
}
