import type { ReactNode, ThemeType } from "ui-kit";
import {
  AppTopBar,
  FormattedMessage,
  Modal,
  React,
  makeStyles,
  useState,
} from "ui-kit";

import { TOP_BAR_TUTORIAL_URL } from "../../utils/videoUrls";
import NavBar from "./NavBar";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflowX: "hidden",
    overflowY: "auto",
    padding: theme.spacing(3),
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
  },
  topBarItemContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  topBarItem: {
    color: "white",
    marginLeft: 10,
    textDecoration: "underline",
    cursor: "pointer",
  },
  customModalCloseBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 30,
    height: 30,
    backgroundColor: "#262626",
    textAlign: "center",
    fontSize: 25,
    color: "white",
    cursor: "pointer",
  },
}));

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const [isViewingTutorialVideo, setIsViewingTutorialVideo] =
    useState<boolean>(false);

  return (
    <div className={classes.root}>
      <AppTopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        data-cy="drawer"
      >
        <div className={classes.topBarItemContainer}>
          <p
            className={classes.topBarItem}
            onClick={() => setIsViewingTutorialVideo(true)}
            onKeyDown={undefined}
          >
            <FormattedMessage id="general.watchTutorialVideo" />
          </p>
        </div>
      </AppTopBar>
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      <Modal
        open={isViewingTutorialVideo}
        title={null}
        onClose={() => setIsViewingTutorialVideo(false)}
        hideCloseIcon
        neverMobile
        maxWidth={false}
        footer={
          <span
            onClick={() => setIsViewingTutorialVideo(false)}
            onKeyDown={undefined}
            className={classes.customModalCloseBtn}
            role="dialog"
          >
            X
          </span>
        }
      >
        <iframe
          title="tutorial-video"
          width="800"
          height="600"
          src={TOP_BAR_TUTORIAL_URL}
        />
      </Modal>
    </div>
  );
};

DashboardLayout.defaultProps = {
  children: null,
};

export default DashboardLayout;
