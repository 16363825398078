import { decodeToken } from "common";
import { useAuth, useMemo } from "ui-kit";

interface IJwtPayload extends Record<string, unknown> {
  "https://getspacecloud.co/jwt/claims": {
    "spacecloud-admin-first-time-logged-in": boolean;
  };
}

const getFirstTimeLoggedInFromToken = (token: string): boolean => {
  const payload = decodeToken(token) as IJwtPayload;
  const claims = payload["https://getspacecloud.co/jwt/claims"];

  return claims["spacecloud-admin-first-time-logged-in"];
};

export function useFirstTimeLoggedIn() {
  const { user, isLoading } = useAuth();

  const isFirstTimeLoggedIn = useMemo(() => {
    if (isLoading) return false;

    if (!user.userToken) return false;
    return getFirstTimeLoggedInFromToken(user.userToken);
  }, [isLoading, user.userToken]);

  return { isFirstTimeLoggedIn, isLoading };
}
