import {
  ErrorBoundary,
  ErrorScreen,
  React,
  ReactNode,
  useAuth,
  useCallback,
  useEffect,
  useUserAuth,
} from "ui-kit";

import useLogout from "../hooks/useLogout";

interface Props {
  children: ReactNode;
}

function AuthErrorScreen() {
  return <ErrorScreen error={new Error("Session Expired")} />;
}

export default function AuthErrorBoundary({ children }: Props) {
  const { logout } = useLogout();
  const { error: useUserAuthError } = useUserAuth();
  const { error: useAuthError } = useAuth();

  const handleError = useCallback(
    (_error: Error) => {
      if (
        _error.message === "Login required" ||
        _error.message === "Could not verify JWT: JWTExpired"
      ) {
        logout();
      } else {
        throw _error;
      }
    },
    [logout]
  );

  useEffect(() => {
    if (useAuthError) {
      handleError(useAuthError);
    }
    if (useUserAuthError) {
      handleError(useUserAuthError);
    }
  }, [handleError, useAuthError, useUserAuthError]);

  return (
    <ErrorBoundary onError={handleError} FallbackComponent={AuthErrorScreen}>
      {children}
    </ErrorBoundary>
  );
}
