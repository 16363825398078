import { generatePath } from "ui-kit";

export const paths = {
  listAll: "/assets",
  list: "/:groupId/assets",
  bulkUpsert: "/:groupId/assets/bulk-upsert",
  create: "/:groupId/assets/create",
  view: "/:groupId/assets/:assetId",
  edit: "/:groupId/assets/:assetId/edit",
};

export const generators = {
  listAll: () => generatePath(paths.listAll),
  list: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.list, { groupId }),
  create: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.create, { groupId }),
  view: ({ groupId, assetId }: Record<"groupId" | "assetId", string>) =>
    generatePath(paths.view, { groupId, assetId }),
  edit: ({ groupId, assetId }: Record<"groupId" | "assetId", string>) =>
    generatePath(paths.edit, { groupId, assetId }),
  bulkUpsert: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.bulkUpsert, { groupId }),
};
