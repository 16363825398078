import type { RouteType } from "ui-kit";
import { NotFoundPage, React } from "ui-kit";

import DashboardLayout from "../layouts/Dashboard";
import { Home } from "../pages/home";
import { paths } from "../paths";
import { routes as assetGroupsRoutes } from "./assetGroups";
import { routes as assetsRoutes } from "./assets";
import { routes as bookingsRoutes } from "./bookings";
import { routes as campaignsRoutes } from "./campaigns";
import { routes as groupsRoutes } from "./groups";
import { routes as helpCenter } from "./helpCenter";
import { routes as invitesRoutes } from "./invites";
import { routes as usersRoutes } from "./users";

const getApplicationRoutes = (routesConfig: RouteType[]): RouteType[] => {
  const definedRoutes = routesConfig.map((route) => ({
    ...route,
    exact: route?.exact || true,
    layout: route?.layout || DashboardLayout,
  }));

  return [
    {
      path: paths.home,
      exact: true,
      component: () => <Home />,
    },
    ...definedRoutes,
    {
      path: "*",
      component: () => <NotFoundPage />,
    },
  ];
};

const applicationRoutes: RouteType[] = [
  ...groupsRoutes,
  ...assetGroupsRoutes,
  ...assetsRoutes,
  ...invitesRoutes,
  ...usersRoutes,
  ...bookingsRoutes,
  ...campaignsRoutes,
  ...helpCenter,
];

export default getApplicationRoutes(applicationRoutes);
