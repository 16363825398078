import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

import { paths } from "../paths/users";

export const routes: RouteType[] = [
  {
    path: paths.listAll,
    component: lazy(() => import("../pages/users/List/AllGroups")),
  },
  {
    path: paths.list,
    component: lazy(() => import("../pages/users/List")),
  },
  {
    path: paths.massEdit,
    component: lazy(() => import("../pages/users/MassEdit")),
  },
  {
    path: paths.editUserDetails,
    component: lazy(() => import("../pages/users/Edit/EditUserDetails")),
  },
  {
    path: paths.editUserRoles,
    component: lazy(() => import("../pages/users/Edit/EditUserRoles")),
  },
  {
    path: paths.editMoreInfo,
    component: lazy(() => import("../pages/users/Edit/EditMoreInfoFields")),
  },
  {
    path: paths.editAdminFields,
    component: lazy(() => import("../pages/users/Edit/EditAdminFields")),
  },
  {
    path: paths.view,
    component: lazy(() => import("../pages/users/View")),
  },
];
