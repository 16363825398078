import { generatePath } from "ui-kit";

export const paths = {
  listAll: "/asset-groups",
  list: "/:groupId/asset-groups",
  create: "/:groupId/asset-groups/create",
  view: "/:groupId/asset-groups/:assetGroupId",
  edit: "/:groupId/asset-groups/:assetGroupId/edit",
};

export const generators = {
  listAll: () => generatePath(paths.listAll),
  list: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.list, { groupId }),
  create: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.create, { groupId }),
  view: ({
    groupId,
    assetGroupId,
  }: Record<"groupId" | "assetGroupId", string>) =>
    generatePath(paths.view, { groupId, assetGroupId }),
  edit: ({
    groupId,
    assetGroupId,
  }: Record<"groupId" | "assetGroupId", string>) =>
    generatePath(paths.edit, { groupId, assetGroupId }),
};
