import { useRouteMatch } from "ui-kit";

import { generators, paths } from ".";

type ReturnPathGenerators = {
  listAll?: () => string;
  list: ({ groupId }: { groupId: string }) => string;
} | null;

export function useCurrentPathGenerators(): ReturnPathGenerators {
  const { path: pathPatternToFind } = useRouteMatch();
  if (pathPatternToFind === "/") return null;

  const pathKeys: Array<keyof Omit<typeof paths, "home">> = [
    "groups",
    "assetGroups",
    "assets",
    "users",
    "invites",
    "bookings",
    "campaigns",
  ];

  for (const pathKey of pathKeys) {
    const pathTypes = paths[pathKey];
    for (const [, path] of Object.entries(pathTypes)) {
      if (path === pathPatternToFind) {
        return generators[
          pathKey as keyof Omit<typeof generators, "home" | "helpCenter">
        ];
      }
    }
  }
  return null;
}
