import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined | null;
export type InputMaybe<T> = T | undefined | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  asset_groups_annex_type_enum: any;
  assets_rental_type_enum: any;
  assets_utility_bill_period_type_enum: any;
  bookings_shop_type_enum: any;
  citext: any;
  date: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  seed_float: any;
  timestamp: any;
  timestamptz: any;
  timetz: any;
  uuid: any;
};

export type ActivateUserFromGroupInput = {
  user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type ActivateUserFromGroupOutput = {
  __typename?: 'ActivateUserFromGroupOutput';
  message: Scalars['String'];
};

export type ApproveAssetBookingOutput = {
  __typename?: 'ApproveAssetBookingOutput';
  asset_booking?: Maybe<AssetBookings>;
  asset_booking_id: Scalars['String'];
};

export type AssetGroupOperatingHoursInsertCustomInput = {
  day_of_week: Scalars['String'];
  hour_end: Scalars['String'];
  hour_start: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CancelAssetBookingOutput = {
  __typename?: 'CancelAssetBookingOutput';
  asset_booking?: Maybe<AssetBookings>;
  asset_booking_id: Scalars['String'];
};

export type CompleteExternalOnboardingOutput = {
  __typename?: 'CompleteExternalOnboardingOutput';
  user?: Maybe<Users>;
  user_group_id: Scalars['String'];
  user_group_role: Scalars['String'];
  user_id: Scalars['String'];
};

export type CreateAssetBookingInput = {
  approver?: InputMaybe<Scalars['String']>;
  asset_id: Scalars['String'];
  booking_for?: InputMaybe<Scalars['String']>;
  booking_title?: InputMaybe<Scalars['String']>;
  date_end: Scalars['String'];
  date_start: Scalars['String'];
  participant_list?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  reminder?: InputMaybe<Scalars['Boolean']>;
  time_end: Scalars['String'];
  time_start: Scalars['String'];
  use_personal_credits?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAssetBookingOutput = {
  __typename?: 'CreateAssetBookingOutput';
  asset_booking?: Maybe<AssetBookings>;
  asset_booking_id: Scalars['String'];
};

export type CreateAssetGroupOutput = {
  __typename?: 'CreateAssetGroupOutput';
  asset_class_id: Scalars['String'];
  asset_classes?: Maybe<AssetClasses>;
  description?: Maybe<Scalars['String']>;
  display_name: Scalars['String'];
  floorplan_image_url?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  parent_id?: Maybe<Scalars['String']>;
  thumbnail_image_url?: Maybe<Scalars['String']>;
};

export type DeactivateUserFromGroupInput = {
  user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type DeactivateUserFromGroupOutput = {
  __typename?: 'DeactivateUserFromGroupOutput';
  message: Scalars['String'];
};

export type DeleteUserGroupInput = {
  user_group_id: Scalars['uuid'];
};

export type DeleteUserGroupOutput = {
  __typename?: 'DeleteUserGroupOutput';
  message: Scalars['String'];
};

export type DownloadCsvTemplateInput = {
  type: Scalars['String'];
  user_group_id: Scalars['uuid'];
};

export type DownloadCsvTemplateOutput = {
  __typename?: 'DownloadCsvTemplateOutput';
  csv_data: Array<Scalars['jsonb']>;
};

export type EditAssetBookingInput = {
  approver?: InputMaybe<Scalars['String']>;
  asset_booking_id: Scalars['String'];
  booking_for?: InputMaybe<Scalars['String']>;
  booking_title?: InputMaybe<Scalars['String']>;
  date_end?: InputMaybe<Scalars['String']>;
  date_start?: InputMaybe<Scalars['String']>;
  participant_list?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  reminder?: InputMaybe<Scalars['Boolean']>;
  time_end?: InputMaybe<Scalars['String']>;
  time_start?: InputMaybe<Scalars['String']>;
  use_personal_credits?: InputMaybe<Scalars['Boolean']>;
};

export type EditAssetBookingOutput = {
  __typename?: 'EditAssetBookingOutput';
  asset_booking?: Maybe<AssetBookings>;
  asset_booking_id: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type MassEditProcessDataInput = {
  csv_json_array: Array<Scalars['json']>;
  type: Scalars['String'];
  user_group_id: Scalars['uuid'];
};

export type MassEditProcessDataOutput = {
  __typename?: 'MassEditProcessDataOutput';
  message: Scalars['String'];
};

export type RejectAssetBookingOutput = {
  __typename?: 'RejectAssetBookingOutput';
  asset_booking?: Maybe<AssetBookings>;
  asset_booking_id: Scalars['String'];
};

export type RemoveUserFromGroupOutput = {
  __typename?: 'RemoveUserFromGroupOutput';
  user_id: Scalars['String'];
};

export type SendInviteInput = {
  end_date?: InputMaybe<Scalars['String']>;
  receiver_email: Scalars['String'];
  start_date?: InputMaybe<Scalars['String']>;
  user_group_id: Scalars['String'];
  user_group_role_id: Scalars['String'];
};

export type SendInviteOutput = {
  __typename?: 'SendInviteOutput';
  invite?: Maybe<Invites>;
  invite_id: Scalars['String'];
  receiver_email: Scalars['String'];
  sender?: Maybe<Users>;
  sender_id?: Maybe<Scalars['String']>;
  user_group?: Maybe<UserGroups>;
  user_group_id: Scalars['String'];
};

export type SignedMediaUrlsInput = {
  filename: Scalars['String'];
  mime_type: Scalars['String'];
};

export type SignedMediaUrlsOutput = {
  __typename?: 'SignedMediaUrlsOutput';
  filename: Scalars['String'];
  get_url: Scalars['String'];
  mime_type: Scalars['String'];
  put_url: Scalars['String'];
  resource_key: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type UpdateUserRolesInGroupsInput = {
  initial_roles: Array<Scalars['uuid']>;
  updated_roles: Array<Scalars['uuid']>;
  user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

export type UpdateUserRolesInGroupsOutput = {
  __typename?: 'UpdateUserRolesInGroupsOutput';
  message: Scalars['String'];
};

/** columns and relationships of "asset_amenities" */
export type AssetAmenities = {
  __typename?: 'asset_amenities';
  /** An object relationship */
  asset: Assets;
  asset_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  global_amenity_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "asset_amenities" */
export type AssetAmenitiesAggregate = {
  __typename?: 'asset_amenities_aggregate';
  aggregate?: Maybe<AssetAmenitiesAggregateFields>;
  nodes: Array<AssetAmenities>;
};

export type AssetAmenitiesAggregateBoolExp = {
  count?: InputMaybe<AssetAmenitiesAggregateBoolExpCount>;
};

export type AssetAmenitiesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssetAmenitiesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetAmenitiesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "asset_amenities" */
export type AssetAmenitiesAggregateFields = {
  __typename?: 'asset_amenities_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AssetAmenitiesMaxFields>;
  min?: Maybe<AssetAmenitiesMinFields>;
};


/** aggregate fields of "asset_amenities" */
export type AssetAmenitiesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetAmenitiesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset_amenities" */
export type AssetAmenitiesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetAmenitiesMaxOrderBy>;
  min?: InputMaybe<AssetAmenitiesMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_amenities" */
export type AssetAmenitiesArrRelInsertInput = {
  data: Array<AssetAmenitiesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetAmenitiesOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_amenities". All fields are combined with a logical 'AND'. */
export type AssetAmenitiesBoolExp = {
  _and?: InputMaybe<Array<AssetAmenitiesBoolExp>>;
  _not?: InputMaybe<AssetAmenitiesBoolExp>;
  _or?: InputMaybe<Array<AssetAmenitiesBoolExp>>;
  asset?: InputMaybe<AssetsBoolExp>;
  asset_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  global_amenity_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "asset_amenities" */
export enum AssetAmenitiesConstraint {
  /** unique or primary key constraint on columns "asset_id", "global_amenity_id" */
  AssetAmenitiesPkey = 'asset_amenities_pkey'
}

/** input type for inserting data into table "asset_amenities" */
export type AssetAmenitiesInsertInput = {
  asset?: InputMaybe<AssetsObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  global_amenity_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AssetAmenitiesMaxFields = {
  __typename?: 'asset_amenities_max_fields';
  asset_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  global_amenity_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "asset_amenities" */
export type AssetAmenitiesMaxOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  global_amenity_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssetAmenitiesMinFields = {
  __typename?: 'asset_amenities_min_fields';
  asset_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  global_amenity_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "asset_amenities" */
export type AssetAmenitiesMinOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  global_amenity_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "asset_amenities" */
export type AssetAmenitiesMutationResponse = {
  __typename?: 'asset_amenities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssetAmenities>;
};

/** on_conflict condition type for table "asset_amenities" */
export type AssetAmenitiesOnConflict = {
  constraint: AssetAmenitiesConstraint;
  update_columns?: Array<AssetAmenitiesUpdateColumn>;
  where?: InputMaybe<AssetAmenitiesBoolExp>;
};

/** Ordering options when selecting data from "asset_amenities". */
export type AssetAmenitiesOrderBy = {
  asset?: InputMaybe<AssetsOrderBy>;
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  global_amenity_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: asset_amenities */
export type AssetAmenitiesPkColumnsInput = {
  asset_id: Scalars['uuid'];
  global_amenity_id: Scalars['uuid'];
};

/** select columns of table "asset_amenities" */
export enum AssetAmenitiesSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GlobalAmenityId = 'global_amenity_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "asset_amenities" */
export type AssetAmenitiesSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  global_amenity_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "asset_amenities" */
export type AssetAmenitiesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetAmenitiesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetAmenitiesStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  global_amenity_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "asset_amenities" */
export enum AssetAmenitiesUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GlobalAmenityId = 'global_amenity_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type AssetAmenitiesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssetAmenitiesSetInput>;
  /** filter the rows which have to be updated */
  where: AssetAmenitiesBoolExp;
};

/** columns and relationships of "asset_booking_events" */
export type AssetBookingEvents = {
  __typename?: 'asset_booking_events';
  /** An object relationship */
  asset_booking?: Maybe<AssetBookings>;
  asset_booking_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  diff: Scalars['jsonb'];
  event_time: Scalars['timestamptz'];
  id: Scalars['uuid'];
  payload: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "asset_booking_events" */
export type AssetBookingEventsDiffArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "asset_booking_events" */
export type AssetBookingEventsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "asset_booking_events" */
export type AssetBookingEventsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetBookingEventsMaxOrderBy>;
  min?: InputMaybe<AssetBookingEventsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "asset_booking_events". All fields are combined with a logical 'AND'. */
export type AssetBookingEventsBoolExp = {
  _and?: InputMaybe<Array<AssetBookingEventsBoolExp>>;
  _not?: InputMaybe<AssetBookingEventsBoolExp>;
  _or?: InputMaybe<Array<AssetBookingEventsBoolExp>>;
  asset_booking?: InputMaybe<AssetBookingsBoolExp>;
  asset_booking_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  diff?: InputMaybe<JsonbComparisonExp>;
  event_time?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  payload?: InputMaybe<JsonbComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** order by max() on columns of table "asset_booking_events" */
export type AssetBookingEventsMaxOrderBy = {
  asset_booking_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  event_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "asset_booking_events" */
export type AssetBookingEventsMinOrderBy = {
  asset_booking_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  event_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "asset_booking_events". */
export type AssetBookingEventsOrderBy = {
  asset_booking?: InputMaybe<AssetBookingsOrderBy>;
  asset_booking_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  diff?: InputMaybe<OrderBy>;
  event_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payload?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** select columns of table "asset_booking_events" */
export enum AssetBookingEventsSelectColumn {
  /** column name */
  AssetBookingId = 'asset_booking_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Diff = 'diff',
  /** column name */
  EventTime = 'event_time',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "asset_booking_events" */
export type AssetBookingEventsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetBookingEventsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetBookingEventsStreamCursorValueInput = {
  asset_booking_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  diff?: InputMaybe<Scalars['jsonb']>;
  event_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "asset_bookings" */
export type AssetBookings = {
  __typename?: 'asset_bookings';
  account_type?: Maybe<Scalars['String']>;
  approver?: Maybe<Scalars['citext']>;
  approver_list: Scalars['jsonb'];
  /** An object relationship */
  asset: Assets;
  /** An array relationship */
  asset_booking_events: Array<AssetBookingEvents>;
  asset_id: Scalars['uuid'];
  /** An object relationship */
  booker?: Maybe<Bookers>;
  booking_for?: Maybe<Scalars['String']>;
  booking_title?: Maybe<Scalars['String']>;
  cancellation_date?: Maybe<Scalars['timestamp']>;
  created_at: Scalars['timestamptz'];
  credit: Scalars['Int'];
  date_end: Scalars['date'];
  date_start: Scalars['date'];
  datetime_end: Scalars['timestamptz'];
  datetime_start: Scalars['timestamptz'];
  /** An object relationship */
  enum_asset_booking_status: EnumAssetBookingStatuses;
  event_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  participant_list?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reference_id: Scalars['String'];
  reminder: Scalars['Boolean'];
  /** An object relationship */
  sc_booking?: Maybe<ScBookings>;
  status: EnumAssetBookingStatusesEnum;
  time_end: Scalars['timetz'];
  time_start: Scalars['timetz'];
  updated_at: Scalars['timestamptz'];
  updated_by_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  updated_by_id_user?: Maybe<Users>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "asset_bookings" */
export type AssetBookingsApproverListArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "asset_bookings" */
export type AssetBookingsAssetBookingEventsArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingEventsOrderBy>>;
  where?: InputMaybe<AssetBookingEventsBoolExp>;
};

/** aggregated selection of "asset_bookings" */
export type AssetBookingsAggregate = {
  __typename?: 'asset_bookings_aggregate';
  aggregate?: Maybe<AssetBookingsAggregateFields>;
  nodes: Array<AssetBookings>;
};

export type AssetBookingsAggregateBoolExp = {
  bool_and?: InputMaybe<AssetBookingsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<AssetBookingsAggregateBoolExpBoolOr>;
  count?: InputMaybe<AssetBookingsAggregateBoolExpCount>;
};

export type AssetBookingsAggregateBoolExpBoolAnd = {
  arguments: AssetBookingsSelectColumnAssetBookingsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetBookingsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetBookingsAggregateBoolExpBoolOr = {
  arguments: AssetBookingsSelectColumnAssetBookingsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetBookingsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetBookingsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetBookingsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "asset_bookings" */
export type AssetBookingsAggregateFields = {
  __typename?: 'asset_bookings_aggregate_fields';
  avg?: Maybe<AssetBookingsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AssetBookingsMaxFields>;
  min?: Maybe<AssetBookingsMinFields>;
  stddev?: Maybe<AssetBookingsStddevFields>;
  stddev_pop?: Maybe<AssetBookingsStddevPopFields>;
  stddev_samp?: Maybe<AssetBookingsStddevSampFields>;
  sum?: Maybe<AssetBookingsSumFields>;
  var_pop?: Maybe<AssetBookingsVarPopFields>;
  var_samp?: Maybe<AssetBookingsVarSampFields>;
  variance?: Maybe<AssetBookingsVarianceFields>;
};


/** aggregate fields of "asset_bookings" */
export type AssetBookingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset_bookings" */
export type AssetBookingsAggregateOrderBy = {
  avg?: InputMaybe<AssetBookingsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetBookingsMaxOrderBy>;
  min?: InputMaybe<AssetBookingsMinOrderBy>;
  stddev?: InputMaybe<AssetBookingsStddevOrderBy>;
  stddev_pop?: InputMaybe<AssetBookingsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<AssetBookingsStddevSampOrderBy>;
  sum?: InputMaybe<AssetBookingsSumOrderBy>;
  var_pop?: InputMaybe<AssetBookingsVarPopOrderBy>;
  var_samp?: InputMaybe<AssetBookingsVarSampOrderBy>;
  variance?: InputMaybe<AssetBookingsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "asset_bookings" */
export type AssetBookingsArrRelInsertInput = {
  data: Array<AssetBookingsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetBookingsOnConflict>;
};

/** aggregate avg on columns */
export type AssetBookingsAvgFields = {
  __typename?: 'asset_bookings_avg_fields';
  credit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "asset_bookings" */
export type AssetBookingsAvgOrderBy = {
  credit?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "asset_bookings". All fields are combined with a logical 'AND'. */
export type AssetBookingsBoolExp = {
  _and?: InputMaybe<Array<AssetBookingsBoolExp>>;
  _not?: InputMaybe<AssetBookingsBoolExp>;
  _or?: InputMaybe<Array<AssetBookingsBoolExp>>;
  account_type?: InputMaybe<StringComparisonExp>;
  approver?: InputMaybe<CitextComparisonExp>;
  approver_list?: InputMaybe<JsonbComparisonExp>;
  asset?: InputMaybe<AssetsBoolExp>;
  asset_booking_events?: InputMaybe<AssetBookingEventsBoolExp>;
  asset_id?: InputMaybe<UuidComparisonExp>;
  booker?: InputMaybe<BookersBoolExp>;
  booking_for?: InputMaybe<StringComparisonExp>;
  booking_title?: InputMaybe<StringComparisonExp>;
  cancellation_date?: InputMaybe<TimestampComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  credit?: InputMaybe<IntComparisonExp>;
  date_end?: InputMaybe<DateComparisonExp>;
  date_start?: InputMaybe<DateComparisonExp>;
  datetime_end?: InputMaybe<TimestamptzComparisonExp>;
  datetime_start?: InputMaybe<TimestamptzComparisonExp>;
  enum_asset_booking_status?: InputMaybe<EnumAssetBookingStatusesBoolExp>;
  event_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  participant_list?: InputMaybe<StringComparisonExp>;
  reason?: InputMaybe<StringComparisonExp>;
  reference_id?: InputMaybe<StringComparisonExp>;
  reminder?: InputMaybe<BooleanComparisonExp>;
  sc_booking?: InputMaybe<ScBookingsBoolExp>;
  status?: InputMaybe<EnumAssetBookingStatusesEnumComparisonExp>;
  time_end?: InputMaybe<TimetzComparisonExp>;
  time_start?: InputMaybe<TimetzComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  updated_by_id?: InputMaybe<UuidComparisonExp>;
  updated_by_id_user?: InputMaybe<UsersBoolExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "asset_bookings" */
export enum AssetBookingsConstraint {
  /** unique or primary key constraint on columns "id" */
  AssetBookingsPkey = 'asset_bookings_pkey'
}

/** input type for incrementing numeric columns in table "asset_bookings" */
export type AssetBookingsIncInput = {
  credit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "asset_bookings" */
export type AssetBookingsInsertInput = {
  account_type?: InputMaybe<Scalars['String']>;
  approver?: InputMaybe<Scalars['citext']>;
  asset?: InputMaybe<AssetsObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']>;
  booking_for?: InputMaybe<Scalars['String']>;
  booking_title?: InputMaybe<Scalars['String']>;
  cancellation_date?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit?: InputMaybe<Scalars['Int']>;
  date_end?: InputMaybe<Scalars['date']>;
  date_start?: InputMaybe<Scalars['date']>;
  participant_list?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  reminder?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<EnumAssetBookingStatusesEnum>;
  time_end?: InputMaybe<Scalars['timetz']>;
  time_start?: InputMaybe<Scalars['timetz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by_id?: InputMaybe<Scalars['uuid']>;
  updated_by_id_user?: InputMaybe<UsersObjRelInsertInput>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AssetBookingsMaxFields = {
  __typename?: 'asset_bookings_max_fields';
  account_type?: Maybe<Scalars['String']>;
  approver?: Maybe<Scalars['citext']>;
  asset_id?: Maybe<Scalars['uuid']>;
  booking_for?: Maybe<Scalars['String']>;
  booking_title?: Maybe<Scalars['String']>;
  cancellation_date?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credit?: Maybe<Scalars['Int']>;
  date_end?: Maybe<Scalars['date']>;
  date_start?: Maybe<Scalars['date']>;
  datetime_end?: Maybe<Scalars['timestamptz']>;
  datetime_start?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  participant_list?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reference_id?: Maybe<Scalars['String']>;
  time_end?: Maybe<Scalars['timetz']>;
  time_start?: Maybe<Scalars['timetz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "asset_bookings" */
export type AssetBookingsMaxOrderBy = {
  account_type?: InputMaybe<OrderBy>;
  approver?: InputMaybe<OrderBy>;
  asset_id?: InputMaybe<OrderBy>;
  booking_for?: InputMaybe<OrderBy>;
  booking_title?: InputMaybe<OrderBy>;
  cancellation_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  credit?: InputMaybe<OrderBy>;
  date_end?: InputMaybe<OrderBy>;
  date_start?: InputMaybe<OrderBy>;
  datetime_end?: InputMaybe<OrderBy>;
  datetime_start?: InputMaybe<OrderBy>;
  event_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  participant_list?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  reference_id?: InputMaybe<OrderBy>;
  time_end?: InputMaybe<OrderBy>;
  time_start?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssetBookingsMinFields = {
  __typename?: 'asset_bookings_min_fields';
  account_type?: Maybe<Scalars['String']>;
  approver?: Maybe<Scalars['citext']>;
  asset_id?: Maybe<Scalars['uuid']>;
  booking_for?: Maybe<Scalars['String']>;
  booking_title?: Maybe<Scalars['String']>;
  cancellation_date?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  credit?: Maybe<Scalars['Int']>;
  date_end?: Maybe<Scalars['date']>;
  date_start?: Maybe<Scalars['date']>;
  datetime_end?: Maybe<Scalars['timestamptz']>;
  datetime_start?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  participant_list?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reference_id?: Maybe<Scalars['String']>;
  time_end?: Maybe<Scalars['timetz']>;
  time_start?: Maybe<Scalars['timetz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "asset_bookings" */
export type AssetBookingsMinOrderBy = {
  account_type?: InputMaybe<OrderBy>;
  approver?: InputMaybe<OrderBy>;
  asset_id?: InputMaybe<OrderBy>;
  booking_for?: InputMaybe<OrderBy>;
  booking_title?: InputMaybe<OrderBy>;
  cancellation_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  credit?: InputMaybe<OrderBy>;
  date_end?: InputMaybe<OrderBy>;
  date_start?: InputMaybe<OrderBy>;
  datetime_end?: InputMaybe<OrderBy>;
  datetime_start?: InputMaybe<OrderBy>;
  event_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  participant_list?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  reference_id?: InputMaybe<OrderBy>;
  time_end?: InputMaybe<OrderBy>;
  time_start?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "asset_bookings" */
export type AssetBookingsMutationResponse = {
  __typename?: 'asset_bookings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssetBookings>;
};

/** on_conflict condition type for table "asset_bookings" */
export type AssetBookingsOnConflict = {
  constraint: AssetBookingsConstraint;
  update_columns?: Array<AssetBookingsUpdateColumn>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};

/** Ordering options when selecting data from "asset_bookings". */
export type AssetBookingsOrderBy = {
  account_type?: InputMaybe<OrderBy>;
  approver?: InputMaybe<OrderBy>;
  approver_list?: InputMaybe<OrderBy>;
  asset?: InputMaybe<AssetsOrderBy>;
  asset_booking_events_aggregate?: InputMaybe<AssetBookingEventsAggregateOrderBy>;
  asset_id?: InputMaybe<OrderBy>;
  booker?: InputMaybe<BookersOrderBy>;
  booking_for?: InputMaybe<OrderBy>;
  booking_title?: InputMaybe<OrderBy>;
  cancellation_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  credit?: InputMaybe<OrderBy>;
  date_end?: InputMaybe<OrderBy>;
  date_start?: InputMaybe<OrderBy>;
  datetime_end?: InputMaybe<OrderBy>;
  datetime_start?: InputMaybe<OrderBy>;
  enum_asset_booking_status?: InputMaybe<EnumAssetBookingStatusesOrderBy>;
  event_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  participant_list?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  reference_id?: InputMaybe<OrderBy>;
  reminder?: InputMaybe<OrderBy>;
  sc_booking?: InputMaybe<ScBookingsOrderBy>;
  status?: InputMaybe<OrderBy>;
  time_end?: InputMaybe<OrderBy>;
  time_start?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_id?: InputMaybe<OrderBy>;
  updated_by_id_user?: InputMaybe<UsersOrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: asset_bookings */
export type AssetBookingsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "asset_bookings" */
export enum AssetBookingsSelectColumn {
  /** column name */
  AccountType = 'account_type',
  /** column name */
  Approver = 'approver',
  /** column name */
  ApproverList = 'approver_list',
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  BookingFor = 'booking_for',
  /** column name */
  BookingTitle = 'booking_title',
  /** column name */
  CancellationDate = 'cancellation_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Credit = 'credit',
  /** column name */
  DateEnd = 'date_end',
  /** column name */
  DateStart = 'date_start',
  /** column name */
  DatetimeEnd = 'datetime_end',
  /** column name */
  DatetimeStart = 'datetime_start',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  ParticipantList = 'participant_list',
  /** column name */
  Reason = 'reason',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  Reminder = 'reminder',
  /** column name */
  Status = 'status',
  /** column name */
  TimeEnd = 'time_end',
  /** column name */
  TimeStart = 'time_start',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id',
  /** column name */
  UserId = 'user_id'
}

/** select "asset_bookings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "asset_bookings" */
export enum AssetBookingsSelectColumnAssetBookingsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Reminder = 'reminder'
}

/** select "asset_bookings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "asset_bookings" */
export enum AssetBookingsSelectColumnAssetBookingsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Reminder = 'reminder'
}

/** input type for updating data in table "asset_bookings" */
export type AssetBookingsSetInput = {
  account_type?: InputMaybe<Scalars['String']>;
  approver?: InputMaybe<Scalars['citext']>;
  asset_id?: InputMaybe<Scalars['uuid']>;
  booking_for?: InputMaybe<Scalars['String']>;
  booking_title?: InputMaybe<Scalars['String']>;
  cancellation_date?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit?: InputMaybe<Scalars['Int']>;
  date_end?: InputMaybe<Scalars['date']>;
  date_start?: InputMaybe<Scalars['date']>;
  participant_list?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  reminder?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<EnumAssetBookingStatusesEnum>;
  time_end?: InputMaybe<Scalars['timetz']>;
  time_start?: InputMaybe<Scalars['timetz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AssetBookingsStddevFields = {
  __typename?: 'asset_bookings_stddev_fields';
  credit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "asset_bookings" */
export type AssetBookingsStddevOrderBy = {
  credit?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AssetBookingsStddevPopFields = {
  __typename?: 'asset_bookings_stddev_pop_fields';
  credit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "asset_bookings" */
export type AssetBookingsStddevPopOrderBy = {
  credit?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AssetBookingsStddevSampFields = {
  __typename?: 'asset_bookings_stddev_samp_fields';
  credit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "asset_bookings" */
export type AssetBookingsStddevSampOrderBy = {
  credit?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "asset_bookings" */
export type AssetBookingsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetBookingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetBookingsStreamCursorValueInput = {
  account_type?: InputMaybe<Scalars['String']>;
  approver?: InputMaybe<Scalars['citext']>;
  approver_list?: InputMaybe<Scalars['jsonb']>;
  asset_id?: InputMaybe<Scalars['uuid']>;
  booking_for?: InputMaybe<Scalars['String']>;
  booking_title?: InputMaybe<Scalars['String']>;
  cancellation_date?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit?: InputMaybe<Scalars['Int']>;
  date_end?: InputMaybe<Scalars['date']>;
  date_start?: InputMaybe<Scalars['date']>;
  datetime_end?: InputMaybe<Scalars['timestamptz']>;
  datetime_start?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  participant_list?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  reference_id?: InputMaybe<Scalars['String']>;
  reminder?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<EnumAssetBookingStatusesEnum>;
  time_end?: InputMaybe<Scalars['timetz']>;
  time_start?: InputMaybe<Scalars['timetz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type AssetBookingsSumFields = {
  __typename?: 'asset_bookings_sum_fields';
  credit?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "asset_bookings" */
export type AssetBookingsSumOrderBy = {
  credit?: InputMaybe<OrderBy>;
};

/** update columns of table "asset_bookings" */
export enum AssetBookingsUpdateColumn {
  /** column name */
  AccountType = 'account_type',
  /** column name */
  Approver = 'approver',
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  BookingFor = 'booking_for',
  /** column name */
  BookingTitle = 'booking_title',
  /** column name */
  CancellationDate = 'cancellation_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Credit = 'credit',
  /** column name */
  DateEnd = 'date_end',
  /** column name */
  DateStart = 'date_start',
  /** column name */
  ParticipantList = 'participant_list',
  /** column name */
  Reason = 'reason',
  /** column name */
  Reminder = 'reminder',
  /** column name */
  Status = 'status',
  /** column name */
  TimeEnd = 'time_end',
  /** column name */
  TimeStart = 'time_start',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id',
  /** column name */
  UserId = 'user_id'
}

export type AssetBookingsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AssetBookingsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssetBookingsSetInput>;
  /** filter the rows which have to be updated */
  where: AssetBookingsBoolExp;
};

/** aggregate var_pop on columns */
export type AssetBookingsVarPopFields = {
  __typename?: 'asset_bookings_var_pop_fields';
  credit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "asset_bookings" */
export type AssetBookingsVarPopOrderBy = {
  credit?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AssetBookingsVarSampFields = {
  __typename?: 'asset_bookings_var_samp_fields';
  credit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "asset_bookings" */
export type AssetBookingsVarSampOrderBy = {
  credit?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AssetBookingsVarianceFields = {
  __typename?: 'asset_bookings_variance_fields';
  credit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "asset_bookings" */
export type AssetBookingsVarianceOrderBy = {
  credit?: InputMaybe<OrderBy>;
};

/** columns and relationships of "asset_classes" */
export type AssetClasses = {
  __typename?: 'asset_classes';
  /** An array relationship */
  asset_groups: Array<AssetGroups>;
  /** An aggregate relationship */
  asset_groups_aggregate: AssetGroupsAggregate;
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: AssetsAggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  enable: Scalars['Boolean'];
  id: Scalars['uuid'];
  is_group: Scalars['Boolean'];
  name: Scalars['String'];
  requires_approval: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_group: UserGroups;
  user_group_id: Scalars['uuid'];
};


/** columns and relationships of "asset_classes" */
export type AssetClassesAssetGroupsArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupsOrderBy>>;
  where?: InputMaybe<AssetGroupsBoolExp>;
};


/** columns and relationships of "asset_classes" */
export type AssetClassesAssetGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupsOrderBy>>;
  where?: InputMaybe<AssetGroupsBoolExp>;
};


/** columns and relationships of "asset_classes" */
export type AssetClassesAssetsArgs = {
  distinct_on?: InputMaybe<Array<AssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetsOrderBy>>;
  where?: InputMaybe<AssetsBoolExp>;
};


/** columns and relationships of "asset_classes" */
export type AssetClassesAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetsOrderBy>>;
  where?: InputMaybe<AssetsBoolExp>;
};

/** aggregated selection of "asset_classes" */
export type AssetClassesAggregate = {
  __typename?: 'asset_classes_aggregate';
  aggregate?: Maybe<AssetClassesAggregateFields>;
  nodes: Array<AssetClasses>;
};

export type AssetClassesAggregateBoolExp = {
  bool_and?: InputMaybe<AssetClassesAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<AssetClassesAggregateBoolExpBoolOr>;
  count?: InputMaybe<AssetClassesAggregateBoolExpCount>;
};

export type AssetClassesAggregateBoolExpBoolAnd = {
  arguments: AssetClassesSelectColumnAssetClassesAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetClassesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetClassesAggregateBoolExpBoolOr = {
  arguments: AssetClassesSelectColumnAssetClassesAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetClassesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetClassesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssetClassesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetClassesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "asset_classes" */
export type AssetClassesAggregateFields = {
  __typename?: 'asset_classes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AssetClassesMaxFields>;
  min?: Maybe<AssetClassesMinFields>;
};


/** aggregate fields of "asset_classes" */
export type AssetClassesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetClassesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset_classes" */
export type AssetClassesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetClassesMaxOrderBy>;
  min?: InputMaybe<AssetClassesMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_classes" */
export type AssetClassesArrRelInsertInput = {
  data: Array<AssetClassesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetClassesOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_classes". All fields are combined with a logical 'AND'. */
export type AssetClassesBoolExp = {
  _and?: InputMaybe<Array<AssetClassesBoolExp>>;
  _not?: InputMaybe<AssetClassesBoolExp>;
  _or?: InputMaybe<Array<AssetClassesBoolExp>>;
  asset_groups?: InputMaybe<AssetGroupsBoolExp>;
  asset_groups_aggregate?: InputMaybe<AssetGroupsAggregateBoolExp>;
  assets?: InputMaybe<AssetsBoolExp>;
  assets_aggregate?: InputMaybe<AssetsAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  enable?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_group?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  requires_approval?: InputMaybe<BooleanComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user_group?: InputMaybe<UserGroupsBoolExp>;
  user_group_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "asset_classes" */
export enum AssetClassesConstraint {
  /** unique or primary key constraint on columns "name", "user_group_id" */
  AssetClassesNameUserGroupIdKey = 'asset_classes_name_user_group_id_key',
  /** unique or primary key constraint on columns "id" */
  AssetClassesPkey = 'asset_classes_pkey'
}

/** input type for inserting data into table "asset_classes" */
export type AssetClassesInsertInput = {
  asset_groups?: InputMaybe<AssetGroupsArrRelInsertInput>;
  assets?: InputMaybe<AssetsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_group?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group?: InputMaybe<UserGroupsObjRelInsertInput>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AssetClassesMaxFields = {
  __typename?: 'asset_classes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "asset_classes" */
export type AssetClassesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssetClassesMinFields = {
  __typename?: 'asset_classes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "asset_classes" */
export type AssetClassesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "asset_classes" */
export type AssetClassesMutationResponse = {
  __typename?: 'asset_classes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssetClasses>;
};

/** input type for inserting object relation for remote table "asset_classes" */
export type AssetClassesObjRelInsertInput = {
  data: AssetClassesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetClassesOnConflict>;
};

/** on_conflict condition type for table "asset_classes" */
export type AssetClassesOnConflict = {
  constraint: AssetClassesConstraint;
  update_columns?: Array<AssetClassesUpdateColumn>;
  where?: InputMaybe<AssetClassesBoolExp>;
};

/** Ordering options when selecting data from "asset_classes". */
export type AssetClassesOrderBy = {
  asset_groups_aggregate?: InputMaybe<AssetGroupsAggregateOrderBy>;
  assets_aggregate?: InputMaybe<AssetsAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  enable?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_group?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  requires_approval?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group?: InputMaybe<UserGroupsOrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: asset_classes */
export type AssetClassesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "asset_classes" */
export enum AssetClassesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Enable = 'enable',
  /** column name */
  Id = 'id',
  /** column name */
  IsGroup = 'is_group',
  /** column name */
  Name = 'name',
  /** column name */
  RequiresApproval = 'requires_approval',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupId = 'user_group_id'
}

/** select "asset_classes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "asset_classes" */
export enum AssetClassesSelectColumnAssetClassesAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Enable = 'enable',
  /** column name */
  IsGroup = 'is_group',
  /** column name */
  RequiresApproval = 'requires_approval'
}

/** select "asset_classes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "asset_classes" */
export enum AssetClassesSelectColumnAssetClassesAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Enable = 'enable',
  /** column name */
  IsGroup = 'is_group',
  /** column name */
  RequiresApproval = 'requires_approval'
}

/** input type for updating data in table "asset_classes" */
export type AssetClassesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_group?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "asset_classes" */
export type AssetClassesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetClassesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetClassesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  enable?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_group?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  requires_approval?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "asset_classes" */
export enum AssetClassesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsGroup = 'is_group',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupId = 'user_group_id'
}

export type AssetClassesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssetClassesSetInput>;
  /** filter the rows which have to be updated */
  where: AssetClassesBoolExp;
};

/** columns and relationships of "asset_group_media" */
export type AssetGroupMedia = {
  __typename?: 'asset_group_media';
  /** An object relationship */
  asset_group: AssetGroups;
  asset_group_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  media: Media;
  media_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "asset_group_media" */
export type AssetGroupMediaAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetGroupMediaMaxOrderBy>;
  min?: InputMaybe<AssetGroupMediaMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_group_media" */
export type AssetGroupMediaArrRelInsertInput = {
  data: Array<AssetGroupMediaInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetGroupMediaOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_group_media". All fields are combined with a logical 'AND'. */
export type AssetGroupMediaBoolExp = {
  _and?: InputMaybe<Array<AssetGroupMediaBoolExp>>;
  _not?: InputMaybe<AssetGroupMediaBoolExp>;
  _or?: InputMaybe<Array<AssetGroupMediaBoolExp>>;
  asset_group?: InputMaybe<AssetGroupsBoolExp>;
  asset_group_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  media?: InputMaybe<MediaBoolExp>;
  media_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "asset_group_media" */
export enum AssetGroupMediaConstraint {
  /** unique or primary key constraint on columns "asset_group_id", "media_id" */
  AssetGroupMediaPkey = 'asset_group_media_pkey'
}

/** input type for inserting data into table "asset_group_media" */
export type AssetGroupMediaInsertInput = {
  asset_group?: InputMaybe<AssetGroupsObjRelInsertInput>;
  asset_group_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  media?: InputMaybe<MediaObjRelInsertInput>;
  media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "asset_group_media" */
export type AssetGroupMediaMaxOrderBy = {
  asset_group_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "asset_group_media" */
export type AssetGroupMediaMinOrderBy = {
  asset_group_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "asset_group_media" */
export type AssetGroupMediaMutationResponse = {
  __typename?: 'asset_group_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssetGroupMedia>;
};

/** on_conflict condition type for table "asset_group_media" */
export type AssetGroupMediaOnConflict = {
  constraint: AssetGroupMediaConstraint;
  update_columns?: Array<AssetGroupMediaUpdateColumn>;
  where?: InputMaybe<AssetGroupMediaBoolExp>;
};

/** Ordering options when selecting data from "asset_group_media". */
export type AssetGroupMediaOrderBy = {
  asset_group?: InputMaybe<AssetGroupsOrderBy>;
  asset_group_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  media?: InputMaybe<MediaOrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** select columns of table "asset_group_media" */
export enum AssetGroupMediaSelectColumn {
  /** column name */
  AssetGroupId = 'asset_group_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MediaId = 'media_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "asset_group_media" */
export type AssetGroupMediaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetGroupMediaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetGroupMediaStreamCursorValueInput = {
  asset_group_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** placeholder for update columns of table "asset_group_media" (current role has no relevant permissions) */
export enum AssetGroupMediaUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "asset_group_operating_hours" */
export type AssetGroupOperatingHours = {
  __typename?: 'asset_group_operating_hours';
  /** An object relationship */
  asset_group: AssetGroups;
  asset_group_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  day_of_week: Scalars['String'];
  hour_end: Scalars['timetz'];
  hour_start: Scalars['timetz'];
  id: Scalars['uuid'];
  operating_group_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "asset_group_operating_hours" */
export type AssetGroupOperatingHoursAggregate = {
  __typename?: 'asset_group_operating_hours_aggregate';
  aggregate?: Maybe<AssetGroupOperatingHoursAggregateFields>;
  nodes: Array<AssetGroupOperatingHours>;
};

export type AssetGroupOperatingHoursAggregateBoolExp = {
  count?: InputMaybe<AssetGroupOperatingHoursAggregateBoolExpCount>;
};

export type AssetGroupOperatingHoursAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssetGroupOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "asset_group_operating_hours" */
export type AssetGroupOperatingHoursAggregateFields = {
  __typename?: 'asset_group_operating_hours_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AssetGroupOperatingHoursMaxFields>;
  min?: Maybe<AssetGroupOperatingHoursMinFields>;
};


/** aggregate fields of "asset_group_operating_hours" */
export type AssetGroupOperatingHoursAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetGroupOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset_group_operating_hours" */
export type AssetGroupOperatingHoursAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetGroupOperatingHoursMaxOrderBy>;
  min?: InputMaybe<AssetGroupOperatingHoursMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_group_operating_hours" */
export type AssetGroupOperatingHoursArrRelInsertInput = {
  data: Array<AssetGroupOperatingHoursInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetGroupOperatingHoursOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_group_operating_hours". All fields are combined with a logical 'AND'. */
export type AssetGroupOperatingHoursBoolExp = {
  _and?: InputMaybe<Array<AssetGroupOperatingHoursBoolExp>>;
  _not?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
  _or?: InputMaybe<Array<AssetGroupOperatingHoursBoolExp>>;
  asset_group?: InputMaybe<AssetGroupsBoolExp>;
  asset_group_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  day_of_week?: InputMaybe<StringComparisonExp>;
  hour_end?: InputMaybe<TimetzComparisonExp>;
  hour_start?: InputMaybe<TimetzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  operating_group_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "asset_group_operating_hours" */
export enum AssetGroupOperatingHoursConstraint {
  /** unique or primary key constraint on columns "asset_group_id", "day_of_week" */
  AssetGroupOperatingHoursDayOfWeekAssetGroupIdKey = 'asset_group_operating_hours_day_of_week_asset_group_id_key',
  /** unique or primary key constraint on columns "id" */
  AssetGroupOperatingHoursPkey = 'asset_group_operating_hours_pkey'
}

/** input type for inserting data into table "asset_group_operating_hours" */
export type AssetGroupOperatingHoursInsertInput = {
  asset_group?: InputMaybe<AssetGroupsObjRelInsertInput>;
  asset_group_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  id?: InputMaybe<Scalars['uuid']>;
  operating_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AssetGroupOperatingHoursMaxFields = {
  __typename?: 'asset_group_operating_hours_max_fields';
  asset_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['uuid']>;
  operating_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "asset_group_operating_hours" */
export type AssetGroupOperatingHoursMaxOrderBy = {
  asset_group_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  operating_group_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssetGroupOperatingHoursMinFields = {
  __typename?: 'asset_group_operating_hours_min_fields';
  asset_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['uuid']>;
  operating_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "asset_group_operating_hours" */
export type AssetGroupOperatingHoursMinOrderBy = {
  asset_group_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  operating_group_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "asset_group_operating_hours" */
export type AssetGroupOperatingHoursMutationResponse = {
  __typename?: 'asset_group_operating_hours_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssetGroupOperatingHours>;
};

/** on_conflict condition type for table "asset_group_operating_hours" */
export type AssetGroupOperatingHoursOnConflict = {
  constraint: AssetGroupOperatingHoursConstraint;
  update_columns?: Array<AssetGroupOperatingHoursUpdateColumn>;
  where?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
};

/** Ordering options when selecting data from "asset_group_operating_hours". */
export type AssetGroupOperatingHoursOrderBy = {
  asset_group?: InputMaybe<AssetGroupsOrderBy>;
  asset_group_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  operating_group_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: asset_group_operating_hours */
export type AssetGroupOperatingHoursPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "asset_group_operating_hours" */
export enum AssetGroupOperatingHoursSelectColumn {
  /** column name */
  AssetGroupId = 'asset_group_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  Id = 'id',
  /** column name */
  OperatingGroupId = 'operating_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "asset_group_operating_hours" */
export type AssetGroupOperatingHoursSetInput = {
  asset_group_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  id?: InputMaybe<Scalars['uuid']>;
  operating_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "asset_group_operating_hours" */
export type AssetGroupOperatingHoursStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetGroupOperatingHoursStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetGroupOperatingHoursStreamCursorValueInput = {
  asset_group_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  id?: InputMaybe<Scalars['uuid']>;
  operating_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "asset_group_operating_hours" */
export enum AssetGroupOperatingHoursUpdateColumn {
  /** column name */
  AssetGroupId = 'asset_group_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  Id = 'id',
  /** column name */
  OperatingGroupId = 'operating_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type AssetGroupOperatingHoursUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssetGroupOperatingHoursSetInput>;
  /** filter the rows which have to be updated */
  where: AssetGroupOperatingHoursBoolExp;
};

/** columns and relationships of "asset_groups" */
export type AssetGroups = {
  __typename?: 'asset_groups';
  active: Scalars['Boolean'];
  /** An object relationship */
  asset_class: AssetClasses;
  asset_class_id: Scalars['uuid'];
  /** An object relationship */
  asset_group?: Maybe<AssetGroups>;
  /** An array relationship */
  asset_group_media: Array<AssetGroupMedia>;
  /** An array relationship */
  asset_group_operating_hours: Array<AssetGroupOperatingHours>;
  /** An aggregate relationship */
  asset_group_operating_hours_aggregate: AssetGroupOperatingHoursAggregate;
  /** An array relationship */
  asset_groups: Array<AssetGroups>;
  /** An aggregate relationship */
  asset_groups_aggregate: AssetGroupsAggregate;
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: AssetsAggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  display_name: Scalars['String'];
  /** An array relationship */
  flattened_asset_groups_by_child_id: Array<FlattenedAssetGroups>;
  /** An aggregate relationship */
  flattened_asset_groups_by_child_id_aggregate: FlattenedAssetGroupsAggregate;
  /** An array relationship */
  flattened_asset_groups_by_parent_id: Array<FlattenedAssetGroups>;
  /** An aggregate relationship */
  flattened_asset_groups_by_parent_id_aggregate: FlattenedAssetGroupsAggregate;
  floorplan_image_url?: Maybe<Scalars['citext']>;
  /** An object relationship */
  friday_operating_hour?: Maybe<FridayOperatingHours>;
  id: Scalars['uuid'];
  /** An object relationship */
  monday_operating_hour?: Maybe<MondayOperatingHours>;
  note?: Maybe<Scalars['String']>;
  optional_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  saturday_operating_hour?: Maybe<SaturdayOperatingHours>;
  /** An object relationship */
  sc_asset_group?: Maybe<ScAssetGroupsAnnex>;
  /** An object relationship */
  sunday_operating_hour?: Maybe<SundayOperatingHours>;
  thumbnail_image_url?: Maybe<Scalars['citext']>;
  /** An object relationship */
  thursday_operating_hour?: Maybe<ThursdayOperatingHours>;
  /** An object relationship */
  tuesday_operating_hour?: Maybe<TuesdayOperatingHours>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  wednesday_operating_hour?: Maybe<WednesdayOperatingHours>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsAssetGroupMediaArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupMediaOrderBy>>;
  where?: InputMaybe<AssetGroupMediaBoolExp>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsAssetGroupOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsAssetGroupOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsAssetGroupsArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupsOrderBy>>;
  where?: InputMaybe<AssetGroupsBoolExp>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsAssetGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupsOrderBy>>;
  where?: InputMaybe<AssetGroupsBoolExp>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsAssetsArgs = {
  distinct_on?: InputMaybe<Array<AssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetsOrderBy>>;
  where?: InputMaybe<AssetsBoolExp>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetsOrderBy>>;
  where?: InputMaybe<AssetsBoolExp>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsFlattenedAssetGroupsByChildIdArgs = {
  distinct_on?: InputMaybe<Array<FlattenedAssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedAssetGroupsOrderBy>>;
  where?: InputMaybe<FlattenedAssetGroupsBoolExp>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsFlattenedAssetGroupsByChildIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedAssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedAssetGroupsOrderBy>>;
  where?: InputMaybe<FlattenedAssetGroupsBoolExp>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsFlattenedAssetGroupsByParentIdArgs = {
  distinct_on?: InputMaybe<Array<FlattenedAssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedAssetGroupsOrderBy>>;
  where?: InputMaybe<FlattenedAssetGroupsBoolExp>;
};


/** columns and relationships of "asset_groups" */
export type AssetGroupsFlattenedAssetGroupsByParentIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedAssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedAssetGroupsOrderBy>>;
  where?: InputMaybe<FlattenedAssetGroupsBoolExp>;
};

/** aggregated selection of "asset_groups" */
export type AssetGroupsAggregate = {
  __typename?: 'asset_groups_aggregate';
  aggregate?: Maybe<AssetGroupsAggregateFields>;
  nodes: Array<AssetGroups>;
};

export type AssetGroupsAggregateBoolExp = {
  bool_and?: InputMaybe<AssetGroupsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<AssetGroupsAggregateBoolExpBoolOr>;
  count?: InputMaybe<AssetGroupsAggregateBoolExpCount>;
};

export type AssetGroupsAggregateBoolExpBoolAnd = {
  arguments: AssetGroupsSelectColumnAssetGroupsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetGroupsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetGroupsAggregateBoolExpBoolOr = {
  arguments: AssetGroupsSelectColumnAssetGroupsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetGroupsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetGroupsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssetGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetGroupsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "asset_groups" */
export type AssetGroupsAggregateFields = {
  __typename?: 'asset_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AssetGroupsMaxFields>;
  min?: Maybe<AssetGroupsMinFields>;
};


/** aggregate fields of "asset_groups" */
export type AssetGroupsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset_groups" */
export type AssetGroupsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetGroupsMaxOrderBy>;
  min?: InputMaybe<AssetGroupsMinOrderBy>;
};

/** Boolean expression to compare columns of type "asset_groups_annex_type_enum". All fields are combined with logical 'AND'. */
export type AssetGroupsAnnexTypeEnumComparisonExp = {
  _eq?: InputMaybe<Scalars['asset_groups_annex_type_enum']>;
  _gt?: InputMaybe<Scalars['asset_groups_annex_type_enum']>;
  _gte?: InputMaybe<Scalars['asset_groups_annex_type_enum']>;
  _in?: InputMaybe<Array<Scalars['asset_groups_annex_type_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['asset_groups_annex_type_enum']>;
  _lte?: InputMaybe<Scalars['asset_groups_annex_type_enum']>;
  _neq?: InputMaybe<Scalars['asset_groups_annex_type_enum']>;
  _nin?: InputMaybe<Array<Scalars['asset_groups_annex_type_enum']>>;
};

/** input type for inserting array relation for remote table "asset_groups" */
export type AssetGroupsArrRelInsertInput = {
  data: Array<AssetGroupsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetGroupsOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_groups". All fields are combined with a logical 'AND'. */
export type AssetGroupsBoolExp = {
  _and?: InputMaybe<Array<AssetGroupsBoolExp>>;
  _not?: InputMaybe<AssetGroupsBoolExp>;
  _or?: InputMaybe<Array<AssetGroupsBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  asset_class?: InputMaybe<AssetClassesBoolExp>;
  asset_class_id?: InputMaybe<UuidComparisonExp>;
  asset_group?: InputMaybe<AssetGroupsBoolExp>;
  asset_group_media?: InputMaybe<AssetGroupMediaBoolExp>;
  asset_group_operating_hours?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
  asset_group_operating_hours_aggregate?: InputMaybe<AssetGroupOperatingHoursAggregateBoolExp>;
  asset_groups?: InputMaybe<AssetGroupsBoolExp>;
  asset_groups_aggregate?: InputMaybe<AssetGroupsAggregateBoolExp>;
  assets?: InputMaybe<AssetsBoolExp>;
  assets_aggregate?: InputMaybe<AssetsAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  display_name?: InputMaybe<StringComparisonExp>;
  flattened_asset_groups_by_child_id?: InputMaybe<FlattenedAssetGroupsBoolExp>;
  flattened_asset_groups_by_child_id_aggregate?: InputMaybe<FlattenedAssetGroupsAggregateBoolExp>;
  flattened_asset_groups_by_parent_id?: InputMaybe<FlattenedAssetGroupsBoolExp>;
  flattened_asset_groups_by_parent_id_aggregate?: InputMaybe<FlattenedAssetGroupsAggregateBoolExp>;
  floorplan_image_url?: InputMaybe<CitextComparisonExp>;
  friday_operating_hour?: InputMaybe<FridayOperatingHoursBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  monday_operating_hour?: InputMaybe<MondayOperatingHoursBoolExp>;
  note?: InputMaybe<StringComparisonExp>;
  optional_name?: InputMaybe<StringComparisonExp>;
  parent_id?: InputMaybe<UuidComparisonExp>;
  saturday_operating_hour?: InputMaybe<SaturdayOperatingHoursBoolExp>;
  sc_asset_group?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
  sunday_operating_hour?: InputMaybe<SundayOperatingHoursBoolExp>;
  thumbnail_image_url?: InputMaybe<CitextComparisonExp>;
  thursday_operating_hour?: InputMaybe<ThursdayOperatingHoursBoolExp>;
  tuesday_operating_hour?: InputMaybe<TuesdayOperatingHoursBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  wednesday_operating_hour?: InputMaybe<WednesdayOperatingHoursBoolExp>;
};

/** unique or primary key constraints on table "asset_groups" */
export enum AssetGroupsConstraint {
  /** unique or primary key constraint on columns "id" */
  AssetGroupsPkey = 'asset_groups_pkey'
}

/** input type for inserting data into table "asset_groups" */
export type AssetGroupsInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  asset_class?: InputMaybe<AssetClassesObjRelInsertInput>;
  asset_class_id?: InputMaybe<Scalars['uuid']>;
  asset_group?: InputMaybe<AssetGroupsObjRelInsertInput>;
  asset_group_media?: InputMaybe<AssetGroupMediaArrRelInsertInput>;
  asset_group_operating_hours?: InputMaybe<AssetGroupOperatingHoursArrRelInsertInput>;
  asset_groups?: InputMaybe<AssetGroupsArrRelInsertInput>;
  assets?: InputMaybe<AssetsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  floorplan_image_url?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  optional_name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  sc_asset_group?: InputMaybe<ScAssetGroupsAnnexObjRelInsertInput>;
  thumbnail_image_url?: InputMaybe<Scalars['citext']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AssetGroupsMaxFields = {
  __typename?: 'asset_groups_max_fields';
  asset_class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  floorplan_image_url?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  optional_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  thumbnail_image_url?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "asset_groups" */
export type AssetGroupsMaxOrderBy = {
  asset_class_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  floorplan_image_url?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  optional_name?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  thumbnail_image_url?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssetGroupsMinFields = {
  __typename?: 'asset_groups_min_fields';
  asset_class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  floorplan_image_url?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  optional_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  thumbnail_image_url?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "asset_groups" */
export type AssetGroupsMinOrderBy = {
  asset_class_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  floorplan_image_url?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  optional_name?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  thumbnail_image_url?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "asset_groups" */
export type AssetGroupsMutationResponse = {
  __typename?: 'asset_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssetGroups>;
};

/** input type for inserting object relation for remote table "asset_groups" */
export type AssetGroupsObjRelInsertInput = {
  data: AssetGroupsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetGroupsOnConflict>;
};

/** on_conflict condition type for table "asset_groups" */
export type AssetGroupsOnConflict = {
  constraint: AssetGroupsConstraint;
  update_columns?: Array<AssetGroupsUpdateColumn>;
  where?: InputMaybe<AssetGroupsBoolExp>;
};

/** Ordering options when selecting data from "asset_groups". */
export type AssetGroupsOrderBy = {
  active?: InputMaybe<OrderBy>;
  asset_class?: InputMaybe<AssetClassesOrderBy>;
  asset_class_id?: InputMaybe<OrderBy>;
  asset_group?: InputMaybe<AssetGroupsOrderBy>;
  asset_group_media_aggregate?: InputMaybe<AssetGroupMediaAggregateOrderBy>;
  asset_group_operating_hours_aggregate?: InputMaybe<AssetGroupOperatingHoursAggregateOrderBy>;
  asset_groups_aggregate?: InputMaybe<AssetGroupsAggregateOrderBy>;
  assets_aggregate?: InputMaybe<AssetsAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  flattened_asset_groups_by_child_id_aggregate?: InputMaybe<FlattenedAssetGroupsAggregateOrderBy>;
  flattened_asset_groups_by_parent_id_aggregate?: InputMaybe<FlattenedAssetGroupsAggregateOrderBy>;
  floorplan_image_url?: InputMaybe<OrderBy>;
  friday_operating_hour?: InputMaybe<FridayOperatingHoursOrderBy>;
  id?: InputMaybe<OrderBy>;
  monday_operating_hour?: InputMaybe<MondayOperatingHoursOrderBy>;
  note?: InputMaybe<OrderBy>;
  optional_name?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  saturday_operating_hour?: InputMaybe<SaturdayOperatingHoursOrderBy>;
  sc_asset_group?: InputMaybe<ScAssetGroupsAnnexOrderBy>;
  sunday_operating_hour?: InputMaybe<SundayOperatingHoursOrderBy>;
  thumbnail_image_url?: InputMaybe<OrderBy>;
  thursday_operating_hour?: InputMaybe<ThursdayOperatingHoursOrderBy>;
  tuesday_operating_hour?: InputMaybe<TuesdayOperatingHoursOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  wednesday_operating_hour?: InputMaybe<WednesdayOperatingHoursOrderBy>;
};

/** primary key columns input for table: asset_groups */
export type AssetGroupsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "asset_groups" */
export enum AssetGroupsSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AssetClassId = 'asset_class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FloorplanImageUrl = 'floorplan_image_url',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  OptionalName = 'optional_name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ThumbnailImageUrl = 'thumbnail_image_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "asset_groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "asset_groups" */
export enum AssetGroupsSelectColumnAssetGroupsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Active = 'active'
}

/** select "asset_groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "asset_groups" */
export enum AssetGroupsSelectColumnAssetGroupsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "asset_groups" */
export type AssetGroupsSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  asset_class_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  floorplan_image_url?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  optional_name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  thumbnail_image_url?: InputMaybe<Scalars['citext']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "asset_groups" */
export type AssetGroupsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetGroupsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetGroupsStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  asset_class_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  floorplan_image_url?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  optional_name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  thumbnail_image_url?: InputMaybe<Scalars['citext']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "asset_groups" */
export enum AssetGroupsUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AssetClassId = 'asset_class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FloorplanImageUrl = 'floorplan_image_url',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  OptionalName = 'optional_name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ThumbnailImageUrl = 'thumbnail_image_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type AssetGroupsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssetGroupsSetInput>;
  /** filter the rows which have to be updated */
  where: AssetGroupsBoolExp;
};

/** columns and relationships of "asset_media" */
export type AssetMedia = {
  __typename?: 'asset_media';
  /** An object relationship */
  asset: Assets;
  asset_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  media: Media;
  media_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** order by aggregate values of table "asset_media" */
export type AssetMediaAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetMediaMaxOrderBy>;
  min?: InputMaybe<AssetMediaMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_media" */
export type AssetMediaArrRelInsertInput = {
  data: Array<AssetMediaInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetMediaOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_media". All fields are combined with a logical 'AND'. */
export type AssetMediaBoolExp = {
  _and?: InputMaybe<Array<AssetMediaBoolExp>>;
  _not?: InputMaybe<AssetMediaBoolExp>;
  _or?: InputMaybe<Array<AssetMediaBoolExp>>;
  asset?: InputMaybe<AssetsBoolExp>;
  asset_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  media?: InputMaybe<MediaBoolExp>;
  media_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "asset_media" */
export enum AssetMediaConstraint {
  /** unique or primary key constraint on columns "asset_id", "media_id" */
  AssetMediaPkey = 'asset_media_pkey'
}

/** input type for inserting data into table "asset_media" */
export type AssetMediaInsertInput = {
  asset?: InputMaybe<AssetsObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  media?: InputMaybe<MediaObjRelInsertInput>;
  media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "asset_media" */
export type AssetMediaMaxOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "asset_media" */
export type AssetMediaMinOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "asset_media" */
export type AssetMediaMutationResponse = {
  __typename?: 'asset_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssetMedia>;
};

/** on_conflict condition type for table "asset_media" */
export type AssetMediaOnConflict = {
  constraint: AssetMediaConstraint;
  update_columns?: Array<AssetMediaUpdateColumn>;
  where?: InputMaybe<AssetMediaBoolExp>;
};

/** Ordering options when selecting data from "asset_media". */
export type AssetMediaOrderBy = {
  asset?: InputMaybe<AssetsOrderBy>;
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  media?: InputMaybe<MediaOrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** select columns of table "asset_media" */
export enum AssetMediaSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MediaId = 'media_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "asset_media" */
export type AssetMediaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetMediaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetMediaStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** placeholder for update columns of table "asset_media" (current role has no relevant permissions) */
export enum AssetMediaUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "asset_operating_hours" */
export type AssetOperatingHours = {
  __typename?: 'asset_operating_hours';
  /** An object relationship */
  asset: Assets;
  asset_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  day_of_week: Scalars['String'];
  hour_end: Scalars['timetz'];
  hour_start: Scalars['timetz'];
  id: Scalars['uuid'];
  operating_group_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "asset_operating_hours" */
export type AssetOperatingHoursAggregate = {
  __typename?: 'asset_operating_hours_aggregate';
  aggregate?: Maybe<AssetOperatingHoursAggregateFields>;
  nodes: Array<AssetOperatingHours>;
};

export type AssetOperatingHoursAggregateBoolExp = {
  count?: InputMaybe<AssetOperatingHoursAggregateBoolExpCount>;
};

export type AssetOperatingHoursAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssetOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetOperatingHoursBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "asset_operating_hours" */
export type AssetOperatingHoursAggregateFields = {
  __typename?: 'asset_operating_hours_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AssetOperatingHoursMaxFields>;
  min?: Maybe<AssetOperatingHoursMinFields>;
};


/** aggregate fields of "asset_operating_hours" */
export type AssetOperatingHoursAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset_operating_hours" */
export type AssetOperatingHoursAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetOperatingHoursMaxOrderBy>;
  min?: InputMaybe<AssetOperatingHoursMinOrderBy>;
};

/** input type for inserting array relation for remote table "asset_operating_hours" */
export type AssetOperatingHoursArrRelInsertInput = {
  data: Array<AssetOperatingHoursInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetOperatingHoursOnConflict>;
};

/** Boolean expression to filter rows from the table "asset_operating_hours". All fields are combined with a logical 'AND'. */
export type AssetOperatingHoursBoolExp = {
  _and?: InputMaybe<Array<AssetOperatingHoursBoolExp>>;
  _not?: InputMaybe<AssetOperatingHoursBoolExp>;
  _or?: InputMaybe<Array<AssetOperatingHoursBoolExp>>;
  asset?: InputMaybe<AssetsBoolExp>;
  asset_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  day_of_week?: InputMaybe<StringComparisonExp>;
  hour_end?: InputMaybe<TimetzComparisonExp>;
  hour_start?: InputMaybe<TimetzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  operating_group_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "asset_operating_hours" */
export enum AssetOperatingHoursConstraint {
  /** unique or primary key constraint on columns "asset_id", "day_of_week" */
  AssetOperatingHoursDayOfWeekAssetIdKey = 'asset_operating_hours_day_of_week_asset_id_key',
  /** unique or primary key constraint on columns "id" */
  AssetOperatingHoursPkey = 'asset_operating_hours_pkey'
}

/** input type for inserting data into table "asset_operating_hours" */
export type AssetOperatingHoursInsertInput = {
  asset?: InputMaybe<AssetsObjRelInsertInput>;
  asset_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  id?: InputMaybe<Scalars['uuid']>;
  operating_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AssetOperatingHoursMaxFields = {
  __typename?: 'asset_operating_hours_max_fields';
  asset_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['uuid']>;
  operating_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "asset_operating_hours" */
export type AssetOperatingHoursMaxOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  operating_group_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssetOperatingHoursMinFields = {
  __typename?: 'asset_operating_hours_min_fields';
  asset_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['uuid']>;
  operating_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "asset_operating_hours" */
export type AssetOperatingHoursMinOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  operating_group_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "asset_operating_hours" */
export type AssetOperatingHoursMutationResponse = {
  __typename?: 'asset_operating_hours_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssetOperatingHours>;
};

/** on_conflict condition type for table "asset_operating_hours" */
export type AssetOperatingHoursOnConflict = {
  constraint: AssetOperatingHoursConstraint;
  update_columns?: Array<AssetOperatingHoursUpdateColumn>;
  where?: InputMaybe<AssetOperatingHoursBoolExp>;
};

/** Ordering options when selecting data from "asset_operating_hours". */
export type AssetOperatingHoursOrderBy = {
  asset?: InputMaybe<AssetsOrderBy>;
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  operating_group_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: asset_operating_hours */
export type AssetOperatingHoursPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "asset_operating_hours" */
export enum AssetOperatingHoursSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  Id = 'id',
  /** column name */
  OperatingGroupId = 'operating_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "asset_operating_hours" */
export type AssetOperatingHoursSetInput = {
  asset_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  id?: InputMaybe<Scalars['uuid']>;
  operating_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "asset_operating_hours" */
export type AssetOperatingHoursStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetOperatingHoursStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetOperatingHoursStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  id?: InputMaybe<Scalars['uuid']>;
  operating_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "asset_operating_hours" */
export enum AssetOperatingHoursUpdateColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  Id = 'id',
  /** column name */
  OperatingGroupId = 'operating_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type AssetOperatingHoursUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssetOperatingHoursSetInput>;
  /** filter the rows which have to be updated */
  where: AssetOperatingHoursBoolExp;
};

/** columns and relationships of "asset_unavailabilities" */
export type AssetUnavailabilities = {
  __typename?: 'asset_unavailabilities';
  /** An object relationship */
  asset: Assets;
  asset_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  date_end: Scalars['timestamptz'];
  date_start: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  reason: Scalars['String'];
  status: EnumAssetStatusesEnum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "asset_unavailabilities" */
export type AssetUnavailabilitiesAggregate = {
  __typename?: 'asset_unavailabilities_aggregate';
  aggregate?: Maybe<AssetUnavailabilitiesAggregateFields>;
  nodes: Array<AssetUnavailabilities>;
};

export type AssetUnavailabilitiesAggregateBoolExp = {
  count?: InputMaybe<AssetUnavailabilitiesAggregateBoolExpCount>;
};

export type AssetUnavailabilitiesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssetUnavailabilitiesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetUnavailabilitiesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "asset_unavailabilities" */
export type AssetUnavailabilitiesAggregateFields = {
  __typename?: 'asset_unavailabilities_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AssetUnavailabilitiesMaxFields>;
  min?: Maybe<AssetUnavailabilitiesMinFields>;
};


/** aggregate fields of "asset_unavailabilities" */
export type AssetUnavailabilitiesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetUnavailabilitiesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset_unavailabilities" */
export type AssetUnavailabilitiesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetUnavailabilitiesMaxOrderBy>;
  min?: InputMaybe<AssetUnavailabilitiesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "asset_unavailabilities". All fields are combined with a logical 'AND'. */
export type AssetUnavailabilitiesBoolExp = {
  _and?: InputMaybe<Array<AssetUnavailabilitiesBoolExp>>;
  _not?: InputMaybe<AssetUnavailabilitiesBoolExp>;
  _or?: InputMaybe<Array<AssetUnavailabilitiesBoolExp>>;
  asset?: InputMaybe<AssetsBoolExp>;
  asset_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  date_end?: InputMaybe<TimestamptzComparisonExp>;
  date_start?: InputMaybe<TimestamptzComparisonExp>;
  deleted_at?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  reason?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<EnumAssetStatusesEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type AssetUnavailabilitiesMaxFields = {
  __typename?: 'asset_unavailabilities_max_fields';
  asset_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_end?: Maybe<Scalars['timestamptz']>;
  date_start?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "asset_unavailabilities" */
export type AssetUnavailabilitiesMaxOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  date_end?: InputMaybe<OrderBy>;
  date_start?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssetUnavailabilitiesMinFields = {
  __typename?: 'asset_unavailabilities_min_fields';
  asset_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_end?: Maybe<Scalars['timestamptz']>;
  date_start?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "asset_unavailabilities" */
export type AssetUnavailabilitiesMinOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  date_end?: InputMaybe<OrderBy>;
  date_start?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "asset_unavailabilities". */
export type AssetUnavailabilitiesOrderBy = {
  asset?: InputMaybe<AssetsOrderBy>;
  asset_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  date_end?: InputMaybe<OrderBy>;
  date_start?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reason?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** select columns of table "asset_unavailabilities" */
export enum AssetUnavailabilitiesSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateEnd = 'date_end',
  /** column name */
  DateStart = 'date_start',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "asset_unavailabilities" */
export type AssetUnavailabilitiesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetUnavailabilitiesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetUnavailabilitiesStreamCursorValueInput = {
  asset_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_end?: InputMaybe<Scalars['timestamptz']>;
  date_start?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EnumAssetStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "assets" */
export type Assets = {
  __typename?: 'assets';
  active: Scalars['Boolean'];
  /** An array relationship */
  asset_amenities: Array<AssetAmenities>;
  /** An aggregate relationship */
  asset_amenities_aggregate: AssetAmenitiesAggregate;
  /** An array relationship */
  asset_bookings: Array<AssetBookings>;
  /** An aggregate relationship */
  asset_bookings_aggregate: AssetBookingsAggregate;
  /** An object relationship */
  asset_class: AssetClasses;
  asset_class_id: Scalars['uuid'];
  /** An object relationship */
  asset_group: AssetGroups;
  asset_group_id: Scalars['uuid'];
  /** An array relationship */
  asset_media: Array<AssetMedia>;
  /** An array relationship */
  asset_operating_hours: Array<AssetOperatingHours>;
  /** An aggregate relationship */
  asset_operating_hours_aggregate: AssetOperatingHoursAggregate;
  /** An array relationship */
  asset_unavailabilities: Array<AssetUnavailabilities>;
  /** An aggregate relationship */
  asset_unavailabilities_aggregate: AssetUnavailabilitiesAggregate;
  /** A computed field, executes function "asset_available_at" */
  available_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  display_name: Scalars['String'];
  /** An object relationship */
  friday_operating_hour?: Maybe<FridayOperatingHours>;
  id: Scalars['uuid'];
  maximum_capacity: Scalars['Int'];
  /** An object relationship */
  monday_operating_hour?: Maybe<MondayOperatingHours>;
  note?: Maybe<Scalars['String']>;
  optional_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "remaining_days" */
  remaining_days?: Maybe<Scalars['Int']>;
  /** An object relationship */
  saturday_operating_hour?: Maybe<SaturdayOperatingHours>;
  /** An object relationship */
  sc_asset?: Maybe<ScAssets>;
  /** An object relationship */
  sunday_operating_hour?: Maybe<SundayOperatingHours>;
  thumbnail_image_url?: Maybe<Scalars['citext']>;
  /** An object relationship */
  thursday_operating_hour?: Maybe<ThursdayOperatingHours>;
  /** An object relationship */
  tuesday_operating_hour?: Maybe<TuesdayOperatingHours>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  wednesday_operating_hour?: Maybe<WednesdayOperatingHours>;
};


/** columns and relationships of "assets" */
export type AssetsAssetAmenitiesArgs = {
  distinct_on?: InputMaybe<Array<AssetAmenitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetAmenitiesOrderBy>>;
  where?: InputMaybe<AssetAmenitiesBoolExp>;
};


/** columns and relationships of "assets" */
export type AssetsAssetAmenitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetAmenitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetAmenitiesOrderBy>>;
  where?: InputMaybe<AssetAmenitiesBoolExp>;
};


/** columns and relationships of "assets" */
export type AssetsAssetBookingsArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


/** columns and relationships of "assets" */
export type AssetsAssetBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


/** columns and relationships of "assets" */
export type AssetsAssetMediaArgs = {
  distinct_on?: InputMaybe<Array<AssetMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetMediaOrderBy>>;
  where?: InputMaybe<AssetMediaBoolExp>;
};


/** columns and relationships of "assets" */
export type AssetsAssetOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<AssetOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetOperatingHoursBoolExp>;
};


/** columns and relationships of "assets" */
export type AssetsAssetOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetOperatingHoursBoolExp>;
};


/** columns and relationships of "assets" */
export type AssetsAssetUnavailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<AssetUnavailabilitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetUnavailabilitiesOrderBy>>;
  where?: InputMaybe<AssetUnavailabilitiesBoolExp>;
};


/** columns and relationships of "assets" */
export type AssetsAssetUnavailabilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetUnavailabilitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetUnavailabilitiesOrderBy>>;
  where?: InputMaybe<AssetUnavailabilitiesBoolExp>;
};


/** columns and relationships of "assets" */
export type AssetsRemainingDaysArgs = {
  args: RemainingDaysAssetsArgs;
};

/** aggregated selection of "assets" */
export type AssetsAggregate = {
  __typename?: 'assets_aggregate';
  aggregate?: Maybe<AssetsAggregateFields>;
  nodes: Array<Assets>;
};

export type AssetsAggregateBoolExp = {
  bool_and?: InputMaybe<AssetsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<AssetsAggregateBoolExpBoolOr>;
  count?: InputMaybe<AssetsAggregateBoolExpCount>;
};

export type AssetsAggregateBoolExpBoolAnd = {
  arguments: AssetsSelectColumnAssetsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetsAggregateBoolExpBoolOr = {
  arguments: AssetsSelectColumnAssetsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AssetsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssetsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssetsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "assets" */
export type AssetsAggregateFields = {
  __typename?: 'assets_aggregate_fields';
  avg?: Maybe<AssetsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AssetsMaxFields>;
  min?: Maybe<AssetsMinFields>;
  stddev?: Maybe<AssetsStddevFields>;
  stddev_pop?: Maybe<AssetsStddevPopFields>;
  stddev_samp?: Maybe<AssetsStddevSampFields>;
  sum?: Maybe<AssetsSumFields>;
  var_pop?: Maybe<AssetsVarPopFields>;
  var_samp?: Maybe<AssetsVarSampFields>;
  variance?: Maybe<AssetsVarianceFields>;
};


/** aggregate fields of "assets" */
export type AssetsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssetsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assets" */
export type AssetsAggregateOrderBy = {
  avg?: InputMaybe<AssetsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssetsMaxOrderBy>;
  min?: InputMaybe<AssetsMinOrderBy>;
  stddev?: InputMaybe<AssetsStddevOrderBy>;
  stddev_pop?: InputMaybe<AssetsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<AssetsStddevSampOrderBy>;
  sum?: InputMaybe<AssetsSumOrderBy>;
  var_pop?: InputMaybe<AssetsVarPopOrderBy>;
  var_samp?: InputMaybe<AssetsVarSampOrderBy>;
  variance?: InputMaybe<AssetsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "assets" */
export type AssetsArrRelInsertInput = {
  data: Array<AssetsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetsOnConflict>;
};

/** aggregate avg on columns */
export type AssetsAvgFields = {
  __typename?: 'assets_avg_fields';
  maximum_capacity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assets" */
export type AssetsAvgOrderBy = {
  maximum_capacity?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "assets". All fields are combined with a logical 'AND'. */
export type AssetsBoolExp = {
  _and?: InputMaybe<Array<AssetsBoolExp>>;
  _not?: InputMaybe<AssetsBoolExp>;
  _or?: InputMaybe<Array<AssetsBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  asset_amenities?: InputMaybe<AssetAmenitiesBoolExp>;
  asset_amenities_aggregate?: InputMaybe<AssetAmenitiesAggregateBoolExp>;
  asset_bookings?: InputMaybe<AssetBookingsBoolExp>;
  asset_bookings_aggregate?: InputMaybe<AssetBookingsAggregateBoolExp>;
  asset_class?: InputMaybe<AssetClassesBoolExp>;
  asset_class_id?: InputMaybe<UuidComparisonExp>;
  asset_group?: InputMaybe<AssetGroupsBoolExp>;
  asset_group_id?: InputMaybe<UuidComparisonExp>;
  asset_media?: InputMaybe<AssetMediaBoolExp>;
  asset_operating_hours?: InputMaybe<AssetOperatingHoursBoolExp>;
  asset_operating_hours_aggregate?: InputMaybe<AssetOperatingHoursAggregateBoolExp>;
  asset_unavailabilities?: InputMaybe<AssetUnavailabilitiesBoolExp>;
  asset_unavailabilities_aggregate?: InputMaybe<AssetUnavailabilitiesAggregateBoolExp>;
  available_at?: InputMaybe<TimestamptzComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  display_name?: InputMaybe<StringComparisonExp>;
  friday_operating_hour?: InputMaybe<FridayOperatingHoursBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  maximum_capacity?: InputMaybe<IntComparisonExp>;
  monday_operating_hour?: InputMaybe<MondayOperatingHoursBoolExp>;
  note?: InputMaybe<StringComparisonExp>;
  optional_name?: InputMaybe<StringComparisonExp>;
  saturday_operating_hour?: InputMaybe<SaturdayOperatingHoursBoolExp>;
  sc_asset?: InputMaybe<ScAssetsBoolExp>;
  sunday_operating_hour?: InputMaybe<SundayOperatingHoursBoolExp>;
  thumbnail_image_url?: InputMaybe<CitextComparisonExp>;
  thursday_operating_hour?: InputMaybe<ThursdayOperatingHoursBoolExp>;
  tuesday_operating_hour?: InputMaybe<TuesdayOperatingHoursBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  wednesday_operating_hour?: InputMaybe<WednesdayOperatingHoursBoolExp>;
};

/** unique or primary key constraints on table "assets" */
export enum AssetsConstraint {
  /** unique or primary key constraint on columns "id" */
  AssetsPkey = 'assets_pkey'
}

/** input type for incrementing numeric columns in table "assets" */
export type AssetsIncInput = {
  maximum_capacity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "assets" */
export type AssetsInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  asset_amenities?: InputMaybe<AssetAmenitiesArrRelInsertInput>;
  asset_bookings?: InputMaybe<AssetBookingsArrRelInsertInput>;
  asset_class?: InputMaybe<AssetClassesObjRelInsertInput>;
  asset_class_id?: InputMaybe<Scalars['uuid']>;
  asset_group?: InputMaybe<AssetGroupsObjRelInsertInput>;
  asset_group_id?: InputMaybe<Scalars['uuid']>;
  asset_media?: InputMaybe<AssetMediaArrRelInsertInput>;
  asset_operating_hours?: InputMaybe<AssetOperatingHoursArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  maximum_capacity?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  optional_name?: InputMaybe<Scalars['String']>;
  thumbnail_image_url?: InputMaybe<Scalars['citext']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AssetsMaxFields = {
  __typename?: 'assets_max_fields';
  asset_class_id?: Maybe<Scalars['uuid']>;
  asset_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  maximum_capacity?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  optional_name?: Maybe<Scalars['String']>;
  thumbnail_image_url?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "assets" */
export type AssetsMaxOrderBy = {
  asset_class_id?: InputMaybe<OrderBy>;
  asset_group_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maximum_capacity?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  optional_name?: InputMaybe<OrderBy>;
  thumbnail_image_url?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssetsMinFields = {
  __typename?: 'assets_min_fields';
  asset_class_id?: Maybe<Scalars['uuid']>;
  asset_group_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  maximum_capacity?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  optional_name?: Maybe<Scalars['String']>;
  thumbnail_image_url?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "assets" */
export type AssetsMinOrderBy = {
  asset_class_id?: InputMaybe<OrderBy>;
  asset_group_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maximum_capacity?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  optional_name?: InputMaybe<OrderBy>;
  thumbnail_image_url?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "assets" */
export type AssetsMutationResponse = {
  __typename?: 'assets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assets>;
};

/** input type for inserting object relation for remote table "assets" */
export type AssetsObjRelInsertInput = {
  data: AssetsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AssetsOnConflict>;
};

/** on_conflict condition type for table "assets" */
export type AssetsOnConflict = {
  constraint: AssetsConstraint;
  update_columns?: Array<AssetsUpdateColumn>;
  where?: InputMaybe<AssetsBoolExp>;
};

/** Ordering options when selecting data from "assets". */
export type AssetsOrderBy = {
  active?: InputMaybe<OrderBy>;
  asset_amenities_aggregate?: InputMaybe<AssetAmenitiesAggregateOrderBy>;
  asset_bookings_aggregate?: InputMaybe<AssetBookingsAggregateOrderBy>;
  asset_class?: InputMaybe<AssetClassesOrderBy>;
  asset_class_id?: InputMaybe<OrderBy>;
  asset_group?: InputMaybe<AssetGroupsOrderBy>;
  asset_group_id?: InputMaybe<OrderBy>;
  asset_media_aggregate?: InputMaybe<AssetMediaAggregateOrderBy>;
  asset_operating_hours_aggregate?: InputMaybe<AssetOperatingHoursAggregateOrderBy>;
  asset_unavailabilities_aggregate?: InputMaybe<AssetUnavailabilitiesAggregateOrderBy>;
  available_at?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  friday_operating_hour?: InputMaybe<FridayOperatingHoursOrderBy>;
  id?: InputMaybe<OrderBy>;
  maximum_capacity?: InputMaybe<OrderBy>;
  monday_operating_hour?: InputMaybe<MondayOperatingHoursOrderBy>;
  note?: InputMaybe<OrderBy>;
  optional_name?: InputMaybe<OrderBy>;
  saturday_operating_hour?: InputMaybe<SaturdayOperatingHoursOrderBy>;
  sc_asset?: InputMaybe<ScAssetsOrderBy>;
  sunday_operating_hour?: InputMaybe<SundayOperatingHoursOrderBy>;
  thumbnail_image_url?: InputMaybe<OrderBy>;
  thursday_operating_hour?: InputMaybe<ThursdayOperatingHoursOrderBy>;
  tuesday_operating_hour?: InputMaybe<TuesdayOperatingHoursOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  wednesday_operating_hour?: InputMaybe<WednesdayOperatingHoursOrderBy>;
};

/** primary key columns input for table: assets */
export type AssetsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "assets_rental_type_enum". All fields are combined with logical 'AND'. */
export type AssetsRentalTypeEnumComparisonExp = {
  _eq?: InputMaybe<Scalars['assets_rental_type_enum']>;
  _gt?: InputMaybe<Scalars['assets_rental_type_enum']>;
  _gte?: InputMaybe<Scalars['assets_rental_type_enum']>;
  _in?: InputMaybe<Array<Scalars['assets_rental_type_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['assets_rental_type_enum']>;
  _lte?: InputMaybe<Scalars['assets_rental_type_enum']>;
  _neq?: InputMaybe<Scalars['assets_rental_type_enum']>;
  _nin?: InputMaybe<Array<Scalars['assets_rental_type_enum']>>;
};

/** select columns of table "assets" */
export enum AssetsSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AssetClassId = 'asset_class_id',
  /** column name */
  AssetGroupId = 'asset_group_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  MaximumCapacity = 'maximum_capacity',
  /** column name */
  Note = 'note',
  /** column name */
  OptionalName = 'optional_name',
  /** column name */
  ThumbnailImageUrl = 'thumbnail_image_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "assets_aggregate_bool_exp_bool_and_arguments_columns" columns of table "assets" */
export enum AssetsSelectColumnAssetsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Active = 'active'
}

/** select "assets_aggregate_bool_exp_bool_or_arguments_columns" columns of table "assets" */
export enum AssetsSelectColumnAssetsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "assets" */
export type AssetsSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  asset_class_id?: InputMaybe<Scalars['uuid']>;
  asset_group_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  maximum_capacity?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  optional_name?: InputMaybe<Scalars['String']>;
  thumbnail_image_url?: InputMaybe<Scalars['citext']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type AssetsStddevFields = {
  __typename?: 'assets_stddev_fields';
  maximum_capacity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assets" */
export type AssetsStddevOrderBy = {
  maximum_capacity?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AssetsStddevPopFields = {
  __typename?: 'assets_stddev_pop_fields';
  maximum_capacity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assets" */
export type AssetsStddevPopOrderBy = {
  maximum_capacity?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AssetsStddevSampFields = {
  __typename?: 'assets_stddev_samp_fields';
  maximum_capacity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assets" */
export type AssetsStddevSampOrderBy = {
  maximum_capacity?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "assets" */
export type AssetsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssetsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssetsStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  asset_class_id?: InputMaybe<Scalars['uuid']>;
  asset_group_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  maximum_capacity?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  optional_name?: InputMaybe<Scalars['String']>;
  thumbnail_image_url?: InputMaybe<Scalars['citext']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type AssetsSumFields = {
  __typename?: 'assets_sum_fields';
  maximum_capacity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "assets" */
export type AssetsSumOrderBy = {
  maximum_capacity?: InputMaybe<OrderBy>;
};

/** update columns of table "assets" */
export enum AssetsUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AssetClassId = 'asset_class_id',
  /** column name */
  AssetGroupId = 'asset_group_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  MaximumCapacity = 'maximum_capacity',
  /** column name */
  Note = 'note',
  /** column name */
  OptionalName = 'optional_name',
  /** column name */
  ThumbnailImageUrl = 'thumbnail_image_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type AssetsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AssetsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssetsSetInput>;
  /** filter the rows which have to be updated */
  where: AssetsBoolExp;
};

/** Boolean expression to compare columns of type "assets_utility_bill_period_type_enum". All fields are combined with logical 'AND'. */
export type AssetsUtilityBillPeriodTypeEnumComparisonExp = {
  _eq?: InputMaybe<Scalars['assets_utility_bill_period_type_enum']>;
  _gt?: InputMaybe<Scalars['assets_utility_bill_period_type_enum']>;
  _gte?: InputMaybe<Scalars['assets_utility_bill_period_type_enum']>;
  _in?: InputMaybe<Array<Scalars['assets_utility_bill_period_type_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['assets_utility_bill_period_type_enum']>;
  _lte?: InputMaybe<Scalars['assets_utility_bill_period_type_enum']>;
  _neq?: InputMaybe<Scalars['assets_utility_bill_period_type_enum']>;
  _nin?: InputMaybe<Array<Scalars['assets_utility_bill_period_type_enum']>>;
};

/** aggregate var_pop on columns */
export type AssetsVarPopFields = {
  __typename?: 'assets_var_pop_fields';
  maximum_capacity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assets" */
export type AssetsVarPopOrderBy = {
  maximum_capacity?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AssetsVarSampFields = {
  __typename?: 'assets_var_samp_fields';
  maximum_capacity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assets" */
export type AssetsVarSampOrderBy = {
  maximum_capacity?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AssetsVarianceFields = {
  __typename?: 'assets_variance_fields';
  maximum_capacity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assets" */
export type AssetsVarianceOrderBy = {
  maximum_capacity?: InputMaybe<OrderBy>;
};

/** columns and relationships of "bookers" */
export type Bookers = {
  __typename?: 'bookers';
  /** An object relationship */
  asset_booking?: Maybe<AssetBookings>;
  asset_booking_id?: Maybe<Scalars['uuid']>;
  avatar_url?: Maybe<Scalars['citext']>;
  email?: Maybe<Scalars['citext']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
};

/** aggregated selection of "bookers" */
export type BookersAggregate = {
  __typename?: 'bookers_aggregate';
  aggregate?: Maybe<BookersAggregateFields>;
  nodes: Array<Bookers>;
};

/** aggregate fields of "bookers" */
export type BookersAggregateFields = {
  __typename?: 'bookers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<BookersMaxFields>;
  min?: Maybe<BookersMinFields>;
};


/** aggregate fields of "bookers" */
export type BookersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BookersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bookers". All fields are combined with a logical 'AND'. */
export type BookersBoolExp = {
  _and?: InputMaybe<Array<BookersBoolExp>>;
  _not?: InputMaybe<BookersBoolExp>;
  _or?: InputMaybe<Array<BookersBoolExp>>;
  asset_booking?: InputMaybe<AssetBookingsBoolExp>;
  asset_booking_id?: InputMaybe<UuidComparisonExp>;
  avatar_url?: InputMaybe<CitextComparisonExp>;
  email?: InputMaybe<CitextComparisonExp>;
  first_name?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  last_name?: InputMaybe<StringComparisonExp>;
  salutation?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type BookersMaxFields = {
  __typename?: 'bookers_max_fields';
  asset_booking_id?: Maybe<Scalars['uuid']>;
  avatar_url?: Maybe<Scalars['citext']>;
  email?: Maybe<Scalars['citext']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type BookersMinFields = {
  __typename?: 'bookers_min_fields';
  asset_booking_id?: Maybe<Scalars['uuid']>;
  avatar_url?: Maybe<Scalars['citext']>;
  email?: Maybe<Scalars['citext']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "bookers". */
export type BookersOrderBy = {
  asset_booking?: InputMaybe<AssetBookingsOrderBy>;
  asset_booking_id?: InputMaybe<OrderBy>;
  avatar_url?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  first_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  last_name?: InputMaybe<OrderBy>;
  salutation?: InputMaybe<OrderBy>;
};

/** select columns of table "bookers" */
export enum BookersSelectColumn {
  /** column name */
  AssetBookingId = 'asset_booking_id',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Salutation = 'salutation'
}

/** Streaming cursor of the table "bookers" */
export type BookersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: BookersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BookersStreamCursorValueInput = {
  asset_booking_id?: InputMaybe<Scalars['uuid']>;
  avatar_url?: InputMaybe<Scalars['citext']>;
  email?: InputMaybe<Scalars['citext']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "bookings_shop_type_enum". All fields are combined with logical 'AND'. */
export type BookingsShopTypeEnumComparisonExp = {
  _eq?: InputMaybe<Scalars['bookings_shop_type_enum']>;
  _gt?: InputMaybe<Scalars['bookings_shop_type_enum']>;
  _gte?: InputMaybe<Scalars['bookings_shop_type_enum']>;
  _in?: InputMaybe<Array<Scalars['bookings_shop_type_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bookings_shop_type_enum']>;
  _lte?: InputMaybe<Scalars['bookings_shop_type_enum']>;
  _neq?: InputMaybe<Scalars['bookings_shop_type_enum']>;
  _nin?: InputMaybe<Array<Scalars['bookings_shop_type_enum']>>;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type CitextComparisonExp = {
  _eq?: InputMaybe<Scalars['citext']>;
  _gt?: InputMaybe<Scalars['citext']>;
  _gte?: InputMaybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']>;
  _in?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']>;
  _lt?: InputMaybe<Scalars['citext']>;
  _lte?: InputMaybe<Scalars['citext']>;
  _neq?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']>;
  _nin?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']>;
};

/** columns and relationships of "consents" */
export type Consents = {
  __typename?: 'consents';
  /** An array relationship */
  consents: Array<UserConsents>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_group: UserGroups;
};


/** columns and relationships of "consents" */
export type ConsentsConsentsArgs = {
  distinct_on?: InputMaybe<Array<UserConsentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserConsentsOrderBy>>;
  where?: InputMaybe<UserConsentsBoolExp>;
};

/** aggregated selection of "consents" */
export type ConsentsAggregate = {
  __typename?: 'consents_aggregate';
  aggregate?: Maybe<ConsentsAggregateFields>;
  nodes: Array<Consents>;
};

export type ConsentsAggregateBoolExp = {
  count?: InputMaybe<ConsentsAggregateBoolExpCount>;
};

export type ConsentsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ConsentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ConsentsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "consents" */
export type ConsentsAggregateFields = {
  __typename?: 'consents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ConsentsMaxFields>;
  min?: Maybe<ConsentsMinFields>;
};


/** aggregate fields of "consents" */
export type ConsentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConsentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "consents" */
export type ConsentsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ConsentsMaxOrderBy>;
  min?: InputMaybe<ConsentsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "consents". All fields are combined with a logical 'AND'. */
export type ConsentsBoolExp = {
  _and?: InputMaybe<Array<ConsentsBoolExp>>;
  _not?: InputMaybe<ConsentsBoolExp>;
  _or?: InputMaybe<Array<ConsentsBoolExp>>;
  consents?: InputMaybe<UserConsentsBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user_group?: InputMaybe<UserGroupsBoolExp>;
};

/** aggregate max on columns */
export type ConsentsMaxFields = {
  __typename?: 'consents_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "consents" */
export type ConsentsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ConsentsMinFields = {
  __typename?: 'consents_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "consents" */
export type ConsentsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "consents". */
export type ConsentsOrderBy = {
  consents_aggregate?: InputMaybe<UserConsentsAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group?: InputMaybe<UserGroupsOrderBy>;
};

/** select columns of table "consents" */
export enum ConsentsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "consents" */
export type ConsentsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConsentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsentsStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "enum_asset_booking_statuses" */
export type EnumAssetBookingStatuses = {
  __typename?: 'enum_asset_booking_statuses';
  /** An array relationship */
  asset_bookings: Array<AssetBookings>;
  /** An aggregate relationship */
  asset_bookings_aggregate: AssetBookingsAggregate;
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};


/** columns and relationships of "enum_asset_booking_statuses" */
export type EnumAssetBookingStatusesAssetBookingsArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


/** columns and relationships of "enum_asset_booking_statuses" */
export type EnumAssetBookingStatusesAssetBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};

/** aggregated selection of "enum_asset_booking_statuses" */
export type EnumAssetBookingStatusesAggregate = {
  __typename?: 'enum_asset_booking_statuses_aggregate';
  aggregate?: Maybe<EnumAssetBookingStatusesAggregateFields>;
  nodes: Array<EnumAssetBookingStatuses>;
};

/** aggregate fields of "enum_asset_booking_statuses" */
export type EnumAssetBookingStatusesAggregateFields = {
  __typename?: 'enum_asset_booking_statuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EnumAssetBookingStatusesMaxFields>;
  min?: Maybe<EnumAssetBookingStatusesMinFields>;
};


/** aggregate fields of "enum_asset_booking_statuses" */
export type EnumAssetBookingStatusesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EnumAssetBookingStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_asset_booking_statuses". All fields are combined with a logical 'AND'. */
export type EnumAssetBookingStatusesBoolExp = {
  _and?: InputMaybe<Array<EnumAssetBookingStatusesBoolExp>>;
  _not?: InputMaybe<EnumAssetBookingStatusesBoolExp>;
  _or?: InputMaybe<Array<EnumAssetBookingStatusesBoolExp>>;
  asset_bookings?: InputMaybe<AssetBookingsBoolExp>;
  asset_bookings_aggregate?: InputMaybe<AssetBookingsAggregateBoolExp>;
  comment?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum EnumAssetBookingStatusesEnum {
  /** cancelled */
  Cancelled = 'cancelled',
  /** confirmed */
  Confirmed = 'confirmed',
  /** pending */
  Pending = 'pending'
}

/** Boolean expression to compare columns of type "enum_asset_booking_statuses_enum". All fields are combined with logical 'AND'. */
export type EnumAssetBookingStatusesEnumComparisonExp = {
  _eq?: InputMaybe<EnumAssetBookingStatusesEnum>;
  _in?: InputMaybe<Array<EnumAssetBookingStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EnumAssetBookingStatusesEnum>;
  _nin?: InputMaybe<Array<EnumAssetBookingStatusesEnum>>;
};

/** aggregate max on columns */
export type EnumAssetBookingStatusesMaxFields = {
  __typename?: 'enum_asset_booking_statuses_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EnumAssetBookingStatusesMinFields = {
  __typename?: 'enum_asset_booking_statuses_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "enum_asset_booking_statuses". */
export type EnumAssetBookingStatusesOrderBy = {
  asset_bookings_aggregate?: InputMaybe<AssetBookingsAggregateOrderBy>;
  comment?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "enum_asset_booking_statuses" */
export enum EnumAssetBookingStatusesSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "enum_asset_booking_statuses" */
export type EnumAssetBookingStatusesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EnumAssetBookingStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumAssetBookingStatusesStreamCursorValueInput = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum EnumAssetStatusesEnum {
  Available = 'available',
  OutOfService = 'out_of_service',
  Unavailable = 'unavailable'
}

/** Boolean expression to compare columns of type "enum_asset_statuses_enum". All fields are combined with logical 'AND'. */
export type EnumAssetStatusesEnumComparisonExp = {
  _eq?: InputMaybe<EnumAssetStatusesEnum>;
  _in?: InputMaybe<Array<EnumAssetStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EnumAssetStatusesEnum>;
  _nin?: InputMaybe<Array<EnumAssetStatusesEnum>>;
};

/** columns and relationships of "enum_invite_statuses" */
export type EnumInviteStatuses = {
  __typename?: 'enum_invite_statuses';
  /** An array relationship */
  invites: Array<Invites>;
  /** An aggregate relationship */
  invites_aggregate: InvitesAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "enum_invite_statuses" */
export type EnumInviteStatusesInvitesArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


/** columns and relationships of "enum_invite_statuses" */
export type EnumInviteStatusesInvitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};

/** aggregated selection of "enum_invite_statuses" */
export type EnumInviteStatusesAggregate = {
  __typename?: 'enum_invite_statuses_aggregate';
  aggregate?: Maybe<EnumInviteStatusesAggregateFields>;
  nodes: Array<EnumInviteStatuses>;
};

/** aggregate fields of "enum_invite_statuses" */
export type EnumInviteStatusesAggregateFields = {
  __typename?: 'enum_invite_statuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EnumInviteStatusesMaxFields>;
  min?: Maybe<EnumInviteStatusesMinFields>;
};


/** aggregate fields of "enum_invite_statuses" */
export type EnumInviteStatusesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EnumInviteStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_invite_statuses". All fields are combined with a logical 'AND'. */
export type EnumInviteStatusesBoolExp = {
  _and?: InputMaybe<Array<EnumInviteStatusesBoolExp>>;
  _not?: InputMaybe<EnumInviteStatusesBoolExp>;
  _or?: InputMaybe<Array<EnumInviteStatusesBoolExp>>;
  invites?: InputMaybe<InvitesBoolExp>;
  invites_aggregate?: InputMaybe<InvitesAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum EnumInviteStatusesEnum {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending'
}

/** Boolean expression to compare columns of type "enum_invite_statuses_enum". All fields are combined with logical 'AND'. */
export type EnumInviteStatusesEnumComparisonExp = {
  _eq?: InputMaybe<EnumInviteStatusesEnum>;
  _in?: InputMaybe<Array<EnumInviteStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EnumInviteStatusesEnum>;
  _nin?: InputMaybe<Array<EnumInviteStatusesEnum>>;
};

/** aggregate max on columns */
export type EnumInviteStatusesMaxFields = {
  __typename?: 'enum_invite_statuses_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EnumInviteStatusesMinFields = {
  __typename?: 'enum_invite_statuses_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "enum_invite_statuses". */
export type EnumInviteStatusesOrderBy = {
  invites_aggregate?: InputMaybe<InvitesAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "enum_invite_statuses" */
export enum EnumInviteStatusesSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "enum_invite_statuses" */
export type EnumInviteStatusesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EnumInviteStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumInviteStatusesStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

export enum EnumProcessTypesEnum {
  External = 'external',
  User = 'user',
  UserGroup = 'user_group'
}

/** Boolean expression to compare columns of type "enum_process_types_enum". All fields are combined with logical 'AND'. */
export type EnumProcessTypesEnumComparisonExp = {
  _eq?: InputMaybe<EnumProcessTypesEnum>;
  _in?: InputMaybe<Array<EnumProcessTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EnumProcessTypesEnum>;
  _nin?: InputMaybe<Array<EnumProcessTypesEnum>>;
};

/** enum_user_groups_tier */
export type EnumUserGroupsTier = {
  __typename?: 'enum_user_groups_tier';
  /** An array relationship */
  user_groups: Array<UserGroups>;
  /** An aggregate relationship */
  user_groups_aggregate: UserGroupsAggregate;
  value: Scalars['String'];
};


/** enum_user_groups_tier */
export type EnumUserGroupsTierUserGroupsArgs = {
  distinct_on?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


/** enum_user_groups_tier */
export type EnumUserGroupsTierUserGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};

/** aggregated selection of "enum_user_groups_tier" */
export type EnumUserGroupsTierAggregate = {
  __typename?: 'enum_user_groups_tier_aggregate';
  aggregate?: Maybe<EnumUserGroupsTierAggregateFields>;
  nodes: Array<EnumUserGroupsTier>;
};

/** aggregate fields of "enum_user_groups_tier" */
export type EnumUserGroupsTierAggregateFields = {
  __typename?: 'enum_user_groups_tier_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EnumUserGroupsTierMaxFields>;
  min?: Maybe<EnumUserGroupsTierMinFields>;
};


/** aggregate fields of "enum_user_groups_tier" */
export type EnumUserGroupsTierAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EnumUserGroupsTierSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_user_groups_tier". All fields are combined with a logical 'AND'. */
export type EnumUserGroupsTierBoolExp = {
  _and?: InputMaybe<Array<EnumUserGroupsTierBoolExp>>;
  _not?: InputMaybe<EnumUserGroupsTierBoolExp>;
  _or?: InputMaybe<Array<EnumUserGroupsTierBoolExp>>;
  user_groups?: InputMaybe<UserGroupsBoolExp>;
  user_groups_aggregate?: InputMaybe<UserGroupsAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum EnumUserGroupsTierEnum {
  ActiveService = 'active_service',
  SelfService = 'self_service',
  Standard = 'standard'
}

/** Boolean expression to compare columns of type "enum_user_groups_tier_enum". All fields are combined with logical 'AND'. */
export type EnumUserGroupsTierEnumComparisonExp = {
  _eq?: InputMaybe<EnumUserGroupsTierEnum>;
  _in?: InputMaybe<Array<EnumUserGroupsTierEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EnumUserGroupsTierEnum>;
  _nin?: InputMaybe<Array<EnumUserGroupsTierEnum>>;
};

/** aggregate max on columns */
export type EnumUserGroupsTierMaxFields = {
  __typename?: 'enum_user_groups_tier_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EnumUserGroupsTierMinFields = {
  __typename?: 'enum_user_groups_tier_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "enum_user_groups_tier". */
export type EnumUserGroupsTierOrderBy = {
  user_groups_aggregate?: InputMaybe<UserGroupsAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "enum_user_groups_tier" */
export enum EnumUserGroupsTierSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "enum_user_groups_tier" */
export type EnumUserGroupsTierStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EnumUserGroupsTierStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumUserGroupsTierStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "external_process_values" */
export type ExternalProcessValues = {
  __typename?: 'external_process_values';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  process: Processes;
  process_id: Scalars['uuid'];
  process_values: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "external_process_values" */
export type ExternalProcessValuesProcessValuesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "external_process_values" */
export type ExternalProcessValuesAggregate = {
  __typename?: 'external_process_values_aggregate';
  aggregate?: Maybe<ExternalProcessValuesAggregateFields>;
  nodes: Array<ExternalProcessValues>;
};

export type ExternalProcessValuesAggregateBoolExp = {
  count?: InputMaybe<ExternalProcessValuesAggregateBoolExpCount>;
};

export type ExternalProcessValuesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ExternalProcessValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ExternalProcessValuesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "external_process_values" */
export type ExternalProcessValuesAggregateFields = {
  __typename?: 'external_process_values_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ExternalProcessValuesMaxFields>;
  min?: Maybe<ExternalProcessValuesMinFields>;
};


/** aggregate fields of "external_process_values" */
export type ExternalProcessValuesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalProcessValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "external_process_values" */
export type ExternalProcessValuesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ExternalProcessValuesMaxOrderBy>;
  min?: InputMaybe<ExternalProcessValuesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "external_process_values". All fields are combined with a logical 'AND'. */
export type ExternalProcessValuesBoolExp = {
  _and?: InputMaybe<Array<ExternalProcessValuesBoolExp>>;
  _not?: InputMaybe<ExternalProcessValuesBoolExp>;
  _or?: InputMaybe<Array<ExternalProcessValuesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  process?: InputMaybe<ProcessesBoolExp>;
  process_id?: InputMaybe<UuidComparisonExp>;
  process_values?: InputMaybe<JsonbComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type ExternalProcessValuesMaxFields = {
  __typename?: 'external_process_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  process_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "external_process_values" */
export type ExternalProcessValuesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  process_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ExternalProcessValuesMinFields = {
  __typename?: 'external_process_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  process_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "external_process_values" */
export type ExternalProcessValuesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  process_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "external_process_values". */
export type ExternalProcessValuesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  process?: InputMaybe<ProcessesOrderBy>;
  process_id?: InputMaybe<OrderBy>;
  process_values?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** select columns of table "external_process_values" */
export enum ExternalProcessValuesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProcessId = 'process_id',
  /** column name */
  ProcessValues = 'process_values',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "external_process_values" */
export type ExternalProcessValuesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ExternalProcessValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalProcessValuesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  process_id?: InputMaybe<Scalars['uuid']>;
  process_values?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "flattened_asset_groups" */
export type FlattenedAssetGroups = {
  __typename?: 'flattened_asset_groups';
  /** An object relationship */
  asset_group_by_child_id: AssetGroups;
  /** An object relationship */
  asset_group_by_parent_id: AssetGroups;
  child_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  parent_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "flattened_asset_groups" */
export type FlattenedAssetGroupsAggregate = {
  __typename?: 'flattened_asset_groups_aggregate';
  aggregate?: Maybe<FlattenedAssetGroupsAggregateFields>;
  nodes: Array<FlattenedAssetGroups>;
};

export type FlattenedAssetGroupsAggregateBoolExp = {
  count?: InputMaybe<FlattenedAssetGroupsAggregateBoolExpCount>;
};

export type FlattenedAssetGroupsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FlattenedAssetGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FlattenedAssetGroupsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "flattened_asset_groups" */
export type FlattenedAssetGroupsAggregateFields = {
  __typename?: 'flattened_asset_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FlattenedAssetGroupsMaxFields>;
  min?: Maybe<FlattenedAssetGroupsMinFields>;
};


/** aggregate fields of "flattened_asset_groups" */
export type FlattenedAssetGroupsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FlattenedAssetGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "flattened_asset_groups" */
export type FlattenedAssetGroupsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FlattenedAssetGroupsMaxOrderBy>;
  min?: InputMaybe<FlattenedAssetGroupsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "flattened_asset_groups". All fields are combined with a logical 'AND'. */
export type FlattenedAssetGroupsBoolExp = {
  _and?: InputMaybe<Array<FlattenedAssetGroupsBoolExp>>;
  _not?: InputMaybe<FlattenedAssetGroupsBoolExp>;
  _or?: InputMaybe<Array<FlattenedAssetGroupsBoolExp>>;
  asset_group_by_child_id?: InputMaybe<AssetGroupsBoolExp>;
  asset_group_by_parent_id?: InputMaybe<AssetGroupsBoolExp>;
  child_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  parent_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type FlattenedAssetGroupsMaxFields = {
  __typename?: 'flattened_asset_groups_max_fields';
  child_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "flattened_asset_groups" */
export type FlattenedAssetGroupsMaxOrderBy = {
  child_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FlattenedAssetGroupsMinFields = {
  __typename?: 'flattened_asset_groups_min_fields';
  child_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "flattened_asset_groups" */
export type FlattenedAssetGroupsMinOrderBy = {
  child_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "flattened_asset_groups". */
export type FlattenedAssetGroupsOrderBy = {
  asset_group_by_child_id?: InputMaybe<AssetGroupsOrderBy>;
  asset_group_by_parent_id?: InputMaybe<AssetGroupsOrderBy>;
  child_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** select columns of table "flattened_asset_groups" */
export enum FlattenedAssetGroupsSelectColumn {
  /** column name */
  ChildId = 'child_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "flattened_asset_groups" */
export type FlattenedAssetGroupsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FlattenedAssetGroupsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FlattenedAssetGroupsStreamCursorValueInput = {
  child_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "flattened_user_group_user_permissions" */
export type FlattenedUserGroupUserPermissions = {
  __typename?: 'flattened_user_group_user_permissions';
  created_at: Scalars['timestamptz'];
  permission_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  user_group: UserGroups;
  user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "flattened_user_group_user_permissions" */
export type FlattenedUserGroupUserPermissionsAggregate = {
  __typename?: 'flattened_user_group_user_permissions_aggregate';
  aggregate?: Maybe<FlattenedUserGroupUserPermissionsAggregateFields>;
  nodes: Array<FlattenedUserGroupUserPermissions>;
};

export type FlattenedUserGroupUserPermissionsAggregateBoolExp = {
  count?: InputMaybe<FlattenedUserGroupUserPermissionsAggregateBoolExpCount>;
};

export type FlattenedUserGroupUserPermissionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FlattenedUserGroupUserPermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "flattened_user_group_user_permissions" */
export type FlattenedUserGroupUserPermissionsAggregateFields = {
  __typename?: 'flattened_user_group_user_permissions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FlattenedUserGroupUserPermissionsMaxFields>;
  min?: Maybe<FlattenedUserGroupUserPermissionsMinFields>;
};


/** aggregate fields of "flattened_user_group_user_permissions" */
export type FlattenedUserGroupUserPermissionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FlattenedUserGroupUserPermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "flattened_user_group_user_permissions" */
export type FlattenedUserGroupUserPermissionsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FlattenedUserGroupUserPermissionsMaxOrderBy>;
  min?: InputMaybe<FlattenedUserGroupUserPermissionsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "flattened_user_group_user_permissions". All fields are combined with a logical 'AND'. */
export type FlattenedUserGroupUserPermissionsBoolExp = {
  _and?: InputMaybe<Array<FlattenedUserGroupUserPermissionsBoolExp>>;
  _not?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
  _or?: InputMaybe<Array<FlattenedUserGroupUserPermissionsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  permission_id?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_group?: InputMaybe<UserGroupsBoolExp>;
  user_group_id?: InputMaybe<UuidComparisonExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type FlattenedUserGroupUserPermissionsMaxFields = {
  __typename?: 'flattened_user_group_user_permissions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  permission_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "flattened_user_group_user_permissions" */
export type FlattenedUserGroupUserPermissionsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  permission_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FlattenedUserGroupUserPermissionsMinFields = {
  __typename?: 'flattened_user_group_user_permissions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  permission_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "flattened_user_group_user_permissions" */
export type FlattenedUserGroupUserPermissionsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  permission_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "flattened_user_group_user_permissions". */
export type FlattenedUserGroupUserPermissionsOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  permission_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_group?: InputMaybe<UserGroupsOrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** select columns of table "flattened_user_group_user_permissions" */
export enum FlattenedUserGroupUserPermissionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupId = 'user_group_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "flattened_user_group_user_permissions" */
export type FlattenedUserGroupUserPermissionsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FlattenedUserGroupUserPermissionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FlattenedUserGroupUserPermissionsStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  permission_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "flattened_user_group_users" */
export type FlattenedUserGroupUsers = {
  __typename?: 'flattened_user_group_users';
  created_at: Scalars['timestamptz'];
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  user_group: UserGroups;
  /** An object relationship */
  user_group_by_organization_id: UserGroups;
  user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "flattened_user_group_users" */
export type FlattenedUserGroupUsersAggregate = {
  __typename?: 'flattened_user_group_users_aggregate';
  aggregate?: Maybe<FlattenedUserGroupUsersAggregateFields>;
  nodes: Array<FlattenedUserGroupUsers>;
};

export type FlattenedUserGroupUsersAggregateBoolExp = {
  count?: InputMaybe<FlattenedUserGroupUsersAggregateBoolExpCount>;
};

export type FlattenedUserGroupUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "flattened_user_group_users" */
export type FlattenedUserGroupUsersAggregateFields = {
  __typename?: 'flattened_user_group_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FlattenedUserGroupUsersMaxFields>;
  min?: Maybe<FlattenedUserGroupUsersMinFields>;
};


/** aggregate fields of "flattened_user_group_users" */
export type FlattenedUserGroupUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "flattened_user_group_users" */
export type FlattenedUserGroupUsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FlattenedUserGroupUsersMaxOrderBy>;
  min?: InputMaybe<FlattenedUserGroupUsersMinOrderBy>;
};

/** Boolean expression to filter rows from the table "flattened_user_group_users". All fields are combined with a logical 'AND'. */
export type FlattenedUserGroupUsersBoolExp = {
  _and?: InputMaybe<Array<FlattenedUserGroupUsersBoolExp>>;
  _not?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
  _or?: InputMaybe<Array<FlattenedUserGroupUsersBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  organization_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_group?: InputMaybe<UserGroupsBoolExp>;
  user_group_by_organization_id?: InputMaybe<UserGroupsBoolExp>;
  user_group_id?: InputMaybe<UuidComparisonExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type FlattenedUserGroupUsersMaxFields = {
  __typename?: 'flattened_user_group_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "flattened_user_group_users" */
export type FlattenedUserGroupUsersMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FlattenedUserGroupUsersMinFields = {
  __typename?: 'flattened_user_group_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "flattened_user_group_users" */
export type FlattenedUserGroupUsersMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "flattened_user_group_users". */
export type FlattenedUserGroupUsersOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_group?: InputMaybe<UserGroupsOrderBy>;
  user_group_by_organization_id?: InputMaybe<UserGroupsOrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** select columns of table "flattened_user_group_users" */
export enum FlattenedUserGroupUsersSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupId = 'user_group_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "flattened_user_group_users" */
export type FlattenedUserGroupUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FlattenedUserGroupUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FlattenedUserGroupUsersStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "flattened_user_groups" */
export type FlattenedUserGroups = {
  __typename?: 'flattened_user_groups';
  child_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  parent_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_group_by_child_id: UserGroups;
  /** An object relationship */
  user_group_by_parent_id: UserGroups;
};

/** aggregated selection of "flattened_user_groups" */
export type FlattenedUserGroupsAggregate = {
  __typename?: 'flattened_user_groups_aggregate';
  aggregate?: Maybe<FlattenedUserGroupsAggregateFields>;
  nodes: Array<FlattenedUserGroups>;
};

export type FlattenedUserGroupsAggregateBoolExp = {
  count?: InputMaybe<FlattenedUserGroupsAggregateBoolExpCount>;
};

export type FlattenedUserGroupsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FlattenedUserGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FlattenedUserGroupsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "flattened_user_groups" */
export type FlattenedUserGroupsAggregateFields = {
  __typename?: 'flattened_user_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FlattenedUserGroupsMaxFields>;
  min?: Maybe<FlattenedUserGroupsMinFields>;
};


/** aggregate fields of "flattened_user_groups" */
export type FlattenedUserGroupsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FlattenedUserGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "flattened_user_groups" */
export type FlattenedUserGroupsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FlattenedUserGroupsMaxOrderBy>;
  min?: InputMaybe<FlattenedUserGroupsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "flattened_user_groups". All fields are combined with a logical 'AND'. */
export type FlattenedUserGroupsBoolExp = {
  _and?: InputMaybe<Array<FlattenedUserGroupsBoolExp>>;
  _not?: InputMaybe<FlattenedUserGroupsBoolExp>;
  _or?: InputMaybe<Array<FlattenedUserGroupsBoolExp>>;
  child_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  parent_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user_group_by_child_id?: InputMaybe<UserGroupsBoolExp>;
  user_group_by_parent_id?: InputMaybe<UserGroupsBoolExp>;
};

/** aggregate max on columns */
export type FlattenedUserGroupsMaxFields = {
  __typename?: 'flattened_user_groups_max_fields';
  child_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "flattened_user_groups" */
export type FlattenedUserGroupsMaxOrderBy = {
  child_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FlattenedUserGroupsMinFields = {
  __typename?: 'flattened_user_groups_min_fields';
  child_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  parent_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "flattened_user_groups" */
export type FlattenedUserGroupsMinOrderBy = {
  child_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "flattened_user_groups". */
export type FlattenedUserGroupsOrderBy = {
  child_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_by_child_id?: InputMaybe<UserGroupsOrderBy>;
  user_group_by_parent_id?: InputMaybe<UserGroupsOrderBy>;
};

/** select columns of table "flattened_user_groups" */
export enum FlattenedUserGroupsSelectColumn {
  /** column name */
  ChildId = 'child_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "flattened_user_groups" */
export type FlattenedUserGroupsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FlattenedUserGroupsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FlattenedUserGroupsStreamCursorValueInput = {
  child_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "friday_operating_hours" */
export type FridayOperatingHours = {
  __typename?: 'friday_operating_hours';
  day_of_week: Scalars['String'];
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id: Scalars['uuid'];
};

/** aggregated selection of "friday_operating_hours" */
export type FridayOperatingHoursAggregate = {
  __typename?: 'friday_operating_hours_aggregate';
  aggregate?: Maybe<FridayOperatingHoursAggregateFields>;
  nodes: Array<FridayOperatingHours>;
};

/** aggregate fields of "friday_operating_hours" */
export type FridayOperatingHoursAggregateFields = {
  __typename?: 'friday_operating_hours_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FridayOperatingHoursMaxFields>;
  min?: Maybe<FridayOperatingHoursMinFields>;
};


/** aggregate fields of "friday_operating_hours" */
export type FridayOperatingHoursAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FridayOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "friday_operating_hours". All fields are combined with a logical 'AND'. */
export type FridayOperatingHoursBoolExp = {
  _and?: InputMaybe<Array<FridayOperatingHoursBoolExp>>;
  _not?: InputMaybe<FridayOperatingHoursBoolExp>;
  _or?: InputMaybe<Array<FridayOperatingHoursBoolExp>>;
  day_of_week?: InputMaybe<StringComparisonExp>;
  hour_end?: InputMaybe<TimetzComparisonExp>;
  hour_start?: InputMaybe<TimetzComparisonExp>;
  model_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type FridayOperatingHoursMaxFields = {
  __typename?: 'friday_operating_hours_max_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type FridayOperatingHoursMinFields = {
  __typename?: 'friday_operating_hours_min_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "friday_operating_hours". */
export type FridayOperatingHoursOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  model_id?: InputMaybe<OrderBy>;
};

/** select columns of table "friday_operating_hours" */
export enum FridayOperatingHoursSelectColumn {
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  ModelId = 'model_id'
}

/** Streaming cursor of the table "friday_operating_hours" */
export type FridayOperatingHoursStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FridayOperatingHoursStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FridayOperatingHoursStreamCursorValueInput = {
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  model_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "invites" */
export type Invites = {
  __typename?: 'invites';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_invite_status: EnumInviteStatuses;
  error?: Maybe<Scalars['jsonb']>;
  expires_at: Scalars['timestamptz'];
  global_role_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  receiver_email: Scalars['citext'];
  sender_id?: Maybe<Scalars['uuid']>;
  status: EnumInviteStatusesEnum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_by_sender_id?: Maybe<Users>;
  /** An object relationship */
  user_group?: Maybe<UserGroups>;
  user_group_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user_group_role?: Maybe<UserGroupRoles>;
  user_group_role_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "invites" */
export type InvitesErrorArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "invites" */
export type InvitesAggregate = {
  __typename?: 'invites_aggregate';
  aggregate?: Maybe<InvitesAggregateFields>;
  nodes: Array<Invites>;
};

export type InvitesAggregateBoolExp = {
  count?: InputMaybe<InvitesAggregateBoolExpCount>;
};

export type InvitesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<InvitesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvitesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "invites" */
export type InvitesAggregateFields = {
  __typename?: 'invites_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<InvitesMaxFields>;
  min?: Maybe<InvitesMinFields>;
};


/** aggregate fields of "invites" */
export type InvitesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InvitesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invites" */
export type InvitesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InvitesMaxOrderBy>;
  min?: InputMaybe<InvitesMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type InvitesAppendInput = {
  error?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "invites" */
export type InvitesArrRelInsertInput = {
  data: Array<InvitesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<InvitesOnConflict>;
};

/** Boolean expression to filter rows from the table "invites". All fields are combined with a logical 'AND'. */
export type InvitesBoolExp = {
  _and?: InputMaybe<Array<InvitesBoolExp>>;
  _not?: InputMaybe<InvitesBoolExp>;
  _or?: InputMaybe<Array<InvitesBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  enum_invite_status?: InputMaybe<EnumInviteStatusesBoolExp>;
  error?: InputMaybe<JsonbComparisonExp>;
  expires_at?: InputMaybe<TimestamptzComparisonExp>;
  global_role_id?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  receiver_email?: InputMaybe<CitextComparisonExp>;
  sender_id?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<EnumInviteStatusesEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user_by_sender_id?: InputMaybe<UsersBoolExp>;
  user_group?: InputMaybe<UserGroupsBoolExp>;
  user_group_id?: InputMaybe<UuidComparisonExp>;
  user_group_role?: InputMaybe<UserGroupRolesBoolExp>;
  user_group_role_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "invites" */
export enum InvitesConstraint {
  /** unique or primary key constraint on columns "id" */
  InvitesPkey = 'invites_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type InvitesDeleteAtPathInput = {
  error?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type InvitesDeleteElemInput = {
  error?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type InvitesDeleteKeyInput = {
  error?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "invites" */
export type InvitesInsertInput = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['jsonb']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  global_role_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  receiver_email?: InputMaybe<Scalars['citext']>;
  sender_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<EnumInviteStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_by_sender_id?: InputMaybe<UsersObjRelInsertInput>;
  user_group?: InputMaybe<UserGroupsObjRelInsertInput>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
  user_group_role_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type InvitesMaxFields = {
  __typename?: 'invites_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  global_role_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  receiver_email?: Maybe<Scalars['citext']>;
  sender_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
  user_group_role_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "invites" */
export type InvitesMaxOrderBy = {
  code?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  expires_at?: InputMaybe<OrderBy>;
  global_role_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  receiver_email?: InputMaybe<OrderBy>;
  sender_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_group_role_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InvitesMinFields = {
  __typename?: 'invites_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  global_role_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  receiver_email?: Maybe<Scalars['citext']>;
  sender_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
  user_group_role_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "invites" */
export type InvitesMinOrderBy = {
  code?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  expires_at?: InputMaybe<OrderBy>;
  global_role_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  receiver_email?: InputMaybe<OrderBy>;
  sender_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_group_role_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "invites" */
export type InvitesMutationResponse = {
  __typename?: 'invites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invites>;
};

/** on_conflict condition type for table "invites" */
export type InvitesOnConflict = {
  constraint: InvitesConstraint;
  update_columns?: Array<InvitesUpdateColumn>;
  where?: InputMaybe<InvitesBoolExp>;
};

/** Ordering options when selecting data from "invites". */
export type InvitesOrderBy = {
  code?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  enum_invite_status?: InputMaybe<EnumInviteStatusesOrderBy>;
  error?: InputMaybe<OrderBy>;
  expires_at?: InputMaybe<OrderBy>;
  global_role_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  receiver_email?: InputMaybe<OrderBy>;
  sender_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_by_sender_id?: InputMaybe<UsersOrderBy>;
  user_group?: InputMaybe<UserGroupsOrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_group_role?: InputMaybe<UserGroupRolesOrderBy>;
  user_group_role_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: invites */
export type InvitesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type InvitesPrependInput = {
  error?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "invites" */
export enum InvitesSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  GlobalRoleId = 'global_role_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReceiverEmail = 'receiver_email',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupId = 'user_group_id',
  /** column name */
  UserGroupRoleId = 'user_group_role_id'
}

/** input type for updating data in table "invites" */
export type InvitesSetInput = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['jsonb']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  global_role_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  receiver_email?: InputMaybe<Scalars['citext']>;
  sender_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<EnumInviteStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
  user_group_role_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "invites" */
export type InvitesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: InvitesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InvitesStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['jsonb']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  global_role_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  receiver_email?: InputMaybe<Scalars['citext']>;
  sender_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<EnumInviteStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
  user_group_role_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "invites" */
export enum InvitesUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  GlobalRoleId = 'global_role_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReceiverEmail = 'receiver_email',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupId = 'user_group_id',
  /** column name */
  UserGroupRoleId = 'user_group_role_id'
}

export type InvitesUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<InvitesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<InvitesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<InvitesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<InvitesDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<InvitesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvitesSetInput>;
  /** filter the rows which have to be updated */
  where: InvitesBoolExp;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "media" */
export type Media = {
  __typename?: 'media';
  /** An array relationship */
  asset_group_media: Array<AssetGroupMedia>;
  /** An array relationship */
  asset_media: Array<AssetMedia>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_format: Scalars['String'];
  id: Scalars['uuid'];
  resource_key: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['citext'];
  /** An array relationship */
  user_avatar_media: Array<UserAvatarMedia>;
  /** An aggregate relationship */
  user_avatar_media_aggregate: UserAvatarMediaAggregate;
  /** An array relationship */
  user_group_logo_media: Array<UserGroupLogoMedia>;
  /** An aggregate relationship */
  user_group_logo_media_aggregate: UserGroupLogoMediaAggregate;
};


/** columns and relationships of "media" */
export type MediaAssetGroupMediaArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupMediaOrderBy>>;
  where?: InputMaybe<AssetGroupMediaBoolExp>;
};


/** columns and relationships of "media" */
export type MediaAssetMediaArgs = {
  distinct_on?: InputMaybe<Array<AssetMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetMediaOrderBy>>;
  where?: InputMaybe<AssetMediaBoolExp>;
};


/** columns and relationships of "media" */
export type MediaUserAvatarMediaArgs = {
  distinct_on?: InputMaybe<Array<UserAvatarMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAvatarMediaOrderBy>>;
  where?: InputMaybe<UserAvatarMediaBoolExp>;
};


/** columns and relationships of "media" */
export type MediaUserAvatarMediaAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAvatarMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAvatarMediaOrderBy>>;
  where?: InputMaybe<UserAvatarMediaBoolExp>;
};


/** columns and relationships of "media" */
export type MediaUserGroupLogoMediaArgs = {
  distinct_on?: InputMaybe<Array<UserGroupLogoMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupLogoMediaOrderBy>>;
  where?: InputMaybe<UserGroupLogoMediaBoolExp>;
};


/** columns and relationships of "media" */
export type MediaUserGroupLogoMediaAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupLogoMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupLogoMediaOrderBy>>;
  where?: InputMaybe<UserGroupLogoMediaBoolExp>;
};

/** aggregated selection of "media" */
export type MediaAggregate = {
  __typename?: 'media_aggregate';
  aggregate?: Maybe<MediaAggregateFields>;
  nodes: Array<Media>;
};

/** aggregate fields of "media" */
export type MediaAggregateFields = {
  __typename?: 'media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MediaMaxFields>;
  min?: Maybe<MediaMinFields>;
};


/** aggregate fields of "media" */
export type MediaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MediaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "media". All fields are combined with a logical 'AND'. */
export type MediaBoolExp = {
  _and?: InputMaybe<Array<MediaBoolExp>>;
  _not?: InputMaybe<MediaBoolExp>;
  _or?: InputMaybe<Array<MediaBoolExp>>;
  asset_group_media?: InputMaybe<AssetGroupMediaBoolExp>;
  asset_media?: InputMaybe<AssetMediaBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  file_format?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  resource_key?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  url?: InputMaybe<CitextComparisonExp>;
  user_avatar_media?: InputMaybe<UserAvatarMediaBoolExp>;
  user_avatar_media_aggregate?: InputMaybe<UserAvatarMediaAggregateBoolExp>;
  user_group_logo_media?: InputMaybe<UserGroupLogoMediaBoolExp>;
  user_group_logo_media_aggregate?: InputMaybe<UserGroupLogoMediaAggregateBoolExp>;
};

/** unique or primary key constraints on table "media" */
export enum MediaConstraint {
  /** unique or primary key constraint on columns "id" */
  MediaPkey = 'media_pkey',
  /** unique or primary key constraint on columns "resource_key" */
  MediaResourceKeyKey = 'media_resource_key_key'
}

/** input type for inserting data into table "media" */
export type MediaInsertInput = {
  asset_group_media?: InputMaybe<AssetGroupMediaArrRelInsertInput>;
  asset_media?: InputMaybe<AssetMediaArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  file_format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  resource_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['citext']>;
  user_avatar_media?: InputMaybe<UserAvatarMediaArrRelInsertInput>;
  user_group_logo_media?: InputMaybe<UserGroupLogoMediaArrRelInsertInput>;
};

/** aggregate max on columns */
export type MediaMaxFields = {
  __typename?: 'media_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  resource_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['citext']>;
};

/** aggregate min on columns */
export type MediaMinFields = {
  __typename?: 'media_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  resource_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['citext']>;
};

/** response of any mutation on the table "media" */
export type MediaMutationResponse = {
  __typename?: 'media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Media>;
};

/** input type for inserting object relation for remote table "media" */
export type MediaObjRelInsertInput = {
  data: MediaInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MediaOnConflict>;
};

/** on_conflict condition type for table "media" */
export type MediaOnConflict = {
  constraint: MediaConstraint;
  update_columns?: Array<MediaUpdateColumn>;
  where?: InputMaybe<MediaBoolExp>;
};

/** Ordering options when selecting data from "media". */
export type MediaOrderBy = {
  asset_group_media_aggregate?: InputMaybe<AssetGroupMediaAggregateOrderBy>;
  asset_media_aggregate?: InputMaybe<AssetMediaAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  file_format?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  resource_key?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  user_avatar_media_aggregate?: InputMaybe<UserAvatarMediaAggregateOrderBy>;
  user_group_logo_media_aggregate?: InputMaybe<UserGroupLogoMediaAggregateOrderBy>;
};

/** select columns of table "media" */
export enum MediaSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FileFormat = 'file_format',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceKey = 'resource_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** Streaming cursor of the table "media" */
export type MediaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MediaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MediaStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  file_format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  resource_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['citext']>;
};

/** placeholder for update columns of table "media" (current role has no relevant permissions) */
export enum MediaUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "monday_operating_hours" */
export type MondayOperatingHours = {
  __typename?: 'monday_operating_hours';
  day_of_week: Scalars['String'];
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id: Scalars['uuid'];
};

/** aggregated selection of "monday_operating_hours" */
export type MondayOperatingHoursAggregate = {
  __typename?: 'monday_operating_hours_aggregate';
  aggregate?: Maybe<MondayOperatingHoursAggregateFields>;
  nodes: Array<MondayOperatingHours>;
};

/** aggregate fields of "monday_operating_hours" */
export type MondayOperatingHoursAggregateFields = {
  __typename?: 'monday_operating_hours_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MondayOperatingHoursMaxFields>;
  min?: Maybe<MondayOperatingHoursMinFields>;
};


/** aggregate fields of "monday_operating_hours" */
export type MondayOperatingHoursAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MondayOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "monday_operating_hours". All fields are combined with a logical 'AND'. */
export type MondayOperatingHoursBoolExp = {
  _and?: InputMaybe<Array<MondayOperatingHoursBoolExp>>;
  _not?: InputMaybe<MondayOperatingHoursBoolExp>;
  _or?: InputMaybe<Array<MondayOperatingHoursBoolExp>>;
  day_of_week?: InputMaybe<StringComparisonExp>;
  hour_end?: InputMaybe<TimetzComparisonExp>;
  hour_start?: InputMaybe<TimetzComparisonExp>;
  model_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type MondayOperatingHoursMaxFields = {
  __typename?: 'monday_operating_hours_max_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MondayOperatingHoursMinFields = {
  __typename?: 'monday_operating_hours_min_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "monday_operating_hours". */
export type MondayOperatingHoursOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  model_id?: InputMaybe<OrderBy>;
};

/** select columns of table "monday_operating_hours" */
export enum MondayOperatingHoursSelectColumn {
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  ModelId = 'model_id'
}

/** Streaming cursor of the table "monday_operating_hours" */
export type MondayOperatingHoursStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MondayOperatingHoursStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MondayOperatingHoursStreamCursorValueInput = {
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  model_id?: InputMaybe<Scalars['uuid']>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  activate_user_from_group: ActivateUserFromGroupOutput;
  approve_asset_booking: ApproveAssetBookingOutput;
  cancel_asset_booking: CancelAssetBookingOutput;
  complete_external_onboarding: CompleteExternalOnboardingOutput;
  create_asset_booking: CreateAssetBookingOutput;
  create_asset_group: CreateAssetGroupOutput;
  deactivate_user_from_group: DeactivateUserFromGroupOutput;
  /** delete data from the table: "asset_amenities" */
  delete_asset_amenities?: Maybe<AssetAmenitiesMutationResponse>;
  /** delete single row from the table: "asset_amenities" */
  delete_asset_amenities_by_pk?: Maybe<AssetAmenities>;
  /** delete data from the table: "asset_group_operating_hours" */
  delete_asset_group_operating_hours?: Maybe<AssetGroupOperatingHoursMutationResponse>;
  /** delete single row from the table: "asset_group_operating_hours" */
  delete_asset_group_operating_hours_by_pk?: Maybe<AssetGroupOperatingHours>;
  /** delete data from the table: "asset_groups" */
  delete_asset_groups?: Maybe<AssetGroupsMutationResponse>;
  /** delete single row from the table: "asset_groups" */
  delete_asset_groups_by_pk?: Maybe<AssetGroups>;
  /** delete data from the table: "asset_operating_hours" */
  delete_asset_operating_hours?: Maybe<AssetOperatingHoursMutationResponse>;
  /** delete single row from the table: "asset_operating_hours" */
  delete_asset_operating_hours_by_pk?: Maybe<AssetOperatingHours>;
  /** delete data from the table: "assets" */
  delete_assets?: Maybe<AssetsMutationResponse>;
  /** delete single row from the table: "assets" */
  delete_assets_by_pk?: Maybe<Assets>;
  /** delete data from the table: "spacecloud.asset_groups_annex" */
  delete_sc_asset_groups_annex?: Maybe<ScAssetGroupsAnnexMutationResponse>;
  /** delete single row from the table: "spacecloud.asset_groups_annex" */
  delete_sc_asset_groups_annex_by_pk?: Maybe<ScAssetGroupsAnnex>;
  delete_user_group: DeleteUserGroupOutput;
  /** delete data from the table: "user_group_process_values" */
  delete_user_group_process_values?: Maybe<UserGroupProcessValuesMutationResponse>;
  /** delete single row from the table: "user_group_process_values" */
  delete_user_group_process_values_by_pk?: Maybe<UserGroupProcessValues>;
  /** delete data from the table: "user_group_user_roles" */
  delete_user_group_user_roles?: Maybe<UserGroupUserRolesMutationResponse>;
  /** delete single row from the table: "user_group_user_roles" */
  delete_user_group_user_roles_by_pk?: Maybe<UserGroupUserRoles>;
  /** delete data from the table: "user_groups" */
  delete_user_groups?: Maybe<UserGroupsMutationResponse>;
  /** delete single row from the table: "user_groups" */
  delete_user_groups_by_pk?: Maybe<UserGroups>;
  /** delete data from the table: "user_process_values" */
  delete_user_process_values?: Maybe<UserProcessValuesMutationResponse>;
  /** delete single row from the table: "user_process_values" */
  delete_user_process_values_by_pk?: Maybe<UserProcessValues>;
  download_csv_template: DownloadCsvTemplateOutput;
  edit_asset_booking: EditAssetBookingOutput;
  get_media_signed_urls: Array<SignedMediaUrlsOutput>;
  /** insert data into the table: "asset_amenities" */
  insert_asset_amenities?: Maybe<AssetAmenitiesMutationResponse>;
  /** insert a single row into the table: "asset_amenities" */
  insert_asset_amenities_one?: Maybe<AssetAmenities>;
  /** insert data into the table: "asset_bookings" */
  insert_asset_bookings?: Maybe<AssetBookingsMutationResponse>;
  /** insert a single row into the table: "asset_bookings" */
  insert_asset_bookings_one?: Maybe<AssetBookings>;
  /** insert data into the table: "asset_classes" */
  insert_asset_classes?: Maybe<AssetClassesMutationResponse>;
  /** insert a single row into the table: "asset_classes" */
  insert_asset_classes_one?: Maybe<AssetClasses>;
  /** insert data into the table: "asset_group_media" */
  insert_asset_group_media?: Maybe<AssetGroupMediaMutationResponse>;
  /** insert a single row into the table: "asset_group_media" */
  insert_asset_group_media_one?: Maybe<AssetGroupMedia>;
  /** insert data into the table: "asset_group_operating_hours" */
  insert_asset_group_operating_hours?: Maybe<AssetGroupOperatingHoursMutationResponse>;
  /** insert a single row into the table: "asset_group_operating_hours" */
  insert_asset_group_operating_hours_one?: Maybe<AssetGroupOperatingHours>;
  /** insert data into the table: "asset_groups" */
  insert_asset_groups?: Maybe<AssetGroupsMutationResponse>;
  /** insert a single row into the table: "asset_groups" */
  insert_asset_groups_one?: Maybe<AssetGroups>;
  /** insert data into the table: "asset_media" */
  insert_asset_media?: Maybe<AssetMediaMutationResponse>;
  /** insert a single row into the table: "asset_media" */
  insert_asset_media_one?: Maybe<AssetMedia>;
  /** insert data into the table: "asset_operating_hours" */
  insert_asset_operating_hours?: Maybe<AssetOperatingHoursMutationResponse>;
  /** insert a single row into the table: "asset_operating_hours" */
  insert_asset_operating_hours_one?: Maybe<AssetOperatingHours>;
  /** insert data into the table: "assets" */
  insert_assets?: Maybe<AssetsMutationResponse>;
  /** insert a single row into the table: "assets" */
  insert_assets_one?: Maybe<Assets>;
  /** insert data into the table: "invites" */
  insert_invites?: Maybe<InvitesMutationResponse>;
  /** insert a single row into the table: "invites" */
  insert_invites_one?: Maybe<Invites>;
  /** insert data into the table: "media" */
  insert_media?: Maybe<MediaMutationResponse>;
  /** insert a single row into the table: "media" */
  insert_media_one?: Maybe<Media>;
  /** insert data into the table: "raw_invites" */
  insert_raw_invites?: Maybe<RawInvitesMutationResponse>;
  /** insert data into the table: "spacecloud.asset_groups_annex" */
  insert_sc_asset_groups_annex?: Maybe<ScAssetGroupsAnnexMutationResponse>;
  /** insert a single row into the table: "spacecloud.asset_groups_annex" */
  insert_sc_asset_groups_annex_one?: Maybe<ScAssetGroupsAnnex>;
  /** insert data into the table: "user_avatar_media" */
  insert_user_avatar_media?: Maybe<UserAvatarMediaMutationResponse>;
  /** insert a single row into the table: "user_avatar_media" */
  insert_user_avatar_media_one?: Maybe<UserAvatarMedia>;
  /** insert data into the table: "user_consents" */
  insert_user_consents?: Maybe<UserConsentsMutationResponse>;
  /** insert a single row into the table: "user_consents" */
  insert_user_consents_one?: Maybe<UserConsents>;
  /** insert data into the table: "user_group_logo_media" */
  insert_user_group_logo_media?: Maybe<UserGroupLogoMediaMutationResponse>;
  /** insert a single row into the table: "user_group_logo_media" */
  insert_user_group_logo_media_one?: Maybe<UserGroupLogoMedia>;
  /** insert data into the table: "user_group_process_values" */
  insert_user_group_process_values?: Maybe<UserGroupProcessValuesMutationResponse>;
  /** insert a single row into the table: "user_group_process_values" */
  insert_user_group_process_values_one?: Maybe<UserGroupProcessValues>;
  /** insert data into the table: "user_group_user_roles" */
  insert_user_group_user_roles?: Maybe<UserGroupUserRolesMutationResponse>;
  /** insert a single row into the table: "user_group_user_roles" */
  insert_user_group_user_roles_one?: Maybe<UserGroupUserRoles>;
  /** insert data into the table: "user_groups" */
  insert_user_groups?: Maybe<UserGroupsMutationResponse>;
  /** insert a single row into the table: "user_groups" */
  insert_user_groups_one?: Maybe<UserGroups>;
  /** insert data into the table: "user_process_values" */
  insert_user_process_values?: Maybe<UserProcessValuesMutationResponse>;
  /** insert a single row into the table: "user_process_values" */
  insert_user_process_values_one?: Maybe<UserProcessValues>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  mass_edit_process_data: MassEditProcessDataOutput;
  reject_asset_booking: RejectAssetBookingOutput;
  remove_user_from_group: RemoveUserFromGroupOutput;
  send_invite: SendInviteOutput;
  /** update data of the table: "asset_amenities" */
  update_asset_amenities?: Maybe<AssetAmenitiesMutationResponse>;
  /** update single row of the table: "asset_amenities" */
  update_asset_amenities_by_pk?: Maybe<AssetAmenities>;
  /** update multiples rows of table: "asset_amenities" */
  update_asset_amenities_many?: Maybe<Array<Maybe<AssetAmenitiesMutationResponse>>>;
  /** update data of the table: "asset_bookings" */
  update_asset_bookings?: Maybe<AssetBookingsMutationResponse>;
  /** update single row of the table: "asset_bookings" */
  update_asset_bookings_by_pk?: Maybe<AssetBookings>;
  /** update multiples rows of table: "asset_bookings" */
  update_asset_bookings_many?: Maybe<Array<Maybe<AssetBookingsMutationResponse>>>;
  /** update data of the table: "asset_classes" */
  update_asset_classes?: Maybe<AssetClassesMutationResponse>;
  /** update single row of the table: "asset_classes" */
  update_asset_classes_by_pk?: Maybe<AssetClasses>;
  /** update multiples rows of table: "asset_classes" */
  update_asset_classes_many?: Maybe<Array<Maybe<AssetClassesMutationResponse>>>;
  /** update data of the table: "asset_group_operating_hours" */
  update_asset_group_operating_hours?: Maybe<AssetGroupOperatingHoursMutationResponse>;
  /** update single row of the table: "asset_group_operating_hours" */
  update_asset_group_operating_hours_by_pk?: Maybe<AssetGroupOperatingHours>;
  /** update multiples rows of table: "asset_group_operating_hours" */
  update_asset_group_operating_hours_many?: Maybe<Array<Maybe<AssetGroupOperatingHoursMutationResponse>>>;
  /** update data of the table: "asset_groups" */
  update_asset_groups?: Maybe<AssetGroupsMutationResponse>;
  /** update single row of the table: "asset_groups" */
  update_asset_groups_by_pk?: Maybe<AssetGroups>;
  /** update multiples rows of table: "asset_groups" */
  update_asset_groups_many?: Maybe<Array<Maybe<AssetGroupsMutationResponse>>>;
  /** update data of the table: "asset_operating_hours" */
  update_asset_operating_hours?: Maybe<AssetOperatingHoursMutationResponse>;
  /** update single row of the table: "asset_operating_hours" */
  update_asset_operating_hours_by_pk?: Maybe<AssetOperatingHours>;
  /** update multiples rows of table: "asset_operating_hours" */
  update_asset_operating_hours_many?: Maybe<Array<Maybe<AssetOperatingHoursMutationResponse>>>;
  /** update data of the table: "assets" */
  update_assets?: Maybe<AssetsMutationResponse>;
  /** update single row of the table: "assets" */
  update_assets_by_pk?: Maybe<Assets>;
  /** update multiples rows of table: "assets" */
  update_assets_many?: Maybe<Array<Maybe<AssetsMutationResponse>>>;
  /** update data of the table: "invites" */
  update_invites?: Maybe<InvitesMutationResponse>;
  /** update single row of the table: "invites" */
  update_invites_by_pk?: Maybe<Invites>;
  /** update multiples rows of table: "invites" */
  update_invites_many?: Maybe<Array<Maybe<InvitesMutationResponse>>>;
  /** update data of the table: "spacecloud.asset_groups_annex" */
  update_sc_asset_groups_annex?: Maybe<ScAssetGroupsAnnexMutationResponse>;
  /** update single row of the table: "spacecloud.asset_groups_annex" */
  update_sc_asset_groups_annex_by_pk?: Maybe<ScAssetGroupsAnnex>;
  /** update multiples rows of table: "spacecloud.asset_groups_annex" */
  update_sc_asset_groups_annex_many?: Maybe<Array<Maybe<ScAssetGroupsAnnexMutationResponse>>>;
  /** update data of the table: "spacecloud.assets" */
  update_sc_assets?: Maybe<ScAssetsMutationResponse>;
  /** update single row of the table: "spacecloud.assets" */
  update_sc_assets_by_pk?: Maybe<ScAssets>;
  /** update multiples rows of table: "spacecloud.assets" */
  update_sc_assets_many?: Maybe<Array<Maybe<ScAssetsMutationResponse>>>;
  /** update data of the table: "spacecloud.bookings" */
  update_sc_bookings?: Maybe<ScBookingsMutationResponse>;
  /** update single row of the table: "spacecloud.bookings" */
  update_sc_bookings_by_pk?: Maybe<ScBookings>;
  /** update multiples rows of table: "spacecloud.bookings" */
  update_sc_bookings_many?: Maybe<Array<Maybe<ScBookingsMutationResponse>>>;
  /** update data of the table: "user_avatar_media" */
  update_user_avatar_media?: Maybe<UserAvatarMediaMutationResponse>;
  /** update single row of the table: "user_avatar_media" */
  update_user_avatar_media_by_pk?: Maybe<UserAvatarMedia>;
  /** update multiples rows of table: "user_avatar_media" */
  update_user_avatar_media_many?: Maybe<Array<Maybe<UserAvatarMediaMutationResponse>>>;
  /** update data of the table: "user_consents" */
  update_user_consents?: Maybe<UserConsentsMutationResponse>;
  /** update single row of the table: "user_consents" */
  update_user_consents_by_pk?: Maybe<UserConsents>;
  /** update multiples rows of table: "user_consents" */
  update_user_consents_many?: Maybe<Array<Maybe<UserConsentsMutationResponse>>>;
  /** update data of the table: "user_group_logo_media" */
  update_user_group_logo_media?: Maybe<UserGroupLogoMediaMutationResponse>;
  /** update single row of the table: "user_group_logo_media" */
  update_user_group_logo_media_by_pk?: Maybe<UserGroupLogoMedia>;
  /** update multiples rows of table: "user_group_logo_media" */
  update_user_group_logo_media_many?: Maybe<Array<Maybe<UserGroupLogoMediaMutationResponse>>>;
  /** update data of the table: "user_group_process_values" */
  update_user_group_process_values?: Maybe<UserGroupProcessValuesMutationResponse>;
  /** update single row of the table: "user_group_process_values" */
  update_user_group_process_values_by_pk?: Maybe<UserGroupProcessValues>;
  /** update multiples rows of table: "user_group_process_values" */
  update_user_group_process_values_many?: Maybe<Array<Maybe<UserGroupProcessValuesMutationResponse>>>;
  /** update data of the table: "user_group_user_roles" */
  update_user_group_user_roles?: Maybe<UserGroupUserRolesMutationResponse>;
  /** update single row of the table: "user_group_user_roles" */
  update_user_group_user_roles_by_pk?: Maybe<UserGroupUserRoles>;
  /** update multiples rows of table: "user_group_user_roles" */
  update_user_group_user_roles_many?: Maybe<Array<Maybe<UserGroupUserRolesMutationResponse>>>;
  /** update data of the table: "user_groups" */
  update_user_groups?: Maybe<UserGroupsMutationResponse>;
  /** update single row of the table: "user_groups" */
  update_user_groups_by_pk?: Maybe<UserGroups>;
  /** update multiples rows of table: "user_groups" */
  update_user_groups_many?: Maybe<Array<Maybe<UserGroupsMutationResponse>>>;
  /** update data of the table: "user_process_values" */
  update_user_process_values?: Maybe<UserProcessValuesMutationResponse>;
  /** update single row of the table: "user_process_values" */
  update_user_process_values_by_pk?: Maybe<UserProcessValues>;
  /** update multiples rows of table: "user_process_values" */
  update_user_process_values_many?: Maybe<Array<Maybe<UserProcessValuesMutationResponse>>>;
  update_user_roles_in_groups: UpdateUserRolesInGroupsOutput;
  /** update data of the table: "users" */
  update_users?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<UsersMutationResponse>>>;
};


/** mutation root */
export type MutationRootActivateUserFromGroupArgs = {
  object: ActivateUserFromGroupInput;
};


/** mutation root */
export type MutationRootApproveAssetBookingArgs = {
  asset_booking_id: Scalars['String'];
};


/** mutation root */
export type MutationRootCancelAssetBookingArgs = {
  asset_booking_id: Scalars['String'];
};


/** mutation root */
export type MutationRootCompleteExternalOnboardingArgs = {
  process_id: Scalars['String'];
  user_id: Scalars['String'];
};


/** mutation root */
export type MutationRootCreateAssetBookingArgs = {
  object: CreateAssetBookingInput;
};


/** mutation root */
export type MutationRootCreateAssetGroupArgs = {
  asset_class_id: Scalars['String'];
  asset_group_operating_hours: Array<AssetGroupOperatingHoursInsertCustomInput>;
  description?: InputMaybe<Scalars['String']>;
  display_name: Scalars['String'];
  floorplan_image_url?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['String']>;
  thumbnail_image_url?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type MutationRootDeactivateUserFromGroupArgs = {
  object: DeactivateUserFromGroupInput;
};


/** mutation root */
export type MutationRootDeleteAssetAmenitiesArgs = {
  where: AssetAmenitiesBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssetAmenitiesByPkArgs = {
  asset_id: Scalars['uuid'];
  global_amenity_id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAssetGroupOperatingHoursArgs = {
  where: AssetGroupOperatingHoursBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssetGroupOperatingHoursByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAssetGroupsArgs = {
  where: AssetGroupsBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssetGroupsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAssetOperatingHoursArgs = {
  where: AssetOperatingHoursBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssetOperatingHoursByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAssetsArgs = {
  where: AssetsBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssetsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteScAssetGroupsAnnexArgs = {
  where: ScAssetGroupsAnnexBoolExp;
};


/** mutation root */
export type MutationRootDeleteScAssetGroupsAnnexByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteUserGroupArgs = {
  object: DeleteUserGroupInput;
};


/** mutation root */
export type MutationRootDeleteUserGroupProcessValuesArgs = {
  where: UserGroupProcessValuesBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserGroupProcessValuesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteUserGroupUserRolesArgs = {
  where: UserGroupUserRolesBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserGroupUserRolesByPkArgs = {
  user_group_role_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteUserGroupsArgs = {
  where: UserGroupsBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserGroupsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteUserProcessValuesArgs = {
  where: UserProcessValuesBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserProcessValuesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDownloadCsvTemplateArgs = {
  object: DownloadCsvTemplateInput;
};


/** mutation root */
export type MutationRootEditAssetBookingArgs = {
  object: EditAssetBookingInput;
};


/** mutation root */
export type MutationRootGetMediaSignedUrlsArgs = {
  files: Array<SignedMediaUrlsInput>;
};


/** mutation root */
export type MutationRootInsertAssetAmenitiesArgs = {
  objects: Array<AssetAmenitiesInsertInput>;
  on_conflict?: InputMaybe<AssetAmenitiesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetAmenitiesOneArgs = {
  object: AssetAmenitiesInsertInput;
  on_conflict?: InputMaybe<AssetAmenitiesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetBookingsArgs = {
  objects: Array<AssetBookingsInsertInput>;
  on_conflict?: InputMaybe<AssetBookingsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetBookingsOneArgs = {
  object: AssetBookingsInsertInput;
  on_conflict?: InputMaybe<AssetBookingsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetClassesArgs = {
  objects: Array<AssetClassesInsertInput>;
  on_conflict?: InputMaybe<AssetClassesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetClassesOneArgs = {
  object: AssetClassesInsertInput;
  on_conflict?: InputMaybe<AssetClassesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetGroupMediaArgs = {
  objects: Array<AssetGroupMediaInsertInput>;
  on_conflict?: InputMaybe<AssetGroupMediaOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetGroupMediaOneArgs = {
  object: AssetGroupMediaInsertInput;
  on_conflict?: InputMaybe<AssetGroupMediaOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetGroupOperatingHoursArgs = {
  objects: Array<AssetGroupOperatingHoursInsertInput>;
  on_conflict?: InputMaybe<AssetGroupOperatingHoursOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetGroupOperatingHoursOneArgs = {
  object: AssetGroupOperatingHoursInsertInput;
  on_conflict?: InputMaybe<AssetGroupOperatingHoursOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetGroupsArgs = {
  objects: Array<AssetGroupsInsertInput>;
  on_conflict?: InputMaybe<AssetGroupsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetGroupsOneArgs = {
  object: AssetGroupsInsertInput;
  on_conflict?: InputMaybe<AssetGroupsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetMediaArgs = {
  objects: Array<AssetMediaInsertInput>;
  on_conflict?: InputMaybe<AssetMediaOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetMediaOneArgs = {
  object: AssetMediaInsertInput;
  on_conflict?: InputMaybe<AssetMediaOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetOperatingHoursArgs = {
  objects: Array<AssetOperatingHoursInsertInput>;
  on_conflict?: InputMaybe<AssetOperatingHoursOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetOperatingHoursOneArgs = {
  object: AssetOperatingHoursInsertInput;
  on_conflict?: InputMaybe<AssetOperatingHoursOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetsArgs = {
  objects: Array<AssetsInsertInput>;
  on_conflict?: InputMaybe<AssetsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssetsOneArgs = {
  object: AssetsInsertInput;
  on_conflict?: InputMaybe<AssetsOnConflict>;
};


/** mutation root */
export type MutationRootInsertInvitesArgs = {
  objects: Array<InvitesInsertInput>;
  on_conflict?: InputMaybe<InvitesOnConflict>;
};


/** mutation root */
export type MutationRootInsertInvitesOneArgs = {
  object: InvitesInsertInput;
  on_conflict?: InputMaybe<InvitesOnConflict>;
};


/** mutation root */
export type MutationRootInsertMediaArgs = {
  objects: Array<MediaInsertInput>;
  on_conflict?: InputMaybe<MediaOnConflict>;
};


/** mutation root */
export type MutationRootInsertMediaOneArgs = {
  object: MediaInsertInput;
  on_conflict?: InputMaybe<MediaOnConflict>;
};


/** mutation root */
export type MutationRootInsertRawInvitesArgs = {
  objects: Array<RawInvitesInsertInput>;
  on_conflict?: InputMaybe<RawInvitesOnConflict>;
};


/** mutation root */
export type MutationRootInsertScAssetGroupsAnnexArgs = {
  objects: Array<ScAssetGroupsAnnexInsertInput>;
  on_conflict?: InputMaybe<ScAssetGroupsAnnexOnConflict>;
};


/** mutation root */
export type MutationRootInsertScAssetGroupsAnnexOneArgs = {
  object: ScAssetGroupsAnnexInsertInput;
  on_conflict?: InputMaybe<ScAssetGroupsAnnexOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserAvatarMediaArgs = {
  objects: Array<UserAvatarMediaInsertInput>;
  on_conflict?: InputMaybe<UserAvatarMediaOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserAvatarMediaOneArgs = {
  object: UserAvatarMediaInsertInput;
  on_conflict?: InputMaybe<UserAvatarMediaOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserConsentsArgs = {
  objects: Array<UserConsentsInsertInput>;
  on_conflict?: InputMaybe<UserConsentsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserConsentsOneArgs = {
  object: UserConsentsInsertInput;
  on_conflict?: InputMaybe<UserConsentsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupLogoMediaArgs = {
  objects: Array<UserGroupLogoMediaInsertInput>;
  on_conflict?: InputMaybe<UserGroupLogoMediaOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupLogoMediaOneArgs = {
  object: UserGroupLogoMediaInsertInput;
  on_conflict?: InputMaybe<UserGroupLogoMediaOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupProcessValuesArgs = {
  objects: Array<UserGroupProcessValuesInsertInput>;
  on_conflict?: InputMaybe<UserGroupProcessValuesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupProcessValuesOneArgs = {
  object: UserGroupProcessValuesInsertInput;
  on_conflict?: InputMaybe<UserGroupProcessValuesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupUserRolesArgs = {
  objects: Array<UserGroupUserRolesInsertInput>;
  on_conflict?: InputMaybe<UserGroupUserRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupUserRolesOneArgs = {
  object: UserGroupUserRolesInsertInput;
  on_conflict?: InputMaybe<UserGroupUserRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupsArgs = {
  objects: Array<UserGroupsInsertInput>;
  on_conflict?: InputMaybe<UserGroupsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupsOneArgs = {
  object: UserGroupsInsertInput;
  on_conflict?: InputMaybe<UserGroupsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserProcessValuesArgs = {
  objects: Array<UserProcessValuesInsertInput>;
  on_conflict?: InputMaybe<UserProcessValuesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserProcessValuesOneArgs = {
  object: UserProcessValuesInsertInput;
  on_conflict?: InputMaybe<UserProcessValuesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  on_conflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersOneArgs = {
  object: UsersInsertInput;
  on_conflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootMassEditProcessDataArgs = {
  object: MassEditProcessDataInput;
};


/** mutation root */
export type MutationRootRejectAssetBookingArgs = {
  asset_booking_id: Scalars['String'];
};


/** mutation root */
export type MutationRootRemoveUserFromGroupArgs = {
  group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootSendInviteArgs = {
  send_invite_inputs: SendInviteInput;
};


/** mutation root */
export type MutationRootUpdateAssetAmenitiesArgs = {
  _set?: InputMaybe<AssetAmenitiesSetInput>;
  where: AssetAmenitiesBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssetAmenitiesByPkArgs = {
  _set?: InputMaybe<AssetAmenitiesSetInput>;
  pk_columns: AssetAmenitiesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssetAmenitiesManyArgs = {
  updates: Array<AssetAmenitiesUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssetBookingsArgs = {
  _inc?: InputMaybe<AssetBookingsIncInput>;
  _set?: InputMaybe<AssetBookingsSetInput>;
  where: AssetBookingsBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssetBookingsByPkArgs = {
  _inc?: InputMaybe<AssetBookingsIncInput>;
  _set?: InputMaybe<AssetBookingsSetInput>;
  pk_columns: AssetBookingsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssetBookingsManyArgs = {
  updates: Array<AssetBookingsUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssetClassesArgs = {
  _set?: InputMaybe<AssetClassesSetInput>;
  where: AssetClassesBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssetClassesByPkArgs = {
  _set?: InputMaybe<AssetClassesSetInput>;
  pk_columns: AssetClassesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssetClassesManyArgs = {
  updates: Array<AssetClassesUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssetGroupOperatingHoursArgs = {
  _set?: InputMaybe<AssetGroupOperatingHoursSetInput>;
  where: AssetGroupOperatingHoursBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssetGroupOperatingHoursByPkArgs = {
  _set?: InputMaybe<AssetGroupOperatingHoursSetInput>;
  pk_columns: AssetGroupOperatingHoursPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssetGroupOperatingHoursManyArgs = {
  updates: Array<AssetGroupOperatingHoursUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssetGroupsArgs = {
  _set?: InputMaybe<AssetGroupsSetInput>;
  where: AssetGroupsBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssetGroupsByPkArgs = {
  _set?: InputMaybe<AssetGroupsSetInput>;
  pk_columns: AssetGroupsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssetGroupsManyArgs = {
  updates: Array<AssetGroupsUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssetOperatingHoursArgs = {
  _set?: InputMaybe<AssetOperatingHoursSetInput>;
  where: AssetOperatingHoursBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssetOperatingHoursByPkArgs = {
  _set?: InputMaybe<AssetOperatingHoursSetInput>;
  pk_columns: AssetOperatingHoursPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssetOperatingHoursManyArgs = {
  updates: Array<AssetOperatingHoursUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssetsArgs = {
  _inc?: InputMaybe<AssetsIncInput>;
  _set?: InputMaybe<AssetsSetInput>;
  where: AssetsBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssetsByPkArgs = {
  _inc?: InputMaybe<AssetsIncInput>;
  _set?: InputMaybe<AssetsSetInput>;
  pk_columns: AssetsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssetsManyArgs = {
  updates: Array<AssetsUpdates>;
};


/** mutation root */
export type MutationRootUpdateInvitesArgs = {
  _append?: InputMaybe<InvitesAppendInput>;
  _delete_at_path?: InputMaybe<InvitesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<InvitesDeleteElemInput>;
  _delete_key?: InputMaybe<InvitesDeleteKeyInput>;
  _prepend?: InputMaybe<InvitesPrependInput>;
  _set?: InputMaybe<InvitesSetInput>;
  where: InvitesBoolExp;
};


/** mutation root */
export type MutationRootUpdateInvitesByPkArgs = {
  _append?: InputMaybe<InvitesAppendInput>;
  _delete_at_path?: InputMaybe<InvitesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<InvitesDeleteElemInput>;
  _delete_key?: InputMaybe<InvitesDeleteKeyInput>;
  _prepend?: InputMaybe<InvitesPrependInput>;
  _set?: InputMaybe<InvitesSetInput>;
  pk_columns: InvitesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateInvitesManyArgs = {
  updates: Array<InvitesUpdates>;
};


/** mutation root */
export type MutationRootUpdateScAssetGroupsAnnexArgs = {
  _inc?: InputMaybe<ScAssetGroupsAnnexIncInput>;
  _set?: InputMaybe<ScAssetGroupsAnnexSetInput>;
  where: ScAssetGroupsAnnexBoolExp;
};


/** mutation root */
export type MutationRootUpdateScAssetGroupsAnnexByPkArgs = {
  _inc?: InputMaybe<ScAssetGroupsAnnexIncInput>;
  _set?: InputMaybe<ScAssetGroupsAnnexSetInput>;
  pk_columns: ScAssetGroupsAnnexPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateScAssetGroupsAnnexManyArgs = {
  updates: Array<ScAssetGroupsAnnexUpdates>;
};


/** mutation root */
export type MutationRootUpdateScAssetsArgs = {
  _set?: InputMaybe<ScAssetsSetInput>;
  where: ScAssetsBoolExp;
};


/** mutation root */
export type MutationRootUpdateScAssetsByPkArgs = {
  _set?: InputMaybe<ScAssetsSetInput>;
  pk_columns: ScAssetsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateScAssetsManyArgs = {
  updates: Array<ScAssetsUpdates>;
};


/** mutation root */
export type MutationRootUpdateScBookingsArgs = {
  _inc?: InputMaybe<ScBookingsIncInput>;
  _set?: InputMaybe<ScBookingsSetInput>;
  where: ScBookingsBoolExp;
};


/** mutation root */
export type MutationRootUpdateScBookingsByPkArgs = {
  _inc?: InputMaybe<ScBookingsIncInput>;
  _set?: InputMaybe<ScBookingsSetInput>;
  pk_columns: ScBookingsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateScBookingsManyArgs = {
  updates: Array<ScBookingsUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserAvatarMediaArgs = {
  _set?: InputMaybe<UserAvatarMediaSetInput>;
  where: UserAvatarMediaBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserAvatarMediaByPkArgs = {
  _set?: InputMaybe<UserAvatarMediaSetInput>;
  pk_columns: UserAvatarMediaPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserAvatarMediaManyArgs = {
  updates: Array<UserAvatarMediaUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserConsentsArgs = {
  _set?: InputMaybe<UserConsentsSetInput>;
  where: UserConsentsBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserConsentsByPkArgs = {
  _set?: InputMaybe<UserConsentsSetInput>;
  pk_columns: UserConsentsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserConsentsManyArgs = {
  updates: Array<UserConsentsUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserGroupLogoMediaArgs = {
  _set?: InputMaybe<UserGroupLogoMediaSetInput>;
  where: UserGroupLogoMediaBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserGroupLogoMediaByPkArgs = {
  _set?: InputMaybe<UserGroupLogoMediaSetInput>;
  pk_columns: UserGroupLogoMediaPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserGroupLogoMediaManyArgs = {
  updates: Array<UserGroupLogoMediaUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserGroupProcessValuesArgs = {
  _append?: InputMaybe<UserGroupProcessValuesAppendInput>;
  _delete_at_path?: InputMaybe<UserGroupProcessValuesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserGroupProcessValuesDeleteElemInput>;
  _delete_key?: InputMaybe<UserGroupProcessValuesDeleteKeyInput>;
  _prepend?: InputMaybe<UserGroupProcessValuesPrependInput>;
  _set?: InputMaybe<UserGroupProcessValuesSetInput>;
  where: UserGroupProcessValuesBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserGroupProcessValuesByPkArgs = {
  _append?: InputMaybe<UserGroupProcessValuesAppendInput>;
  _delete_at_path?: InputMaybe<UserGroupProcessValuesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserGroupProcessValuesDeleteElemInput>;
  _delete_key?: InputMaybe<UserGroupProcessValuesDeleteKeyInput>;
  _prepend?: InputMaybe<UserGroupProcessValuesPrependInput>;
  _set?: InputMaybe<UserGroupProcessValuesSetInput>;
  pk_columns: UserGroupProcessValuesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserGroupProcessValuesManyArgs = {
  updates: Array<UserGroupProcessValuesUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserGroupUserRolesArgs = {
  _set?: InputMaybe<UserGroupUserRolesSetInput>;
  where: UserGroupUserRolesBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserGroupUserRolesByPkArgs = {
  _set?: InputMaybe<UserGroupUserRolesSetInput>;
  pk_columns: UserGroupUserRolesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserGroupUserRolesManyArgs = {
  updates: Array<UserGroupUserRolesUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserGroupsArgs = {
  _append?: InputMaybe<UserGroupsAppendInput>;
  _delete_at_path?: InputMaybe<UserGroupsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserGroupsDeleteElemInput>;
  _delete_key?: InputMaybe<UserGroupsDeleteKeyInput>;
  _prepend?: InputMaybe<UserGroupsPrependInput>;
  _set?: InputMaybe<UserGroupsSetInput>;
  where: UserGroupsBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserGroupsByPkArgs = {
  _append?: InputMaybe<UserGroupsAppendInput>;
  _delete_at_path?: InputMaybe<UserGroupsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserGroupsDeleteElemInput>;
  _delete_key?: InputMaybe<UserGroupsDeleteKeyInput>;
  _prepend?: InputMaybe<UserGroupsPrependInput>;
  _set?: InputMaybe<UserGroupsSetInput>;
  pk_columns: UserGroupsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserGroupsManyArgs = {
  updates: Array<UserGroupsUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserProcessValuesArgs = {
  _append?: InputMaybe<UserProcessValuesAppendInput>;
  _delete_at_path?: InputMaybe<UserProcessValuesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserProcessValuesDeleteElemInput>;
  _delete_key?: InputMaybe<UserProcessValuesDeleteKeyInput>;
  _prepend?: InputMaybe<UserProcessValuesPrependInput>;
  _set?: InputMaybe<UserProcessValuesSetInput>;
  where: UserProcessValuesBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserProcessValuesByPkArgs = {
  _append?: InputMaybe<UserProcessValuesAppendInput>;
  _delete_at_path?: InputMaybe<UserProcessValuesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserProcessValuesDeleteElemInput>;
  _delete_key?: InputMaybe<UserProcessValuesDeleteKeyInput>;
  _prepend?: InputMaybe<UserProcessValuesPrependInput>;
  _set?: InputMaybe<UserProcessValuesSetInput>;
  pk_columns: UserProcessValuesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserProcessValuesManyArgs = {
  updates: Array<UserProcessValuesUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserRolesInGroupsArgs = {
  update_user_roles_in_groups_input: UpdateUserRolesInGroupsInput;
};


/** mutation root */
export type MutationRootUpdateUsersArgs = {
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateUsersByPkArgs = {
  _set?: InputMaybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "phone_number_verifications" */
export type PhoneNumberVerifications = {
  __typename?: 'phone_number_verifications';
  id: Scalars['uuid'];
  /** An object relationship */
  organization: UserGroups;
  organization_id: Scalars['uuid'];
  phone_number: Scalars['String'];
  refno?: Maybe<Scalars['String']>;
  /** A computed field, executes function "phone_number_verification_status" */
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  verified_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "phone_number_verifications" */
export type PhoneNumberVerificationsAggregate = {
  __typename?: 'phone_number_verifications_aggregate';
  aggregate?: Maybe<PhoneNumberVerificationsAggregateFields>;
  nodes: Array<PhoneNumberVerifications>;
};

export type PhoneNumberVerificationsAggregateBoolExp = {
  count?: InputMaybe<PhoneNumberVerificationsAggregateBoolExpCount>;
};

export type PhoneNumberVerificationsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PhoneNumberVerificationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PhoneNumberVerificationsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "phone_number_verifications" */
export type PhoneNumberVerificationsAggregateFields = {
  __typename?: 'phone_number_verifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PhoneNumberVerificationsMaxFields>;
  min?: Maybe<PhoneNumberVerificationsMinFields>;
};


/** aggregate fields of "phone_number_verifications" */
export type PhoneNumberVerificationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PhoneNumberVerificationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "phone_number_verifications" */
export type PhoneNumberVerificationsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PhoneNumberVerificationsMaxOrderBy>;
  min?: InputMaybe<PhoneNumberVerificationsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "phone_number_verifications". All fields are combined with a logical 'AND'. */
export type PhoneNumberVerificationsBoolExp = {
  _and?: InputMaybe<Array<PhoneNumberVerificationsBoolExp>>;
  _not?: InputMaybe<PhoneNumberVerificationsBoolExp>;
  _or?: InputMaybe<Array<PhoneNumberVerificationsBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  organization?: InputMaybe<UserGroupsBoolExp>;
  organization_id?: InputMaybe<UuidComparisonExp>;
  phone_number?: InputMaybe<StringComparisonExp>;
  refno?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
  verified_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type PhoneNumberVerificationsMaxFields = {
  __typename?: 'phone_number_verifications_max_fields';
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  refno?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "phone_number_verifications" */
export type PhoneNumberVerificationsMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  phone_number?: InputMaybe<OrderBy>;
  refno?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  verified_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PhoneNumberVerificationsMinFields = {
  __typename?: 'phone_number_verifications_min_fields';
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  refno?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "phone_number_verifications" */
export type PhoneNumberVerificationsMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  phone_number?: InputMaybe<OrderBy>;
  refno?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  verified_at?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "phone_number_verifications". */
export type PhoneNumberVerificationsOrderBy = {
  id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<UserGroupsOrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  phone_number?: InputMaybe<OrderBy>;
  refno?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
  verified_at?: InputMaybe<OrderBy>;
};

/** select columns of table "phone_number_verifications" */
export enum PhoneNumberVerificationsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Refno = 'refno',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VerifiedAt = 'verified_at'
}

/** Streaming cursor of the table "phone_number_verifications" */
export type PhoneNumberVerificationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PhoneNumberVerificationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PhoneNumberVerificationsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  phone_number?: InputMaybe<Scalars['String']>;
  refno?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  verified_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "processes" */
export type Processes = {
  __typename?: 'processes';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  external_group_process_values: Array<ExternalProcessValues>;
  /** An aggregate relationship */
  external_group_process_values_aggregate: ExternalProcessValuesAggregate;
  external_url?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  schema: Scalars['jsonb'];
  type: EnumProcessTypesEnum;
  uischema?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  user_group: UserGroups;
  user_group_id: Scalars['uuid'];
  /** An array relationship */
  user_group_process_values: Array<UserGroupProcessValues>;
  /** An aggregate relationship */
  user_group_process_values_aggregate: UserGroupProcessValuesAggregate;
  /** An array relationship */
  user_process_values: Array<UserProcessValues>;
  /** An aggregate relationship */
  user_process_values_aggregate: UserProcessValuesAggregate;
};


/** columns and relationships of "processes" */
export type ProcessesExternalGroupProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<ExternalProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProcessValuesOrderBy>>;
  where?: InputMaybe<ExternalProcessValuesBoolExp>;
};


/** columns and relationships of "processes" */
export type ProcessesExternalGroupProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProcessValuesOrderBy>>;
  where?: InputMaybe<ExternalProcessValuesBoolExp>;
};


/** columns and relationships of "processes" */
export type ProcessesSchemaArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "processes" */
export type ProcessesUischemaArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "processes" */
export type ProcessesUserGroupProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupProcessValuesOrderBy>>;
  where?: InputMaybe<UserGroupProcessValuesBoolExp>;
};


/** columns and relationships of "processes" */
export type ProcessesUserGroupProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupProcessValuesOrderBy>>;
  where?: InputMaybe<UserGroupProcessValuesBoolExp>;
};


/** columns and relationships of "processes" */
export type ProcessesUserProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<UserProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProcessValuesOrderBy>>;
  where?: InputMaybe<UserProcessValuesBoolExp>;
};


/** columns and relationships of "processes" */
export type ProcessesUserProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProcessValuesOrderBy>>;
  where?: InputMaybe<UserProcessValuesBoolExp>;
};

/** aggregated selection of "processes" */
export type ProcessesAggregate = {
  __typename?: 'processes_aggregate';
  aggregate?: Maybe<ProcessesAggregateFields>;
  nodes: Array<Processes>;
};

export type ProcessesAggregateBoolExp = {
  count?: InputMaybe<ProcessesAggregateBoolExpCount>;
};

export type ProcessesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProcessesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProcessesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "processes" */
export type ProcessesAggregateFields = {
  __typename?: 'processes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProcessesMaxFields>;
  min?: Maybe<ProcessesMinFields>;
};


/** aggregate fields of "processes" */
export type ProcessesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProcessesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "processes" */
export type ProcessesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProcessesMaxOrderBy>;
  min?: InputMaybe<ProcessesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "processes". All fields are combined with a logical 'AND'. */
export type ProcessesBoolExp = {
  _and?: InputMaybe<Array<ProcessesBoolExp>>;
  _not?: InputMaybe<ProcessesBoolExp>;
  _or?: InputMaybe<Array<ProcessesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by?: InputMaybe<UuidComparisonExp>;
  external_group_process_values?: InputMaybe<ExternalProcessValuesBoolExp>;
  external_group_process_values_aggregate?: InputMaybe<ExternalProcessValuesAggregateBoolExp>;
  external_url?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  schema?: InputMaybe<JsonbComparisonExp>;
  type?: InputMaybe<EnumProcessTypesEnumComparisonExp>;
  uischema?: InputMaybe<JsonbComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_group?: InputMaybe<UserGroupsBoolExp>;
  user_group_id?: InputMaybe<UuidComparisonExp>;
  user_group_process_values?: InputMaybe<UserGroupProcessValuesBoolExp>;
  user_group_process_values_aggregate?: InputMaybe<UserGroupProcessValuesAggregateBoolExp>;
  user_process_values?: InputMaybe<UserProcessValuesBoolExp>;
  user_process_values_aggregate?: InputMaybe<UserProcessValuesAggregateBoolExp>;
};

/** aggregate max on columns */
export type ProcessesMaxFields = {
  __typename?: 'processes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  external_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "processes" */
export type ProcessesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  created_by?: InputMaybe<OrderBy>;
  external_url?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProcessesMinFields = {
  __typename?: 'processes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  external_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "processes" */
export type ProcessesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  created_by?: InputMaybe<OrderBy>;
  external_url?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "processes". */
export type ProcessesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  created_by?: InputMaybe<OrderBy>;
  external_group_process_values_aggregate?: InputMaybe<ExternalProcessValuesAggregateOrderBy>;
  external_url?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  schema?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  uischema?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_group?: InputMaybe<UserGroupsOrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_group_process_values_aggregate?: InputMaybe<UserGroupProcessValuesAggregateOrderBy>;
  user_process_values_aggregate?: InputMaybe<UserProcessValuesAggregateOrderBy>;
};

/** select columns of table "processes" */
export enum ProcessesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ExternalUrl = 'external_url',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Schema = 'schema',
  /** column name */
  Type = 'type',
  /** column name */
  Uischema = 'uischema',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupId = 'user_group_id'
}

/** Streaming cursor of the table "processes" */
export type ProcessesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ProcessesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProcessesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  external_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<EnumProcessTypesEnum>;
  uischema?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "profiles" */
export type Profiles = {
  __typename?: 'profiles';
  auth0_id: Scalars['String'];
  auth0_tenant_name: Scalars['String'];
  connection_strategy: Scalars['String'];
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['citext']>;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "profiles" */
export type ProfilesDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "profiles" */
export type ProfilesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProfilesMaxOrderBy>;
  min?: InputMaybe<ProfilesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "profiles". All fields are combined with a logical 'AND'. */
export type ProfilesBoolExp = {
  _and?: InputMaybe<Array<ProfilesBoolExp>>;
  _not?: InputMaybe<ProfilesBoolExp>;
  _or?: InputMaybe<Array<ProfilesBoolExp>>;
  auth0_id?: InputMaybe<StringComparisonExp>;
  auth0_tenant_name?: InputMaybe<StringComparisonExp>;
  connection_strategy?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  email?: InputMaybe<CitextComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "profiles" */
export type ProfilesMaxOrderBy = {
  auth0_id?: InputMaybe<OrderBy>;
  auth0_tenant_name?: InputMaybe<OrderBy>;
  connection_strategy?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "profiles" */
export type ProfilesMinOrderBy = {
  auth0_id?: InputMaybe<OrderBy>;
  auth0_tenant_name?: InputMaybe<OrderBy>;
  connection_strategy?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "profiles". */
export type ProfilesOrderBy = {
  auth0_id?: InputMaybe<OrderBy>;
  auth0_tenant_name?: InputMaybe<OrderBy>;
  connection_strategy?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** select columns of table "profiles" */
export enum ProfilesSelectColumn {
  /** column name */
  Auth0Id = 'auth0_id',
  /** column name */
  Auth0TenantName = 'auth0_tenant_name',
  /** column name */
  ConnectionStrategy = 'connection_strategy',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "profiles" */
export type ProfilesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ProfilesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProfilesStreamCursorValueInput = {
  auth0_id?: InputMaybe<Scalars['String']>;
  auth0_tenant_name?: InputMaybe<Scalars['String']>;
  connection_strategy?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** An array relationship */
  asset_amenities: Array<AssetAmenities>;
  /** An aggregate relationship */
  asset_amenities_aggregate: AssetAmenitiesAggregate;
  /** fetch data from the table: "asset_amenities" using primary key columns */
  asset_amenities_by_pk?: Maybe<AssetAmenities>;
  /** An array relationship */
  asset_booking_events: Array<AssetBookingEvents>;
  /** fetch data from the table: "asset_booking_events" using primary key columns */
  asset_booking_events_by_pk?: Maybe<AssetBookingEvents>;
  /** An array relationship */
  asset_bookings: Array<AssetBookings>;
  /** An aggregate relationship */
  asset_bookings_aggregate: AssetBookingsAggregate;
  /** fetch data from the table: "asset_bookings" using primary key columns */
  asset_bookings_by_pk?: Maybe<AssetBookings>;
  /** An array relationship */
  asset_classes: Array<AssetClasses>;
  /** An aggregate relationship */
  asset_classes_aggregate: AssetClassesAggregate;
  /** fetch data from the table: "asset_classes" using primary key columns */
  asset_classes_by_pk?: Maybe<AssetClasses>;
  /** An array relationship */
  asset_group_media: Array<AssetGroupMedia>;
  /** fetch data from the table: "asset_group_media" using primary key columns */
  asset_group_media_by_pk?: Maybe<AssetGroupMedia>;
  /** An array relationship */
  asset_group_operating_hours: Array<AssetGroupOperatingHours>;
  /** An aggregate relationship */
  asset_group_operating_hours_aggregate: AssetGroupOperatingHoursAggregate;
  /** fetch data from the table: "asset_group_operating_hours" using primary key columns */
  asset_group_operating_hours_by_pk?: Maybe<AssetGroupOperatingHours>;
  /** An array relationship */
  asset_groups: Array<AssetGroups>;
  /** An aggregate relationship */
  asset_groups_aggregate: AssetGroupsAggregate;
  /** fetch data from the table: "asset_groups" using primary key columns */
  asset_groups_by_pk?: Maybe<AssetGroups>;
  /** An array relationship */
  asset_media: Array<AssetMedia>;
  /** fetch data from the table: "asset_media" using primary key columns */
  asset_media_by_pk?: Maybe<AssetMedia>;
  /** An array relationship */
  asset_operating_hours: Array<AssetOperatingHours>;
  /** An aggregate relationship */
  asset_operating_hours_aggregate: AssetOperatingHoursAggregate;
  /** fetch data from the table: "asset_operating_hours" using primary key columns */
  asset_operating_hours_by_pk?: Maybe<AssetOperatingHours>;
  /** An array relationship */
  asset_unavailabilities: Array<AssetUnavailabilities>;
  /** An aggregate relationship */
  asset_unavailabilities_aggregate: AssetUnavailabilitiesAggregate;
  /** fetch data from the table: "asset_unavailabilities" using primary key columns */
  asset_unavailabilities_by_pk?: Maybe<AssetUnavailabilities>;
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: AssetsAggregate;
  /** fetch data from the table: "assets" using primary key columns */
  assets_by_pk?: Maybe<Assets>;
  /** fetch data from the table: "bookers" */
  bookers: Array<Bookers>;
  /** fetch aggregated fields from the table: "bookers" */
  bookers_aggregate: BookersAggregate;
  /** fetch data from the table: "consents" */
  consents: Array<Consents>;
  /** fetch aggregated fields from the table: "consents" */
  consents_aggregate: ConsentsAggregate;
  /** fetch data from the table: "consents" using primary key columns */
  consents_by_pk?: Maybe<Consents>;
  /** fetch data from the table: "enum_asset_booking_statuses" */
  enum_asset_booking_statuses: Array<EnumAssetBookingStatuses>;
  /** fetch aggregated fields from the table: "enum_asset_booking_statuses" */
  enum_asset_booking_statuses_aggregate: EnumAssetBookingStatusesAggregate;
  /** fetch data from the table: "enum_asset_booking_statuses" using primary key columns */
  enum_asset_booking_statuses_by_pk?: Maybe<EnumAssetBookingStatuses>;
  /** fetch data from the table: "enum_invite_statuses" */
  enum_invite_statuses: Array<EnumInviteStatuses>;
  /** fetch aggregated fields from the table: "enum_invite_statuses" */
  enum_invite_statuses_aggregate: EnumInviteStatusesAggregate;
  /** fetch data from the table: "enum_invite_statuses" using primary key columns */
  enum_invite_statuses_by_pk?: Maybe<EnumInviteStatuses>;
  /** fetch data from the table: "enum_user_groups_tier" */
  enum_user_groups_tier: Array<EnumUserGroupsTier>;
  /** fetch aggregated fields from the table: "enum_user_groups_tier" */
  enum_user_groups_tier_aggregate: EnumUserGroupsTierAggregate;
  /** fetch data from the table: "enum_user_groups_tier" using primary key columns */
  enum_user_groups_tier_by_pk?: Maybe<EnumUserGroupsTier>;
  /** fetch data from the table: "external_process_values" */
  external_process_values: Array<ExternalProcessValues>;
  /** fetch aggregated fields from the table: "external_process_values" */
  external_process_values_aggregate: ExternalProcessValuesAggregate;
  /** fetch data from the table: "external_process_values" using primary key columns */
  external_process_values_by_pk?: Maybe<ExternalProcessValues>;
  /** fetch data from the table: "flattened_asset_groups" */
  flattened_asset_groups: Array<FlattenedAssetGroups>;
  /** fetch aggregated fields from the table: "flattened_asset_groups" */
  flattened_asset_groups_aggregate: FlattenedAssetGroupsAggregate;
  /** fetch data from the table: "flattened_asset_groups" using primary key columns */
  flattened_asset_groups_by_pk?: Maybe<FlattenedAssetGroups>;
  /** An array relationship */
  flattened_user_group_user_permissions: Array<FlattenedUserGroupUserPermissions>;
  /** An aggregate relationship */
  flattened_user_group_user_permissions_aggregate: FlattenedUserGroupUserPermissionsAggregate;
  /** fetch data from the table: "flattened_user_group_user_permissions" using primary key columns */
  flattened_user_group_user_permissions_by_pk?: Maybe<FlattenedUserGroupUserPermissions>;
  /** An array relationship */
  flattened_user_group_users: Array<FlattenedUserGroupUsers>;
  /** An aggregate relationship */
  flattened_user_group_users_aggregate: FlattenedUserGroupUsersAggregate;
  /** fetch data from the table: "flattened_user_group_users" using primary key columns */
  flattened_user_group_users_by_pk?: Maybe<FlattenedUserGroupUsers>;
  /** fetch data from the table: "flattened_user_groups" */
  flattened_user_groups: Array<FlattenedUserGroups>;
  /** fetch aggregated fields from the table: "flattened_user_groups" */
  flattened_user_groups_aggregate: FlattenedUserGroupsAggregate;
  /** fetch data from the table: "flattened_user_groups" using primary key columns */
  flattened_user_groups_by_pk?: Maybe<FlattenedUserGroups>;
  /** fetch data from the table: "friday_operating_hours" */
  friday_operating_hours: Array<FridayOperatingHours>;
  /** fetch aggregated fields from the table: "friday_operating_hours" */
  friday_operating_hours_aggregate: FridayOperatingHoursAggregate;
  get_signed_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  invites: Array<Invites>;
  /** An aggregate relationship */
  invites_aggregate: InvitesAggregate;
  /** fetch data from the table: "invites" using primary key columns */
  invites_by_pk?: Maybe<Invites>;
  /** fetch data from the table: "media" */
  media: Array<Media>;
  /** fetch aggregated fields from the table: "media" */
  media_aggregate: MediaAggregate;
  /** fetch data from the table: "media" using primary key columns */
  media_by_pk?: Maybe<Media>;
  /** fetch data from the table: "monday_operating_hours" */
  monday_operating_hours: Array<MondayOperatingHours>;
  /** fetch aggregated fields from the table: "monday_operating_hours" */
  monday_operating_hours_aggregate: MondayOperatingHoursAggregate;
  /** An array relationship */
  phone_number_verifications: Array<PhoneNumberVerifications>;
  /** An aggregate relationship */
  phone_number_verifications_aggregate: PhoneNumberVerificationsAggregate;
  /** fetch data from the table: "phone_number_verifications" using primary key columns */
  phone_number_verifications_by_pk?: Maybe<PhoneNumberVerifications>;
  /** An array relationship */
  processes: Array<Processes>;
  /** An aggregate relationship */
  processes_aggregate: ProcessesAggregate;
  /** fetch data from the table: "processes" using primary key columns */
  processes_by_pk?: Maybe<Processes>;
  /** An array relationship */
  profiles: Array<Profiles>;
  /** fetch data from the table: "profiles" using primary key columns */
  profiles_by_pk?: Maybe<Profiles>;
  /** fetch data from the table: "saturday_operating_hours" */
  saturday_operating_hours: Array<SaturdayOperatingHours>;
  /** fetch aggregated fields from the table: "saturday_operating_hours" */
  saturday_operating_hours_aggregate: SaturdayOperatingHoursAggregate;
  /** An array relationship */
  sc_asset_group_annex_images: Array<ScAssetGroupAnnexImages>;
  /** An aggregate relationship */
  sc_asset_group_annex_images_aggregate: ScAssetGroupAnnexImagesAggregate;
  /** fetch data from the table: "spacecloud.asset_group_annex_images" using primary key columns */
  sc_asset_group_annex_images_by_pk?: Maybe<ScAssetGroupAnnexImages>;
  /** fetch data from the table: "spacecloud.asset_groups_annex" */
  sc_asset_groups_annex: Array<ScAssetGroupsAnnex>;
  /** fetch aggregated fields from the table: "spacecloud.asset_groups_annex" */
  sc_asset_groups_annex_aggregate: ScAssetGroupsAnnexAggregate;
  /** fetch data from the table: "spacecloud.asset_groups_annex" using primary key columns */
  sc_asset_groups_annex_by_pk?: Maybe<ScAssetGroupsAnnex>;
  /** fetch data from the table: "spacecloud.assets" */
  sc_assets: Array<ScAssets>;
  /** fetch aggregated fields from the table: "spacecloud.assets" */
  sc_assets_aggregate: ScAssetsAggregate;
  /** fetch data from the table: "spacecloud.assets" using primary key columns */
  sc_assets_by_pk?: Maybe<ScAssets>;
  /** An array relationship */
  sc_cctv_cameras: Array<ScCctvCameras>;
  /** An aggregate relationship */
  sc_cctv_cameras_aggregate: ScCctvCamerasAggregate;
  /** fetch data from the table: "spacecloud.cctv_cameras" using primary key columns */
  sc_cctv_cameras_by_pk?: Maybe<ScCctvCameras>;
  /** fetch data from the table: "spacecloud.enum_period_types" */
  sc_enum_period_types: Array<ScEnumPeriodTypes>;
  /** fetch aggregated fields from the table: "spacecloud.enum_period_types" */
  sc_enum_period_types_aggregate: ScEnumPeriodTypesAggregate;
  /** fetch data from the table: "spacecloud.enum_period_types" using primary key columns */
  sc_enum_period_types_by_pk?: Maybe<ScEnumPeriodTypes>;
  /** fetch data from the table: "spacecloud.enum_rental_business_groups" */
  sc_enum_rental_business_groups: Array<ScEnumRentalBusinessGroups>;
  /** fetch aggregated fields from the table: "spacecloud.enum_rental_business_groups" */
  sc_enum_rental_business_groups_aggregate: ScEnumRentalBusinessGroupsAggregate;
  /** fetch data from the table: "spacecloud.enum_rental_business_groups" using primary key columns */
  sc_enum_rental_business_groups_by_pk?: Maybe<ScEnumRentalBusinessGroups>;
  /** fetch data from the table: "spacecloud.enum_rental_types" */
  sc_enum_rental_types: Array<ScEnumRentalTypes>;
  /** fetch aggregated fields from the table: "spacecloud.enum_rental_types" */
  sc_enum_rental_types_aggregate: ScEnumRentalTypesAggregate;
  /** fetch data from the table: "spacecloud.enum_rental_types" using primary key columns */
  sc_enum_rental_types_by_pk?: Maybe<ScEnumRentalTypes>;
  /** fetch data from the table: "spacecloud.enum_user_preference_asset_types" */
  sc_enum_user_preference_asset_types: Array<ScEnumUserPreferenceAssetTypes>;
  /** fetch aggregated fields from the table: "spacecloud.enum_user_preference_asset_types" */
  sc_enum_user_preference_asset_types_aggregate: ScEnumUserPreferenceAssetTypesAggregate;
  /** fetch data from the table: "spacecloud.enum_user_preference_asset_types" using primary key columns */
  sc_enum_user_preference_asset_types_by_pk?: Maybe<ScEnumUserPreferenceAssetTypes>;
  /** fetch data from the table: "spacecloud.enum_utility_bill_period_types" */
  sc_enum_utility_bill_period_types: Array<ScEnumUtilityBillPeriodTypes>;
  /** fetch aggregated fields from the table: "spacecloud.enum_utility_bill_period_types" */
  sc_enum_utility_bill_period_types_aggregate: ScEnumUtilityBillPeriodTypesAggregate;
  /** fetch data from the table: "spacecloud.enum_utility_bill_period_types" using primary key columns */
  sc_enum_utility_bill_period_types_by_pk?: Maybe<ScEnumUtilityBillPeriodTypes>;
  /** execute function "spacecloud.list_sc_asset_groups_annex_random" which returns "spacecloud.asset_groups_annex" */
  spacecloud_list_sc_asset_groups_annex_random: Array<ScAssetGroupsAnnex>;
  /** execute function "spacecloud.list_sc_asset_groups_annex_random" and query aggregates on result of table type "spacecloud.asset_groups_annex" */
  spacecloud_list_sc_asset_groups_annex_random_aggregate: ScAssetGroupsAnnexAggregate;
  /** fetch data from the table: "sunday_operating_hours" */
  sunday_operating_hours: Array<SundayOperatingHours>;
  /** fetch aggregated fields from the table: "sunday_operating_hours" */
  sunday_operating_hours_aggregate: SundayOperatingHoursAggregate;
  /** fetch data from the table: "thursday_operating_hours" */
  thursday_operating_hours: Array<ThursdayOperatingHours>;
  /** fetch aggregated fields from the table: "thursday_operating_hours" */
  thursday_operating_hours_aggregate: ThursdayOperatingHoursAggregate;
  /** fetch data from the table: "tuesday_operating_hours" */
  tuesday_operating_hours: Array<TuesdayOperatingHours>;
  /** fetch aggregated fields from the table: "tuesday_operating_hours" */
  tuesday_operating_hours_aggregate: TuesdayOperatingHoursAggregate;
  /** An array relationship */
  user_avatar_media: Array<UserAvatarMedia>;
  /** An aggregate relationship */
  user_avatar_media_aggregate: UserAvatarMediaAggregate;
  /** fetch data from the table: "user_avatar_media" using primary key columns */
  user_avatar_media_by_pk?: Maybe<UserAvatarMedia>;
  /** An array relationship */
  user_consents: Array<UserConsents>;
  /** fetch data from the table: "user_consents" using primary key columns */
  user_consents_by_pk?: Maybe<UserConsents>;
  /** An array relationship */
  user_group_logo_media: Array<UserGroupLogoMedia>;
  /** An aggregate relationship */
  user_group_logo_media_aggregate: UserGroupLogoMediaAggregate;
  /** fetch data from the table: "user_group_logo_media" using primary key columns */
  user_group_logo_media_by_pk?: Maybe<UserGroupLogoMedia>;
  /** An array relationship */
  user_group_process_values: Array<UserGroupProcessValues>;
  /** An aggregate relationship */
  user_group_process_values_aggregate: UserGroupProcessValuesAggregate;
  /** fetch data from the table: "user_group_process_values" using primary key columns */
  user_group_process_values_by_pk?: Maybe<UserGroupProcessValues>;
  /** An array relationship */
  user_group_roles: Array<UserGroupRoles>;
  /** An aggregate relationship */
  user_group_roles_aggregate: UserGroupRolesAggregate;
  /** fetch data from the table: "user_group_roles" using primary key columns */
  user_group_roles_by_pk?: Maybe<UserGroupRoles>;
  /** An array relationship */
  user_group_user_roles: Array<UserGroupUserRoles>;
  /** An aggregate relationship */
  user_group_user_roles_aggregate: UserGroupUserRolesAggregate;
  /** fetch data from the table: "user_group_user_roles" using primary key columns */
  user_group_user_roles_by_pk?: Maybe<UserGroupUserRoles>;
  /** An array relationship */
  user_groups: Array<UserGroups>;
  /** An aggregate relationship */
  user_groups_aggregate: UserGroupsAggregate;
  /** fetch data from the table: "user_groups" using primary key columns */
  user_groups_by_pk?: Maybe<UserGroups>;
  /** An array relationship */
  user_process_values: Array<UserProcessValues>;
  /** An aggregate relationship */
  user_process_values_aggregate: UserProcessValuesAggregate;
  /** fetch data from the table: "user_process_values" using primary key columns */
  user_process_values_by_pk?: Maybe<UserProcessValues>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "wednesday_operating_hours" */
  wednesday_operating_hours: Array<WednesdayOperatingHours>;
  /** fetch aggregated fields from the table: "wednesday_operating_hours" */
  wednesday_operating_hours_aggregate: WednesdayOperatingHoursAggregate;
};


export type QueryRootAssetAmenitiesArgs = {
  distinct_on?: InputMaybe<Array<AssetAmenitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetAmenitiesOrderBy>>;
  where?: InputMaybe<AssetAmenitiesBoolExp>;
};


export type QueryRootAssetAmenitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetAmenitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetAmenitiesOrderBy>>;
  where?: InputMaybe<AssetAmenitiesBoolExp>;
};


export type QueryRootAssetAmenitiesByPkArgs = {
  asset_id: Scalars['uuid'];
  global_amenity_id: Scalars['uuid'];
};


export type QueryRootAssetBookingEventsArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingEventsOrderBy>>;
  where?: InputMaybe<AssetBookingEventsBoolExp>;
};


export type QueryRootAssetBookingEventsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAssetBookingsArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


export type QueryRootAssetBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


export type QueryRootAssetBookingsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAssetClassesArgs = {
  distinct_on?: InputMaybe<Array<AssetClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetClassesOrderBy>>;
  where?: InputMaybe<AssetClassesBoolExp>;
};


export type QueryRootAssetClassesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetClassesOrderBy>>;
  where?: InputMaybe<AssetClassesBoolExp>;
};


export type QueryRootAssetClassesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAssetGroupMediaArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupMediaOrderBy>>;
  where?: InputMaybe<AssetGroupMediaBoolExp>;
};


export type QueryRootAssetGroupMediaByPkArgs = {
  asset_group_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


export type QueryRootAssetGroupOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
};


export type QueryRootAssetGroupOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
};


export type QueryRootAssetGroupOperatingHoursByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAssetGroupsArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupsOrderBy>>;
  where?: InputMaybe<AssetGroupsBoolExp>;
};


export type QueryRootAssetGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupsOrderBy>>;
  where?: InputMaybe<AssetGroupsBoolExp>;
};


export type QueryRootAssetGroupsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAssetMediaArgs = {
  distinct_on?: InputMaybe<Array<AssetMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetMediaOrderBy>>;
  where?: InputMaybe<AssetMediaBoolExp>;
};


export type QueryRootAssetMediaByPkArgs = {
  asset_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


export type QueryRootAssetOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<AssetOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetOperatingHoursBoolExp>;
};


export type QueryRootAssetOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetOperatingHoursBoolExp>;
};


export type QueryRootAssetOperatingHoursByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAssetUnavailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<AssetUnavailabilitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetUnavailabilitiesOrderBy>>;
  where?: InputMaybe<AssetUnavailabilitiesBoolExp>;
};


export type QueryRootAssetUnavailabilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetUnavailabilitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetUnavailabilitiesOrderBy>>;
  where?: InputMaybe<AssetUnavailabilitiesBoolExp>;
};


export type QueryRootAssetUnavailabilitiesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAssetsArgs = {
  distinct_on?: InputMaybe<Array<AssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetsOrderBy>>;
  where?: InputMaybe<AssetsBoolExp>;
};


export type QueryRootAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetsOrderBy>>;
  where?: InputMaybe<AssetsBoolExp>;
};


export type QueryRootAssetsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootBookersArgs = {
  distinct_on?: InputMaybe<Array<BookersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookersOrderBy>>;
  where?: InputMaybe<BookersBoolExp>;
};


export type QueryRootBookersAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookersOrderBy>>;
  where?: InputMaybe<BookersBoolExp>;
};


export type QueryRootConsentsArgs = {
  distinct_on?: InputMaybe<Array<ConsentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsentsOrderBy>>;
  where?: InputMaybe<ConsentsBoolExp>;
};


export type QueryRootConsentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsentsOrderBy>>;
  where?: InputMaybe<ConsentsBoolExp>;
};


export type QueryRootConsentsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootEnumAssetBookingStatusesArgs = {
  distinct_on?: InputMaybe<Array<EnumAssetBookingStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumAssetBookingStatusesOrderBy>>;
  where?: InputMaybe<EnumAssetBookingStatusesBoolExp>;
};


export type QueryRootEnumAssetBookingStatusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnumAssetBookingStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumAssetBookingStatusesOrderBy>>;
  where?: InputMaybe<EnumAssetBookingStatusesBoolExp>;
};


export type QueryRootEnumAssetBookingStatusesByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootEnumInviteStatusesArgs = {
  distinct_on?: InputMaybe<Array<EnumInviteStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumInviteStatusesOrderBy>>;
  where?: InputMaybe<EnumInviteStatusesBoolExp>;
};


export type QueryRootEnumInviteStatusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnumInviteStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumInviteStatusesOrderBy>>;
  where?: InputMaybe<EnumInviteStatusesBoolExp>;
};


export type QueryRootEnumInviteStatusesByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootEnumUserGroupsTierArgs = {
  distinct_on?: InputMaybe<Array<EnumUserGroupsTierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumUserGroupsTierOrderBy>>;
  where?: InputMaybe<EnumUserGroupsTierBoolExp>;
};


export type QueryRootEnumUserGroupsTierAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnumUserGroupsTierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumUserGroupsTierOrderBy>>;
  where?: InputMaybe<EnumUserGroupsTierBoolExp>;
};


export type QueryRootEnumUserGroupsTierByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootExternalProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<ExternalProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProcessValuesOrderBy>>;
  where?: InputMaybe<ExternalProcessValuesBoolExp>;
};


export type QueryRootExternalProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProcessValuesOrderBy>>;
  where?: InputMaybe<ExternalProcessValuesBoolExp>;
};


export type QueryRootExternalProcessValuesByPkArgs = {
  process_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type QueryRootFlattenedAssetGroupsArgs = {
  distinct_on?: InputMaybe<Array<FlattenedAssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedAssetGroupsOrderBy>>;
  where?: InputMaybe<FlattenedAssetGroupsBoolExp>;
};


export type QueryRootFlattenedAssetGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedAssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedAssetGroupsOrderBy>>;
  where?: InputMaybe<FlattenedAssetGroupsBoolExp>;
};


export type QueryRootFlattenedAssetGroupsByPkArgs = {
  child_id: Scalars['uuid'];
  parent_id: Scalars['uuid'];
};


export type QueryRootFlattenedUserGroupUserPermissionsArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUserPermissionsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
};


export type QueryRootFlattenedUserGroupUserPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUserPermissionsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
};


export type QueryRootFlattenedUserGroupUserPermissionsByPkArgs = {
  permission_id: Scalars['String'];
  user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type QueryRootFlattenedUserGroupUsersArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUsersOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


export type QueryRootFlattenedUserGroupUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUsersOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


export type QueryRootFlattenedUserGroupUsersByPkArgs = {
  organization_id: Scalars['uuid'];
  user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type QueryRootFlattenedUserGroupsArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupsBoolExp>;
};


export type QueryRootFlattenedUserGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupsBoolExp>;
};


export type QueryRootFlattenedUserGroupsByPkArgs = {
  child_id: Scalars['uuid'];
  parent_id: Scalars['uuid'];
};


export type QueryRootFridayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<FridayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FridayOperatingHoursOrderBy>>;
  where?: InputMaybe<FridayOperatingHoursBoolExp>;
};


export type QueryRootFridayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<FridayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FridayOperatingHoursOrderBy>>;
  where?: InputMaybe<FridayOperatingHoursBoolExp>;
};


export type QueryRootGetSignedUrlArgs = {
  expiresIn?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
};


export type QueryRootInvitesArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


export type QueryRootInvitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


export type QueryRootInvitesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMediaArgs = {
  distinct_on?: InputMaybe<Array<MediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MediaOrderBy>>;
  where?: InputMaybe<MediaBoolExp>;
};


export type QueryRootMediaAggregateArgs = {
  distinct_on?: InputMaybe<Array<MediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MediaOrderBy>>;
  where?: InputMaybe<MediaBoolExp>;
};


export type QueryRootMediaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMondayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<MondayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MondayOperatingHoursOrderBy>>;
  where?: InputMaybe<MondayOperatingHoursBoolExp>;
};


export type QueryRootMondayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<MondayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MondayOperatingHoursOrderBy>>;
  where?: InputMaybe<MondayOperatingHoursBoolExp>;
};


export type QueryRootPhoneNumberVerificationsArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumberVerificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumberVerificationsOrderBy>>;
  where?: InputMaybe<PhoneNumberVerificationsBoolExp>;
};


export type QueryRootPhoneNumberVerificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumberVerificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumberVerificationsOrderBy>>;
  where?: InputMaybe<PhoneNumberVerificationsBoolExp>;
};


export type QueryRootPhoneNumberVerificationsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProcessesArgs = {
  distinct_on?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


export type QueryRootProcessesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


export type QueryRootProcessesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProfilesArgs = {
  distinct_on?: InputMaybe<Array<ProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProfilesOrderBy>>;
  where?: InputMaybe<ProfilesBoolExp>;
};


export type QueryRootProfilesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootSaturdayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<SaturdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SaturdayOperatingHoursOrderBy>>;
  where?: InputMaybe<SaturdayOperatingHoursBoolExp>;
};


export type QueryRootSaturdayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<SaturdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SaturdayOperatingHoursOrderBy>>;
  where?: InputMaybe<SaturdayOperatingHoursBoolExp>;
};


export type QueryRootScAssetGroupAnnexImagesArgs = {
  distinct_on?: InputMaybe<Array<ScAssetGroupAnnexImagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupAnnexImagesOrderBy>>;
  where?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
};


export type QueryRootScAssetGroupAnnexImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScAssetGroupAnnexImagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupAnnexImagesOrderBy>>;
  where?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
};


export type QueryRootScAssetGroupAnnexImagesByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootScAssetGroupsAnnexArgs = {
  distinct_on?: InputMaybe<Array<ScAssetGroupsAnnexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupsAnnexOrderBy>>;
  where?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
};


export type QueryRootScAssetGroupsAnnexAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScAssetGroupsAnnexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupsAnnexOrderBy>>;
  where?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
};


export type QueryRootScAssetGroupsAnnexByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootScAssetsArgs = {
  distinct_on?: InputMaybe<Array<ScAssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetsOrderBy>>;
  where?: InputMaybe<ScAssetsBoolExp>;
};


export type QueryRootScAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScAssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetsOrderBy>>;
  where?: InputMaybe<ScAssetsBoolExp>;
};


export type QueryRootScAssetsByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootScCctvCamerasArgs = {
  distinct_on?: InputMaybe<Array<ScCctvCamerasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScCctvCamerasOrderBy>>;
  where?: InputMaybe<ScCctvCamerasBoolExp>;
};


export type QueryRootScCctvCamerasAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScCctvCamerasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScCctvCamerasOrderBy>>;
  where?: InputMaybe<ScCctvCamerasBoolExp>;
};


export type QueryRootScCctvCamerasByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootScEnumPeriodTypesArgs = {
  distinct_on?: InputMaybe<Array<ScEnumPeriodTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumPeriodTypesOrderBy>>;
  where?: InputMaybe<ScEnumPeriodTypesBoolExp>;
};


export type QueryRootScEnumPeriodTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScEnumPeriodTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumPeriodTypesOrderBy>>;
  where?: InputMaybe<ScEnumPeriodTypesBoolExp>;
};


export type QueryRootScEnumPeriodTypesByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootScEnumRentalBusinessGroupsArgs = {
  distinct_on?: InputMaybe<Array<ScEnumRentalBusinessGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumRentalBusinessGroupsOrderBy>>;
  where?: InputMaybe<ScEnumRentalBusinessGroupsBoolExp>;
};


export type QueryRootScEnumRentalBusinessGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScEnumRentalBusinessGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumRentalBusinessGroupsOrderBy>>;
  where?: InputMaybe<ScEnumRentalBusinessGroupsBoolExp>;
};


export type QueryRootScEnumRentalBusinessGroupsByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootScEnumRentalTypesArgs = {
  distinct_on?: InputMaybe<Array<ScEnumRentalTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumRentalTypesOrderBy>>;
  where?: InputMaybe<ScEnumRentalTypesBoolExp>;
};


export type QueryRootScEnumRentalTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScEnumRentalTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumRentalTypesOrderBy>>;
  where?: InputMaybe<ScEnumRentalTypesBoolExp>;
};


export type QueryRootScEnumRentalTypesByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootScEnumUserPreferenceAssetTypesArgs = {
  distinct_on?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesOrderBy>>;
  where?: InputMaybe<ScEnumUserPreferenceAssetTypesBoolExp>;
};


export type QueryRootScEnumUserPreferenceAssetTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesOrderBy>>;
  where?: InputMaybe<ScEnumUserPreferenceAssetTypesBoolExp>;
};


export type QueryRootScEnumUserPreferenceAssetTypesByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootScEnumUtilityBillPeriodTypesArgs = {
  distinct_on?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesOrderBy>>;
  where?: InputMaybe<ScEnumUtilityBillPeriodTypesBoolExp>;
};


export type QueryRootScEnumUtilityBillPeriodTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesOrderBy>>;
  where?: InputMaybe<ScEnumUtilityBillPeriodTypesBoolExp>;
};


export type QueryRootScEnumUtilityBillPeriodTypesByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootSpacecloudListScAssetGroupsAnnexRandomArgs = {
  args: SpacecloudListScAssetGroupsAnnexRandomArgs;
  distinct_on?: InputMaybe<Array<ScAssetGroupsAnnexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupsAnnexOrderBy>>;
  where?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
};


export type QueryRootSpacecloudListScAssetGroupsAnnexRandomAggregateArgs = {
  args: SpacecloudListScAssetGroupsAnnexRandomArgs;
  distinct_on?: InputMaybe<Array<ScAssetGroupsAnnexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupsAnnexOrderBy>>;
  where?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
};


export type QueryRootSundayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<SundayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SundayOperatingHoursOrderBy>>;
  where?: InputMaybe<SundayOperatingHoursBoolExp>;
};


export type QueryRootSundayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<SundayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SundayOperatingHoursOrderBy>>;
  where?: InputMaybe<SundayOperatingHoursBoolExp>;
};


export type QueryRootThursdayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<ThursdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ThursdayOperatingHoursOrderBy>>;
  where?: InputMaybe<ThursdayOperatingHoursBoolExp>;
};


export type QueryRootThursdayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThursdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ThursdayOperatingHoursOrderBy>>;
  where?: InputMaybe<ThursdayOperatingHoursBoolExp>;
};


export type QueryRootTuesdayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<TuesdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TuesdayOperatingHoursOrderBy>>;
  where?: InputMaybe<TuesdayOperatingHoursBoolExp>;
};


export type QueryRootTuesdayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<TuesdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TuesdayOperatingHoursOrderBy>>;
  where?: InputMaybe<TuesdayOperatingHoursBoolExp>;
};


export type QueryRootUserAvatarMediaArgs = {
  distinct_on?: InputMaybe<Array<UserAvatarMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAvatarMediaOrderBy>>;
  where?: InputMaybe<UserAvatarMediaBoolExp>;
};


export type QueryRootUserAvatarMediaAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAvatarMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAvatarMediaOrderBy>>;
  where?: InputMaybe<UserAvatarMediaBoolExp>;
};


export type QueryRootUserAvatarMediaByPkArgs = {
  media_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type QueryRootUserConsentsArgs = {
  distinct_on?: InputMaybe<Array<UserConsentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserConsentsOrderBy>>;
  where?: InputMaybe<UserConsentsBoolExp>;
};


export type QueryRootUserConsentsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUserGroupLogoMediaArgs = {
  distinct_on?: InputMaybe<Array<UserGroupLogoMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupLogoMediaOrderBy>>;
  where?: InputMaybe<UserGroupLogoMediaBoolExp>;
};


export type QueryRootUserGroupLogoMediaAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupLogoMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupLogoMediaOrderBy>>;
  where?: InputMaybe<UserGroupLogoMediaBoolExp>;
};


export type QueryRootUserGroupLogoMediaByPkArgs = {
  media_id: Scalars['uuid'];
  user_group_id: Scalars['uuid'];
};


export type QueryRootUserGroupProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupProcessValuesOrderBy>>;
  where?: InputMaybe<UserGroupProcessValuesBoolExp>;
};


export type QueryRootUserGroupProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupProcessValuesOrderBy>>;
  where?: InputMaybe<UserGroupProcessValuesBoolExp>;
};


export type QueryRootUserGroupProcessValuesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUserGroupRolesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupRolesOrderBy>>;
  where?: InputMaybe<UserGroupRolesBoolExp>;
};


export type QueryRootUserGroupRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupRolesOrderBy>>;
  where?: InputMaybe<UserGroupRolesBoolExp>;
};


export type QueryRootUserGroupRolesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUserGroupUserRolesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupUserRolesOrderBy>>;
  where?: InputMaybe<UserGroupUserRolesBoolExp>;
};


export type QueryRootUserGroupUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupUserRolesOrderBy>>;
  where?: InputMaybe<UserGroupUserRolesBoolExp>;
};


export type QueryRootUserGroupUserRolesByPkArgs = {
  user_group_role_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type QueryRootUserGroupsArgs = {
  distinct_on?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


export type QueryRootUserGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


export type QueryRootUserGroupsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUserProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<UserProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProcessValuesOrderBy>>;
  where?: InputMaybe<UserProcessValuesBoolExp>;
};


export type QueryRootUserProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProcessValuesOrderBy>>;
  where?: InputMaybe<UserProcessValuesBoolExp>;
};


export type QueryRootUserProcessValuesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type QueryRootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type QueryRootUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootWednesdayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<WednesdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WednesdayOperatingHoursOrderBy>>;
  where?: InputMaybe<WednesdayOperatingHoursBoolExp>;
};


export type QueryRootWednesdayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<WednesdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WednesdayOperatingHoursOrderBy>>;
  where?: InputMaybe<WednesdayOperatingHoursBoolExp>;
};

/** input type for inserting array relation for remote table "raw_invites" */
export type RawInvitesArrRelInsertInput = {
  data: Array<RawInvitesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RawInvitesOnConflict>;
};

/** Boolean expression to filter rows from the table "raw_invites". All fields are combined with a logical 'AND'. */
export type RawInvitesBoolExp = {
  _and?: InputMaybe<Array<RawInvitesBoolExp>>;
  _not?: InputMaybe<RawInvitesBoolExp>;
  _or?: InputMaybe<Array<RawInvitesBoolExp>>;
};

/** unique or primary key constraints on table "raw_invites" */
export enum RawInvitesConstraint {
  /** unique or primary key constraint on columns "id" */
  RawInvitesPkey = 'raw_invites_pkey'
}

/** input type for inserting data into table "raw_invites" */
export type RawInvitesInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['date']>;
  global_role_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  receiver_email?: InputMaybe<Scalars['citext']>;
  sender_id?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['date']>;
  userBySenderId?: InputMaybe<UsersObjRelInsertInput>;
  user_group?: InputMaybe<UserGroupsObjRelInsertInput>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
  user_group_role_id?: InputMaybe<Scalars['uuid']>;
};

/** response of any mutation on the table "raw_invites" */
export type RawInvitesMutationResponse = {
  __typename?: 'raw_invites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
};

/** on_conflict condition type for table "raw_invites" */
export type RawInvitesOnConflict = {
  constraint: RawInvitesConstraint;
  update_columns?: Array<RawInvitesUpdateColumn>;
  where?: InputMaybe<RawInvitesBoolExp>;
};

/** placeholder for update columns of table "raw_invites" (current role has no relevant permissions) */
export enum RawInvitesUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

export type RemainingDaysAssetsArgs = {
  input_date?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "saturday_operating_hours" */
export type SaturdayOperatingHours = {
  __typename?: 'saturday_operating_hours';
  day_of_week: Scalars['String'];
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id: Scalars['uuid'];
};

/** aggregated selection of "saturday_operating_hours" */
export type SaturdayOperatingHoursAggregate = {
  __typename?: 'saturday_operating_hours_aggregate';
  aggregate?: Maybe<SaturdayOperatingHoursAggregateFields>;
  nodes: Array<SaturdayOperatingHours>;
};

/** aggregate fields of "saturday_operating_hours" */
export type SaturdayOperatingHoursAggregateFields = {
  __typename?: 'saturday_operating_hours_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SaturdayOperatingHoursMaxFields>;
  min?: Maybe<SaturdayOperatingHoursMinFields>;
};


/** aggregate fields of "saturday_operating_hours" */
export type SaturdayOperatingHoursAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SaturdayOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "saturday_operating_hours". All fields are combined with a logical 'AND'. */
export type SaturdayOperatingHoursBoolExp = {
  _and?: InputMaybe<Array<SaturdayOperatingHoursBoolExp>>;
  _not?: InputMaybe<SaturdayOperatingHoursBoolExp>;
  _or?: InputMaybe<Array<SaturdayOperatingHoursBoolExp>>;
  day_of_week?: InputMaybe<StringComparisonExp>;
  hour_end?: InputMaybe<TimetzComparisonExp>;
  hour_start?: InputMaybe<TimetzComparisonExp>;
  model_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type SaturdayOperatingHoursMaxFields = {
  __typename?: 'saturday_operating_hours_max_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SaturdayOperatingHoursMinFields = {
  __typename?: 'saturday_operating_hours_min_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "saturday_operating_hours". */
export type SaturdayOperatingHoursOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  model_id?: InputMaybe<OrderBy>;
};

/** select columns of table "saturday_operating_hours" */
export enum SaturdayOperatingHoursSelectColumn {
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  ModelId = 'model_id'
}

/** Streaming cursor of the table "saturday_operating_hours" */
export type SaturdayOperatingHoursStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SaturdayOperatingHoursStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SaturdayOperatingHoursStreamCursorValueInput = {
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  model_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImages = {
  __typename?: 'sc_asset_group_annex_images';
  asset_group_annex_id: Scalars['Int'];
  created_at: Scalars['timestamp'];
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  object_key: Scalars['String'];
  /** An object relationship */
  sc_asset_group_annex: ScAssetGroupsAnnex;
  signed_url?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesAggregate = {
  __typename?: 'sc_asset_group_annex_images_aggregate';
  aggregate?: Maybe<ScAssetGroupAnnexImagesAggregateFields>;
  nodes: Array<ScAssetGroupAnnexImages>;
};

export type ScAssetGroupAnnexImagesAggregateBoolExp = {
  bool_and?: InputMaybe<ScAssetGroupAnnexImagesAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ScAssetGroupAnnexImagesAggregateBoolExpBoolOr>;
  count?: InputMaybe<ScAssetGroupAnnexImagesAggregateBoolExpCount>;
};

export type ScAssetGroupAnnexImagesAggregateBoolExpBoolAnd = {
  arguments: ScAssetGroupAnnexImagesSelectColumnScAssetGroupAnnexImagesAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ScAssetGroupAnnexImagesAggregateBoolExpBoolOr = {
  arguments: ScAssetGroupAnnexImagesSelectColumnScAssetGroupAnnexImagesAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ScAssetGroupAnnexImagesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ScAssetGroupAnnexImagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesAggregateFields = {
  __typename?: 'sc_asset_group_annex_images_aggregate_fields';
  avg?: Maybe<ScAssetGroupAnnexImagesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ScAssetGroupAnnexImagesMaxFields>;
  min?: Maybe<ScAssetGroupAnnexImagesMinFields>;
  stddev?: Maybe<ScAssetGroupAnnexImagesStddevFields>;
  stddev_pop?: Maybe<ScAssetGroupAnnexImagesStddevPopFields>;
  stddev_samp?: Maybe<ScAssetGroupAnnexImagesStddevSampFields>;
  sum?: Maybe<ScAssetGroupAnnexImagesSumFields>;
  var_pop?: Maybe<ScAssetGroupAnnexImagesVarPopFields>;
  var_samp?: Maybe<ScAssetGroupAnnexImagesVarSampFields>;
  variance?: Maybe<ScAssetGroupAnnexImagesVarianceFields>;
};


/** aggregate fields of "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScAssetGroupAnnexImagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesAggregateOrderBy = {
  avg?: InputMaybe<ScAssetGroupAnnexImagesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ScAssetGroupAnnexImagesMaxOrderBy>;
  min?: InputMaybe<ScAssetGroupAnnexImagesMinOrderBy>;
  stddev?: InputMaybe<ScAssetGroupAnnexImagesStddevOrderBy>;
  stddev_pop?: InputMaybe<ScAssetGroupAnnexImagesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ScAssetGroupAnnexImagesStddevSampOrderBy>;
  sum?: InputMaybe<ScAssetGroupAnnexImagesSumOrderBy>;
  var_pop?: InputMaybe<ScAssetGroupAnnexImagesVarPopOrderBy>;
  var_samp?: InputMaybe<ScAssetGroupAnnexImagesVarSampOrderBy>;
  variance?: InputMaybe<ScAssetGroupAnnexImagesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ScAssetGroupAnnexImagesAvgFields = {
  __typename?: 'sc_asset_group_annex_images_avg_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesAvgOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "spacecloud.asset_group_annex_images". All fields are combined with a logical 'AND'. */
export type ScAssetGroupAnnexImagesBoolExp = {
  _and?: InputMaybe<Array<ScAssetGroupAnnexImagesBoolExp>>;
  _not?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
  _or?: InputMaybe<Array<ScAssetGroupAnnexImagesBoolExp>>;
  asset_group_annex_id?: InputMaybe<IntComparisonExp>;
  created_at?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  object_key?: InputMaybe<StringComparisonExp>;
  sc_asset_group_annex?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
  updated_at?: InputMaybe<TimestampComparisonExp>;
};

/** aggregate max on columns */
export type ScAssetGroupAnnexImagesMaxFields = {
  __typename?: 'sc_asset_group_annex_images_max_fields';
  asset_group_annex_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  object_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesMaxOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  object_key?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ScAssetGroupAnnexImagesMinFields = {
  __typename?: 'sc_asset_group_annex_images_min_fields';
  asset_group_annex_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  object_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesMinOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  object_key?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "spacecloud.asset_group_annex_images". */
export type ScAssetGroupAnnexImagesOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  object_key?: InputMaybe<OrderBy>;
  sc_asset_group_annex?: InputMaybe<ScAssetGroupsAnnexOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** select columns of table "spacecloud.asset_group_annex_images" */
export enum ScAssetGroupAnnexImagesSelectColumn {
  /** column name */
  AssetGroupAnnexId = 'asset_group_annex_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  ObjectKey = 'object_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "sc_asset_group_annex_images_aggregate_bool_exp_bool_and_arguments_columns" columns of table "spacecloud.asset_group_annex_images" */
export enum ScAssetGroupAnnexImagesSelectColumnScAssetGroupAnnexImagesAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

/** select "sc_asset_group_annex_images_aggregate_bool_exp_bool_or_arguments_columns" columns of table "spacecloud.asset_group_annex_images" */
export enum ScAssetGroupAnnexImagesSelectColumnScAssetGroupAnnexImagesAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

/** aggregate stddev on columns */
export type ScAssetGroupAnnexImagesStddevFields = {
  __typename?: 'sc_asset_group_annex_images_stddev_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesStddevOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ScAssetGroupAnnexImagesStddevPopFields = {
  __typename?: 'sc_asset_group_annex_images_stddev_pop_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesStddevPopOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ScAssetGroupAnnexImagesStddevSampFields = {
  __typename?: 'sc_asset_group_annex_images_stddev_samp_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesStddevSampOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "sc_asset_group_annex_images" */
export type ScAssetGroupAnnexImagesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ScAssetGroupAnnexImagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScAssetGroupAnnexImagesStreamCursorValueInput = {
  asset_group_annex_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  object_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type ScAssetGroupAnnexImagesSumFields = {
  __typename?: 'sc_asset_group_annex_images_sum_fields';
  asset_group_annex_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesSumOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ScAssetGroupAnnexImagesVarPopFields = {
  __typename?: 'sc_asset_group_annex_images_var_pop_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesVarPopOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ScAssetGroupAnnexImagesVarSampFields = {
  __typename?: 'sc_asset_group_annex_images_var_samp_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesVarSampOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ScAssetGroupAnnexImagesVarianceFields = {
  __typename?: 'sc_asset_group_annex_images_variance_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "spacecloud.asset_group_annex_images" */
export type ScAssetGroupAnnexImagesVarianceOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnex = {
  __typename?: 'sc_asset_groups_annex';
  address: Scalars['String'];
  asset_class_name?: Maybe<Scalars['String']>;
  asset_class_uuid?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  asset_group: AssetGroups;
  created_at: Scalars['timestamp'];
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  district: Scalars['String'];
  district_code: Scalars['Int'];
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  is_bookable: Scalars['Boolean'];
  key?: Maybe<Scalars['uuid']>;
  landlord_email?: Maybe<Scalars['String']>;
  landlord_first_name?: Maybe<Scalars['String']>;
  landlord_full_name?: Maybe<Scalars['String']>;
  landlord_last_name?: Maybe<Scalars['String']>;
  landlord_note?: Maybe<Scalars['String']>;
  landlord_tel?: Maybe<Scalars['String']>;
  latitude: Scalars['float8'];
  line_shopping_url?: Maybe<Scalars['citext']>;
  longitude: Scalars['float8'];
  /** A computed field, executes function "spacecloud.max_of_price_per_square_meter_of_assets" */
  max_of_price_per_square_meter_of_assets?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "spacecloud.max_price_range_of_assets" */
  max_price_range_of_assets?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "spacecloud.min_of_price_per_square_meter_of_assets" */
  min_of_price_per_square_meter_of_assets?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "spacecloud.min_price_range_of_assets" */
  min_price_range_of_assets?: Maybe<Scalars['numeric']>;
  near_places: Scalars['String'];
  num_of_available_assets?: Maybe<Scalars['Int']>;
  parking_available: Scalars['Boolean'];
  province: Scalars['String'];
  province_code: Scalars['Int'];
  /** An array relationship */
  sc_asset_group_annex_images: Array<ScAssetGroupAnnexImages>;
  /** An aggregate relationship */
  sc_asset_group_annex_images_aggregate: ScAssetGroupAnnexImagesAggregate;
  /** An array relationship */
  sc_cctv_cameras: Array<ScCctvCameras>;
  /** An aggregate relationship */
  sc_cctv_cameras_aggregate: ScCctvCamerasAggregate;
  sub_district: Scalars['String'];
  sub_district_code: Scalars['Int'];
  type?: Maybe<Scalars['asset_groups_annex_type_enum']>;
  updated_at: Scalars['timestamp'];
  uuid: Scalars['uuid'];
  video_url?: Maybe<Scalars['citext']>;
};


/** columns and relationships of "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexScAssetGroupAnnexImagesArgs = {
  distinct_on?: InputMaybe<Array<ScAssetGroupAnnexImagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupAnnexImagesOrderBy>>;
  where?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
};


/** columns and relationships of "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexScAssetGroupAnnexImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScAssetGroupAnnexImagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupAnnexImagesOrderBy>>;
  where?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
};


/** columns and relationships of "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexScCctvCamerasArgs = {
  distinct_on?: InputMaybe<Array<ScCctvCamerasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScCctvCamerasOrderBy>>;
  where?: InputMaybe<ScCctvCamerasBoolExp>;
};


/** columns and relationships of "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexScCctvCamerasAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScCctvCamerasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScCctvCamerasOrderBy>>;
  where?: InputMaybe<ScCctvCamerasBoolExp>;
};

/** aggregated selection of "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexAggregate = {
  __typename?: 'sc_asset_groups_annex_aggregate';
  aggregate?: Maybe<ScAssetGroupsAnnexAggregateFields>;
  nodes: Array<ScAssetGroupsAnnex>;
};

/** aggregate fields of "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexAggregateFields = {
  __typename?: 'sc_asset_groups_annex_aggregate_fields';
  avg?: Maybe<ScAssetGroupsAnnexAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ScAssetGroupsAnnexMaxFields>;
  min?: Maybe<ScAssetGroupsAnnexMinFields>;
  stddev?: Maybe<ScAssetGroupsAnnexStddevFields>;
  stddev_pop?: Maybe<ScAssetGroupsAnnexStddevPopFields>;
  stddev_samp?: Maybe<ScAssetGroupsAnnexStddevSampFields>;
  sum?: Maybe<ScAssetGroupsAnnexSumFields>;
  var_pop?: Maybe<ScAssetGroupsAnnexVarPopFields>;
  var_samp?: Maybe<ScAssetGroupsAnnexVarSampFields>;
  variance?: Maybe<ScAssetGroupsAnnexVarianceFields>;
};


/** aggregate fields of "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScAssetGroupsAnnexSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ScAssetGroupsAnnexAvgFields = {
  __typename?: 'sc_asset_groups_annex_avg_fields';
  district_code?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num_of_available_assets?: Maybe<Scalars['Float']>;
  province_code?: Maybe<Scalars['Float']>;
  sub_district_code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spacecloud.asset_groups_annex". All fields are combined with a logical 'AND'. */
export type ScAssetGroupsAnnexBoolExp = {
  _and?: InputMaybe<Array<ScAssetGroupsAnnexBoolExp>>;
  _not?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
  _or?: InputMaybe<Array<ScAssetGroupsAnnexBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  asset_class_name?: InputMaybe<StringComparisonExp>;
  asset_class_uuid?: InputMaybe<UuidComparisonExp>;
  asset_group?: InputMaybe<AssetGroupsBoolExp>;
  created_at?: InputMaybe<TimestampComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  display_name?: InputMaybe<StringComparisonExp>;
  district?: InputMaybe<StringComparisonExp>;
  district_code?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  is_bookable?: InputMaybe<BooleanComparisonExp>;
  key?: InputMaybe<UuidComparisonExp>;
  landlord_email?: InputMaybe<StringComparisonExp>;
  landlord_first_name?: InputMaybe<StringComparisonExp>;
  landlord_full_name?: InputMaybe<StringComparisonExp>;
  landlord_last_name?: InputMaybe<StringComparisonExp>;
  landlord_note?: InputMaybe<StringComparisonExp>;
  landlord_tel?: InputMaybe<StringComparisonExp>;
  latitude?: InputMaybe<Float8ComparisonExp>;
  line_shopping_url?: InputMaybe<CitextComparisonExp>;
  longitude?: InputMaybe<Float8ComparisonExp>;
  max_of_price_per_square_meter_of_assets?: InputMaybe<NumericComparisonExp>;
  max_price_range_of_assets?: InputMaybe<NumericComparisonExp>;
  min_of_price_per_square_meter_of_assets?: InputMaybe<NumericComparisonExp>;
  min_price_range_of_assets?: InputMaybe<NumericComparisonExp>;
  near_places?: InputMaybe<StringComparisonExp>;
  num_of_available_assets?: InputMaybe<IntComparisonExp>;
  parking_available?: InputMaybe<BooleanComparisonExp>;
  province?: InputMaybe<StringComparisonExp>;
  province_code?: InputMaybe<IntComparisonExp>;
  sc_asset_group_annex_images?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
  sc_asset_group_annex_images_aggregate?: InputMaybe<ScAssetGroupAnnexImagesAggregateBoolExp>;
  sc_cctv_cameras?: InputMaybe<ScCctvCamerasBoolExp>;
  sc_cctv_cameras_aggregate?: InputMaybe<ScCctvCamerasAggregateBoolExp>;
  sub_district?: InputMaybe<StringComparisonExp>;
  sub_district_code?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<AssetGroupsAnnexTypeEnumComparisonExp>;
  updated_at?: InputMaybe<TimestampComparisonExp>;
  uuid?: InputMaybe<UuidComparisonExp>;
  video_url?: InputMaybe<CitextComparisonExp>;
};

/** unique or primary key constraints on table "spacecloud.asset_groups_annex" */
export enum ScAssetGroupsAnnexConstraint {
  /** unique or primary key constraint on columns "id" */
  Pk_681f4a2d0c16f651b2de9b5d730 = 'PK_681f4a2d0c16f651b2de9b5d730',
  /** unique or primary key constraint on columns "uuid" */
  UqD3269e4bc35dcf334063594a970 = 'UQ_d3269e4bc35dcf334063594a970'
}

/** input type for incrementing numeric columns in table "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexIncInput = {
  district_code?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  num_of_available_assets?: InputMaybe<Scalars['Int']>;
  province_code?: InputMaybe<Scalars['Int']>;
  sub_district_code?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  asset_class_name?: InputMaybe<Scalars['String']>;
  asset_class_uuid?: InputMaybe<Scalars['uuid']>;
  asset_group?: InputMaybe<AssetGroupsObjRelInsertInput>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  district_code?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_bookable?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['uuid']>;
  landlord_email?: InputMaybe<Scalars['String']>;
  landlord_first_name?: InputMaybe<Scalars['String']>;
  landlord_full_name?: InputMaybe<Scalars['String']>;
  landlord_last_name?: InputMaybe<Scalars['String']>;
  landlord_note?: InputMaybe<Scalars['String']>;
  landlord_tel?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  near_places?: InputMaybe<Scalars['String']>;
  num_of_available_assets?: InputMaybe<Scalars['Int']>;
  parking_available?: InputMaybe<Scalars['Boolean']>;
  province?: InputMaybe<Scalars['String']>;
  province_code?: InputMaybe<Scalars['Int']>;
  sub_district?: InputMaybe<Scalars['String']>;
  sub_district_code?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['asset_groups_annex_type_enum']>;
  video_url?: InputMaybe<Scalars['citext']>;
};

/** aggregate max on columns */
export type ScAssetGroupsAnnexMaxFields = {
  __typename?: 'sc_asset_groups_annex_max_fields';
  address?: Maybe<Scalars['String']>;
  asset_class_name?: Maybe<Scalars['String']>;
  asset_class_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  district_code?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['uuid']>;
  landlord_email?: Maybe<Scalars['String']>;
  landlord_first_name?: Maybe<Scalars['String']>;
  landlord_full_name?: Maybe<Scalars['String']>;
  landlord_last_name?: Maybe<Scalars['String']>;
  landlord_note?: Maybe<Scalars['String']>;
  landlord_tel?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['float8']>;
  line_shopping_url?: Maybe<Scalars['citext']>;
  longitude?: Maybe<Scalars['float8']>;
  near_places?: Maybe<Scalars['String']>;
  num_of_available_assets?: Maybe<Scalars['Int']>;
  province?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['Int']>;
  sub_district?: Maybe<Scalars['String']>;
  sub_district_code?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['asset_groups_annex_type_enum']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uuid?: Maybe<Scalars['uuid']>;
  video_url?: Maybe<Scalars['citext']>;
};

/** aggregate min on columns */
export type ScAssetGroupsAnnexMinFields = {
  __typename?: 'sc_asset_groups_annex_min_fields';
  address?: Maybe<Scalars['String']>;
  asset_class_name?: Maybe<Scalars['String']>;
  asset_class_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  district_code?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['uuid']>;
  landlord_email?: Maybe<Scalars['String']>;
  landlord_first_name?: Maybe<Scalars['String']>;
  landlord_full_name?: Maybe<Scalars['String']>;
  landlord_last_name?: Maybe<Scalars['String']>;
  landlord_note?: Maybe<Scalars['String']>;
  landlord_tel?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['float8']>;
  line_shopping_url?: Maybe<Scalars['citext']>;
  longitude?: Maybe<Scalars['float8']>;
  near_places?: Maybe<Scalars['String']>;
  num_of_available_assets?: Maybe<Scalars['Int']>;
  province?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['Int']>;
  sub_district?: Maybe<Scalars['String']>;
  sub_district_code?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['asset_groups_annex_type_enum']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uuid?: Maybe<Scalars['uuid']>;
  video_url?: Maybe<Scalars['citext']>;
};

/** response of any mutation on the table "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexMutationResponse = {
  __typename?: 'sc_asset_groups_annex_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ScAssetGroupsAnnex>;
};

/** input type for inserting object relation for remote table "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexObjRelInsertInput = {
  data: ScAssetGroupsAnnexInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ScAssetGroupsAnnexOnConflict>;
};

/** on_conflict condition type for table "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexOnConflict = {
  constraint: ScAssetGroupsAnnexConstraint;
  update_columns?: Array<ScAssetGroupsAnnexUpdateColumn>;
  where?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
};

/** Ordering options when selecting data from "spacecloud.asset_groups_annex". */
export type ScAssetGroupsAnnexOrderBy = {
  address?: InputMaybe<OrderBy>;
  asset_class_name?: InputMaybe<OrderBy>;
  asset_class_uuid?: InputMaybe<OrderBy>;
  asset_group?: InputMaybe<AssetGroupsOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  district?: InputMaybe<OrderBy>;
  district_code?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  is_bookable?: InputMaybe<OrderBy>;
  key?: InputMaybe<OrderBy>;
  landlord_email?: InputMaybe<OrderBy>;
  landlord_first_name?: InputMaybe<OrderBy>;
  landlord_full_name?: InputMaybe<OrderBy>;
  landlord_last_name?: InputMaybe<OrderBy>;
  landlord_note?: InputMaybe<OrderBy>;
  landlord_tel?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  line_shopping_url?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  max_of_price_per_square_meter_of_assets?: InputMaybe<OrderBy>;
  max_price_range_of_assets?: InputMaybe<OrderBy>;
  min_of_price_per_square_meter_of_assets?: InputMaybe<OrderBy>;
  min_price_range_of_assets?: InputMaybe<OrderBy>;
  near_places?: InputMaybe<OrderBy>;
  num_of_available_assets?: InputMaybe<OrderBy>;
  parking_available?: InputMaybe<OrderBy>;
  province?: InputMaybe<OrderBy>;
  province_code?: InputMaybe<OrderBy>;
  sc_asset_group_annex_images_aggregate?: InputMaybe<ScAssetGroupAnnexImagesAggregateOrderBy>;
  sc_cctv_cameras_aggregate?: InputMaybe<ScCctvCamerasAggregateOrderBy>;
  sub_district?: InputMaybe<OrderBy>;
  sub_district_code?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  uuid?: InputMaybe<OrderBy>;
  video_url?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: spacecloud.asset_groups_annex */
export type ScAssetGroupsAnnexPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "spacecloud.asset_groups_annex" */
export enum ScAssetGroupsAnnexSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AssetClassName = 'asset_class_name',
  /** column name */
  AssetClassUuid = 'asset_class_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  District = 'district',
  /** column name */
  DistrictCode = 'district_code',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsBookable = 'is_bookable',
  /** column name */
  Key = 'key',
  /** column name */
  LandlordEmail = 'landlord_email',
  /** column name */
  LandlordFirstName = 'landlord_first_name',
  /** column name */
  LandlordFullName = 'landlord_full_name',
  /** column name */
  LandlordLastName = 'landlord_last_name',
  /** column name */
  LandlordNote = 'landlord_note',
  /** column name */
  LandlordTel = 'landlord_tel',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  LineShoppingUrl = 'line_shopping_url',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  NearPlaces = 'near_places',
  /** column name */
  NumOfAvailableAssets = 'num_of_available_assets',
  /** column name */
  ParkingAvailable = 'parking_available',
  /** column name */
  Province = 'province',
  /** column name */
  ProvinceCode = 'province_code',
  /** column name */
  SubDistrict = 'sub_district',
  /** column name */
  SubDistrictCode = 'sub_district_code',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  VideoUrl = 'video_url'
}

/** input type for updating data in table "spacecloud.asset_groups_annex" */
export type ScAssetGroupsAnnexSetInput = {
  address?: InputMaybe<Scalars['String']>;
  asset_class_name?: InputMaybe<Scalars['String']>;
  asset_class_uuid?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  district_code?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_bookable?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['uuid']>;
  landlord_email?: InputMaybe<Scalars['String']>;
  landlord_first_name?: InputMaybe<Scalars['String']>;
  landlord_full_name?: InputMaybe<Scalars['String']>;
  landlord_last_name?: InputMaybe<Scalars['String']>;
  landlord_note?: InputMaybe<Scalars['String']>;
  landlord_tel?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  near_places?: InputMaybe<Scalars['String']>;
  num_of_available_assets?: InputMaybe<Scalars['Int']>;
  parking_available?: InputMaybe<Scalars['Boolean']>;
  province?: InputMaybe<Scalars['String']>;
  province_code?: InputMaybe<Scalars['Int']>;
  sub_district?: InputMaybe<Scalars['String']>;
  sub_district_code?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['asset_groups_annex_type_enum']>;
  video_url?: InputMaybe<Scalars['citext']>;
};

/** aggregate stddev on columns */
export type ScAssetGroupsAnnexStddevFields = {
  __typename?: 'sc_asset_groups_annex_stddev_fields';
  district_code?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num_of_available_assets?: Maybe<Scalars['Float']>;
  province_code?: Maybe<Scalars['Float']>;
  sub_district_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ScAssetGroupsAnnexStddevPopFields = {
  __typename?: 'sc_asset_groups_annex_stddev_pop_fields';
  district_code?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num_of_available_assets?: Maybe<Scalars['Float']>;
  province_code?: Maybe<Scalars['Float']>;
  sub_district_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ScAssetGroupsAnnexStddevSampFields = {
  __typename?: 'sc_asset_groups_annex_stddev_samp_fields';
  district_code?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num_of_available_assets?: Maybe<Scalars['Float']>;
  province_code?: Maybe<Scalars['Float']>;
  sub_district_code?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "sc_asset_groups_annex" */
export type ScAssetGroupsAnnexStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ScAssetGroupsAnnexStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScAssetGroupsAnnexStreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>;
  asset_class_name?: InputMaybe<Scalars['String']>;
  asset_class_uuid?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  district_code?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_bookable?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['uuid']>;
  landlord_email?: InputMaybe<Scalars['String']>;
  landlord_first_name?: InputMaybe<Scalars['String']>;
  landlord_full_name?: InputMaybe<Scalars['String']>;
  landlord_last_name?: InputMaybe<Scalars['String']>;
  landlord_note?: InputMaybe<Scalars['String']>;
  landlord_tel?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['float8']>;
  line_shopping_url?: InputMaybe<Scalars['citext']>;
  longitude?: InputMaybe<Scalars['float8']>;
  near_places?: InputMaybe<Scalars['String']>;
  num_of_available_assets?: InputMaybe<Scalars['Int']>;
  parking_available?: InputMaybe<Scalars['Boolean']>;
  province?: InputMaybe<Scalars['String']>;
  province_code?: InputMaybe<Scalars['Int']>;
  sub_district?: InputMaybe<Scalars['String']>;
  sub_district_code?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['asset_groups_annex_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  video_url?: InputMaybe<Scalars['citext']>;
};

/** aggregate sum on columns */
export type ScAssetGroupsAnnexSumFields = {
  __typename?: 'sc_asset_groups_annex_sum_fields';
  district_code?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  num_of_available_assets?: Maybe<Scalars['Int']>;
  province_code?: Maybe<Scalars['Int']>;
  sub_district_code?: Maybe<Scalars['Int']>;
};

/** update columns of table "spacecloud.asset_groups_annex" */
export enum ScAssetGroupsAnnexUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AssetClassName = 'asset_class_name',
  /** column name */
  AssetClassUuid = 'asset_class_uuid',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  District = 'district',
  /** column name */
  DistrictCode = 'district_code',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsBookable = 'is_bookable',
  /** column name */
  Key = 'key',
  /** column name */
  LandlordEmail = 'landlord_email',
  /** column name */
  LandlordFirstName = 'landlord_first_name',
  /** column name */
  LandlordFullName = 'landlord_full_name',
  /** column name */
  LandlordLastName = 'landlord_last_name',
  /** column name */
  LandlordNote = 'landlord_note',
  /** column name */
  LandlordTel = 'landlord_tel',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  NearPlaces = 'near_places',
  /** column name */
  NumOfAvailableAssets = 'num_of_available_assets',
  /** column name */
  ParkingAvailable = 'parking_available',
  /** column name */
  Province = 'province',
  /** column name */
  ProvinceCode = 'province_code',
  /** column name */
  SubDistrict = 'sub_district',
  /** column name */
  SubDistrictCode = 'sub_district_code',
  /** column name */
  Type = 'type',
  /** column name */
  VideoUrl = 'video_url'
}

export type ScAssetGroupsAnnexUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ScAssetGroupsAnnexIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ScAssetGroupsAnnexSetInput>;
  /** filter the rows which have to be updated */
  where: ScAssetGroupsAnnexBoolExp;
};

/** aggregate var_pop on columns */
export type ScAssetGroupsAnnexVarPopFields = {
  __typename?: 'sc_asset_groups_annex_var_pop_fields';
  district_code?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num_of_available_assets?: Maybe<Scalars['Float']>;
  province_code?: Maybe<Scalars['Float']>;
  sub_district_code?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ScAssetGroupsAnnexVarSampFields = {
  __typename?: 'sc_asset_groups_annex_var_samp_fields';
  district_code?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num_of_available_assets?: Maybe<Scalars['Float']>;
  province_code?: Maybe<Scalars['Float']>;
  sub_district_code?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ScAssetGroupsAnnexVarianceFields = {
  __typename?: 'sc_asset_groups_annex_variance_fields';
  district_code?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num_of_available_assets?: Maybe<Scalars['Float']>;
  province_code?: Maybe<Scalars['Float']>;
  sub_district_code?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "spacecloud.assets" */
export type ScAssets = {
  __typename?: 'sc_assets';
  approver_1: Scalars['String'];
  approver_2?: Maybe<Scalars['String']>;
  approver_3?: Maybe<Scalars['String']>;
  approver_4?: Maybe<Scalars['String']>;
  approver_5?: Maybe<Scalars['String']>;
  /** An object relationship */
  asset: Assets;
  asset_alias: Scalars['String'];
  asset_group_annex_id: Scalars['Int'];
  asset_type: Scalars['String'];
  common_fee_charge?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamp'];
  deposit_fee?: Maybe<Scalars['float8']>;
  dimensions_area: Scalars['float8'];
  dimensions_length: Scalars['float8'];
  dimensions_width: Scalars['float8'];
  electricity_charge?: Maybe<Scalars['float8']>;
  floor_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  insurance_rental?: Maybe<Scalars['float8']>;
  is_draft: Scalars['Boolean'];
  layout_positions?: Maybe<Scalars['String']>;
  organization_id: Scalars['String'];
  other_fee?: Maybe<Scalars['String']>;
  rental_fixed_amount?: Maybe<Scalars['float8']>;
  rental_percent_amount?: Maybe<Scalars['float8']>;
  rental_type: Scalars['assets_rental_type_enum'];
  /** An object relationship */
  sc_asset_group_annex: ScAssetGroupsAnnex;
  /** An array relationship */
  sc_bookings: Array<ScBookings>;
  /** An aggregate relationship */
  sc_bookings_aggregate: ScBookingsAggregate;
  updated_at: Scalars['timestamp'];
  utility_bill_period_type?: Maybe<Scalars['assets_utility_bill_period_type_enum']>;
  uuid: Scalars['uuid'];
  water_charge?: Maybe<Scalars['float8']>;
};


/** columns and relationships of "spacecloud.assets" */
export type ScAssetsScBookingsArgs = {
  distinct_on?: InputMaybe<Array<ScBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScBookingsOrderBy>>;
  where?: InputMaybe<ScBookingsBoolExp>;
};


/** columns and relationships of "spacecloud.assets" */
export type ScAssetsScBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScBookingsOrderBy>>;
  where?: InputMaybe<ScBookingsBoolExp>;
};

/** aggregated selection of "spacecloud.assets" */
export type ScAssetsAggregate = {
  __typename?: 'sc_assets_aggregate';
  aggregate?: Maybe<ScAssetsAggregateFields>;
  nodes: Array<ScAssets>;
};

/** aggregate fields of "spacecloud.assets" */
export type ScAssetsAggregateFields = {
  __typename?: 'sc_assets_aggregate_fields';
  avg?: Maybe<ScAssetsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ScAssetsMaxFields>;
  min?: Maybe<ScAssetsMinFields>;
  stddev?: Maybe<ScAssetsStddevFields>;
  stddev_pop?: Maybe<ScAssetsStddevPopFields>;
  stddev_samp?: Maybe<ScAssetsStddevSampFields>;
  sum?: Maybe<ScAssetsSumFields>;
  var_pop?: Maybe<ScAssetsVarPopFields>;
  var_samp?: Maybe<ScAssetsVarSampFields>;
  variance?: Maybe<ScAssetsVarianceFields>;
};


/** aggregate fields of "spacecloud.assets" */
export type ScAssetsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScAssetsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ScAssetsAvgFields = {
  __typename?: 'sc_assets_avg_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  dimensions_area?: Maybe<Scalars['Float']>;
  dimensions_length?: Maybe<Scalars['Float']>;
  dimensions_width?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  floor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_fixed_amount?: Maybe<Scalars['Float']>;
  rental_percent_amount?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spacecloud.assets". All fields are combined with a logical 'AND'. */
export type ScAssetsBoolExp = {
  _and?: InputMaybe<Array<ScAssetsBoolExp>>;
  _not?: InputMaybe<ScAssetsBoolExp>;
  _or?: InputMaybe<Array<ScAssetsBoolExp>>;
  approver_1?: InputMaybe<StringComparisonExp>;
  approver_2?: InputMaybe<StringComparisonExp>;
  approver_3?: InputMaybe<StringComparisonExp>;
  approver_4?: InputMaybe<StringComparisonExp>;
  approver_5?: InputMaybe<StringComparisonExp>;
  asset?: InputMaybe<AssetsBoolExp>;
  asset_alias?: InputMaybe<StringComparisonExp>;
  asset_group_annex_id?: InputMaybe<IntComparisonExp>;
  asset_type?: InputMaybe<StringComparisonExp>;
  common_fee_charge?: InputMaybe<Float8ComparisonExp>;
  created_at?: InputMaybe<TimestampComparisonExp>;
  deposit_fee?: InputMaybe<Float8ComparisonExp>;
  dimensions_area?: InputMaybe<Float8ComparisonExp>;
  dimensions_length?: InputMaybe<Float8ComparisonExp>;
  dimensions_width?: InputMaybe<Float8ComparisonExp>;
  electricity_charge?: InputMaybe<Float8ComparisonExp>;
  floor_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  insurance_rental?: InputMaybe<Float8ComparisonExp>;
  is_draft?: InputMaybe<BooleanComparisonExp>;
  layout_positions?: InputMaybe<StringComparisonExp>;
  organization_id?: InputMaybe<StringComparisonExp>;
  other_fee?: InputMaybe<StringComparisonExp>;
  rental_fixed_amount?: InputMaybe<Float8ComparisonExp>;
  rental_percent_amount?: InputMaybe<Float8ComparisonExp>;
  rental_type?: InputMaybe<AssetsRentalTypeEnumComparisonExp>;
  sc_asset_group_annex?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
  sc_bookings?: InputMaybe<ScBookingsBoolExp>;
  sc_bookings_aggregate?: InputMaybe<ScBookingsAggregateBoolExp>;
  updated_at?: InputMaybe<TimestampComparisonExp>;
  utility_bill_period_type?: InputMaybe<AssetsUtilityBillPeriodTypeEnumComparisonExp>;
  uuid?: InputMaybe<UuidComparisonExp>;
  water_charge?: InputMaybe<Float8ComparisonExp>;
};

/** aggregate max on columns */
export type ScAssetsMaxFields = {
  __typename?: 'sc_assets_max_fields';
  approver_1?: Maybe<Scalars['String']>;
  approver_2?: Maybe<Scalars['String']>;
  approver_3?: Maybe<Scalars['String']>;
  approver_4?: Maybe<Scalars['String']>;
  approver_5?: Maybe<Scalars['String']>;
  asset_alias?: Maybe<Scalars['String']>;
  asset_group_annex_id?: Maybe<Scalars['Int']>;
  asset_type?: Maybe<Scalars['String']>;
  common_fee_charge?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  deposit_fee?: Maybe<Scalars['float8']>;
  dimensions_area?: Maybe<Scalars['float8']>;
  dimensions_length?: Maybe<Scalars['float8']>;
  dimensions_width?: Maybe<Scalars['float8']>;
  electricity_charge?: Maybe<Scalars['float8']>;
  floor_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  insurance_rental?: Maybe<Scalars['float8']>;
  layout_positions?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  other_fee?: Maybe<Scalars['String']>;
  rental_fixed_amount?: Maybe<Scalars['float8']>;
  rental_percent_amount?: Maybe<Scalars['float8']>;
  rental_type?: Maybe<Scalars['assets_rental_type_enum']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_bill_period_type?: Maybe<Scalars['assets_utility_bill_period_type_enum']>;
  uuid?: Maybe<Scalars['uuid']>;
  water_charge?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type ScAssetsMinFields = {
  __typename?: 'sc_assets_min_fields';
  approver_1?: Maybe<Scalars['String']>;
  approver_2?: Maybe<Scalars['String']>;
  approver_3?: Maybe<Scalars['String']>;
  approver_4?: Maybe<Scalars['String']>;
  approver_5?: Maybe<Scalars['String']>;
  asset_alias?: Maybe<Scalars['String']>;
  asset_group_annex_id?: Maybe<Scalars['Int']>;
  asset_type?: Maybe<Scalars['String']>;
  common_fee_charge?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  deposit_fee?: Maybe<Scalars['float8']>;
  dimensions_area?: Maybe<Scalars['float8']>;
  dimensions_length?: Maybe<Scalars['float8']>;
  dimensions_width?: Maybe<Scalars['float8']>;
  electricity_charge?: Maybe<Scalars['float8']>;
  floor_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  insurance_rental?: Maybe<Scalars['float8']>;
  layout_positions?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  other_fee?: Maybe<Scalars['String']>;
  rental_fixed_amount?: Maybe<Scalars['float8']>;
  rental_percent_amount?: Maybe<Scalars['float8']>;
  rental_type?: Maybe<Scalars['assets_rental_type_enum']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  utility_bill_period_type?: Maybe<Scalars['assets_utility_bill_period_type_enum']>;
  uuid?: Maybe<Scalars['uuid']>;
  water_charge?: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "spacecloud.assets" */
export type ScAssetsMutationResponse = {
  __typename?: 'sc_assets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ScAssets>;
};

/** Ordering options when selecting data from "spacecloud.assets". */
export type ScAssetsOrderBy = {
  approver_1?: InputMaybe<OrderBy>;
  approver_2?: InputMaybe<OrderBy>;
  approver_3?: InputMaybe<OrderBy>;
  approver_4?: InputMaybe<OrderBy>;
  approver_5?: InputMaybe<OrderBy>;
  asset?: InputMaybe<AssetsOrderBy>;
  asset_alias?: InputMaybe<OrderBy>;
  asset_group_annex_id?: InputMaybe<OrderBy>;
  asset_type?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  dimensions_area?: InputMaybe<OrderBy>;
  dimensions_length?: InputMaybe<OrderBy>;
  dimensions_width?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  floor_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  is_draft?: InputMaybe<OrderBy>;
  layout_positions?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  other_fee?: InputMaybe<OrderBy>;
  rental_fixed_amount?: InputMaybe<OrderBy>;
  rental_percent_amount?: InputMaybe<OrderBy>;
  rental_type?: InputMaybe<OrderBy>;
  sc_asset_group_annex?: InputMaybe<ScAssetGroupsAnnexOrderBy>;
  sc_bookings_aggregate?: InputMaybe<ScBookingsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  utility_bill_period_type?: InputMaybe<OrderBy>;
  uuid?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: spacecloud.assets */
export type ScAssetsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "spacecloud.assets" */
export enum ScAssetsSelectColumn {
  /** column name */
  Approver_1 = 'approver_1',
  /** column name */
  Approver_2 = 'approver_2',
  /** column name */
  Approver_3 = 'approver_3',
  /** column name */
  Approver_4 = 'approver_4',
  /** column name */
  Approver_5 = 'approver_5',
  /** column name */
  AssetAlias = 'asset_alias',
  /** column name */
  AssetGroupAnnexId = 'asset_group_annex_id',
  /** column name */
  AssetType = 'asset_type',
  /** column name */
  CommonFeeCharge = 'common_fee_charge',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepositFee = 'deposit_fee',
  /** column name */
  DimensionsArea = 'dimensions_area',
  /** column name */
  DimensionsLength = 'dimensions_length',
  /** column name */
  DimensionsWidth = 'dimensions_width',
  /** column name */
  ElectricityCharge = 'electricity_charge',
  /** column name */
  FloorId = 'floor_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceRental = 'insurance_rental',
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  LayoutPositions = 'layout_positions',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OtherFee = 'other_fee',
  /** column name */
  RentalFixedAmount = 'rental_fixed_amount',
  /** column name */
  RentalPercentAmount = 'rental_percent_amount',
  /** column name */
  RentalType = 'rental_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityBillPeriodType = 'utility_bill_period_type',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  WaterCharge = 'water_charge'
}

/** input type for updating data in table "spacecloud.assets" */
export type ScAssetsSetInput = {
  is_draft?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type ScAssetsStddevFields = {
  __typename?: 'sc_assets_stddev_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  dimensions_area?: Maybe<Scalars['Float']>;
  dimensions_length?: Maybe<Scalars['Float']>;
  dimensions_width?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  floor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_fixed_amount?: Maybe<Scalars['Float']>;
  rental_percent_amount?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ScAssetsStddevPopFields = {
  __typename?: 'sc_assets_stddev_pop_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  dimensions_area?: Maybe<Scalars['Float']>;
  dimensions_length?: Maybe<Scalars['Float']>;
  dimensions_width?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  floor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_fixed_amount?: Maybe<Scalars['Float']>;
  rental_percent_amount?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ScAssetsStddevSampFields = {
  __typename?: 'sc_assets_stddev_samp_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  dimensions_area?: Maybe<Scalars['Float']>;
  dimensions_length?: Maybe<Scalars['Float']>;
  dimensions_width?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  floor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_fixed_amount?: Maybe<Scalars['Float']>;
  rental_percent_amount?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "sc_assets" */
export type ScAssetsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ScAssetsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScAssetsStreamCursorValueInput = {
  approver_1?: InputMaybe<Scalars['String']>;
  approver_2?: InputMaybe<Scalars['String']>;
  approver_3?: InputMaybe<Scalars['String']>;
  approver_4?: InputMaybe<Scalars['String']>;
  approver_5?: InputMaybe<Scalars['String']>;
  asset_alias?: InputMaybe<Scalars['String']>;
  asset_group_annex_id?: InputMaybe<Scalars['Int']>;
  asset_type?: InputMaybe<Scalars['String']>;
  common_fee_charge?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deposit_fee?: InputMaybe<Scalars['float8']>;
  dimensions_area?: InputMaybe<Scalars['float8']>;
  dimensions_length?: InputMaybe<Scalars['float8']>;
  dimensions_width?: InputMaybe<Scalars['float8']>;
  electricity_charge?: InputMaybe<Scalars['float8']>;
  floor_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  insurance_rental?: InputMaybe<Scalars['float8']>;
  is_draft?: InputMaybe<Scalars['Boolean']>;
  layout_positions?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['String']>;
  other_fee?: InputMaybe<Scalars['String']>;
  rental_fixed_amount?: InputMaybe<Scalars['float8']>;
  rental_percent_amount?: InputMaybe<Scalars['float8']>;
  rental_type?: InputMaybe<Scalars['assets_rental_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  utility_bill_period_type?: InputMaybe<Scalars['assets_utility_bill_period_type_enum']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  water_charge?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type ScAssetsSumFields = {
  __typename?: 'sc_assets_sum_fields';
  asset_group_annex_id?: Maybe<Scalars['Int']>;
  common_fee_charge?: Maybe<Scalars['float8']>;
  deposit_fee?: Maybe<Scalars['float8']>;
  dimensions_area?: Maybe<Scalars['float8']>;
  dimensions_length?: Maybe<Scalars['float8']>;
  dimensions_width?: Maybe<Scalars['float8']>;
  electricity_charge?: Maybe<Scalars['float8']>;
  floor_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  insurance_rental?: Maybe<Scalars['float8']>;
  rental_fixed_amount?: Maybe<Scalars['float8']>;
  rental_percent_amount?: Maybe<Scalars['float8']>;
  water_charge?: Maybe<Scalars['float8']>;
};

export type ScAssetsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ScAssetsSetInput>;
  /** filter the rows which have to be updated */
  where: ScAssetsBoolExp;
};

/** aggregate var_pop on columns */
export type ScAssetsVarPopFields = {
  __typename?: 'sc_assets_var_pop_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  dimensions_area?: Maybe<Scalars['Float']>;
  dimensions_length?: Maybe<Scalars['Float']>;
  dimensions_width?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  floor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_fixed_amount?: Maybe<Scalars['Float']>;
  rental_percent_amount?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ScAssetsVarSampFields = {
  __typename?: 'sc_assets_var_samp_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  dimensions_area?: Maybe<Scalars['Float']>;
  dimensions_length?: Maybe<Scalars['Float']>;
  dimensions_width?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  floor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_fixed_amount?: Maybe<Scalars['Float']>;
  rental_percent_amount?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ScAssetsVarianceFields = {
  __typename?: 'sc_assets_variance_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  dimensions_area?: Maybe<Scalars['Float']>;
  dimensions_length?: Maybe<Scalars['Float']>;
  dimensions_width?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  floor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_fixed_amount?: Maybe<Scalars['Float']>;
  rental_percent_amount?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "spacecloud.bookings" */
export type ScBookings = {
  __typename?: 'sc_bookings';
  /** An object relationship */
  asset_booking: AssetBookings;
  asset_id: Scalars['Int'];
  common_fee_charge?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamp'];
  deposit_fee?: Maybe<Scalars['float8']>;
  electricity_charge?: Maybe<Scalars['float8']>;
  example_items?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  id: Scalars['Int'];
  insurance_rental?: Maybe<Scalars['float8']>;
  is_active: Scalars['Boolean'];
  last_name: Scalars['String'];
  message_to_booker?: Maybe<Scalars['String']>;
  more_info?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  other_fee?: Maybe<Scalars['String']>;
  period_type?: Maybe<ScEnumPeriodTypesEnum>;
  reject_reason?: Maybe<Scalars['String']>;
  rental_business_group?: Maybe<ScEnumRentalBusinessGroupsEnum>;
  rental_cost?: Maybe<Scalars['float8']>;
  rental_cost_percent?: Maybe<Scalars['float8']>;
  rental_type: ScEnumRentalTypesEnum;
  /** An object relationship */
  sc_asset: ScAssets;
  shop_name: Scalars['String'];
  shop_type?: Maybe<Scalars['bookings_shop_type_enum']>;
  tel: Scalars['String'];
  updated_at: Scalars['timestamp'];
  utility_bill_period_type?: Maybe<ScEnumUtilityBillPeriodTypesEnum>;
  uuid: Scalars['uuid'];
  water_charge?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "spacecloud.bookings" */
export type ScBookingsAggregate = {
  __typename?: 'sc_bookings_aggregate';
  aggregate?: Maybe<ScBookingsAggregateFields>;
  nodes: Array<ScBookings>;
};

export type ScBookingsAggregateBoolExp = {
  avg?: InputMaybe<ScBookingsAggregateBoolExpAvg>;
  bool_and?: InputMaybe<ScBookingsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ScBookingsAggregateBoolExpBoolOr>;
  corr?: InputMaybe<ScBookingsAggregateBoolExpCorr>;
  count?: InputMaybe<ScBookingsAggregateBoolExpCount>;
  covar_samp?: InputMaybe<ScBookingsAggregateBoolExpCovarSamp>;
  max?: InputMaybe<ScBookingsAggregateBoolExpMax>;
  min?: InputMaybe<ScBookingsAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<ScBookingsAggregateBoolExpStddevSamp>;
  sum?: InputMaybe<ScBookingsAggregateBoolExpSum>;
  var_samp?: InputMaybe<ScBookingsAggregateBoolExpVarSamp>;
};

export type ScBookingsAggregateBoolExpAvg = {
  arguments: ScBookingsSelectColumnScBookingsAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ScBookingsAggregateBoolExpBoolAnd = {
  arguments: ScBookingsSelectColumnScBookingsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ScBookingsAggregateBoolExpBoolOr = {
  arguments: ScBookingsSelectColumnScBookingsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ScBookingsAggregateBoolExpCorr = {
  arguments: ScBookingsAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ScBookingsAggregateBoolExpCorrArguments = {
  X: ScBookingsSelectColumnScBookingsAggregateBoolExpCorrArgumentsColumns;
  Y: ScBookingsSelectColumnScBookingsAggregateBoolExpCorrArgumentsColumns;
};

export type ScBookingsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ScBookingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: IntComparisonExp;
};

export type ScBookingsAggregateBoolExpCovarSamp = {
  arguments: ScBookingsAggregateBoolExpCovarSampArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ScBookingsAggregateBoolExpCovarSampArguments = {
  X: ScBookingsSelectColumnScBookingsAggregateBoolExpCovarSampArgumentsColumns;
  Y: ScBookingsSelectColumnScBookingsAggregateBoolExpCovarSampArgumentsColumns;
};

export type ScBookingsAggregateBoolExpMax = {
  arguments: ScBookingsSelectColumnScBookingsAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ScBookingsAggregateBoolExpMin = {
  arguments: ScBookingsSelectColumnScBookingsAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ScBookingsAggregateBoolExpStddevSamp = {
  arguments: ScBookingsSelectColumnScBookingsAggregateBoolExpStddevSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ScBookingsAggregateBoolExpSum = {
  arguments: ScBookingsSelectColumnScBookingsAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: Float8ComparisonExp;
};

export type ScBookingsAggregateBoolExpVarSamp = {
  arguments: ScBookingsSelectColumnScBookingsAggregateBoolExpVarSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScBookingsBoolExp>;
  predicate: Float8ComparisonExp;
};

/** aggregate fields of "spacecloud.bookings" */
export type ScBookingsAggregateFields = {
  __typename?: 'sc_bookings_aggregate_fields';
  avg?: Maybe<ScBookingsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ScBookingsMaxFields>;
  min?: Maybe<ScBookingsMinFields>;
  stddev?: Maybe<ScBookingsStddevFields>;
  stddev_pop?: Maybe<ScBookingsStddevPopFields>;
  stddev_samp?: Maybe<ScBookingsStddevSampFields>;
  sum?: Maybe<ScBookingsSumFields>;
  var_pop?: Maybe<ScBookingsVarPopFields>;
  var_samp?: Maybe<ScBookingsVarSampFields>;
  variance?: Maybe<ScBookingsVarianceFields>;
};


/** aggregate fields of "spacecloud.bookings" */
export type ScBookingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScBookingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "spacecloud.bookings" */
export type ScBookingsAggregateOrderBy = {
  avg?: InputMaybe<ScBookingsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ScBookingsMaxOrderBy>;
  min?: InputMaybe<ScBookingsMinOrderBy>;
  stddev?: InputMaybe<ScBookingsStddevOrderBy>;
  stddev_pop?: InputMaybe<ScBookingsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ScBookingsStddevSampOrderBy>;
  sum?: InputMaybe<ScBookingsSumOrderBy>;
  var_pop?: InputMaybe<ScBookingsVarPopOrderBy>;
  var_samp?: InputMaybe<ScBookingsVarSampOrderBy>;
  variance?: InputMaybe<ScBookingsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ScBookingsAvgFields = {
  __typename?: 'sc_bookings_avg_fields';
  asset_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_cost?: Maybe<Scalars['Float']>;
  rental_cost_percent?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "spacecloud.bookings" */
export type ScBookingsAvgOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "spacecloud.bookings". All fields are combined with a logical 'AND'. */
export type ScBookingsBoolExp = {
  _and?: InputMaybe<Array<ScBookingsBoolExp>>;
  _not?: InputMaybe<ScBookingsBoolExp>;
  _or?: InputMaybe<Array<ScBookingsBoolExp>>;
  asset_booking?: InputMaybe<AssetBookingsBoolExp>;
  asset_id?: InputMaybe<IntComparisonExp>;
  common_fee_charge?: InputMaybe<Float8ComparisonExp>;
  created_at?: InputMaybe<TimestampComparisonExp>;
  deposit_fee?: InputMaybe<Float8ComparisonExp>;
  electricity_charge?: InputMaybe<Float8ComparisonExp>;
  example_items?: InputMaybe<StringComparisonExp>;
  first_name?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  insurance_rental?: InputMaybe<Float8ComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  last_name?: InputMaybe<StringComparisonExp>;
  message_to_booker?: InputMaybe<StringComparisonExp>;
  more_info?: InputMaybe<StringComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  organization_id?: InputMaybe<StringComparisonExp>;
  other_fee?: InputMaybe<StringComparisonExp>;
  period_type?: InputMaybe<ScEnumPeriodTypesEnumComparisonExp>;
  reject_reason?: InputMaybe<StringComparisonExp>;
  rental_business_group?: InputMaybe<ScEnumRentalBusinessGroupsEnumComparisonExp>;
  rental_cost?: InputMaybe<Float8ComparisonExp>;
  rental_cost_percent?: InputMaybe<Float8ComparisonExp>;
  rental_type?: InputMaybe<ScEnumRentalTypesEnumComparisonExp>;
  sc_asset?: InputMaybe<ScAssetsBoolExp>;
  shop_name?: InputMaybe<StringComparisonExp>;
  shop_type?: InputMaybe<BookingsShopTypeEnumComparisonExp>;
  tel?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestampComparisonExp>;
  utility_bill_period_type?: InputMaybe<ScEnumUtilityBillPeriodTypesEnumComparisonExp>;
  uuid?: InputMaybe<UuidComparisonExp>;
  water_charge?: InputMaybe<Float8ComparisonExp>;
};

/** input type for incrementing numeric columns in table "spacecloud.bookings" */
export type ScBookingsIncInput = {
  common_fee_charge?: InputMaybe<Scalars['float8']>;
  deposit_fee?: InputMaybe<Scalars['float8']>;
  electricity_charge?: InputMaybe<Scalars['float8']>;
  insurance_rental?: InputMaybe<Scalars['float8']>;
  rental_cost?: InputMaybe<Scalars['float8']>;
  rental_cost_percent?: InputMaybe<Scalars['float8']>;
  water_charge?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type ScBookingsMaxFields = {
  __typename?: 'sc_bookings_max_fields';
  asset_id?: Maybe<Scalars['Int']>;
  common_fee_charge?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  deposit_fee?: Maybe<Scalars['float8']>;
  electricity_charge?: Maybe<Scalars['float8']>;
  example_items?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  insurance_rental?: Maybe<Scalars['float8']>;
  last_name?: Maybe<Scalars['String']>;
  message_to_booker?: Maybe<Scalars['String']>;
  more_info?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  other_fee?: Maybe<Scalars['String']>;
  reject_reason?: Maybe<Scalars['String']>;
  rental_cost?: Maybe<Scalars['float8']>;
  rental_cost_percent?: Maybe<Scalars['float8']>;
  shop_name?: Maybe<Scalars['String']>;
  shop_type?: Maybe<Scalars['bookings_shop_type_enum']>;
  tel?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uuid?: Maybe<Scalars['uuid']>;
  water_charge?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "spacecloud.bookings" */
export type ScBookingsMaxOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  example_items?: InputMaybe<OrderBy>;
  first_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  last_name?: InputMaybe<OrderBy>;
  message_to_booker?: InputMaybe<OrderBy>;
  more_info?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  other_fee?: InputMaybe<OrderBy>;
  reject_reason?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  shop_name?: InputMaybe<OrderBy>;
  shop_type?: InputMaybe<OrderBy>;
  tel?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  uuid?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ScBookingsMinFields = {
  __typename?: 'sc_bookings_min_fields';
  asset_id?: Maybe<Scalars['Int']>;
  common_fee_charge?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  deposit_fee?: Maybe<Scalars['float8']>;
  electricity_charge?: Maybe<Scalars['float8']>;
  example_items?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  insurance_rental?: Maybe<Scalars['float8']>;
  last_name?: Maybe<Scalars['String']>;
  message_to_booker?: Maybe<Scalars['String']>;
  more_info?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  other_fee?: Maybe<Scalars['String']>;
  reject_reason?: Maybe<Scalars['String']>;
  rental_cost?: Maybe<Scalars['float8']>;
  rental_cost_percent?: Maybe<Scalars['float8']>;
  shop_name?: Maybe<Scalars['String']>;
  shop_type?: Maybe<Scalars['bookings_shop_type_enum']>;
  tel?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uuid?: Maybe<Scalars['uuid']>;
  water_charge?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "spacecloud.bookings" */
export type ScBookingsMinOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  example_items?: InputMaybe<OrderBy>;
  first_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  last_name?: InputMaybe<OrderBy>;
  message_to_booker?: InputMaybe<OrderBy>;
  more_info?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  other_fee?: InputMaybe<OrderBy>;
  reject_reason?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  shop_name?: InputMaybe<OrderBy>;
  shop_type?: InputMaybe<OrderBy>;
  tel?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  uuid?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "spacecloud.bookings" */
export type ScBookingsMutationResponse = {
  __typename?: 'sc_bookings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ScBookings>;
};

/** Ordering options when selecting data from "spacecloud.bookings". */
export type ScBookingsOrderBy = {
  asset_booking?: InputMaybe<AssetBookingsOrderBy>;
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  example_items?: InputMaybe<OrderBy>;
  first_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  last_name?: InputMaybe<OrderBy>;
  message_to_booker?: InputMaybe<OrderBy>;
  more_info?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  other_fee?: InputMaybe<OrderBy>;
  period_type?: InputMaybe<OrderBy>;
  reject_reason?: InputMaybe<OrderBy>;
  rental_business_group?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  rental_type?: InputMaybe<OrderBy>;
  sc_asset?: InputMaybe<ScAssetsOrderBy>;
  shop_name?: InputMaybe<OrderBy>;
  shop_type?: InputMaybe<OrderBy>;
  tel?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  utility_bill_period_type?: InputMaybe<OrderBy>;
  uuid?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: spacecloud.bookings */
export type ScBookingsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumn {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CommonFeeCharge = 'common_fee_charge',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepositFee = 'deposit_fee',
  /** column name */
  ElectricityCharge = 'electricity_charge',
  /** column name */
  ExampleItems = 'example_items',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceRental = 'insurance_rental',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MessageToBooker = 'message_to_booker',
  /** column name */
  MoreInfo = 'more_info',
  /** column name */
  Note = 'note',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OtherFee = 'other_fee',
  /** column name */
  PeriodType = 'period_type',
  /** column name */
  RejectReason = 'reject_reason',
  /** column name */
  RentalBusinessGroup = 'rental_business_group',
  /** column name */
  RentalCost = 'rental_cost',
  /** column name */
  RentalCostPercent = 'rental_cost_percent',
  /** column name */
  RentalType = 'rental_type',
  /** column name */
  ShopName = 'shop_name',
  /** column name */
  ShopType = 'shop_type',
  /** column name */
  Tel = 'tel',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilityBillPeriodType = 'utility_bill_period_type',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  WaterCharge = 'water_charge'
}

/** select "sc_bookings_aggregate_bool_exp_avg_arguments_columns" columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumnScBookingsAggregateBoolExpAvgArgumentsColumns {
  /** column name */
  CommonFeeCharge = 'common_fee_charge',
  /** column name */
  DepositFee = 'deposit_fee',
  /** column name */
  ElectricityCharge = 'electricity_charge',
  /** column name */
  InsuranceRental = 'insurance_rental',
  /** column name */
  RentalCost = 'rental_cost',
  /** column name */
  RentalCostPercent = 'rental_cost_percent',
  /** column name */
  WaterCharge = 'water_charge'
}

/** select "sc_bookings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumnScBookingsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

/** select "sc_bookings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumnScBookingsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

/** select "sc_bookings_aggregate_bool_exp_corr_arguments_columns" columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumnScBookingsAggregateBoolExpCorrArgumentsColumns {
  /** column name */
  CommonFeeCharge = 'common_fee_charge',
  /** column name */
  DepositFee = 'deposit_fee',
  /** column name */
  ElectricityCharge = 'electricity_charge',
  /** column name */
  InsuranceRental = 'insurance_rental',
  /** column name */
  RentalCost = 'rental_cost',
  /** column name */
  RentalCostPercent = 'rental_cost_percent',
  /** column name */
  WaterCharge = 'water_charge'
}

/** select "sc_bookings_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumnScBookingsAggregateBoolExpCovarSampArgumentsColumns {
  /** column name */
  CommonFeeCharge = 'common_fee_charge',
  /** column name */
  DepositFee = 'deposit_fee',
  /** column name */
  ElectricityCharge = 'electricity_charge',
  /** column name */
  InsuranceRental = 'insurance_rental',
  /** column name */
  RentalCost = 'rental_cost',
  /** column name */
  RentalCostPercent = 'rental_cost_percent',
  /** column name */
  WaterCharge = 'water_charge'
}

/** select "sc_bookings_aggregate_bool_exp_max_arguments_columns" columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumnScBookingsAggregateBoolExpMaxArgumentsColumns {
  /** column name */
  CommonFeeCharge = 'common_fee_charge',
  /** column name */
  DepositFee = 'deposit_fee',
  /** column name */
  ElectricityCharge = 'electricity_charge',
  /** column name */
  InsuranceRental = 'insurance_rental',
  /** column name */
  RentalCost = 'rental_cost',
  /** column name */
  RentalCostPercent = 'rental_cost_percent',
  /** column name */
  WaterCharge = 'water_charge'
}

/** select "sc_bookings_aggregate_bool_exp_min_arguments_columns" columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumnScBookingsAggregateBoolExpMinArgumentsColumns {
  /** column name */
  CommonFeeCharge = 'common_fee_charge',
  /** column name */
  DepositFee = 'deposit_fee',
  /** column name */
  ElectricityCharge = 'electricity_charge',
  /** column name */
  InsuranceRental = 'insurance_rental',
  /** column name */
  RentalCost = 'rental_cost',
  /** column name */
  RentalCostPercent = 'rental_cost_percent',
  /** column name */
  WaterCharge = 'water_charge'
}

/** select "sc_bookings_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumnScBookingsAggregateBoolExpStddevSampArgumentsColumns {
  /** column name */
  CommonFeeCharge = 'common_fee_charge',
  /** column name */
  DepositFee = 'deposit_fee',
  /** column name */
  ElectricityCharge = 'electricity_charge',
  /** column name */
  InsuranceRental = 'insurance_rental',
  /** column name */
  RentalCost = 'rental_cost',
  /** column name */
  RentalCostPercent = 'rental_cost_percent',
  /** column name */
  WaterCharge = 'water_charge'
}

/** select "sc_bookings_aggregate_bool_exp_sum_arguments_columns" columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumnScBookingsAggregateBoolExpSumArgumentsColumns {
  /** column name */
  CommonFeeCharge = 'common_fee_charge',
  /** column name */
  DepositFee = 'deposit_fee',
  /** column name */
  ElectricityCharge = 'electricity_charge',
  /** column name */
  InsuranceRental = 'insurance_rental',
  /** column name */
  RentalCost = 'rental_cost',
  /** column name */
  RentalCostPercent = 'rental_cost_percent',
  /** column name */
  WaterCharge = 'water_charge'
}

/** select "sc_bookings_aggregate_bool_exp_var_samp_arguments_columns" columns of table "spacecloud.bookings" */
export enum ScBookingsSelectColumnScBookingsAggregateBoolExpVarSampArgumentsColumns {
  /** column name */
  CommonFeeCharge = 'common_fee_charge',
  /** column name */
  DepositFee = 'deposit_fee',
  /** column name */
  ElectricityCharge = 'electricity_charge',
  /** column name */
  InsuranceRental = 'insurance_rental',
  /** column name */
  RentalCost = 'rental_cost',
  /** column name */
  RentalCostPercent = 'rental_cost_percent',
  /** column name */
  WaterCharge = 'water_charge'
}

/** input type for updating data in table "spacecloud.bookings" */
export type ScBookingsSetInput = {
  common_fee_charge?: InputMaybe<Scalars['float8']>;
  deposit_fee?: InputMaybe<Scalars['float8']>;
  electricity_charge?: InputMaybe<Scalars['float8']>;
  first_name?: InputMaybe<Scalars['String']>;
  insurance_rental?: InputMaybe<Scalars['float8']>;
  last_name?: InputMaybe<Scalars['String']>;
  message_to_booker?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  other_fee?: InputMaybe<Scalars['String']>;
  period_type?: InputMaybe<ScEnumPeriodTypesEnum>;
  reject_reason?: InputMaybe<Scalars['String']>;
  rental_business_group?: InputMaybe<ScEnumRentalBusinessGroupsEnum>;
  rental_cost?: InputMaybe<Scalars['float8']>;
  rental_cost_percent?: InputMaybe<Scalars['float8']>;
  rental_type?: InputMaybe<ScEnumRentalTypesEnum>;
  shop_name?: InputMaybe<Scalars['String']>;
  shop_type?: InputMaybe<Scalars['bookings_shop_type_enum']>;
  tel?: InputMaybe<Scalars['String']>;
  utility_bill_period_type?: InputMaybe<ScEnumUtilityBillPeriodTypesEnum>;
  water_charge?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type ScBookingsStddevFields = {
  __typename?: 'sc_bookings_stddev_fields';
  asset_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_cost?: Maybe<Scalars['Float']>;
  rental_cost_percent?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "spacecloud.bookings" */
export type ScBookingsStddevOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ScBookingsStddevPopFields = {
  __typename?: 'sc_bookings_stddev_pop_fields';
  asset_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_cost?: Maybe<Scalars['Float']>;
  rental_cost_percent?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "spacecloud.bookings" */
export type ScBookingsStddevPopOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ScBookingsStddevSampFields = {
  __typename?: 'sc_bookings_stddev_samp_fields';
  asset_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_cost?: Maybe<Scalars['Float']>;
  rental_cost_percent?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "spacecloud.bookings" */
export type ScBookingsStddevSampOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ScBookingsSumFields = {
  __typename?: 'sc_bookings_sum_fields';
  asset_id?: Maybe<Scalars['Int']>;
  common_fee_charge?: Maybe<Scalars['float8']>;
  deposit_fee?: Maybe<Scalars['float8']>;
  electricity_charge?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  insurance_rental?: Maybe<Scalars['float8']>;
  rental_cost?: Maybe<Scalars['float8']>;
  rental_cost_percent?: Maybe<Scalars['float8']>;
  water_charge?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "spacecloud.bookings" */
export type ScBookingsSumOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

export type ScBookingsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ScBookingsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ScBookingsSetInput>;
  /** filter the rows which have to be updated */
  where: ScBookingsBoolExp;
};

/** aggregate var_pop on columns */
export type ScBookingsVarPopFields = {
  __typename?: 'sc_bookings_var_pop_fields';
  asset_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_cost?: Maybe<Scalars['Float']>;
  rental_cost_percent?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "spacecloud.bookings" */
export type ScBookingsVarPopOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ScBookingsVarSampFields = {
  __typename?: 'sc_bookings_var_samp_fields';
  asset_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_cost?: Maybe<Scalars['Float']>;
  rental_cost_percent?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "spacecloud.bookings" */
export type ScBookingsVarSampOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ScBookingsVarianceFields = {
  __typename?: 'sc_bookings_variance_fields';
  asset_id?: Maybe<Scalars['Float']>;
  common_fee_charge?: Maybe<Scalars['Float']>;
  deposit_fee?: Maybe<Scalars['Float']>;
  electricity_charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rental?: Maybe<Scalars['Float']>;
  rental_cost?: Maybe<Scalars['Float']>;
  rental_cost_percent?: Maybe<Scalars['Float']>;
  water_charge?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "spacecloud.bookings" */
export type ScBookingsVarianceOrderBy = {
  asset_id?: InputMaybe<OrderBy>;
  common_fee_charge?: InputMaybe<OrderBy>;
  deposit_fee?: InputMaybe<OrderBy>;
  electricity_charge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurance_rental?: InputMaybe<OrderBy>;
  rental_cost?: InputMaybe<OrderBy>;
  rental_cost_percent?: InputMaybe<OrderBy>;
  water_charge?: InputMaybe<OrderBy>;
};

/** columns and relationships of "spacecloud.cctv_cameras" */
export type ScCctvCameras = {
  __typename?: 'sc_cctv_cameras';
  alias_name?: Maybe<Scalars['String']>;
  asset_group_annex_id: Scalars['Int'];
  count_type: ScEnumCctvCamerasCountTypeEnum;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  sc_asset_group_annex: ScAssetGroupsAnnex;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "spacecloud.cctv_cameras" */
export type ScCctvCamerasAggregate = {
  __typename?: 'sc_cctv_cameras_aggregate';
  aggregate?: Maybe<ScCctvCamerasAggregateFields>;
  nodes: Array<ScCctvCameras>;
};

export type ScCctvCamerasAggregateBoolExp = {
  count?: InputMaybe<ScCctvCamerasAggregateBoolExpCount>;
};

export type ScCctvCamerasAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ScCctvCamerasSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScCctvCamerasBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "spacecloud.cctv_cameras" */
export type ScCctvCamerasAggregateFields = {
  __typename?: 'sc_cctv_cameras_aggregate_fields';
  avg?: Maybe<ScCctvCamerasAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ScCctvCamerasMaxFields>;
  min?: Maybe<ScCctvCamerasMinFields>;
  stddev?: Maybe<ScCctvCamerasStddevFields>;
  stddev_pop?: Maybe<ScCctvCamerasStddevPopFields>;
  stddev_samp?: Maybe<ScCctvCamerasStddevSampFields>;
  sum?: Maybe<ScCctvCamerasSumFields>;
  var_pop?: Maybe<ScCctvCamerasVarPopFields>;
  var_samp?: Maybe<ScCctvCamerasVarSampFields>;
  variance?: Maybe<ScCctvCamerasVarianceFields>;
};


/** aggregate fields of "spacecloud.cctv_cameras" */
export type ScCctvCamerasAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScCctvCamerasSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasAggregateOrderBy = {
  avg?: InputMaybe<ScCctvCamerasAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ScCctvCamerasMaxOrderBy>;
  min?: InputMaybe<ScCctvCamerasMinOrderBy>;
  stddev?: InputMaybe<ScCctvCamerasStddevOrderBy>;
  stddev_pop?: InputMaybe<ScCctvCamerasStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ScCctvCamerasStddevSampOrderBy>;
  sum?: InputMaybe<ScCctvCamerasSumOrderBy>;
  var_pop?: InputMaybe<ScCctvCamerasVarPopOrderBy>;
  var_samp?: InputMaybe<ScCctvCamerasVarSampOrderBy>;
  variance?: InputMaybe<ScCctvCamerasVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ScCctvCamerasAvgFields = {
  __typename?: 'sc_cctv_cameras_avg_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasAvgOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "spacecloud.cctv_cameras". All fields are combined with a logical 'AND'. */
export type ScCctvCamerasBoolExp = {
  _and?: InputMaybe<Array<ScCctvCamerasBoolExp>>;
  _not?: InputMaybe<ScCctvCamerasBoolExp>;
  _or?: InputMaybe<Array<ScCctvCamerasBoolExp>>;
  alias_name?: InputMaybe<StringComparisonExp>;
  asset_group_annex_id?: InputMaybe<IntComparisonExp>;
  count_type?: InputMaybe<ScEnumCctvCamerasCountTypeEnumComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  sc_asset_group_annex?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type ScCctvCamerasMaxFields = {
  __typename?: 'sc_cctv_cameras_max_fields';
  alias_name?: Maybe<Scalars['String']>;
  asset_group_annex_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasMaxOrderBy = {
  alias_name?: InputMaybe<OrderBy>;
  asset_group_annex_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ScCctvCamerasMinFields = {
  __typename?: 'sc_cctv_cameras_min_fields';
  alias_name?: Maybe<Scalars['String']>;
  asset_group_annex_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasMinOrderBy = {
  alias_name?: InputMaybe<OrderBy>;
  asset_group_annex_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "spacecloud.cctv_cameras". */
export type ScCctvCamerasOrderBy = {
  alias_name?: InputMaybe<OrderBy>;
  asset_group_annex_id?: InputMaybe<OrderBy>;
  count_type?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sc_asset_group_annex?: InputMaybe<ScAssetGroupsAnnexOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** select columns of table "spacecloud.cctv_cameras" */
export enum ScCctvCamerasSelectColumn {
  /** column name */
  AliasName = 'alias_name',
  /** column name */
  AssetGroupAnnexId = 'asset_group_annex_id',
  /** column name */
  CountType = 'count_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type ScCctvCamerasStddevFields = {
  __typename?: 'sc_cctv_cameras_stddev_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasStddevOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ScCctvCamerasStddevPopFields = {
  __typename?: 'sc_cctv_cameras_stddev_pop_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasStddevPopOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ScCctvCamerasStddevSampFields = {
  __typename?: 'sc_cctv_cameras_stddev_samp_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasStddevSampOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "sc_cctv_cameras" */
export type ScCctvCamerasStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ScCctvCamerasStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScCctvCamerasStreamCursorValueInput = {
  alias_name?: InputMaybe<Scalars['String']>;
  asset_group_annex_id?: InputMaybe<Scalars['Int']>;
  count_type?: InputMaybe<ScEnumCctvCamerasCountTypeEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ScCctvCamerasSumFields = {
  __typename?: 'sc_cctv_cameras_sum_fields';
  asset_group_annex_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasSumOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ScCctvCamerasVarPopFields = {
  __typename?: 'sc_cctv_cameras_var_pop_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasVarPopOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ScCctvCamerasVarSampFields = {
  __typename?: 'sc_cctv_cameras_var_samp_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasVarSampOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ScCctvCamerasVarianceFields = {
  __typename?: 'sc_cctv_cameras_variance_fields';
  asset_group_annex_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "spacecloud.cctv_cameras" */
export type ScCctvCamerasVarianceOrderBy = {
  asset_group_annex_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

export enum ScEnumCctvCamerasCountTypeEnum {
  Enter = 'enter',
  EnterAndExit = 'enter_and_exit',
  Exit = 'exit'
}

/** Boolean expression to compare columns of type "sc_enum_cctv_cameras_count_type_enum". All fields are combined with logical 'AND'. */
export type ScEnumCctvCamerasCountTypeEnumComparisonExp = {
  _eq?: InputMaybe<ScEnumCctvCamerasCountTypeEnum>;
  _in?: InputMaybe<Array<ScEnumCctvCamerasCountTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ScEnumCctvCamerasCountTypeEnum>;
  _nin?: InputMaybe<Array<ScEnumCctvCamerasCountTypeEnum>>;
};

/** columns and relationships of "spacecloud.enum_period_types" */
export type ScEnumPeriodTypes = {
  __typename?: 'sc_enum_period_types';
  /** An array relationship */
  bookings: Array<ScBookings>;
  /** An aggregate relationship */
  bookings_aggregate: ScBookingsAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "spacecloud.enum_period_types" */
export type ScEnumPeriodTypesBookingsArgs = {
  distinct_on?: InputMaybe<Array<ScBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScBookingsOrderBy>>;
  where?: InputMaybe<ScBookingsBoolExp>;
};


/** columns and relationships of "spacecloud.enum_period_types" */
export type ScEnumPeriodTypesBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScBookingsOrderBy>>;
  where?: InputMaybe<ScBookingsBoolExp>;
};

/** aggregated selection of "spacecloud.enum_period_types" */
export type ScEnumPeriodTypesAggregate = {
  __typename?: 'sc_enum_period_types_aggregate';
  aggregate?: Maybe<ScEnumPeriodTypesAggregateFields>;
  nodes: Array<ScEnumPeriodTypes>;
};

/** aggregate fields of "spacecloud.enum_period_types" */
export type ScEnumPeriodTypesAggregateFields = {
  __typename?: 'sc_enum_period_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ScEnumPeriodTypesMaxFields>;
  min?: Maybe<ScEnumPeriodTypesMinFields>;
};


/** aggregate fields of "spacecloud.enum_period_types" */
export type ScEnumPeriodTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScEnumPeriodTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "spacecloud.enum_period_types". All fields are combined with a logical 'AND'. */
export type ScEnumPeriodTypesBoolExp = {
  _and?: InputMaybe<Array<ScEnumPeriodTypesBoolExp>>;
  _not?: InputMaybe<ScEnumPeriodTypesBoolExp>;
  _or?: InputMaybe<Array<ScEnumPeriodTypesBoolExp>>;
  bookings?: InputMaybe<ScBookingsBoolExp>;
  bookings_aggregate?: InputMaybe<ScBookingsAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum ScEnumPeriodTypesEnum {
  Daily = 'daily',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

/** Boolean expression to compare columns of type "sc_enum_period_types_enum". All fields are combined with logical 'AND'. */
export type ScEnumPeriodTypesEnumComparisonExp = {
  _eq?: InputMaybe<ScEnumPeriodTypesEnum>;
  _in?: InputMaybe<Array<ScEnumPeriodTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ScEnumPeriodTypesEnum>;
  _nin?: InputMaybe<Array<ScEnumPeriodTypesEnum>>;
};

/** aggregate max on columns */
export type ScEnumPeriodTypesMaxFields = {
  __typename?: 'sc_enum_period_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ScEnumPeriodTypesMinFields = {
  __typename?: 'sc_enum_period_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "spacecloud.enum_period_types". */
export type ScEnumPeriodTypesOrderBy = {
  bookings_aggregate?: InputMaybe<ScBookingsAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "spacecloud.enum_period_types" */
export enum ScEnumPeriodTypesSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "sc_enum_period_types" */
export type ScEnumPeriodTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ScEnumPeriodTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScEnumPeriodTypesStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "spacecloud.enum_rental_business_groups" */
export type ScEnumRentalBusinessGroups = {
  __typename?: 'sc_enum_rental_business_groups';
  /** An array relationship */
  bookings: Array<ScBookings>;
  /** An aggregate relationship */
  bookings_aggregate: ScBookingsAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "spacecloud.enum_rental_business_groups" */
export type ScEnumRentalBusinessGroupsBookingsArgs = {
  distinct_on?: InputMaybe<Array<ScBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScBookingsOrderBy>>;
  where?: InputMaybe<ScBookingsBoolExp>;
};


/** columns and relationships of "spacecloud.enum_rental_business_groups" */
export type ScEnumRentalBusinessGroupsBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScBookingsOrderBy>>;
  where?: InputMaybe<ScBookingsBoolExp>;
};

/** aggregated selection of "spacecloud.enum_rental_business_groups" */
export type ScEnumRentalBusinessGroupsAggregate = {
  __typename?: 'sc_enum_rental_business_groups_aggregate';
  aggregate?: Maybe<ScEnumRentalBusinessGroupsAggregateFields>;
  nodes: Array<ScEnumRentalBusinessGroups>;
};

/** aggregate fields of "spacecloud.enum_rental_business_groups" */
export type ScEnumRentalBusinessGroupsAggregateFields = {
  __typename?: 'sc_enum_rental_business_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ScEnumRentalBusinessGroupsMaxFields>;
  min?: Maybe<ScEnumRentalBusinessGroupsMinFields>;
};


/** aggregate fields of "spacecloud.enum_rental_business_groups" */
export type ScEnumRentalBusinessGroupsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScEnumRentalBusinessGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "spacecloud.enum_rental_business_groups". All fields are combined with a logical 'AND'. */
export type ScEnumRentalBusinessGroupsBoolExp = {
  _and?: InputMaybe<Array<ScEnumRentalBusinessGroupsBoolExp>>;
  _not?: InputMaybe<ScEnumRentalBusinessGroupsBoolExp>;
  _or?: InputMaybe<Array<ScEnumRentalBusinessGroupsBoolExp>>;
  bookings?: InputMaybe<ScBookingsBoolExp>;
  bookings_aggregate?: InputMaybe<ScBookingsAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum ScEnumRentalBusinessGroupsEnum {
  Appetizers = 'appetizers',
  Clinic = 'clinic',
  CommonStuff = 'common_stuff',
  DrinkShop = 'drink_shop',
  Fashion = 'fashion',
  Lifestyle = 'lifestyle',
  MainDishes = 'main_dishes',
  Others = 'others',
  Specialty = 'specialty'
}

/** Boolean expression to compare columns of type "sc_enum_rental_business_groups_enum". All fields are combined with logical 'AND'. */
export type ScEnumRentalBusinessGroupsEnumComparisonExp = {
  _eq?: InputMaybe<ScEnumRentalBusinessGroupsEnum>;
  _in?: InputMaybe<Array<ScEnumRentalBusinessGroupsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ScEnumRentalBusinessGroupsEnum>;
  _nin?: InputMaybe<Array<ScEnumRentalBusinessGroupsEnum>>;
};

/** aggregate max on columns */
export type ScEnumRentalBusinessGroupsMaxFields = {
  __typename?: 'sc_enum_rental_business_groups_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ScEnumRentalBusinessGroupsMinFields = {
  __typename?: 'sc_enum_rental_business_groups_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "spacecloud.enum_rental_business_groups". */
export type ScEnumRentalBusinessGroupsOrderBy = {
  bookings_aggregate?: InputMaybe<ScBookingsAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "spacecloud.enum_rental_business_groups" */
export enum ScEnumRentalBusinessGroupsSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "sc_enum_rental_business_groups" */
export type ScEnumRentalBusinessGroupsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ScEnumRentalBusinessGroupsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScEnumRentalBusinessGroupsStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "spacecloud.enum_rental_types" */
export type ScEnumRentalTypes = {
  __typename?: 'sc_enum_rental_types';
  /** An array relationship */
  bookings: Array<ScBookings>;
  /** An aggregate relationship */
  bookings_aggregate: ScBookingsAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "spacecloud.enum_rental_types" */
export type ScEnumRentalTypesBookingsArgs = {
  distinct_on?: InputMaybe<Array<ScBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScBookingsOrderBy>>;
  where?: InputMaybe<ScBookingsBoolExp>;
};


/** columns and relationships of "spacecloud.enum_rental_types" */
export type ScEnumRentalTypesBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScBookingsOrderBy>>;
  where?: InputMaybe<ScBookingsBoolExp>;
};

/** aggregated selection of "spacecloud.enum_rental_types" */
export type ScEnumRentalTypesAggregate = {
  __typename?: 'sc_enum_rental_types_aggregate';
  aggregate?: Maybe<ScEnumRentalTypesAggregateFields>;
  nodes: Array<ScEnumRentalTypes>;
};

/** aggregate fields of "spacecloud.enum_rental_types" */
export type ScEnumRentalTypesAggregateFields = {
  __typename?: 'sc_enum_rental_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ScEnumRentalTypesMaxFields>;
  min?: Maybe<ScEnumRentalTypesMinFields>;
};


/** aggregate fields of "spacecloud.enum_rental_types" */
export type ScEnumRentalTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScEnumRentalTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "spacecloud.enum_rental_types". All fields are combined with a logical 'AND'. */
export type ScEnumRentalTypesBoolExp = {
  _and?: InputMaybe<Array<ScEnumRentalTypesBoolExp>>;
  _not?: InputMaybe<ScEnumRentalTypesBoolExp>;
  _or?: InputMaybe<Array<ScEnumRentalTypesBoolExp>>;
  bookings?: InputMaybe<ScBookingsBoolExp>;
  bookings_aggregate?: InputMaybe<ScBookingsAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum ScEnumRentalTypesEnum {
  Fixed = 'fixed',
  Float = 'float',
  Negotiable = 'negotiable'
}

/** Boolean expression to compare columns of type "sc_enum_rental_types_enum". All fields are combined with logical 'AND'. */
export type ScEnumRentalTypesEnumComparisonExp = {
  _eq?: InputMaybe<ScEnumRentalTypesEnum>;
  _in?: InputMaybe<Array<ScEnumRentalTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ScEnumRentalTypesEnum>;
  _nin?: InputMaybe<Array<ScEnumRentalTypesEnum>>;
};

/** aggregate max on columns */
export type ScEnumRentalTypesMaxFields = {
  __typename?: 'sc_enum_rental_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ScEnumRentalTypesMinFields = {
  __typename?: 'sc_enum_rental_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "spacecloud.enum_rental_types". */
export type ScEnumRentalTypesOrderBy = {
  bookings_aggregate?: InputMaybe<ScBookingsAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "spacecloud.enum_rental_types" */
export enum ScEnumRentalTypesSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "sc_enum_rental_types" */
export type ScEnumRentalTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ScEnumRentalTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScEnumRentalTypesStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "spacecloud.enum_user_preference_asset_types" */
export type ScEnumUserPreferenceAssetTypes = {
  __typename?: 'sc_enum_user_preference_asset_types';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  user_preference: Array<ScUserPreferences>;
  /** An aggregate relationship */
  user_preference_aggregate: ScUserPreferencesAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "spacecloud.enum_user_preference_asset_types" */
export type ScEnumUserPreferenceAssetTypesUserPreferenceArgs = {
  distinct_on?: InputMaybe<Array<ScUserPreferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScUserPreferencesOrderBy>>;
  where?: InputMaybe<ScUserPreferencesBoolExp>;
};


/** columns and relationships of "spacecloud.enum_user_preference_asset_types" */
export type ScEnumUserPreferenceAssetTypesUserPreferenceAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScUserPreferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScUserPreferencesOrderBy>>;
  where?: InputMaybe<ScUserPreferencesBoolExp>;
};

/** aggregated selection of "spacecloud.enum_user_preference_asset_types" */
export type ScEnumUserPreferenceAssetTypesAggregate = {
  __typename?: 'sc_enum_user_preference_asset_types_aggregate';
  aggregate?: Maybe<ScEnumUserPreferenceAssetTypesAggregateFields>;
  nodes: Array<ScEnumUserPreferenceAssetTypes>;
};

/** aggregate fields of "spacecloud.enum_user_preference_asset_types" */
export type ScEnumUserPreferenceAssetTypesAggregateFields = {
  __typename?: 'sc_enum_user_preference_asset_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ScEnumUserPreferenceAssetTypesMaxFields>;
  min?: Maybe<ScEnumUserPreferenceAssetTypesMinFields>;
};


/** aggregate fields of "spacecloud.enum_user_preference_asset_types" */
export type ScEnumUserPreferenceAssetTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "spacecloud.enum_user_preference_asset_types". All fields are combined with a logical 'AND'. */
export type ScEnumUserPreferenceAssetTypesBoolExp = {
  _and?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesBoolExp>>;
  _not?: InputMaybe<ScEnumUserPreferenceAssetTypesBoolExp>;
  _or?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  user_preference?: InputMaybe<ScUserPreferencesBoolExp>;
  user_preference_aggregate?: InputMaybe<ScUserPreferencesAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum ScEnumUserPreferenceAssetTypesEnum {
  /** Commercial Building */
  CommercialBuilding = 'commercial_building',
  /** Community Mall */
  CommunityMall = 'community_mall',
  /** Gas Station */
  GasStation = 'gas_station',
  /** Market */
  Market = 'market',
  /** Office Building */
  OfficeBuilding = 'office_building',
  /** Others */
  Others = 'others',
  /** Shop House */
  ShopHouse = 'shop_house',
  /** Shopping Mall */
  ShoppingMall = 'shopping_mall',
  /** Specialty Store */
  SpecialtyStore = 'specialty_store'
}

/** Boolean expression to compare columns of type "sc_enum_user_preference_asset_types_enum". All fields are combined with logical 'AND'. */
export type ScEnumUserPreferenceAssetTypesEnumComparisonExp = {
  _eq?: InputMaybe<ScEnumUserPreferenceAssetTypesEnum>;
  _in?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ScEnumUserPreferenceAssetTypesEnum>;
  _nin?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesEnum>>;
};

/** aggregate max on columns */
export type ScEnumUserPreferenceAssetTypesMaxFields = {
  __typename?: 'sc_enum_user_preference_asset_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ScEnumUserPreferenceAssetTypesMinFields = {
  __typename?: 'sc_enum_user_preference_asset_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "spacecloud.enum_user_preference_asset_types". */
export type ScEnumUserPreferenceAssetTypesOrderBy = {
  description?: InputMaybe<OrderBy>;
  user_preference_aggregate?: InputMaybe<ScUserPreferencesAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "spacecloud.enum_user_preference_asset_types" */
export enum ScEnumUserPreferenceAssetTypesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "sc_enum_user_preference_asset_types" */
export type ScEnumUserPreferenceAssetTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ScEnumUserPreferenceAssetTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScEnumUserPreferenceAssetTypesStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "spacecloud.enum_utility_bill_period_types" */
export type ScEnumUtilityBillPeriodTypes = {
  __typename?: 'sc_enum_utility_bill_period_types';
  /** An array relationship */
  bookings: Array<ScBookings>;
  /** An aggregate relationship */
  bookings_aggregate: ScBookingsAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "spacecloud.enum_utility_bill_period_types" */
export type ScEnumUtilityBillPeriodTypesBookingsArgs = {
  distinct_on?: InputMaybe<Array<ScBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScBookingsOrderBy>>;
  where?: InputMaybe<ScBookingsBoolExp>;
};


/** columns and relationships of "spacecloud.enum_utility_bill_period_types" */
export type ScEnumUtilityBillPeriodTypesBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScBookingsOrderBy>>;
  where?: InputMaybe<ScBookingsBoolExp>;
};

/** aggregated selection of "spacecloud.enum_utility_bill_period_types" */
export type ScEnumUtilityBillPeriodTypesAggregate = {
  __typename?: 'sc_enum_utility_bill_period_types_aggregate';
  aggregate?: Maybe<ScEnumUtilityBillPeriodTypesAggregateFields>;
  nodes: Array<ScEnumUtilityBillPeriodTypes>;
};

/** aggregate fields of "spacecloud.enum_utility_bill_period_types" */
export type ScEnumUtilityBillPeriodTypesAggregateFields = {
  __typename?: 'sc_enum_utility_bill_period_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ScEnumUtilityBillPeriodTypesMaxFields>;
  min?: Maybe<ScEnumUtilityBillPeriodTypesMinFields>;
};


/** aggregate fields of "spacecloud.enum_utility_bill_period_types" */
export type ScEnumUtilityBillPeriodTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "spacecloud.enum_utility_bill_period_types". All fields are combined with a logical 'AND'. */
export type ScEnumUtilityBillPeriodTypesBoolExp = {
  _and?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesBoolExp>>;
  _not?: InputMaybe<ScEnumUtilityBillPeriodTypesBoolExp>;
  _or?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesBoolExp>>;
  bookings?: InputMaybe<ScBookingsBoolExp>;
  bookings_aggregate?: InputMaybe<ScBookingsAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum ScEnumUtilityBillPeriodTypesEnum {
  FixedDaily = 'fixed_daily',
  FixedMonthly = 'fixed_monthly',
  FixedWeekly = 'fixed_weekly',
  PerUnitDaily = 'per_unit_daily',
  PerUnitMonthly = 'per_unit_monthly',
  PerUnitWeekly = 'per_unit_weekly'
}

/** Boolean expression to compare columns of type "sc_enum_utility_bill_period_types_enum". All fields are combined with logical 'AND'. */
export type ScEnumUtilityBillPeriodTypesEnumComparisonExp = {
  _eq?: InputMaybe<ScEnumUtilityBillPeriodTypesEnum>;
  _in?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ScEnumUtilityBillPeriodTypesEnum>;
  _nin?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesEnum>>;
};

/** aggregate max on columns */
export type ScEnumUtilityBillPeriodTypesMaxFields = {
  __typename?: 'sc_enum_utility_bill_period_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ScEnumUtilityBillPeriodTypesMinFields = {
  __typename?: 'sc_enum_utility_bill_period_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "spacecloud.enum_utility_bill_period_types". */
export type ScEnumUtilityBillPeriodTypesOrderBy = {
  bookings_aggregate?: InputMaybe<ScBookingsAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "spacecloud.enum_utility_bill_period_types" */
export enum ScEnumUtilityBillPeriodTypesSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "sc_enum_utility_bill_period_types" */
export type ScEnumUtilityBillPeriodTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ScEnumUtilityBillPeriodTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScEnumUtilityBillPeriodTypesStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "spacecloud.user_preferences" */
export type ScUserPreferences = {
  __typename?: 'sc_user_preferences';
  asset_group_description?: Maybe<Scalars['String']>;
  asset_type?: Maybe<ScEnumUserPreferenceAssetTypesEnum>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_user_preference_asset_type?: Maybe<ScEnumUserPreferenceAssetTypes>;
  id: Scalars['Int'];
  max_area?: Maybe<Scalars['numeric']>;
  max_budget?: Maybe<Scalars['numeric']>;
  min_area?: Maybe<Scalars['numeric']>;
  min_budget?: Maybe<Scalars['numeric']>;
  more_details?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "spacecloud.user_preferences" */
export type ScUserPreferencesAggregate = {
  __typename?: 'sc_user_preferences_aggregate';
  aggregate?: Maybe<ScUserPreferencesAggregateFields>;
  nodes: Array<ScUserPreferences>;
};

export type ScUserPreferencesAggregateBoolExp = {
  count?: InputMaybe<ScUserPreferencesAggregateBoolExpCount>;
};

export type ScUserPreferencesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ScUserPreferencesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScUserPreferencesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "spacecloud.user_preferences" */
export type ScUserPreferencesAggregateFields = {
  __typename?: 'sc_user_preferences_aggregate_fields';
  avg?: Maybe<ScUserPreferencesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ScUserPreferencesMaxFields>;
  min?: Maybe<ScUserPreferencesMinFields>;
  stddev?: Maybe<ScUserPreferencesStddevFields>;
  stddev_pop?: Maybe<ScUserPreferencesStddevPopFields>;
  stddev_samp?: Maybe<ScUserPreferencesStddevSampFields>;
  sum?: Maybe<ScUserPreferencesSumFields>;
  var_pop?: Maybe<ScUserPreferencesVarPopFields>;
  var_samp?: Maybe<ScUserPreferencesVarSampFields>;
  variance?: Maybe<ScUserPreferencesVarianceFields>;
};


/** aggregate fields of "spacecloud.user_preferences" */
export type ScUserPreferencesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScUserPreferencesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "spacecloud.user_preferences" */
export type ScUserPreferencesAggregateOrderBy = {
  avg?: InputMaybe<ScUserPreferencesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ScUserPreferencesMaxOrderBy>;
  min?: InputMaybe<ScUserPreferencesMinOrderBy>;
  stddev?: InputMaybe<ScUserPreferencesStddevOrderBy>;
  stddev_pop?: InputMaybe<ScUserPreferencesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ScUserPreferencesStddevSampOrderBy>;
  sum?: InputMaybe<ScUserPreferencesSumOrderBy>;
  var_pop?: InputMaybe<ScUserPreferencesVarPopOrderBy>;
  var_samp?: InputMaybe<ScUserPreferencesVarSampOrderBy>;
  variance?: InputMaybe<ScUserPreferencesVarianceOrderBy>;
};

/** aggregate avg on columns */
export type ScUserPreferencesAvgFields = {
  __typename?: 'sc_user_preferences_avg_fields';
  id?: Maybe<Scalars['Float']>;
  max_area?: Maybe<Scalars['Float']>;
  max_budget?: Maybe<Scalars['Float']>;
  min_area?: Maybe<Scalars['Float']>;
  min_budget?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "spacecloud.user_preferences" */
export type ScUserPreferencesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "spacecloud.user_preferences". All fields are combined with a logical 'AND'. */
export type ScUserPreferencesBoolExp = {
  _and?: InputMaybe<Array<ScUserPreferencesBoolExp>>;
  _not?: InputMaybe<ScUserPreferencesBoolExp>;
  _or?: InputMaybe<Array<ScUserPreferencesBoolExp>>;
  asset_group_description?: InputMaybe<StringComparisonExp>;
  asset_type?: InputMaybe<ScEnumUserPreferenceAssetTypesEnumComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  enum_user_preference_asset_type?: InputMaybe<ScEnumUserPreferenceAssetTypesBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  max_area?: InputMaybe<NumericComparisonExp>;
  max_budget?: InputMaybe<NumericComparisonExp>;
  min_area?: InputMaybe<NumericComparisonExp>;
  min_budget?: InputMaybe<NumericComparisonExp>;
  more_details?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type ScUserPreferencesMaxFields = {
  __typename?: 'sc_user_preferences_max_fields';
  asset_group_description?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  max_area?: Maybe<Scalars['numeric']>;
  max_budget?: Maybe<Scalars['numeric']>;
  min_area?: Maybe<Scalars['numeric']>;
  min_budget?: Maybe<Scalars['numeric']>;
  more_details?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "spacecloud.user_preferences" */
export type ScUserPreferencesMaxOrderBy = {
  asset_group_description?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
  more_details?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ScUserPreferencesMinFields = {
  __typename?: 'sc_user_preferences_min_fields';
  asset_group_description?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  max_area?: Maybe<Scalars['numeric']>;
  max_budget?: Maybe<Scalars['numeric']>;
  min_area?: Maybe<Scalars['numeric']>;
  min_budget?: Maybe<Scalars['numeric']>;
  more_details?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "spacecloud.user_preferences" */
export type ScUserPreferencesMinOrderBy = {
  asset_group_description?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
  more_details?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "spacecloud.user_preferences". */
export type ScUserPreferencesOrderBy = {
  asset_group_description?: InputMaybe<OrderBy>;
  asset_type?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  enum_user_preference_asset_type?: InputMaybe<ScEnumUserPreferenceAssetTypesOrderBy>;
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
  more_details?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** select columns of table "spacecloud.user_preferences" */
export enum ScUserPreferencesSelectColumn {
  /** column name */
  AssetGroupDescription = 'asset_group_description',
  /** column name */
  AssetType = 'asset_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MaxArea = 'max_area',
  /** column name */
  MaxBudget = 'max_budget',
  /** column name */
  MinArea = 'min_area',
  /** column name */
  MinBudget = 'min_budget',
  /** column name */
  MoreDetails = 'more_details',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type ScUserPreferencesStddevFields = {
  __typename?: 'sc_user_preferences_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  max_area?: Maybe<Scalars['Float']>;
  max_budget?: Maybe<Scalars['Float']>;
  min_area?: Maybe<Scalars['Float']>;
  min_budget?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "spacecloud.user_preferences" */
export type ScUserPreferencesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ScUserPreferencesStddevPopFields = {
  __typename?: 'sc_user_preferences_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_area?: Maybe<Scalars['Float']>;
  max_budget?: Maybe<Scalars['Float']>;
  min_area?: Maybe<Scalars['Float']>;
  min_budget?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "spacecloud.user_preferences" */
export type ScUserPreferencesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ScUserPreferencesStddevSampFields = {
  __typename?: 'sc_user_preferences_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_area?: Maybe<Scalars['Float']>;
  max_budget?: Maybe<Scalars['Float']>;
  min_area?: Maybe<Scalars['Float']>;
  min_budget?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "spacecloud.user_preferences" */
export type ScUserPreferencesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type ScUserPreferencesSumFields = {
  __typename?: 'sc_user_preferences_sum_fields';
  id?: Maybe<Scalars['Int']>;
  max_area?: Maybe<Scalars['numeric']>;
  max_budget?: Maybe<Scalars['numeric']>;
  min_area?: Maybe<Scalars['numeric']>;
  min_budget?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "spacecloud.user_preferences" */
export type ScUserPreferencesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ScUserPreferencesVarPopFields = {
  __typename?: 'sc_user_preferences_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_area?: Maybe<Scalars['Float']>;
  max_budget?: Maybe<Scalars['Float']>;
  min_area?: Maybe<Scalars['Float']>;
  min_budget?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "spacecloud.user_preferences" */
export type ScUserPreferencesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ScUserPreferencesVarSampFields = {
  __typename?: 'sc_user_preferences_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_area?: Maybe<Scalars['Float']>;
  max_budget?: Maybe<Scalars['Float']>;
  min_area?: Maybe<Scalars['Float']>;
  min_budget?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "spacecloud.user_preferences" */
export type ScUserPreferencesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ScUserPreferencesVarianceFields = {
  __typename?: 'sc_user_preferences_variance_fields';
  id?: Maybe<Scalars['Float']>;
  max_area?: Maybe<Scalars['Float']>;
  max_budget?: Maybe<Scalars['Float']>;
  min_area?: Maybe<Scalars['Float']>;
  min_budget?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "spacecloud.user_preferences" */
export type ScUserPreferencesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  max_area?: InputMaybe<OrderBy>;
  max_budget?: InputMaybe<OrderBy>;
  min_area?: InputMaybe<OrderBy>;
  min_budget?: InputMaybe<OrderBy>;
};

export type SpacecloudListScAssetGroupsAnnexRandomArgs = {
  seed?: InputMaybe<Scalars['seed_float']>;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** An array relationship */
  asset_amenities: Array<AssetAmenities>;
  /** An aggregate relationship */
  asset_amenities_aggregate: AssetAmenitiesAggregate;
  /** fetch data from the table: "asset_amenities" using primary key columns */
  asset_amenities_by_pk?: Maybe<AssetAmenities>;
  /** fetch data from the table in a streaming manner: "asset_amenities" */
  asset_amenities_stream: Array<AssetAmenities>;
  /** An array relationship */
  asset_booking_events: Array<AssetBookingEvents>;
  /** fetch data from the table: "asset_booking_events" using primary key columns */
  asset_booking_events_by_pk?: Maybe<AssetBookingEvents>;
  /** fetch data from the table in a streaming manner: "asset_booking_events" */
  asset_booking_events_stream: Array<AssetBookingEvents>;
  /** An array relationship */
  asset_bookings: Array<AssetBookings>;
  /** An aggregate relationship */
  asset_bookings_aggregate: AssetBookingsAggregate;
  /** fetch data from the table: "asset_bookings" using primary key columns */
  asset_bookings_by_pk?: Maybe<AssetBookings>;
  /** fetch data from the table in a streaming manner: "asset_bookings" */
  asset_bookings_stream: Array<AssetBookings>;
  /** An array relationship */
  asset_classes: Array<AssetClasses>;
  /** An aggregate relationship */
  asset_classes_aggregate: AssetClassesAggregate;
  /** fetch data from the table: "asset_classes" using primary key columns */
  asset_classes_by_pk?: Maybe<AssetClasses>;
  /** fetch data from the table in a streaming manner: "asset_classes" */
  asset_classes_stream: Array<AssetClasses>;
  /** An array relationship */
  asset_group_media: Array<AssetGroupMedia>;
  /** fetch data from the table: "asset_group_media" using primary key columns */
  asset_group_media_by_pk?: Maybe<AssetGroupMedia>;
  /** fetch data from the table in a streaming manner: "asset_group_media" */
  asset_group_media_stream: Array<AssetGroupMedia>;
  /** An array relationship */
  asset_group_operating_hours: Array<AssetGroupOperatingHours>;
  /** An aggregate relationship */
  asset_group_operating_hours_aggregate: AssetGroupOperatingHoursAggregate;
  /** fetch data from the table: "asset_group_operating_hours" using primary key columns */
  asset_group_operating_hours_by_pk?: Maybe<AssetGroupOperatingHours>;
  /** fetch data from the table in a streaming manner: "asset_group_operating_hours" */
  asset_group_operating_hours_stream: Array<AssetGroupOperatingHours>;
  /** An array relationship */
  asset_groups: Array<AssetGroups>;
  /** An aggregate relationship */
  asset_groups_aggregate: AssetGroupsAggregate;
  /** fetch data from the table: "asset_groups" using primary key columns */
  asset_groups_by_pk?: Maybe<AssetGroups>;
  /** fetch data from the table in a streaming manner: "asset_groups" */
  asset_groups_stream: Array<AssetGroups>;
  /** An array relationship */
  asset_media: Array<AssetMedia>;
  /** fetch data from the table: "asset_media" using primary key columns */
  asset_media_by_pk?: Maybe<AssetMedia>;
  /** fetch data from the table in a streaming manner: "asset_media" */
  asset_media_stream: Array<AssetMedia>;
  /** An array relationship */
  asset_operating_hours: Array<AssetOperatingHours>;
  /** An aggregate relationship */
  asset_operating_hours_aggregate: AssetOperatingHoursAggregate;
  /** fetch data from the table: "asset_operating_hours" using primary key columns */
  asset_operating_hours_by_pk?: Maybe<AssetOperatingHours>;
  /** fetch data from the table in a streaming manner: "asset_operating_hours" */
  asset_operating_hours_stream: Array<AssetOperatingHours>;
  /** An array relationship */
  asset_unavailabilities: Array<AssetUnavailabilities>;
  /** An aggregate relationship */
  asset_unavailabilities_aggregate: AssetUnavailabilitiesAggregate;
  /** fetch data from the table: "asset_unavailabilities" using primary key columns */
  asset_unavailabilities_by_pk?: Maybe<AssetUnavailabilities>;
  /** fetch data from the table in a streaming manner: "asset_unavailabilities" */
  asset_unavailabilities_stream: Array<AssetUnavailabilities>;
  /** An array relationship */
  assets: Array<Assets>;
  /** An aggregate relationship */
  assets_aggregate: AssetsAggregate;
  /** fetch data from the table: "assets" using primary key columns */
  assets_by_pk?: Maybe<Assets>;
  /** fetch data from the table in a streaming manner: "assets" */
  assets_stream: Array<Assets>;
  /** fetch data from the table: "bookers" */
  bookers: Array<Bookers>;
  /** fetch aggregated fields from the table: "bookers" */
  bookers_aggregate: BookersAggregate;
  /** fetch data from the table in a streaming manner: "bookers" */
  bookers_stream: Array<Bookers>;
  /** fetch data from the table: "consents" */
  consents: Array<Consents>;
  /** fetch aggregated fields from the table: "consents" */
  consents_aggregate: ConsentsAggregate;
  /** fetch data from the table: "consents" using primary key columns */
  consents_by_pk?: Maybe<Consents>;
  /** fetch data from the table in a streaming manner: "consents" */
  consents_stream: Array<Consents>;
  /** fetch data from the table: "enum_asset_booking_statuses" */
  enum_asset_booking_statuses: Array<EnumAssetBookingStatuses>;
  /** fetch aggregated fields from the table: "enum_asset_booking_statuses" */
  enum_asset_booking_statuses_aggregate: EnumAssetBookingStatusesAggregate;
  /** fetch data from the table: "enum_asset_booking_statuses" using primary key columns */
  enum_asset_booking_statuses_by_pk?: Maybe<EnumAssetBookingStatuses>;
  /** fetch data from the table in a streaming manner: "enum_asset_booking_statuses" */
  enum_asset_booking_statuses_stream: Array<EnumAssetBookingStatuses>;
  /** fetch data from the table: "enum_invite_statuses" */
  enum_invite_statuses: Array<EnumInviteStatuses>;
  /** fetch aggregated fields from the table: "enum_invite_statuses" */
  enum_invite_statuses_aggregate: EnumInviteStatusesAggregate;
  /** fetch data from the table: "enum_invite_statuses" using primary key columns */
  enum_invite_statuses_by_pk?: Maybe<EnumInviteStatuses>;
  /** fetch data from the table in a streaming manner: "enum_invite_statuses" */
  enum_invite_statuses_stream: Array<EnumInviteStatuses>;
  /** fetch data from the table: "enum_user_groups_tier" */
  enum_user_groups_tier: Array<EnumUserGroupsTier>;
  /** fetch aggregated fields from the table: "enum_user_groups_tier" */
  enum_user_groups_tier_aggregate: EnumUserGroupsTierAggregate;
  /** fetch data from the table: "enum_user_groups_tier" using primary key columns */
  enum_user_groups_tier_by_pk?: Maybe<EnumUserGroupsTier>;
  /** fetch data from the table in a streaming manner: "enum_user_groups_tier" */
  enum_user_groups_tier_stream: Array<EnumUserGroupsTier>;
  /** fetch data from the table: "external_process_values" */
  external_process_values: Array<ExternalProcessValues>;
  /** fetch aggregated fields from the table: "external_process_values" */
  external_process_values_aggregate: ExternalProcessValuesAggregate;
  /** fetch data from the table: "external_process_values" using primary key columns */
  external_process_values_by_pk?: Maybe<ExternalProcessValues>;
  /** fetch data from the table in a streaming manner: "external_process_values" */
  external_process_values_stream: Array<ExternalProcessValues>;
  /** fetch data from the table: "flattened_asset_groups" */
  flattened_asset_groups: Array<FlattenedAssetGroups>;
  /** fetch aggregated fields from the table: "flattened_asset_groups" */
  flattened_asset_groups_aggregate: FlattenedAssetGroupsAggregate;
  /** fetch data from the table: "flattened_asset_groups" using primary key columns */
  flattened_asset_groups_by_pk?: Maybe<FlattenedAssetGroups>;
  /** fetch data from the table in a streaming manner: "flattened_asset_groups" */
  flattened_asset_groups_stream: Array<FlattenedAssetGroups>;
  /** An array relationship */
  flattened_user_group_user_permissions: Array<FlattenedUserGroupUserPermissions>;
  /** An aggregate relationship */
  flattened_user_group_user_permissions_aggregate: FlattenedUserGroupUserPermissionsAggregate;
  /** fetch data from the table: "flattened_user_group_user_permissions" using primary key columns */
  flattened_user_group_user_permissions_by_pk?: Maybe<FlattenedUserGroupUserPermissions>;
  /** fetch data from the table in a streaming manner: "flattened_user_group_user_permissions" */
  flattened_user_group_user_permissions_stream: Array<FlattenedUserGroupUserPermissions>;
  /** An array relationship */
  flattened_user_group_users: Array<FlattenedUserGroupUsers>;
  /** An aggregate relationship */
  flattened_user_group_users_aggregate: FlattenedUserGroupUsersAggregate;
  /** fetch data from the table: "flattened_user_group_users" using primary key columns */
  flattened_user_group_users_by_pk?: Maybe<FlattenedUserGroupUsers>;
  /** fetch data from the table in a streaming manner: "flattened_user_group_users" */
  flattened_user_group_users_stream: Array<FlattenedUserGroupUsers>;
  /** fetch data from the table: "flattened_user_groups" */
  flattened_user_groups: Array<FlattenedUserGroups>;
  /** fetch aggregated fields from the table: "flattened_user_groups" */
  flattened_user_groups_aggregate: FlattenedUserGroupsAggregate;
  /** fetch data from the table: "flattened_user_groups" using primary key columns */
  flattened_user_groups_by_pk?: Maybe<FlattenedUserGroups>;
  /** fetch data from the table in a streaming manner: "flattened_user_groups" */
  flattened_user_groups_stream: Array<FlattenedUserGroups>;
  /** fetch data from the table: "friday_operating_hours" */
  friday_operating_hours: Array<FridayOperatingHours>;
  /** fetch aggregated fields from the table: "friday_operating_hours" */
  friday_operating_hours_aggregate: FridayOperatingHoursAggregate;
  /** fetch data from the table in a streaming manner: "friday_operating_hours" */
  friday_operating_hours_stream: Array<FridayOperatingHours>;
  /** An array relationship */
  invites: Array<Invites>;
  /** An aggregate relationship */
  invites_aggregate: InvitesAggregate;
  /** fetch data from the table: "invites" using primary key columns */
  invites_by_pk?: Maybe<Invites>;
  /** fetch data from the table in a streaming manner: "invites" */
  invites_stream: Array<Invites>;
  /** fetch data from the table: "media" */
  media: Array<Media>;
  /** fetch aggregated fields from the table: "media" */
  media_aggregate: MediaAggregate;
  /** fetch data from the table: "media" using primary key columns */
  media_by_pk?: Maybe<Media>;
  /** fetch data from the table in a streaming manner: "media" */
  media_stream: Array<Media>;
  /** fetch data from the table: "monday_operating_hours" */
  monday_operating_hours: Array<MondayOperatingHours>;
  /** fetch aggregated fields from the table: "monday_operating_hours" */
  monday_operating_hours_aggregate: MondayOperatingHoursAggregate;
  /** fetch data from the table in a streaming manner: "monday_operating_hours" */
  monday_operating_hours_stream: Array<MondayOperatingHours>;
  /** An array relationship */
  phone_number_verifications: Array<PhoneNumberVerifications>;
  /** An aggregate relationship */
  phone_number_verifications_aggregate: PhoneNumberVerificationsAggregate;
  /** fetch data from the table: "phone_number_verifications" using primary key columns */
  phone_number_verifications_by_pk?: Maybe<PhoneNumberVerifications>;
  /** fetch data from the table in a streaming manner: "phone_number_verifications" */
  phone_number_verifications_stream: Array<PhoneNumberVerifications>;
  /** An array relationship */
  processes: Array<Processes>;
  /** An aggregate relationship */
  processes_aggregate: ProcessesAggregate;
  /** fetch data from the table: "processes" using primary key columns */
  processes_by_pk?: Maybe<Processes>;
  /** fetch data from the table in a streaming manner: "processes" */
  processes_stream: Array<Processes>;
  /** An array relationship */
  profiles: Array<Profiles>;
  /** fetch data from the table: "profiles" using primary key columns */
  profiles_by_pk?: Maybe<Profiles>;
  /** fetch data from the table in a streaming manner: "profiles" */
  profiles_stream: Array<Profiles>;
  /** fetch data from the table: "saturday_operating_hours" */
  saturday_operating_hours: Array<SaturdayOperatingHours>;
  /** fetch aggregated fields from the table: "saturday_operating_hours" */
  saturday_operating_hours_aggregate: SaturdayOperatingHoursAggregate;
  /** fetch data from the table in a streaming manner: "saturday_operating_hours" */
  saturday_operating_hours_stream: Array<SaturdayOperatingHours>;
  /** An array relationship */
  sc_asset_group_annex_images: Array<ScAssetGroupAnnexImages>;
  /** An aggregate relationship */
  sc_asset_group_annex_images_aggregate: ScAssetGroupAnnexImagesAggregate;
  /** fetch data from the table: "spacecloud.asset_group_annex_images" using primary key columns */
  sc_asset_group_annex_images_by_pk?: Maybe<ScAssetGroupAnnexImages>;
  /** fetch data from the table in a streaming manner: "spacecloud.asset_group_annex_images" */
  sc_asset_group_annex_images_stream: Array<ScAssetGroupAnnexImages>;
  /** fetch data from the table: "spacecloud.asset_groups_annex" */
  sc_asset_groups_annex: Array<ScAssetGroupsAnnex>;
  /** fetch aggregated fields from the table: "spacecloud.asset_groups_annex" */
  sc_asset_groups_annex_aggregate: ScAssetGroupsAnnexAggregate;
  /** fetch data from the table: "spacecloud.asset_groups_annex" using primary key columns */
  sc_asset_groups_annex_by_pk?: Maybe<ScAssetGroupsAnnex>;
  /** fetch data from the table in a streaming manner: "spacecloud.asset_groups_annex" */
  sc_asset_groups_annex_stream: Array<ScAssetGroupsAnnex>;
  /** fetch data from the table: "spacecloud.assets" */
  sc_assets: Array<ScAssets>;
  /** fetch aggregated fields from the table: "spacecloud.assets" */
  sc_assets_aggregate: ScAssetsAggregate;
  /** fetch data from the table: "spacecloud.assets" using primary key columns */
  sc_assets_by_pk?: Maybe<ScAssets>;
  /** fetch data from the table in a streaming manner: "spacecloud.assets" */
  sc_assets_stream: Array<ScAssets>;
  /** An array relationship */
  sc_cctv_cameras: Array<ScCctvCameras>;
  /** An aggregate relationship */
  sc_cctv_cameras_aggregate: ScCctvCamerasAggregate;
  /** fetch data from the table: "spacecloud.cctv_cameras" using primary key columns */
  sc_cctv_cameras_by_pk?: Maybe<ScCctvCameras>;
  /** fetch data from the table in a streaming manner: "spacecloud.cctv_cameras" */
  sc_cctv_cameras_stream: Array<ScCctvCameras>;
  /** fetch data from the table: "spacecloud.enum_period_types" */
  sc_enum_period_types: Array<ScEnumPeriodTypes>;
  /** fetch aggregated fields from the table: "spacecloud.enum_period_types" */
  sc_enum_period_types_aggregate: ScEnumPeriodTypesAggregate;
  /** fetch data from the table: "spacecloud.enum_period_types" using primary key columns */
  sc_enum_period_types_by_pk?: Maybe<ScEnumPeriodTypes>;
  /** fetch data from the table in a streaming manner: "spacecloud.enum_period_types" */
  sc_enum_period_types_stream: Array<ScEnumPeriodTypes>;
  /** fetch data from the table: "spacecloud.enum_rental_business_groups" */
  sc_enum_rental_business_groups: Array<ScEnumRentalBusinessGroups>;
  /** fetch aggregated fields from the table: "spacecloud.enum_rental_business_groups" */
  sc_enum_rental_business_groups_aggregate: ScEnumRentalBusinessGroupsAggregate;
  /** fetch data from the table: "spacecloud.enum_rental_business_groups" using primary key columns */
  sc_enum_rental_business_groups_by_pk?: Maybe<ScEnumRentalBusinessGroups>;
  /** fetch data from the table in a streaming manner: "spacecloud.enum_rental_business_groups" */
  sc_enum_rental_business_groups_stream: Array<ScEnumRentalBusinessGroups>;
  /** fetch data from the table: "spacecloud.enum_rental_types" */
  sc_enum_rental_types: Array<ScEnumRentalTypes>;
  /** fetch aggregated fields from the table: "spacecloud.enum_rental_types" */
  sc_enum_rental_types_aggregate: ScEnumRentalTypesAggregate;
  /** fetch data from the table: "spacecloud.enum_rental_types" using primary key columns */
  sc_enum_rental_types_by_pk?: Maybe<ScEnumRentalTypes>;
  /** fetch data from the table in a streaming manner: "spacecloud.enum_rental_types" */
  sc_enum_rental_types_stream: Array<ScEnumRentalTypes>;
  /** fetch data from the table: "spacecloud.enum_user_preference_asset_types" */
  sc_enum_user_preference_asset_types: Array<ScEnumUserPreferenceAssetTypes>;
  /** fetch aggregated fields from the table: "spacecloud.enum_user_preference_asset_types" */
  sc_enum_user_preference_asset_types_aggregate: ScEnumUserPreferenceAssetTypesAggregate;
  /** fetch data from the table: "spacecloud.enum_user_preference_asset_types" using primary key columns */
  sc_enum_user_preference_asset_types_by_pk?: Maybe<ScEnumUserPreferenceAssetTypes>;
  /** fetch data from the table in a streaming manner: "spacecloud.enum_user_preference_asset_types" */
  sc_enum_user_preference_asset_types_stream: Array<ScEnumUserPreferenceAssetTypes>;
  /** fetch data from the table: "spacecloud.enum_utility_bill_period_types" */
  sc_enum_utility_bill_period_types: Array<ScEnumUtilityBillPeriodTypes>;
  /** fetch aggregated fields from the table: "spacecloud.enum_utility_bill_period_types" */
  sc_enum_utility_bill_period_types_aggregate: ScEnumUtilityBillPeriodTypesAggregate;
  /** fetch data from the table: "spacecloud.enum_utility_bill_period_types" using primary key columns */
  sc_enum_utility_bill_period_types_by_pk?: Maybe<ScEnumUtilityBillPeriodTypes>;
  /** fetch data from the table in a streaming manner: "spacecloud.enum_utility_bill_period_types" */
  sc_enum_utility_bill_period_types_stream: Array<ScEnumUtilityBillPeriodTypes>;
  /** execute function "spacecloud.list_sc_asset_groups_annex_random" which returns "spacecloud.asset_groups_annex" */
  spacecloud_list_sc_asset_groups_annex_random: Array<ScAssetGroupsAnnex>;
  /** execute function "spacecloud.list_sc_asset_groups_annex_random" and query aggregates on result of table type "spacecloud.asset_groups_annex" */
  spacecloud_list_sc_asset_groups_annex_random_aggregate: ScAssetGroupsAnnexAggregate;
  /** fetch data from the table: "sunday_operating_hours" */
  sunday_operating_hours: Array<SundayOperatingHours>;
  /** fetch aggregated fields from the table: "sunday_operating_hours" */
  sunday_operating_hours_aggregate: SundayOperatingHoursAggregate;
  /** fetch data from the table in a streaming manner: "sunday_operating_hours" */
  sunday_operating_hours_stream: Array<SundayOperatingHours>;
  /** fetch data from the table: "thursday_operating_hours" */
  thursday_operating_hours: Array<ThursdayOperatingHours>;
  /** fetch aggregated fields from the table: "thursday_operating_hours" */
  thursday_operating_hours_aggregate: ThursdayOperatingHoursAggregate;
  /** fetch data from the table in a streaming manner: "thursday_operating_hours" */
  thursday_operating_hours_stream: Array<ThursdayOperatingHours>;
  /** fetch data from the table: "tuesday_operating_hours" */
  tuesday_operating_hours: Array<TuesdayOperatingHours>;
  /** fetch aggregated fields from the table: "tuesday_operating_hours" */
  tuesday_operating_hours_aggregate: TuesdayOperatingHoursAggregate;
  /** fetch data from the table in a streaming manner: "tuesday_operating_hours" */
  tuesday_operating_hours_stream: Array<TuesdayOperatingHours>;
  /** An array relationship */
  user_avatar_media: Array<UserAvatarMedia>;
  /** An aggregate relationship */
  user_avatar_media_aggregate: UserAvatarMediaAggregate;
  /** fetch data from the table: "user_avatar_media" using primary key columns */
  user_avatar_media_by_pk?: Maybe<UserAvatarMedia>;
  /** fetch data from the table in a streaming manner: "user_avatar_media" */
  user_avatar_media_stream: Array<UserAvatarMedia>;
  /** An array relationship */
  user_consents: Array<UserConsents>;
  /** fetch data from the table: "user_consents" using primary key columns */
  user_consents_by_pk?: Maybe<UserConsents>;
  /** fetch data from the table in a streaming manner: "user_consents" */
  user_consents_stream: Array<UserConsents>;
  /** An array relationship */
  user_group_logo_media: Array<UserGroupLogoMedia>;
  /** An aggregate relationship */
  user_group_logo_media_aggregate: UserGroupLogoMediaAggregate;
  /** fetch data from the table: "user_group_logo_media" using primary key columns */
  user_group_logo_media_by_pk?: Maybe<UserGroupLogoMedia>;
  /** fetch data from the table in a streaming manner: "user_group_logo_media" */
  user_group_logo_media_stream: Array<UserGroupLogoMedia>;
  /** An array relationship */
  user_group_process_values: Array<UserGroupProcessValues>;
  /** An aggregate relationship */
  user_group_process_values_aggregate: UserGroupProcessValuesAggregate;
  /** fetch data from the table: "user_group_process_values" using primary key columns */
  user_group_process_values_by_pk?: Maybe<UserGroupProcessValues>;
  /** fetch data from the table in a streaming manner: "user_group_process_values" */
  user_group_process_values_stream: Array<UserGroupProcessValues>;
  /** An array relationship */
  user_group_roles: Array<UserGroupRoles>;
  /** An aggregate relationship */
  user_group_roles_aggregate: UserGroupRolesAggregate;
  /** fetch data from the table: "user_group_roles" using primary key columns */
  user_group_roles_by_pk?: Maybe<UserGroupRoles>;
  /** fetch data from the table in a streaming manner: "user_group_roles" */
  user_group_roles_stream: Array<UserGroupRoles>;
  /** An array relationship */
  user_group_user_roles: Array<UserGroupUserRoles>;
  /** An aggregate relationship */
  user_group_user_roles_aggregate: UserGroupUserRolesAggregate;
  /** fetch data from the table: "user_group_user_roles" using primary key columns */
  user_group_user_roles_by_pk?: Maybe<UserGroupUserRoles>;
  /** fetch data from the table in a streaming manner: "user_group_user_roles" */
  user_group_user_roles_stream: Array<UserGroupUserRoles>;
  /** An array relationship */
  user_groups: Array<UserGroups>;
  /** An aggregate relationship */
  user_groups_aggregate: UserGroupsAggregate;
  /** fetch data from the table: "user_groups" using primary key columns */
  user_groups_by_pk?: Maybe<UserGroups>;
  /** fetch data from the table in a streaming manner: "user_groups" */
  user_groups_stream: Array<UserGroups>;
  /** An array relationship */
  user_process_values: Array<UserProcessValues>;
  /** An aggregate relationship */
  user_process_values_aggregate: UserProcessValuesAggregate;
  /** fetch data from the table: "user_process_values" using primary key columns */
  user_process_values_by_pk?: Maybe<UserProcessValues>;
  /** fetch data from the table in a streaming manner: "user_process_values" */
  user_process_values_stream: Array<UserProcessValues>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "wednesday_operating_hours" */
  wednesday_operating_hours: Array<WednesdayOperatingHours>;
  /** fetch aggregated fields from the table: "wednesday_operating_hours" */
  wednesday_operating_hours_aggregate: WednesdayOperatingHoursAggregate;
  /** fetch data from the table in a streaming manner: "wednesday_operating_hours" */
  wednesday_operating_hours_stream: Array<WednesdayOperatingHours>;
};


export type SubscriptionRootAssetAmenitiesArgs = {
  distinct_on?: InputMaybe<Array<AssetAmenitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetAmenitiesOrderBy>>;
  where?: InputMaybe<AssetAmenitiesBoolExp>;
};


export type SubscriptionRootAssetAmenitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetAmenitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetAmenitiesOrderBy>>;
  where?: InputMaybe<AssetAmenitiesBoolExp>;
};


export type SubscriptionRootAssetAmenitiesByPkArgs = {
  asset_id: Scalars['uuid'];
  global_amenity_id: Scalars['uuid'];
};


export type SubscriptionRootAssetAmenitiesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetAmenitiesStreamCursorInput>>;
  where?: InputMaybe<AssetAmenitiesBoolExp>;
};


export type SubscriptionRootAssetBookingEventsArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingEventsOrderBy>>;
  where?: InputMaybe<AssetBookingEventsBoolExp>;
};


export type SubscriptionRootAssetBookingEventsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAssetBookingEventsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetBookingEventsStreamCursorInput>>;
  where?: InputMaybe<AssetBookingEventsBoolExp>;
};


export type SubscriptionRootAssetBookingsArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


export type SubscriptionRootAssetBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


export type SubscriptionRootAssetBookingsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAssetBookingsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetBookingsStreamCursorInput>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


export type SubscriptionRootAssetClassesArgs = {
  distinct_on?: InputMaybe<Array<AssetClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetClassesOrderBy>>;
  where?: InputMaybe<AssetClassesBoolExp>;
};


export type SubscriptionRootAssetClassesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetClassesOrderBy>>;
  where?: InputMaybe<AssetClassesBoolExp>;
};


export type SubscriptionRootAssetClassesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAssetClassesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetClassesStreamCursorInput>>;
  where?: InputMaybe<AssetClassesBoolExp>;
};


export type SubscriptionRootAssetGroupMediaArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupMediaOrderBy>>;
  where?: InputMaybe<AssetGroupMediaBoolExp>;
};


export type SubscriptionRootAssetGroupMediaByPkArgs = {
  asset_group_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


export type SubscriptionRootAssetGroupMediaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetGroupMediaStreamCursorInput>>;
  where?: InputMaybe<AssetGroupMediaBoolExp>;
};


export type SubscriptionRootAssetGroupOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
};


export type SubscriptionRootAssetGroupOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
};


export type SubscriptionRootAssetGroupOperatingHoursByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAssetGroupOperatingHoursStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetGroupOperatingHoursStreamCursorInput>>;
  where?: InputMaybe<AssetGroupOperatingHoursBoolExp>;
};


export type SubscriptionRootAssetGroupsArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupsOrderBy>>;
  where?: InputMaybe<AssetGroupsBoolExp>;
};


export type SubscriptionRootAssetGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetGroupsOrderBy>>;
  where?: InputMaybe<AssetGroupsBoolExp>;
};


export type SubscriptionRootAssetGroupsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAssetGroupsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetGroupsStreamCursorInput>>;
  where?: InputMaybe<AssetGroupsBoolExp>;
};


export type SubscriptionRootAssetMediaArgs = {
  distinct_on?: InputMaybe<Array<AssetMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetMediaOrderBy>>;
  where?: InputMaybe<AssetMediaBoolExp>;
};


export type SubscriptionRootAssetMediaByPkArgs = {
  asset_id: Scalars['uuid'];
  media_id: Scalars['uuid'];
};


export type SubscriptionRootAssetMediaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetMediaStreamCursorInput>>;
  where?: InputMaybe<AssetMediaBoolExp>;
};


export type SubscriptionRootAssetOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<AssetOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetOperatingHoursBoolExp>;
};


export type SubscriptionRootAssetOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetOperatingHoursOrderBy>>;
  where?: InputMaybe<AssetOperatingHoursBoolExp>;
};


export type SubscriptionRootAssetOperatingHoursByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAssetOperatingHoursStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetOperatingHoursStreamCursorInput>>;
  where?: InputMaybe<AssetOperatingHoursBoolExp>;
};


export type SubscriptionRootAssetUnavailabilitiesArgs = {
  distinct_on?: InputMaybe<Array<AssetUnavailabilitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetUnavailabilitiesOrderBy>>;
  where?: InputMaybe<AssetUnavailabilitiesBoolExp>;
};


export type SubscriptionRootAssetUnavailabilitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetUnavailabilitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetUnavailabilitiesOrderBy>>;
  where?: InputMaybe<AssetUnavailabilitiesBoolExp>;
};


export type SubscriptionRootAssetUnavailabilitiesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAssetUnavailabilitiesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetUnavailabilitiesStreamCursorInput>>;
  where?: InputMaybe<AssetUnavailabilitiesBoolExp>;
};


export type SubscriptionRootAssetsArgs = {
  distinct_on?: InputMaybe<Array<AssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetsOrderBy>>;
  where?: InputMaybe<AssetsBoolExp>;
};


export type SubscriptionRootAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetsOrderBy>>;
  where?: InputMaybe<AssetsBoolExp>;
};


export type SubscriptionRootAssetsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAssetsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AssetsStreamCursorInput>>;
  where?: InputMaybe<AssetsBoolExp>;
};


export type SubscriptionRootBookersArgs = {
  distinct_on?: InputMaybe<Array<BookersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookersOrderBy>>;
  where?: InputMaybe<BookersBoolExp>;
};


export type SubscriptionRootBookersAggregateArgs = {
  distinct_on?: InputMaybe<Array<BookersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BookersOrderBy>>;
  where?: InputMaybe<BookersBoolExp>;
};


export type SubscriptionRootBookersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BookersStreamCursorInput>>;
  where?: InputMaybe<BookersBoolExp>;
};


export type SubscriptionRootConsentsArgs = {
  distinct_on?: InputMaybe<Array<ConsentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsentsOrderBy>>;
  where?: InputMaybe<ConsentsBoolExp>;
};


export type SubscriptionRootConsentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsentsOrderBy>>;
  where?: InputMaybe<ConsentsBoolExp>;
};


export type SubscriptionRootConsentsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootConsentsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConsentsStreamCursorInput>>;
  where?: InputMaybe<ConsentsBoolExp>;
};


export type SubscriptionRootEnumAssetBookingStatusesArgs = {
  distinct_on?: InputMaybe<Array<EnumAssetBookingStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumAssetBookingStatusesOrderBy>>;
  where?: InputMaybe<EnumAssetBookingStatusesBoolExp>;
};


export type SubscriptionRootEnumAssetBookingStatusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnumAssetBookingStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumAssetBookingStatusesOrderBy>>;
  where?: InputMaybe<EnumAssetBookingStatusesBoolExp>;
};


export type SubscriptionRootEnumAssetBookingStatusesByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootEnumAssetBookingStatusesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EnumAssetBookingStatusesStreamCursorInput>>;
  where?: InputMaybe<EnumAssetBookingStatusesBoolExp>;
};


export type SubscriptionRootEnumInviteStatusesArgs = {
  distinct_on?: InputMaybe<Array<EnumInviteStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumInviteStatusesOrderBy>>;
  where?: InputMaybe<EnumInviteStatusesBoolExp>;
};


export type SubscriptionRootEnumInviteStatusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnumInviteStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumInviteStatusesOrderBy>>;
  where?: InputMaybe<EnumInviteStatusesBoolExp>;
};


export type SubscriptionRootEnumInviteStatusesByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootEnumInviteStatusesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EnumInviteStatusesStreamCursorInput>>;
  where?: InputMaybe<EnumInviteStatusesBoolExp>;
};


export type SubscriptionRootEnumUserGroupsTierArgs = {
  distinct_on?: InputMaybe<Array<EnumUserGroupsTierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumUserGroupsTierOrderBy>>;
  where?: InputMaybe<EnumUserGroupsTierBoolExp>;
};


export type SubscriptionRootEnumUserGroupsTierAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnumUserGroupsTierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnumUserGroupsTierOrderBy>>;
  where?: InputMaybe<EnumUserGroupsTierBoolExp>;
};


export type SubscriptionRootEnumUserGroupsTierByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootEnumUserGroupsTierStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EnumUserGroupsTierStreamCursorInput>>;
  where?: InputMaybe<EnumUserGroupsTierBoolExp>;
};


export type SubscriptionRootExternalProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<ExternalProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProcessValuesOrderBy>>;
  where?: InputMaybe<ExternalProcessValuesBoolExp>;
};


export type SubscriptionRootExternalProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProcessValuesOrderBy>>;
  where?: InputMaybe<ExternalProcessValuesBoolExp>;
};


export type SubscriptionRootExternalProcessValuesByPkArgs = {
  process_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type SubscriptionRootExternalProcessValuesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalProcessValuesStreamCursorInput>>;
  where?: InputMaybe<ExternalProcessValuesBoolExp>;
};


export type SubscriptionRootFlattenedAssetGroupsArgs = {
  distinct_on?: InputMaybe<Array<FlattenedAssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedAssetGroupsOrderBy>>;
  where?: InputMaybe<FlattenedAssetGroupsBoolExp>;
};


export type SubscriptionRootFlattenedAssetGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedAssetGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedAssetGroupsOrderBy>>;
  where?: InputMaybe<FlattenedAssetGroupsBoolExp>;
};


export type SubscriptionRootFlattenedAssetGroupsByPkArgs = {
  child_id: Scalars['uuid'];
  parent_id: Scalars['uuid'];
};


export type SubscriptionRootFlattenedAssetGroupsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FlattenedAssetGroupsStreamCursorInput>>;
  where?: InputMaybe<FlattenedAssetGroupsBoolExp>;
};


export type SubscriptionRootFlattenedUserGroupUserPermissionsArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUserPermissionsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
};


export type SubscriptionRootFlattenedUserGroupUserPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUserPermissionsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
};


export type SubscriptionRootFlattenedUserGroupUserPermissionsByPkArgs = {
  permission_id: Scalars['String'];
  user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type SubscriptionRootFlattenedUserGroupUserPermissionsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FlattenedUserGroupUserPermissionsStreamCursorInput>>;
  where?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
};


export type SubscriptionRootFlattenedUserGroupUsersArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUsersOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


export type SubscriptionRootFlattenedUserGroupUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUsersOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


export type SubscriptionRootFlattenedUserGroupUsersByPkArgs = {
  organization_id: Scalars['uuid'];
  user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type SubscriptionRootFlattenedUserGroupUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FlattenedUserGroupUsersStreamCursorInput>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


export type SubscriptionRootFlattenedUserGroupsArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupsBoolExp>;
};


export type SubscriptionRootFlattenedUserGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupsBoolExp>;
};


export type SubscriptionRootFlattenedUserGroupsByPkArgs = {
  child_id: Scalars['uuid'];
  parent_id: Scalars['uuid'];
};


export type SubscriptionRootFlattenedUserGroupsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FlattenedUserGroupsStreamCursorInput>>;
  where?: InputMaybe<FlattenedUserGroupsBoolExp>;
};


export type SubscriptionRootFridayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<FridayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FridayOperatingHoursOrderBy>>;
  where?: InputMaybe<FridayOperatingHoursBoolExp>;
};


export type SubscriptionRootFridayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<FridayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FridayOperatingHoursOrderBy>>;
  where?: InputMaybe<FridayOperatingHoursBoolExp>;
};


export type SubscriptionRootFridayOperatingHoursStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FridayOperatingHoursStreamCursorInput>>;
  where?: InputMaybe<FridayOperatingHoursBoolExp>;
};


export type SubscriptionRootInvitesArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


export type SubscriptionRootInvitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


export type SubscriptionRootInvitesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootInvitesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvitesStreamCursorInput>>;
  where?: InputMaybe<InvitesBoolExp>;
};


export type SubscriptionRootMediaArgs = {
  distinct_on?: InputMaybe<Array<MediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MediaOrderBy>>;
  where?: InputMaybe<MediaBoolExp>;
};


export type SubscriptionRootMediaAggregateArgs = {
  distinct_on?: InputMaybe<Array<MediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MediaOrderBy>>;
  where?: InputMaybe<MediaBoolExp>;
};


export type SubscriptionRootMediaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMediaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MediaStreamCursorInput>>;
  where?: InputMaybe<MediaBoolExp>;
};


export type SubscriptionRootMondayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<MondayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MondayOperatingHoursOrderBy>>;
  where?: InputMaybe<MondayOperatingHoursBoolExp>;
};


export type SubscriptionRootMondayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<MondayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MondayOperatingHoursOrderBy>>;
  where?: InputMaybe<MondayOperatingHoursBoolExp>;
};


export type SubscriptionRootMondayOperatingHoursStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MondayOperatingHoursStreamCursorInput>>;
  where?: InputMaybe<MondayOperatingHoursBoolExp>;
};


export type SubscriptionRootPhoneNumberVerificationsArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumberVerificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumberVerificationsOrderBy>>;
  where?: InputMaybe<PhoneNumberVerificationsBoolExp>;
};


export type SubscriptionRootPhoneNumberVerificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumberVerificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumberVerificationsOrderBy>>;
  where?: InputMaybe<PhoneNumberVerificationsBoolExp>;
};


export type SubscriptionRootPhoneNumberVerificationsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootPhoneNumberVerificationsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PhoneNumberVerificationsStreamCursorInput>>;
  where?: InputMaybe<PhoneNumberVerificationsBoolExp>;
};


export type SubscriptionRootProcessesArgs = {
  distinct_on?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


export type SubscriptionRootProcessesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


export type SubscriptionRootProcessesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProcessesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProcessesStreamCursorInput>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


export type SubscriptionRootProfilesArgs = {
  distinct_on?: InputMaybe<Array<ProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProfilesOrderBy>>;
  where?: InputMaybe<ProfilesBoolExp>;
};


export type SubscriptionRootProfilesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProfilesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProfilesStreamCursorInput>>;
  where?: InputMaybe<ProfilesBoolExp>;
};


export type SubscriptionRootSaturdayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<SaturdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SaturdayOperatingHoursOrderBy>>;
  where?: InputMaybe<SaturdayOperatingHoursBoolExp>;
};


export type SubscriptionRootSaturdayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<SaturdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SaturdayOperatingHoursOrderBy>>;
  where?: InputMaybe<SaturdayOperatingHoursBoolExp>;
};


export type SubscriptionRootSaturdayOperatingHoursStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SaturdayOperatingHoursStreamCursorInput>>;
  where?: InputMaybe<SaturdayOperatingHoursBoolExp>;
};


export type SubscriptionRootScAssetGroupAnnexImagesArgs = {
  distinct_on?: InputMaybe<Array<ScAssetGroupAnnexImagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupAnnexImagesOrderBy>>;
  where?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
};


export type SubscriptionRootScAssetGroupAnnexImagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScAssetGroupAnnexImagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupAnnexImagesOrderBy>>;
  where?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
};


export type SubscriptionRootScAssetGroupAnnexImagesByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootScAssetGroupAnnexImagesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScAssetGroupAnnexImagesStreamCursorInput>>;
  where?: InputMaybe<ScAssetGroupAnnexImagesBoolExp>;
};


export type SubscriptionRootScAssetGroupsAnnexArgs = {
  distinct_on?: InputMaybe<Array<ScAssetGroupsAnnexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupsAnnexOrderBy>>;
  where?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
};


export type SubscriptionRootScAssetGroupsAnnexAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScAssetGroupsAnnexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupsAnnexOrderBy>>;
  where?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
};


export type SubscriptionRootScAssetGroupsAnnexByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootScAssetGroupsAnnexStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScAssetGroupsAnnexStreamCursorInput>>;
  where?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
};


export type SubscriptionRootScAssetsArgs = {
  distinct_on?: InputMaybe<Array<ScAssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetsOrderBy>>;
  where?: InputMaybe<ScAssetsBoolExp>;
};


export type SubscriptionRootScAssetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScAssetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetsOrderBy>>;
  where?: InputMaybe<ScAssetsBoolExp>;
};


export type SubscriptionRootScAssetsByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootScAssetsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScAssetsStreamCursorInput>>;
  where?: InputMaybe<ScAssetsBoolExp>;
};


export type SubscriptionRootScCctvCamerasArgs = {
  distinct_on?: InputMaybe<Array<ScCctvCamerasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScCctvCamerasOrderBy>>;
  where?: InputMaybe<ScCctvCamerasBoolExp>;
};


export type SubscriptionRootScCctvCamerasAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScCctvCamerasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScCctvCamerasOrderBy>>;
  where?: InputMaybe<ScCctvCamerasBoolExp>;
};


export type SubscriptionRootScCctvCamerasByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootScCctvCamerasStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScCctvCamerasStreamCursorInput>>;
  where?: InputMaybe<ScCctvCamerasBoolExp>;
};


export type SubscriptionRootScEnumPeriodTypesArgs = {
  distinct_on?: InputMaybe<Array<ScEnumPeriodTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumPeriodTypesOrderBy>>;
  where?: InputMaybe<ScEnumPeriodTypesBoolExp>;
};


export type SubscriptionRootScEnumPeriodTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScEnumPeriodTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumPeriodTypesOrderBy>>;
  where?: InputMaybe<ScEnumPeriodTypesBoolExp>;
};


export type SubscriptionRootScEnumPeriodTypesByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootScEnumPeriodTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScEnumPeriodTypesStreamCursorInput>>;
  where?: InputMaybe<ScEnumPeriodTypesBoolExp>;
};


export type SubscriptionRootScEnumRentalBusinessGroupsArgs = {
  distinct_on?: InputMaybe<Array<ScEnumRentalBusinessGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumRentalBusinessGroupsOrderBy>>;
  where?: InputMaybe<ScEnumRentalBusinessGroupsBoolExp>;
};


export type SubscriptionRootScEnumRentalBusinessGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScEnumRentalBusinessGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumRentalBusinessGroupsOrderBy>>;
  where?: InputMaybe<ScEnumRentalBusinessGroupsBoolExp>;
};


export type SubscriptionRootScEnumRentalBusinessGroupsByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootScEnumRentalBusinessGroupsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScEnumRentalBusinessGroupsStreamCursorInput>>;
  where?: InputMaybe<ScEnumRentalBusinessGroupsBoolExp>;
};


export type SubscriptionRootScEnumRentalTypesArgs = {
  distinct_on?: InputMaybe<Array<ScEnumRentalTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumRentalTypesOrderBy>>;
  where?: InputMaybe<ScEnumRentalTypesBoolExp>;
};


export type SubscriptionRootScEnumRentalTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScEnumRentalTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumRentalTypesOrderBy>>;
  where?: InputMaybe<ScEnumRentalTypesBoolExp>;
};


export type SubscriptionRootScEnumRentalTypesByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootScEnumRentalTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScEnumRentalTypesStreamCursorInput>>;
  where?: InputMaybe<ScEnumRentalTypesBoolExp>;
};


export type SubscriptionRootScEnumUserPreferenceAssetTypesArgs = {
  distinct_on?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesOrderBy>>;
  where?: InputMaybe<ScEnumUserPreferenceAssetTypesBoolExp>;
};


export type SubscriptionRootScEnumUserPreferenceAssetTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumUserPreferenceAssetTypesOrderBy>>;
  where?: InputMaybe<ScEnumUserPreferenceAssetTypesBoolExp>;
};


export type SubscriptionRootScEnumUserPreferenceAssetTypesByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootScEnumUserPreferenceAssetTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScEnumUserPreferenceAssetTypesStreamCursorInput>>;
  where?: InputMaybe<ScEnumUserPreferenceAssetTypesBoolExp>;
};


export type SubscriptionRootScEnumUtilityBillPeriodTypesArgs = {
  distinct_on?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesOrderBy>>;
  where?: InputMaybe<ScEnumUtilityBillPeriodTypesBoolExp>;
};


export type SubscriptionRootScEnumUtilityBillPeriodTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScEnumUtilityBillPeriodTypesOrderBy>>;
  where?: InputMaybe<ScEnumUtilityBillPeriodTypesBoolExp>;
};


export type SubscriptionRootScEnumUtilityBillPeriodTypesByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootScEnumUtilityBillPeriodTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScEnumUtilityBillPeriodTypesStreamCursorInput>>;
  where?: InputMaybe<ScEnumUtilityBillPeriodTypesBoolExp>;
};


export type SubscriptionRootSpacecloudListScAssetGroupsAnnexRandomArgs = {
  args: SpacecloudListScAssetGroupsAnnexRandomArgs;
  distinct_on?: InputMaybe<Array<ScAssetGroupsAnnexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupsAnnexOrderBy>>;
  where?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
};


export type SubscriptionRootSpacecloudListScAssetGroupsAnnexRandomAggregateArgs = {
  args: SpacecloudListScAssetGroupsAnnexRandomArgs;
  distinct_on?: InputMaybe<Array<ScAssetGroupsAnnexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScAssetGroupsAnnexOrderBy>>;
  where?: InputMaybe<ScAssetGroupsAnnexBoolExp>;
};


export type SubscriptionRootSundayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<SundayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SundayOperatingHoursOrderBy>>;
  where?: InputMaybe<SundayOperatingHoursBoolExp>;
};


export type SubscriptionRootSundayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<SundayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SundayOperatingHoursOrderBy>>;
  where?: InputMaybe<SundayOperatingHoursBoolExp>;
};


export type SubscriptionRootSundayOperatingHoursStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SundayOperatingHoursStreamCursorInput>>;
  where?: InputMaybe<SundayOperatingHoursBoolExp>;
};


export type SubscriptionRootThursdayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<ThursdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ThursdayOperatingHoursOrderBy>>;
  where?: InputMaybe<ThursdayOperatingHoursBoolExp>;
};


export type SubscriptionRootThursdayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThursdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ThursdayOperatingHoursOrderBy>>;
  where?: InputMaybe<ThursdayOperatingHoursBoolExp>;
};


export type SubscriptionRootThursdayOperatingHoursStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ThursdayOperatingHoursStreamCursorInput>>;
  where?: InputMaybe<ThursdayOperatingHoursBoolExp>;
};


export type SubscriptionRootTuesdayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<TuesdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TuesdayOperatingHoursOrderBy>>;
  where?: InputMaybe<TuesdayOperatingHoursBoolExp>;
};


export type SubscriptionRootTuesdayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<TuesdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TuesdayOperatingHoursOrderBy>>;
  where?: InputMaybe<TuesdayOperatingHoursBoolExp>;
};


export type SubscriptionRootTuesdayOperatingHoursStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TuesdayOperatingHoursStreamCursorInput>>;
  where?: InputMaybe<TuesdayOperatingHoursBoolExp>;
};


export type SubscriptionRootUserAvatarMediaArgs = {
  distinct_on?: InputMaybe<Array<UserAvatarMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAvatarMediaOrderBy>>;
  where?: InputMaybe<UserAvatarMediaBoolExp>;
};


export type SubscriptionRootUserAvatarMediaAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAvatarMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAvatarMediaOrderBy>>;
  where?: InputMaybe<UserAvatarMediaBoolExp>;
};


export type SubscriptionRootUserAvatarMediaByPkArgs = {
  media_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type SubscriptionRootUserAvatarMediaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserAvatarMediaStreamCursorInput>>;
  where?: InputMaybe<UserAvatarMediaBoolExp>;
};


export type SubscriptionRootUserConsentsArgs = {
  distinct_on?: InputMaybe<Array<UserConsentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserConsentsOrderBy>>;
  where?: InputMaybe<UserConsentsBoolExp>;
};


export type SubscriptionRootUserConsentsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUserConsentsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserConsentsStreamCursorInput>>;
  where?: InputMaybe<UserConsentsBoolExp>;
};


export type SubscriptionRootUserGroupLogoMediaArgs = {
  distinct_on?: InputMaybe<Array<UserGroupLogoMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupLogoMediaOrderBy>>;
  where?: InputMaybe<UserGroupLogoMediaBoolExp>;
};


export type SubscriptionRootUserGroupLogoMediaAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupLogoMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupLogoMediaOrderBy>>;
  where?: InputMaybe<UserGroupLogoMediaBoolExp>;
};


export type SubscriptionRootUserGroupLogoMediaByPkArgs = {
  media_id: Scalars['uuid'];
  user_group_id: Scalars['uuid'];
};


export type SubscriptionRootUserGroupLogoMediaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserGroupLogoMediaStreamCursorInput>>;
  where?: InputMaybe<UserGroupLogoMediaBoolExp>;
};


export type SubscriptionRootUserGroupProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupProcessValuesOrderBy>>;
  where?: InputMaybe<UserGroupProcessValuesBoolExp>;
};


export type SubscriptionRootUserGroupProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupProcessValuesOrderBy>>;
  where?: InputMaybe<UserGroupProcessValuesBoolExp>;
};


export type SubscriptionRootUserGroupProcessValuesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUserGroupProcessValuesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserGroupProcessValuesStreamCursorInput>>;
  where?: InputMaybe<UserGroupProcessValuesBoolExp>;
};


export type SubscriptionRootUserGroupRolesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupRolesOrderBy>>;
  where?: InputMaybe<UserGroupRolesBoolExp>;
};


export type SubscriptionRootUserGroupRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupRolesOrderBy>>;
  where?: InputMaybe<UserGroupRolesBoolExp>;
};


export type SubscriptionRootUserGroupRolesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUserGroupRolesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserGroupRolesStreamCursorInput>>;
  where?: InputMaybe<UserGroupRolesBoolExp>;
};


export type SubscriptionRootUserGroupUserRolesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupUserRolesOrderBy>>;
  where?: InputMaybe<UserGroupUserRolesBoolExp>;
};


export type SubscriptionRootUserGroupUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupUserRolesOrderBy>>;
  where?: InputMaybe<UserGroupUserRolesBoolExp>;
};


export type SubscriptionRootUserGroupUserRolesByPkArgs = {
  user_group_role_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type SubscriptionRootUserGroupUserRolesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserGroupUserRolesStreamCursorInput>>;
  where?: InputMaybe<UserGroupUserRolesBoolExp>;
};


export type SubscriptionRootUserGroupsArgs = {
  distinct_on?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


export type SubscriptionRootUserGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


export type SubscriptionRootUserGroupsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUserGroupsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserGroupsStreamCursorInput>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


export type SubscriptionRootUserProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<UserProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProcessValuesOrderBy>>;
  where?: InputMaybe<UserProcessValuesBoolExp>;
};


export type SubscriptionRootUserProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProcessValuesOrderBy>>;
  where?: InputMaybe<UserProcessValuesBoolExp>;
};


export type SubscriptionRootUserProcessValuesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUserProcessValuesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserProcessValuesStreamCursorInput>>;
  where?: InputMaybe<UserProcessValuesBoolExp>;
};


export type SubscriptionRootUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootWednesdayOperatingHoursArgs = {
  distinct_on?: InputMaybe<Array<WednesdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WednesdayOperatingHoursOrderBy>>;
  where?: InputMaybe<WednesdayOperatingHoursBoolExp>;
};


export type SubscriptionRootWednesdayOperatingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<WednesdayOperatingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WednesdayOperatingHoursOrderBy>>;
  where?: InputMaybe<WednesdayOperatingHoursBoolExp>;
};


export type SubscriptionRootWednesdayOperatingHoursStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WednesdayOperatingHoursStreamCursorInput>>;
  where?: InputMaybe<WednesdayOperatingHoursBoolExp>;
};

/** columns and relationships of "sunday_operating_hours" */
export type SundayOperatingHours = {
  __typename?: 'sunday_operating_hours';
  day_of_week: Scalars['String'];
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id: Scalars['uuid'];
};

/** aggregated selection of "sunday_operating_hours" */
export type SundayOperatingHoursAggregate = {
  __typename?: 'sunday_operating_hours_aggregate';
  aggregate?: Maybe<SundayOperatingHoursAggregateFields>;
  nodes: Array<SundayOperatingHours>;
};

/** aggregate fields of "sunday_operating_hours" */
export type SundayOperatingHoursAggregateFields = {
  __typename?: 'sunday_operating_hours_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SundayOperatingHoursMaxFields>;
  min?: Maybe<SundayOperatingHoursMinFields>;
};


/** aggregate fields of "sunday_operating_hours" */
export type SundayOperatingHoursAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SundayOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sunday_operating_hours". All fields are combined with a logical 'AND'. */
export type SundayOperatingHoursBoolExp = {
  _and?: InputMaybe<Array<SundayOperatingHoursBoolExp>>;
  _not?: InputMaybe<SundayOperatingHoursBoolExp>;
  _or?: InputMaybe<Array<SundayOperatingHoursBoolExp>>;
  day_of_week?: InputMaybe<StringComparisonExp>;
  hour_end?: InputMaybe<TimetzComparisonExp>;
  hour_start?: InputMaybe<TimetzComparisonExp>;
  model_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type SundayOperatingHoursMaxFields = {
  __typename?: 'sunday_operating_hours_max_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SundayOperatingHoursMinFields = {
  __typename?: 'sunday_operating_hours_min_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "sunday_operating_hours". */
export type SundayOperatingHoursOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  model_id?: InputMaybe<OrderBy>;
};

/** select columns of table "sunday_operating_hours" */
export enum SundayOperatingHoursSelectColumn {
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  ModelId = 'model_id'
}

/** Streaming cursor of the table "sunday_operating_hours" */
export type SundayOperatingHoursStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SundayOperatingHoursStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SundayOperatingHoursStreamCursorValueInput = {
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  model_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "thursday_operating_hours" */
export type ThursdayOperatingHours = {
  __typename?: 'thursday_operating_hours';
  day_of_week: Scalars['String'];
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id: Scalars['uuid'];
};

/** aggregated selection of "thursday_operating_hours" */
export type ThursdayOperatingHoursAggregate = {
  __typename?: 'thursday_operating_hours_aggregate';
  aggregate?: Maybe<ThursdayOperatingHoursAggregateFields>;
  nodes: Array<ThursdayOperatingHours>;
};

/** aggregate fields of "thursday_operating_hours" */
export type ThursdayOperatingHoursAggregateFields = {
  __typename?: 'thursday_operating_hours_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ThursdayOperatingHoursMaxFields>;
  min?: Maybe<ThursdayOperatingHoursMinFields>;
};


/** aggregate fields of "thursday_operating_hours" */
export type ThursdayOperatingHoursAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThursdayOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "thursday_operating_hours". All fields are combined with a logical 'AND'. */
export type ThursdayOperatingHoursBoolExp = {
  _and?: InputMaybe<Array<ThursdayOperatingHoursBoolExp>>;
  _not?: InputMaybe<ThursdayOperatingHoursBoolExp>;
  _or?: InputMaybe<Array<ThursdayOperatingHoursBoolExp>>;
  day_of_week?: InputMaybe<StringComparisonExp>;
  hour_end?: InputMaybe<TimetzComparisonExp>;
  hour_start?: InputMaybe<TimetzComparisonExp>;
  model_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type ThursdayOperatingHoursMaxFields = {
  __typename?: 'thursday_operating_hours_max_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ThursdayOperatingHoursMinFields = {
  __typename?: 'thursday_operating_hours_min_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "thursday_operating_hours". */
export type ThursdayOperatingHoursOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  model_id?: InputMaybe<OrderBy>;
};

/** select columns of table "thursday_operating_hours" */
export enum ThursdayOperatingHoursSelectColumn {
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  ModelId = 'model_id'
}

/** Streaming cursor of the table "thursday_operating_hours" */
export type ThursdayOperatingHoursStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ThursdayOperatingHoursStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThursdayOperatingHoursStreamCursorValueInput = {
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  model_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type TimetzComparisonExp = {
  _eq?: InputMaybe<Scalars['timetz']>;
  _gt?: InputMaybe<Scalars['timetz']>;
  _gte?: InputMaybe<Scalars['timetz']>;
  _in?: InputMaybe<Array<Scalars['timetz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timetz']>;
  _lte?: InputMaybe<Scalars['timetz']>;
  _neq?: InputMaybe<Scalars['timetz']>;
  _nin?: InputMaybe<Array<Scalars['timetz']>>;
};

/** columns and relationships of "tuesday_operating_hours" */
export type TuesdayOperatingHours = {
  __typename?: 'tuesday_operating_hours';
  day_of_week: Scalars['String'];
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id: Scalars['uuid'];
};

/** aggregated selection of "tuesday_operating_hours" */
export type TuesdayOperatingHoursAggregate = {
  __typename?: 'tuesday_operating_hours_aggregate';
  aggregate?: Maybe<TuesdayOperatingHoursAggregateFields>;
  nodes: Array<TuesdayOperatingHours>;
};

/** aggregate fields of "tuesday_operating_hours" */
export type TuesdayOperatingHoursAggregateFields = {
  __typename?: 'tuesday_operating_hours_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TuesdayOperatingHoursMaxFields>;
  min?: Maybe<TuesdayOperatingHoursMinFields>;
};


/** aggregate fields of "tuesday_operating_hours" */
export type TuesdayOperatingHoursAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TuesdayOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tuesday_operating_hours". All fields are combined with a logical 'AND'. */
export type TuesdayOperatingHoursBoolExp = {
  _and?: InputMaybe<Array<TuesdayOperatingHoursBoolExp>>;
  _not?: InputMaybe<TuesdayOperatingHoursBoolExp>;
  _or?: InputMaybe<Array<TuesdayOperatingHoursBoolExp>>;
  day_of_week?: InputMaybe<StringComparisonExp>;
  hour_end?: InputMaybe<TimetzComparisonExp>;
  hour_start?: InputMaybe<TimetzComparisonExp>;
  model_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type TuesdayOperatingHoursMaxFields = {
  __typename?: 'tuesday_operating_hours_max_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type TuesdayOperatingHoursMinFields = {
  __typename?: 'tuesday_operating_hours_min_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "tuesday_operating_hours". */
export type TuesdayOperatingHoursOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  model_id?: InputMaybe<OrderBy>;
};

/** select columns of table "tuesday_operating_hours" */
export enum TuesdayOperatingHoursSelectColumn {
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  ModelId = 'model_id'
}

/** Streaming cursor of the table "tuesday_operating_hours" */
export type TuesdayOperatingHoursStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TuesdayOperatingHoursStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TuesdayOperatingHoursStreamCursorValueInput = {
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  model_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "user_avatar_media" */
export type UserAvatarMedia = {
  __typename?: 'user_avatar_media';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  media: Media;
  media_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_avatar_media" */
export type UserAvatarMediaAggregate = {
  __typename?: 'user_avatar_media_aggregate';
  aggregate?: Maybe<UserAvatarMediaAggregateFields>;
  nodes: Array<UserAvatarMedia>;
};

export type UserAvatarMediaAggregateBoolExp = {
  count?: InputMaybe<UserAvatarMediaAggregateBoolExpCount>;
};

export type UserAvatarMediaAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserAvatarMediaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserAvatarMediaBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_avatar_media" */
export type UserAvatarMediaAggregateFields = {
  __typename?: 'user_avatar_media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserAvatarMediaMaxFields>;
  min?: Maybe<UserAvatarMediaMinFields>;
};


/** aggregate fields of "user_avatar_media" */
export type UserAvatarMediaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserAvatarMediaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_avatar_media" */
export type UserAvatarMediaAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserAvatarMediaMaxOrderBy>;
  min?: InputMaybe<UserAvatarMediaMinOrderBy>;
};

/** input type for inserting array relation for remote table "user_avatar_media" */
export type UserAvatarMediaArrRelInsertInput = {
  data: Array<UserAvatarMediaInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserAvatarMediaOnConflict>;
};

/** Boolean expression to filter rows from the table "user_avatar_media". All fields are combined with a logical 'AND'. */
export type UserAvatarMediaBoolExp = {
  _and?: InputMaybe<Array<UserAvatarMediaBoolExp>>;
  _not?: InputMaybe<UserAvatarMediaBoolExp>;
  _or?: InputMaybe<Array<UserAvatarMediaBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  media?: InputMaybe<MediaBoolExp>;
  media_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_avatar_media" */
export enum UserAvatarMediaConstraint {
  /** unique or primary key constraint on columns "user_id", "media_id" */
  UserAvatarMediaPkey = 'user_avatar_media_pkey'
}

/** input type for inserting data into table "user_avatar_media" */
export type UserAvatarMediaInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  media?: InputMaybe<MediaObjRelInsertInput>;
  media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserAvatarMediaMaxFields = {
  __typename?: 'user_avatar_media_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_avatar_media" */
export type UserAvatarMediaMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserAvatarMediaMinFields = {
  __typename?: 'user_avatar_media_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_avatar_media" */
export type UserAvatarMediaMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_avatar_media" */
export type UserAvatarMediaMutationResponse = {
  __typename?: 'user_avatar_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserAvatarMedia>;
};

/** on_conflict condition type for table "user_avatar_media" */
export type UserAvatarMediaOnConflict = {
  constraint: UserAvatarMediaConstraint;
  update_columns?: Array<UserAvatarMediaUpdateColumn>;
  where?: InputMaybe<UserAvatarMediaBoolExp>;
};

/** Ordering options when selecting data from "user_avatar_media". */
export type UserAvatarMediaOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  media?: InputMaybe<MediaOrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_avatar_media */
export type UserAvatarMediaPkColumnsInput = {
  media_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "user_avatar_media" */
export enum UserAvatarMediaSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MediaId = 'media_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_avatar_media" */
export type UserAvatarMediaSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_avatar_media" */
export type UserAvatarMediaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserAvatarMediaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserAvatarMediaStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_avatar_media" */
export enum UserAvatarMediaUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MediaId = 'media_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type UserAvatarMediaUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserAvatarMediaSetInput>;
  /** filter the rows which have to be updated */
  where: UserAvatarMediaBoolExp;
};

/** columns and relationships of "user_consents" */
export type UserConsents = {
  __typename?: 'user_consents';
  consent: Scalars['Boolean'];
  /** An object relationship */
  consent_by_consent_id: Consents;
  consent_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** order by aggregate values of table "user_consents" */
export type UserConsentsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserConsentsMaxOrderBy>;
  min?: InputMaybe<UserConsentsMinOrderBy>;
};

/** input type for inserting array relation for remote table "user_consents" */
export type UserConsentsArrRelInsertInput = {
  data: Array<UserConsentsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserConsentsOnConflict>;
};

/** Boolean expression to filter rows from the table "user_consents". All fields are combined with a logical 'AND'. */
export type UserConsentsBoolExp = {
  _and?: InputMaybe<Array<UserConsentsBoolExp>>;
  _not?: InputMaybe<UserConsentsBoolExp>;
  _or?: InputMaybe<Array<UserConsentsBoolExp>>;
  consent?: InputMaybe<BooleanComparisonExp>;
  consent_by_consent_id?: InputMaybe<ConsentsBoolExp>;
  consent_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_consents" */
export enum UserConsentsConstraint {
  /** unique or primary key constraint on columns "consent_id", "user_id" */
  UserConsentsConsentIdUserIdKey = 'user_consents_consent_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  UserConsentsPkey = 'user_consents_pkey'
}

/** input type for inserting data into table "user_consents" */
export type UserConsentsInsertInput = {
  consent?: InputMaybe<Scalars['Boolean']>;
  consent_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_consents" */
export type UserConsentsMaxOrderBy = {
  consent_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "user_consents" */
export type UserConsentsMinOrderBy = {
  consent_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_consents" */
export type UserConsentsMutationResponse = {
  __typename?: 'user_consents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserConsents>;
};

/** on_conflict condition type for table "user_consents" */
export type UserConsentsOnConflict = {
  constraint: UserConsentsConstraint;
  update_columns?: Array<UserConsentsUpdateColumn>;
  where?: InputMaybe<UserConsentsBoolExp>;
};

/** Ordering options when selecting data from "user_consents". */
export type UserConsentsOrderBy = {
  consent?: InputMaybe<OrderBy>;
  consent_by_consent_id?: InputMaybe<ConsentsOrderBy>;
  consent_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_consents */
export type UserConsentsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "user_consents" */
export enum UserConsentsSelectColumn {
  /** column name */
  Consent = 'consent',
  /** column name */
  ConsentId = 'consent_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_consents" */
export type UserConsentsSetInput = {
  consent?: InputMaybe<Scalars['Boolean']>;
  consent_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_consents" */
export type UserConsentsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserConsentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserConsentsStreamCursorValueInput = {
  consent?: InputMaybe<Scalars['Boolean']>;
  consent_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_consents" */
export enum UserConsentsUpdateColumn {
  /** column name */
  Consent = 'consent',
  /** column name */
  ConsentId = 'consent_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type UserConsentsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserConsentsSetInput>;
  /** filter the rows which have to be updated */
  where: UserConsentsBoolExp;
};

/** columns and relationships of "user_group_logo_media" */
export type UserGroupLogoMedia = {
  __typename?: 'user_group_logo_media';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  media: Media;
  media_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_group: UserGroups;
  user_group_id: Scalars['uuid'];
};

/** aggregated selection of "user_group_logo_media" */
export type UserGroupLogoMediaAggregate = {
  __typename?: 'user_group_logo_media_aggregate';
  aggregate?: Maybe<UserGroupLogoMediaAggregateFields>;
  nodes: Array<UserGroupLogoMedia>;
};

export type UserGroupLogoMediaAggregateBoolExp = {
  count?: InputMaybe<UserGroupLogoMediaAggregateBoolExpCount>;
};

export type UserGroupLogoMediaAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserGroupLogoMediaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserGroupLogoMediaBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_group_logo_media" */
export type UserGroupLogoMediaAggregateFields = {
  __typename?: 'user_group_logo_media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserGroupLogoMediaMaxFields>;
  min?: Maybe<UserGroupLogoMediaMinFields>;
};


/** aggregate fields of "user_group_logo_media" */
export type UserGroupLogoMediaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserGroupLogoMediaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_group_logo_media" */
export type UserGroupLogoMediaAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserGroupLogoMediaMaxOrderBy>;
  min?: InputMaybe<UserGroupLogoMediaMinOrderBy>;
};

/** input type for inserting array relation for remote table "user_group_logo_media" */
export type UserGroupLogoMediaArrRelInsertInput = {
  data: Array<UserGroupLogoMediaInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserGroupLogoMediaOnConflict>;
};

/** Boolean expression to filter rows from the table "user_group_logo_media". All fields are combined with a logical 'AND'. */
export type UserGroupLogoMediaBoolExp = {
  _and?: InputMaybe<Array<UserGroupLogoMediaBoolExp>>;
  _not?: InputMaybe<UserGroupLogoMediaBoolExp>;
  _or?: InputMaybe<Array<UserGroupLogoMediaBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  media?: InputMaybe<MediaBoolExp>;
  media_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user_group?: InputMaybe<UserGroupsBoolExp>;
  user_group_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_group_logo_media" */
export enum UserGroupLogoMediaConstraint {
  /** unique or primary key constraint on columns "user_group_id", "media_id" */
  UserGroupLogoMediaPkey = 'user_group_logo_media_pkey'
}

/** input type for inserting data into table "user_group_logo_media" */
export type UserGroupLogoMediaInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  media?: InputMaybe<MediaObjRelInsertInput>;
  media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group?: InputMaybe<UserGroupsObjRelInsertInput>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserGroupLogoMediaMaxFields = {
  __typename?: 'user_group_logo_media_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_group_logo_media" */
export type UserGroupLogoMediaMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserGroupLogoMediaMinFields = {
  __typename?: 'user_group_logo_media_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_group_logo_media" */
export type UserGroupLogoMediaMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_group_logo_media" */
export type UserGroupLogoMediaMutationResponse = {
  __typename?: 'user_group_logo_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserGroupLogoMedia>;
};

/** on_conflict condition type for table "user_group_logo_media" */
export type UserGroupLogoMediaOnConflict = {
  constraint: UserGroupLogoMediaConstraint;
  update_columns?: Array<UserGroupLogoMediaUpdateColumn>;
  where?: InputMaybe<UserGroupLogoMediaBoolExp>;
};

/** Ordering options when selecting data from "user_group_logo_media". */
export type UserGroupLogoMediaOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  media?: InputMaybe<MediaOrderBy>;
  media_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group?: InputMaybe<UserGroupsOrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_group_logo_media */
export type UserGroupLogoMediaPkColumnsInput = {
  media_id: Scalars['uuid'];
  user_group_id: Scalars['uuid'];
};

/** select columns of table "user_group_logo_media" */
export enum UserGroupLogoMediaSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MediaId = 'media_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupId = 'user_group_id'
}

/** input type for updating data in table "user_group_logo_media" */
export type UserGroupLogoMediaSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_group_logo_media" */
export type UserGroupLogoMediaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserGroupLogoMediaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroupLogoMediaStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_group_logo_media" */
export enum UserGroupLogoMediaUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MediaId = 'media_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupId = 'user_group_id'
}

export type UserGroupLogoMediaUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserGroupLogoMediaSetInput>;
  /** filter the rows which have to be updated */
  where: UserGroupLogoMediaBoolExp;
};

/** columns and relationships of "user_group_process_values" */
export type UserGroupProcessValues = {
  __typename?: 'user_group_process_values';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  process: Processes;
  process_id: Scalars['uuid'];
  process_values: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "user_group_process_values" */
export type UserGroupProcessValuesProcessValuesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_group_process_values" */
export type UserGroupProcessValuesAggregate = {
  __typename?: 'user_group_process_values_aggregate';
  aggregate?: Maybe<UserGroupProcessValuesAggregateFields>;
  nodes: Array<UserGroupProcessValues>;
};

export type UserGroupProcessValuesAggregateBoolExp = {
  count?: InputMaybe<UserGroupProcessValuesAggregateBoolExpCount>;
};

export type UserGroupProcessValuesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserGroupProcessValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserGroupProcessValuesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_group_process_values" */
export type UserGroupProcessValuesAggregateFields = {
  __typename?: 'user_group_process_values_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserGroupProcessValuesMaxFields>;
  min?: Maybe<UserGroupProcessValuesMinFields>;
};


/** aggregate fields of "user_group_process_values" */
export type UserGroupProcessValuesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserGroupProcessValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_group_process_values" */
export type UserGroupProcessValuesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserGroupProcessValuesMaxOrderBy>;
  min?: InputMaybe<UserGroupProcessValuesMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserGroupProcessValuesAppendInput = {
  process_values?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_group_process_values" */
export type UserGroupProcessValuesArrRelInsertInput = {
  data: Array<UserGroupProcessValuesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserGroupProcessValuesOnConflict>;
};

/** Boolean expression to filter rows from the table "user_group_process_values". All fields are combined with a logical 'AND'. */
export type UserGroupProcessValuesBoolExp = {
  _and?: InputMaybe<Array<UserGroupProcessValuesBoolExp>>;
  _not?: InputMaybe<UserGroupProcessValuesBoolExp>;
  _or?: InputMaybe<Array<UserGroupProcessValuesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  process?: InputMaybe<ProcessesBoolExp>;
  process_id?: InputMaybe<UuidComparisonExp>;
  process_values?: InputMaybe<JsonbComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_group_process_values" */
export enum UserGroupProcessValuesConstraint {
  /** unique or primary key constraint on columns "id" */
  UserGroupProcessValuesPkey = 'user_group_process_values_pkey',
  /** unique or primary key constraint on columns "user_id", "process_id" */
  UserGroupProcessValuesProcessIdUserIdKey = 'user_group_process_values_process_id_user_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserGroupProcessValuesDeleteAtPathInput = {
  process_values?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserGroupProcessValuesDeleteElemInput = {
  process_values?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserGroupProcessValuesDeleteKeyInput = {
  process_values?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_group_process_values" */
export type UserGroupProcessValuesInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  process_id?: InputMaybe<Scalars['uuid']>;
  process_values?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserGroupProcessValuesMaxFields = {
  __typename?: 'user_group_process_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  process_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_group_process_values" */
export type UserGroupProcessValuesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  process_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserGroupProcessValuesMinFields = {
  __typename?: 'user_group_process_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  process_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_group_process_values" */
export type UserGroupProcessValuesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  process_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_group_process_values" */
export type UserGroupProcessValuesMutationResponse = {
  __typename?: 'user_group_process_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserGroupProcessValues>;
};

/** on_conflict condition type for table "user_group_process_values" */
export type UserGroupProcessValuesOnConflict = {
  constraint: UserGroupProcessValuesConstraint;
  update_columns?: Array<UserGroupProcessValuesUpdateColumn>;
  where?: InputMaybe<UserGroupProcessValuesBoolExp>;
};

/** Ordering options when selecting data from "user_group_process_values". */
export type UserGroupProcessValuesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  process?: InputMaybe<ProcessesOrderBy>;
  process_id?: InputMaybe<OrderBy>;
  process_values?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_group_process_values */
export type UserGroupProcessValuesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserGroupProcessValuesPrependInput = {
  process_values?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_group_process_values" */
export enum UserGroupProcessValuesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessId = 'process_id',
  /** column name */
  ProcessValues = 'process_values',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_group_process_values" */
export type UserGroupProcessValuesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  process_id?: InputMaybe<Scalars['uuid']>;
  process_values?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_group_process_values" */
export type UserGroupProcessValuesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserGroupProcessValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroupProcessValuesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  process_id?: InputMaybe<Scalars['uuid']>;
  process_values?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_group_process_values" */
export enum UserGroupProcessValuesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessId = 'process_id',
  /** column name */
  ProcessValues = 'process_values',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type UserGroupProcessValuesUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserGroupProcessValuesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserGroupProcessValuesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserGroupProcessValuesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserGroupProcessValuesDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserGroupProcessValuesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserGroupProcessValuesSetInput>;
  /** filter the rows which have to be updated */
  where: UserGroupProcessValuesBoolExp;
};

/** columns and relationships of "user_group_roles" */
export type UserGroupRoles = {
  __typename?: 'user_group_roles';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  invites: Array<Invites>;
  /** An aggregate relationship */
  invites_aggregate: InvitesAggregate;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_group: UserGroups;
  user_group_id: Scalars['uuid'];
  /** An array relationship */
  user_group_user_roles: Array<UserGroupUserRoles>;
  /** An aggregate relationship */
  user_group_user_roles_aggregate: UserGroupUserRolesAggregate;
};


/** columns and relationships of "user_group_roles" */
export type UserGroupRolesInvitesArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


/** columns and relationships of "user_group_roles" */
export type UserGroupRolesInvitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


/** columns and relationships of "user_group_roles" */
export type UserGroupRolesUserGroupUserRolesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupUserRolesOrderBy>>;
  where?: InputMaybe<UserGroupUserRolesBoolExp>;
};


/** columns and relationships of "user_group_roles" */
export type UserGroupRolesUserGroupUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupUserRolesOrderBy>>;
  where?: InputMaybe<UserGroupUserRolesBoolExp>;
};

/** aggregated selection of "user_group_roles" */
export type UserGroupRolesAggregate = {
  __typename?: 'user_group_roles_aggregate';
  aggregate?: Maybe<UserGroupRolesAggregateFields>;
  nodes: Array<UserGroupRoles>;
};

export type UserGroupRolesAggregateBoolExp = {
  count?: InputMaybe<UserGroupRolesAggregateBoolExpCount>;
};

export type UserGroupRolesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserGroupRolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserGroupRolesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_group_roles" */
export type UserGroupRolesAggregateFields = {
  __typename?: 'user_group_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserGroupRolesMaxFields>;
  min?: Maybe<UserGroupRolesMinFields>;
};


/** aggregate fields of "user_group_roles" */
export type UserGroupRolesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserGroupRolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_group_roles" */
export type UserGroupRolesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserGroupRolesMaxOrderBy>;
  min?: InputMaybe<UserGroupRolesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "user_group_roles". All fields are combined with a logical 'AND'. */
export type UserGroupRolesBoolExp = {
  _and?: InputMaybe<Array<UserGroupRolesBoolExp>>;
  _not?: InputMaybe<UserGroupRolesBoolExp>;
  _or?: InputMaybe<Array<UserGroupRolesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  invites?: InputMaybe<InvitesBoolExp>;
  invites_aggregate?: InputMaybe<InvitesAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user_group?: InputMaybe<UserGroupsBoolExp>;
  user_group_id?: InputMaybe<UuidComparisonExp>;
  user_group_user_roles?: InputMaybe<UserGroupUserRolesBoolExp>;
  user_group_user_roles_aggregate?: InputMaybe<UserGroupUserRolesAggregateBoolExp>;
};

/** aggregate max on columns */
export type UserGroupRolesMaxFields = {
  __typename?: 'user_group_roles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_group_roles" */
export type UserGroupRolesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserGroupRolesMinFields = {
  __typename?: 'user_group_roles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_group_roles" */
export type UserGroupRolesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "user_group_roles". */
export type UserGroupRolesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invites_aggregate?: InputMaybe<InvitesAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group?: InputMaybe<UserGroupsOrderBy>;
  user_group_id?: InputMaybe<OrderBy>;
  user_group_user_roles_aggregate?: InputMaybe<UserGroupUserRolesAggregateOrderBy>;
};

/** select columns of table "user_group_roles" */
export enum UserGroupRolesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupId = 'user_group_id'
}

/** Streaming cursor of the table "user_group_roles" */
export type UserGroupRolesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserGroupRolesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroupRolesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "user_group_user_roles" */
export type UserGroupUserRoles = {
  __typename?: 'user_group_user_roles';
  created_at: Scalars['timestamptz'];
  end_date?: Maybe<Scalars['date']>;
  is_active: Scalars['Boolean'];
  start_date?: Maybe<Scalars['date']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  user_group_role: UserGroupRoles;
  user_group_role_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_group_user_roles" */
export type UserGroupUserRolesAggregate = {
  __typename?: 'user_group_user_roles_aggregate';
  aggregate?: Maybe<UserGroupUserRolesAggregateFields>;
  nodes: Array<UserGroupUserRoles>;
};

export type UserGroupUserRolesAggregateBoolExp = {
  bool_and?: InputMaybe<UserGroupUserRolesAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UserGroupUserRolesAggregateBoolExpBoolOr>;
  count?: InputMaybe<UserGroupUserRolesAggregateBoolExpCount>;
};

export type UserGroupUserRolesAggregateBoolExpBoolAnd = {
  arguments: UserGroupUserRolesSelectColumnUserGroupUserRolesAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserGroupUserRolesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserGroupUserRolesAggregateBoolExpBoolOr = {
  arguments: UserGroupUserRolesSelectColumnUserGroupUserRolesAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserGroupUserRolesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserGroupUserRolesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserGroupUserRolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserGroupUserRolesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_group_user_roles" */
export type UserGroupUserRolesAggregateFields = {
  __typename?: 'user_group_user_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserGroupUserRolesMaxFields>;
  min?: Maybe<UserGroupUserRolesMinFields>;
};


/** aggregate fields of "user_group_user_roles" */
export type UserGroupUserRolesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserGroupUserRolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_group_user_roles" */
export type UserGroupUserRolesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserGroupUserRolesMaxOrderBy>;
  min?: InputMaybe<UserGroupUserRolesMinOrderBy>;
};

/** input type for inserting array relation for remote table "user_group_user_roles" */
export type UserGroupUserRolesArrRelInsertInput = {
  data: Array<UserGroupUserRolesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserGroupUserRolesOnConflict>;
};

/** Boolean expression to filter rows from the table "user_group_user_roles". All fields are combined with a logical 'AND'. */
export type UserGroupUserRolesBoolExp = {
  _and?: InputMaybe<Array<UserGroupUserRolesBoolExp>>;
  _not?: InputMaybe<UserGroupUserRolesBoolExp>;
  _or?: InputMaybe<Array<UserGroupUserRolesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  end_date?: InputMaybe<DateComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  start_date?: InputMaybe<DateComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_group_role?: InputMaybe<UserGroupRolesBoolExp>;
  user_group_role_id?: InputMaybe<UuidComparisonExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_group_user_roles" */
export enum UserGroupUserRolesConstraint {
  /** unique or primary key constraint on columns "user_group_role_id", "user_id" */
  GroupUserRolesPkey = 'group_user_roles_pkey'
}

/** input type for inserting data into table "user_group_user_roles" */
export type UserGroupUserRolesInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['date']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_group_role_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserGroupUserRolesMaxFields = {
  __typename?: 'user_group_user_roles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_group_user_roles" */
export type UserGroupUserRolesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  start_date?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserGroupUserRolesMinFields = {
  __typename?: 'user_group_user_roles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_group_role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_group_user_roles" */
export type UserGroupUserRolesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  start_date?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_group_role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_group_user_roles" */
export type UserGroupUserRolesMutationResponse = {
  __typename?: 'user_group_user_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserGroupUserRoles>;
};

/** on_conflict condition type for table "user_group_user_roles" */
export type UserGroupUserRolesOnConflict = {
  constraint: UserGroupUserRolesConstraint;
  update_columns?: Array<UserGroupUserRolesUpdateColumn>;
  where?: InputMaybe<UserGroupUserRolesBoolExp>;
};

/** Ordering options when selecting data from "user_group_user_roles". */
export type UserGroupUserRolesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  start_date?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_group_role?: InputMaybe<UserGroupRolesOrderBy>;
  user_group_role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_group_user_roles */
export type UserGroupUserRolesPkColumnsInput = {
  user_group_role_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "user_group_user_roles" */
export enum UserGroupUserRolesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupRoleId = 'user_group_role_id',
  /** column name */
  UserId = 'user_id'
}

/** select "user_group_user_roles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_group_user_roles" */
export enum UserGroupUserRolesSelectColumnUserGroupUserRolesAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

/** select "user_group_user_roles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_group_user_roles" */
export enum UserGroupUserRolesSelectColumnUserGroupUserRolesAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

/** input type for updating data in table "user_group_user_roles" */
export type UserGroupUserRolesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['date']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_role_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_group_user_roles" */
export type UserGroupUserRolesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserGroupUserRolesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroupUserRolesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['date']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_group_role_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_group_user_roles" */
export enum UserGroupUserRolesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserGroupRoleId = 'user_group_role_id',
  /** column name */
  UserId = 'user_id'
}

export type UserGroupUserRolesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserGroupUserRolesSetInput>;
  /** filter the rows which have to be updated */
  where: UserGroupUserRolesBoolExp;
};

/** columns and relationships of "user_groups" */
export type UserGroups = {
  __typename?: 'user_groups';
  active: Scalars['Boolean'];
  /** An array relationship */
  asset_classes: Array<AssetClasses>;
  /** An aggregate relationship */
  asset_classes_aggregate: AssetClassesAggregate;
  /** An array relationship */
  consents_by_organization_id: Array<Consents>;
  /** An aggregate relationship */
  consents_by_organization_id_aggregate: ConsentsAggregate;
  created_at: Scalars['timestamptz'];
  created_by_id?: Maybe<Scalars['uuid']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  display_name: Scalars['String'];
  /** An object relationship */
  enum_user_groups_tier: EnumUserGroupsTier;
  /** An array relationship */
  flattened_user_group_user_permissions: Array<FlattenedUserGroupUserPermissions>;
  /** An aggregate relationship */
  flattened_user_group_user_permissions_aggregate: FlattenedUserGroupUserPermissionsAggregate;
  /** An array relationship */
  flattened_user_group_users: Array<FlattenedUserGroupUsers>;
  /** An aggregate relationship */
  flattened_user_group_users_aggregate: FlattenedUserGroupUsersAggregate;
  /** An array relationship */
  flattened_user_group_users_by_organization_id: Array<FlattenedUserGroupUsers>;
  /** An aggregate relationship */
  flattened_user_group_users_by_organization_id_aggregate: FlattenedUserGroupUsersAggregate;
  /** An array relationship */
  flattened_user_groups_by_child_id: Array<FlattenedUserGroups>;
  /** An aggregate relationship */
  flattened_user_groups_by_child_id_aggregate: FlattenedUserGroupsAggregate;
  /** An array relationship */
  flattened_user_groups_by_parent_id: Array<FlattenedUserGroups>;
  /** An aggregate relationship */
  flattened_user_groups_by_parent_id_aggregate: FlattenedUserGroupsAggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  invites: Array<Invites>;
  /** An aggregate relationship */
  invites_aggregate: InvitesAggregate;
  logo_url?: Maybe<Scalars['citext']>;
  name: Scalars['String'];
  parent_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  phone_number_verifications: Array<PhoneNumberVerifications>;
  /** An aggregate relationship */
  phone_number_verifications_aggregate: PhoneNumberVerificationsAggregate;
  process_values?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  processes: Array<Processes>;
  /** An aggregate relationship */
  processes_aggregate: ProcessesAggregate;
  schema?: Maybe<Scalars['jsonb']>;
  slug?: Maybe<Scalars['String']>;
  tier: EnumUserGroupsTierEnum;
  uischema?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  user_group?: Maybe<UserGroups>;
  /** A computed field, executes function "user_group_configuration" */
  user_group_configuration?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  user_group_roles: Array<UserGroupRoles>;
  /** An aggregate relationship */
  user_group_roles_aggregate: UserGroupRolesAggregate;
  /** An array relationship */
  user_groups: Array<UserGroups>;
  /** An aggregate relationship */
  user_groups_aggregate: UserGroupsAggregate;
};


/** columns and relationships of "user_groups" */
export type UserGroupsAssetClassesArgs = {
  distinct_on?: InputMaybe<Array<AssetClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetClassesOrderBy>>;
  where?: InputMaybe<AssetClassesBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsAssetClassesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetClassesOrderBy>>;
  where?: InputMaybe<AssetClassesBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsConsentsByOrganizationIdArgs = {
  distinct_on?: InputMaybe<Array<ConsentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsentsOrderBy>>;
  where?: InputMaybe<ConsentsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsConsentsByOrganizationIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConsentsOrderBy>>;
  where?: InputMaybe<ConsentsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsFlattenedUserGroupUserPermissionsArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUserPermissionsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsFlattenedUserGroupUserPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUserPermissionsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsFlattenedUserGroupUsersArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUsersOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsFlattenedUserGroupUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUsersOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsFlattenedUserGroupUsersByOrganizationIdArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUsersOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsFlattenedUserGroupUsersByOrganizationIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUsersOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsFlattenedUserGroupsByChildIdArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsFlattenedUserGroupsByChildIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsFlattenedUserGroupsByParentIdArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsFlattenedUserGroupsByParentIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsInvitesArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsInvitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsPhoneNumberVerificationsArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumberVerificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumberVerificationsOrderBy>>;
  where?: InputMaybe<PhoneNumberVerificationsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsPhoneNumberVerificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumberVerificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumberVerificationsOrderBy>>;
  where?: InputMaybe<PhoneNumberVerificationsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsProcessValuesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsProcessesArgs = {
  distinct_on?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsProcessesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsSchemaArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsUischemaArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsUserGroupConfigurationArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsUserGroupRolesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupRolesOrderBy>>;
  where?: InputMaybe<UserGroupRolesBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsUserGroupRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupRolesOrderBy>>;
  where?: InputMaybe<UserGroupRolesBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsUserGroupsArgs = {
  distinct_on?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


/** columns and relationships of "user_groups" */
export type UserGroupsUserGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};

/** aggregated selection of "user_groups" */
export type UserGroupsAggregate = {
  __typename?: 'user_groups_aggregate';
  aggregate?: Maybe<UserGroupsAggregateFields>;
  nodes: Array<UserGroups>;
};

export type UserGroupsAggregateBoolExp = {
  bool_and?: InputMaybe<UserGroupsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UserGroupsAggregateBoolExpBoolOr>;
  count?: InputMaybe<UserGroupsAggregateBoolExpCount>;
};

export type UserGroupsAggregateBoolExpBoolAnd = {
  arguments: UserGroupsSelectColumnUserGroupsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserGroupsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserGroupsAggregateBoolExpBoolOr = {
  arguments: UserGroupsSelectColumnUserGroupsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserGroupsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserGroupsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserGroupsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_groups" */
export type UserGroupsAggregateFields = {
  __typename?: 'user_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserGroupsMaxFields>;
  min?: Maybe<UserGroupsMinFields>;
};


/** aggregate fields of "user_groups" */
export type UserGroupsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_groups" */
export type UserGroupsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserGroupsMaxOrderBy>;
  min?: InputMaybe<UserGroupsMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserGroupsAppendInput = {
  process_values?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_groups" */
export type UserGroupsArrRelInsertInput = {
  data: Array<UserGroupsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserGroupsOnConflict>;
};

/** Boolean expression to filter rows from the table "user_groups". All fields are combined with a logical 'AND'. */
export type UserGroupsBoolExp = {
  _and?: InputMaybe<Array<UserGroupsBoolExp>>;
  _not?: InputMaybe<UserGroupsBoolExp>;
  _or?: InputMaybe<Array<UserGroupsBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  asset_classes?: InputMaybe<AssetClassesBoolExp>;
  asset_classes_aggregate?: InputMaybe<AssetClassesAggregateBoolExp>;
  consents_by_organization_id?: InputMaybe<ConsentsBoolExp>;
  consents_by_organization_id_aggregate?: InputMaybe<ConsentsAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_id?: InputMaybe<UuidComparisonExp>;
  deactivated_at?: InputMaybe<TimestamptzComparisonExp>;
  display_name?: InputMaybe<StringComparisonExp>;
  enum_user_groups_tier?: InputMaybe<EnumUserGroupsTierBoolExp>;
  flattened_user_group_user_permissions?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
  flattened_user_group_user_permissions_aggregate?: InputMaybe<FlattenedUserGroupUserPermissionsAggregateBoolExp>;
  flattened_user_group_users?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
  flattened_user_group_users_aggregate?: InputMaybe<FlattenedUserGroupUsersAggregateBoolExp>;
  flattened_user_group_users_by_organization_id?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
  flattened_user_group_users_by_organization_id_aggregate?: InputMaybe<FlattenedUserGroupUsersAggregateBoolExp>;
  flattened_user_groups_by_child_id?: InputMaybe<FlattenedUserGroupsBoolExp>;
  flattened_user_groups_by_child_id_aggregate?: InputMaybe<FlattenedUserGroupsAggregateBoolExp>;
  flattened_user_groups_by_parent_id?: InputMaybe<FlattenedUserGroupsBoolExp>;
  flattened_user_groups_by_parent_id_aggregate?: InputMaybe<FlattenedUserGroupsAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  invites?: InputMaybe<InvitesBoolExp>;
  invites_aggregate?: InputMaybe<InvitesAggregateBoolExp>;
  logo_url?: InputMaybe<CitextComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  parent_id?: InputMaybe<UuidComparisonExp>;
  phone_number_verifications?: InputMaybe<PhoneNumberVerificationsBoolExp>;
  phone_number_verifications_aggregate?: InputMaybe<PhoneNumberVerificationsAggregateBoolExp>;
  process_values?: InputMaybe<JsonbComparisonExp>;
  processes?: InputMaybe<ProcessesBoolExp>;
  processes_aggregate?: InputMaybe<ProcessesAggregateBoolExp>;
  schema?: InputMaybe<JsonbComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  tier?: InputMaybe<EnumUserGroupsTierEnumComparisonExp>;
  uischema?: InputMaybe<JsonbComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_group?: InputMaybe<UserGroupsBoolExp>;
  user_group_configuration?: InputMaybe<JsonbComparisonExp>;
  user_group_roles?: InputMaybe<UserGroupRolesBoolExp>;
  user_group_roles_aggregate?: InputMaybe<UserGroupRolesAggregateBoolExp>;
  user_groups?: InputMaybe<UserGroupsBoolExp>;
  user_groups_aggregate?: InputMaybe<UserGroupsAggregateBoolExp>;
};

/** unique or primary key constraints on table "user_groups" */
export enum UserGroupsConstraint {
  /** unique or primary key constraint on columns "name" */
  UserGroupsNameKey = 'user_groups_name_key',
  /** unique or primary key constraint on columns "id" */
  UserGroupsPkey = 'user_groups_pkey',
  /** unique or primary key constraint on columns "slug" */
  UserGroupsSlugKey = 'user_groups_slug_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserGroupsDeleteAtPathInput = {
  process_values?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserGroupsDeleteElemInput = {
  process_values?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserGroupsDeleteKeyInput = {
  process_values?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_groups" */
export type UserGroupsInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  asset_classes?: InputMaybe<AssetClassesArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_id?: InputMaybe<Scalars['uuid']>;
  deactivated_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invites?: InputMaybe<InvitesArrRelInsertInput>;
  logo_url?: InputMaybe<Scalars['citext']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  raw_invites?: InputMaybe<RawInvitesArrRelInsertInput>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_group?: InputMaybe<UserGroupsObjRelInsertInput>;
  user_groups?: InputMaybe<UserGroupsArrRelInsertInput>;
};

/** aggregate max on columns */
export type UserGroupsMaxFields = {
  __typename?: 'user_groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['citext']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user_groups" */
export type UserGroupsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  created_by_id?: InputMaybe<OrderBy>;
  deactivated_at?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logo_url?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserGroupsMinFields = {
  __typename?: 'user_groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['citext']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user_groups" */
export type UserGroupsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  created_by_id?: InputMaybe<OrderBy>;
  deactivated_at?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logo_url?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_groups" */
export type UserGroupsMutationResponse = {
  __typename?: 'user_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserGroups>;
};

/** input type for inserting object relation for remote table "user_groups" */
export type UserGroupsObjRelInsertInput = {
  data: UserGroupsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserGroupsOnConflict>;
};

/** on_conflict condition type for table "user_groups" */
export type UserGroupsOnConflict = {
  constraint: UserGroupsConstraint;
  update_columns?: Array<UserGroupsUpdateColumn>;
  where?: InputMaybe<UserGroupsBoolExp>;
};

/** Ordering options when selecting data from "user_groups". */
export type UserGroupsOrderBy = {
  active?: InputMaybe<OrderBy>;
  asset_classes_aggregate?: InputMaybe<AssetClassesAggregateOrderBy>;
  consents_by_organization_id_aggregate?: InputMaybe<ConsentsAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_id?: InputMaybe<OrderBy>;
  deactivated_at?: InputMaybe<OrderBy>;
  display_name?: InputMaybe<OrderBy>;
  enum_user_groups_tier?: InputMaybe<EnumUserGroupsTierOrderBy>;
  flattened_user_group_user_permissions_aggregate?: InputMaybe<FlattenedUserGroupUserPermissionsAggregateOrderBy>;
  flattened_user_group_users_aggregate?: InputMaybe<FlattenedUserGroupUsersAggregateOrderBy>;
  flattened_user_group_users_by_organization_id_aggregate?: InputMaybe<FlattenedUserGroupUsersAggregateOrderBy>;
  flattened_user_groups_by_child_id_aggregate?: InputMaybe<FlattenedUserGroupsAggregateOrderBy>;
  flattened_user_groups_by_parent_id_aggregate?: InputMaybe<FlattenedUserGroupsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  invites_aggregate?: InputMaybe<InvitesAggregateOrderBy>;
  logo_url?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  phone_number_verifications_aggregate?: InputMaybe<PhoneNumberVerificationsAggregateOrderBy>;
  process_values?: InputMaybe<OrderBy>;
  processes_aggregate?: InputMaybe<ProcessesAggregateOrderBy>;
  schema?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  tier?: InputMaybe<OrderBy>;
  uischema?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_group?: InputMaybe<UserGroupsOrderBy>;
  user_group_configuration?: InputMaybe<OrderBy>;
  user_group_roles_aggregate?: InputMaybe<UserGroupRolesAggregateOrderBy>;
  user_groups_aggregate?: InputMaybe<UserGroupsAggregateOrderBy>;
};

/** primary key columns input for table: user_groups */
export type UserGroupsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserGroupsPrependInput = {
  process_values?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_groups" */
export enum UserGroupsSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ProcessValues = 'process_values',
  /** column name */
  Schema = 'schema',
  /** column name */
  Slug = 'slug',
  /** column name */
  Tier = 'tier',
  /** column name */
  Uischema = 'uischema',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "user_groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_groups" */
export enum UserGroupsSelectColumnUserGroupsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Active = 'active'
}

/** select "user_groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_groups" */
export enum UserGroupsSelectColumnUserGroupsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "user_groups" */
export type UserGroupsSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_id?: InputMaybe<Scalars['uuid']>;
  deactivated_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  logo_url?: InputMaybe<Scalars['citext']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  process_values?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "user_groups" */
export type UserGroupsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserGroupsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroupsStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_id?: InputMaybe<Scalars['uuid']>;
  deactivated_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  logo_url?: InputMaybe<Scalars['citext']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  process_values?: InputMaybe<Scalars['jsonb']>;
  schema?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  tier?: InputMaybe<EnumUserGroupsTierEnum>;
  uischema?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user_groups" */
export enum UserGroupsUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ProcessValues = 'process_values',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type UserGroupsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserGroupsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserGroupsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserGroupsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserGroupsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserGroupsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserGroupsSetInput>;
  /** filter the rows which have to be updated */
  where: UserGroupsBoolExp;
};

/** columns and relationships of "user_process_values" */
export type UserProcessValues = {
  __typename?: 'user_process_values';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  process: Processes;
  process_id: Scalars['uuid'];
  process_values: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "user_process_values" */
export type UserProcessValuesProcessValuesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_process_values" */
export type UserProcessValuesAggregate = {
  __typename?: 'user_process_values_aggregate';
  aggregate?: Maybe<UserProcessValuesAggregateFields>;
  nodes: Array<UserProcessValues>;
};

export type UserProcessValuesAggregateBoolExp = {
  count?: InputMaybe<UserProcessValuesAggregateBoolExpCount>;
};

export type UserProcessValuesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserProcessValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserProcessValuesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_process_values" */
export type UserProcessValuesAggregateFields = {
  __typename?: 'user_process_values_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserProcessValuesMaxFields>;
  min?: Maybe<UserProcessValuesMinFields>;
};


/** aggregate fields of "user_process_values" */
export type UserProcessValuesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserProcessValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_process_values" */
export type UserProcessValuesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserProcessValuesMaxOrderBy>;
  min?: InputMaybe<UserProcessValuesMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserProcessValuesAppendInput = {
  process_values?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_process_values" */
export type UserProcessValuesArrRelInsertInput = {
  data: Array<UserProcessValuesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserProcessValuesOnConflict>;
};

/** Boolean expression to filter rows from the table "user_process_values". All fields are combined with a logical 'AND'. */
export type UserProcessValuesBoolExp = {
  _and?: InputMaybe<Array<UserProcessValuesBoolExp>>;
  _not?: InputMaybe<UserProcessValuesBoolExp>;
  _or?: InputMaybe<Array<UserProcessValuesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  process?: InputMaybe<ProcessesBoolExp>;
  process_id?: InputMaybe<UuidComparisonExp>;
  process_values?: InputMaybe<JsonbComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_process_values" */
export enum UserProcessValuesConstraint {
  /** unique or primary key constraint on columns "id" */
  UserProcessValuesPkey = 'user_process_values_pkey',
  /** unique or primary key constraint on columns "user_id", "process_id" */
  UserProcessValuesProcessIdUserIdKey = 'user_process_values_process_id_user_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserProcessValuesDeleteAtPathInput = {
  process_values?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserProcessValuesDeleteElemInput = {
  process_values?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserProcessValuesDeleteKeyInput = {
  process_values?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_process_values" */
export type UserProcessValuesInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  process_id?: InputMaybe<Scalars['uuid']>;
  process_values?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserProcessValuesMaxFields = {
  __typename?: 'user_process_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  process_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_process_values" */
export type UserProcessValuesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  process_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserProcessValuesMinFields = {
  __typename?: 'user_process_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  process_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_process_values" */
export type UserProcessValuesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  process_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_process_values" */
export type UserProcessValuesMutationResponse = {
  __typename?: 'user_process_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserProcessValues>;
};

/** on_conflict condition type for table "user_process_values" */
export type UserProcessValuesOnConflict = {
  constraint: UserProcessValuesConstraint;
  update_columns?: Array<UserProcessValuesUpdateColumn>;
  where?: InputMaybe<UserProcessValuesBoolExp>;
};

/** Ordering options when selecting data from "user_process_values". */
export type UserProcessValuesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  process?: InputMaybe<ProcessesOrderBy>;
  process_id?: InputMaybe<OrderBy>;
  process_values?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_process_values */
export type UserProcessValuesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserProcessValuesPrependInput = {
  process_values?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_process_values" */
export enum UserProcessValuesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessId = 'process_id',
  /** column name */
  ProcessValues = 'process_values',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_process_values" */
export type UserProcessValuesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  process_id?: InputMaybe<Scalars['uuid']>;
  process_values?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_process_values" */
export type UserProcessValuesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserProcessValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserProcessValuesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  process_id?: InputMaybe<Scalars['uuid']>;
  process_values?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_process_values" */
export enum UserProcessValuesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessId = 'process_id',
  /** column name */
  ProcessValues = 'process_values',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type UserProcessValuesUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserProcessValuesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserProcessValuesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserProcessValuesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserProcessValuesDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserProcessValuesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserProcessValuesSetInput>;
  /** filter the rows which have to be updated */
  where: UserProcessValuesBoolExp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  active: Scalars['Boolean'];
  /** An array relationship */
  asset_bookings: Array<AssetBookings>;
  /** An aggregate relationship */
  asset_bookings_aggregate: AssetBookingsAggregate;
  /** An array relationship */
  asset_bookings_by_updated_by_id: Array<AssetBookings>;
  /** An aggregate relationship */
  asset_bookings_by_updated_by_id_aggregate: AssetBookingsAggregate;
  avatar_url?: Maybe<Scalars['citext']>;
  created_at: Scalars['timestamptz'];
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['citext']>;
  end_date?: Maybe<Scalars['date']>;
  /** An array relationship */
  external_group_process_values: Array<ExternalProcessValues>;
  /** An aggregate relationship */
  external_group_process_values_aggregate: ExternalProcessValuesAggregate;
  first_name: Scalars['String'];
  /** An array relationship */
  flattened_user_group_user_permissions: Array<FlattenedUserGroupUserPermissions>;
  /** An aggregate relationship */
  flattened_user_group_user_permissions_aggregate: FlattenedUserGroupUserPermissionsAggregate;
  /** An array relationship */
  flattened_user_group_users: Array<FlattenedUserGroupUsers>;
  /** An aggregate relationship */
  flattened_user_group_users_aggregate: FlattenedUserGroupUsersAggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  invites: Array<Invites>;
  /** An aggregate relationship */
  invites_aggregate: InvitesAggregate;
  last_name: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  /** An array relationship */
  phone_number_verifications: Array<PhoneNumberVerifications>;
  /** An aggregate relationship */
  phone_number_verifications_aggregate: PhoneNumberVerificationsAggregate;
  /** An array relationship */
  processes: Array<Processes>;
  /** An aggregate relationship */
  processes_aggregate: ProcessesAggregate;
  /** An array relationship */
  profiles: Array<Profiles>;
  salutation?: Maybe<Scalars['String']>;
  /** An object relationship */
  sc_user_preference?: Maybe<ScUserPreferences>;
  start_date?: Maybe<Scalars['date']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_avatar_media: Array<UserAvatarMedia>;
  /** An aggregate relationship */
  user_avatar_media_aggregate: UserAvatarMediaAggregate;
  /** An array relationship */
  user_consents: Array<UserConsents>;
  /** An array relationship */
  user_group_process_values: Array<UserGroupProcessValues>;
  /** An aggregate relationship */
  user_group_process_values_aggregate: UserGroupProcessValuesAggregate;
  /** An array relationship */
  user_group_user_roles: Array<UserGroupUserRoles>;
  /** An aggregate relationship */
  user_group_user_roles_aggregate: UserGroupUserRolesAggregate;
  /** An array relationship */
  user_groups: Array<UserGroups>;
  /** An aggregate relationship */
  user_groups_aggregate: UserGroupsAggregate;
  /** An array relationship */
  user_process_values: Array<UserProcessValues>;
  /** An aggregate relationship */
  user_process_values_aggregate: UserProcessValuesAggregate;
};


/** columns and relationships of "users" */
export type UsersAssetBookingsArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersAssetBookingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersAssetBookingsByUpdatedByIdArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersAssetBookingsByUpdatedByIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssetBookingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AssetBookingsOrderBy>>;
  where?: InputMaybe<AssetBookingsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersExternalGroupProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<ExternalProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProcessValuesOrderBy>>;
  where?: InputMaybe<ExternalProcessValuesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersExternalGroupProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ExternalProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalProcessValuesOrderBy>>;
  where?: InputMaybe<ExternalProcessValuesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersFlattenedUserGroupUserPermissionsArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUserPermissionsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersFlattenedUserGroupUserPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUserPermissionsOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersFlattenedUserGroupUsersArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUsersOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


/** columns and relationships of "users" */
export type UsersFlattenedUserGroupUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<FlattenedUserGroupUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlattenedUserGroupUsersOrderBy>>;
  where?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
};


/** columns and relationships of "users" */
export type UsersInvitesArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersInvitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<InvitesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvitesOrderBy>>;
  where?: InputMaybe<InvitesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersPhoneNumberVerificationsArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumberVerificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumberVerificationsOrderBy>>;
  where?: InputMaybe<PhoneNumberVerificationsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersPhoneNumberVerificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PhoneNumberVerificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PhoneNumberVerificationsOrderBy>>;
  where?: InputMaybe<PhoneNumberVerificationsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersProcessesArgs = {
  distinct_on?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersProcessesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersProfilesArgs = {
  distinct_on?: InputMaybe<Array<ProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProfilesOrderBy>>;
  where?: InputMaybe<ProfilesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserAvatarMediaArgs = {
  distinct_on?: InputMaybe<Array<UserAvatarMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAvatarMediaOrderBy>>;
  where?: InputMaybe<UserAvatarMediaBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserAvatarMediaAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAvatarMediaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAvatarMediaOrderBy>>;
  where?: InputMaybe<UserAvatarMediaBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserConsentsArgs = {
  distinct_on?: InputMaybe<Array<UserConsentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserConsentsOrderBy>>;
  where?: InputMaybe<UserConsentsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserGroupProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupProcessValuesOrderBy>>;
  where?: InputMaybe<UserGroupProcessValuesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserGroupProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupProcessValuesOrderBy>>;
  where?: InputMaybe<UserGroupProcessValuesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserGroupUserRolesArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupUserRolesOrderBy>>;
  where?: InputMaybe<UserGroupUserRolesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserGroupUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupUserRolesOrderBy>>;
  where?: InputMaybe<UserGroupUserRolesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserGroupsArgs = {
  distinct_on?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserProcessValuesArgs = {
  distinct_on?: InputMaybe<Array<UserProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProcessValuesOrderBy>>;
  where?: InputMaybe<UserProcessValuesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserProcessValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProcessValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProcessValuesOrderBy>>;
  where?: InputMaybe<UserProcessValuesBoolExp>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
};


/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  asset_bookings?: InputMaybe<AssetBookingsBoolExp>;
  asset_bookings_aggregate?: InputMaybe<AssetBookingsAggregateBoolExp>;
  asset_bookings_by_updated_by_id?: InputMaybe<AssetBookingsBoolExp>;
  asset_bookings_by_updated_by_id_aggregate?: InputMaybe<AssetBookingsAggregateBoolExp>;
  avatar_url?: InputMaybe<CitextComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  deactivated_at?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<CitextComparisonExp>;
  end_date?: InputMaybe<DateComparisonExp>;
  external_group_process_values?: InputMaybe<ExternalProcessValuesBoolExp>;
  external_group_process_values_aggregate?: InputMaybe<ExternalProcessValuesAggregateBoolExp>;
  first_name?: InputMaybe<StringComparisonExp>;
  flattened_user_group_user_permissions?: InputMaybe<FlattenedUserGroupUserPermissionsBoolExp>;
  flattened_user_group_user_permissions_aggregate?: InputMaybe<FlattenedUserGroupUserPermissionsAggregateBoolExp>;
  flattened_user_group_users?: InputMaybe<FlattenedUserGroupUsersBoolExp>;
  flattened_user_group_users_aggregate?: InputMaybe<FlattenedUserGroupUsersAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  invites?: InputMaybe<InvitesBoolExp>;
  invites_aggregate?: InputMaybe<InvitesAggregateBoolExp>;
  last_name?: InputMaybe<StringComparisonExp>;
  phone_number?: InputMaybe<StringComparisonExp>;
  phone_number_verifications?: InputMaybe<PhoneNumberVerificationsBoolExp>;
  phone_number_verifications_aggregate?: InputMaybe<PhoneNumberVerificationsAggregateBoolExp>;
  processes?: InputMaybe<ProcessesBoolExp>;
  processes_aggregate?: InputMaybe<ProcessesAggregateBoolExp>;
  profiles?: InputMaybe<ProfilesBoolExp>;
  salutation?: InputMaybe<StringComparisonExp>;
  sc_user_preference?: InputMaybe<ScUserPreferencesBoolExp>;
  start_date?: InputMaybe<DateComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user_avatar_media?: InputMaybe<UserAvatarMediaBoolExp>;
  user_avatar_media_aggregate?: InputMaybe<UserAvatarMediaAggregateBoolExp>;
  user_consents?: InputMaybe<UserConsentsBoolExp>;
  user_group_process_values?: InputMaybe<UserGroupProcessValuesBoolExp>;
  user_group_process_values_aggregate?: InputMaybe<UserGroupProcessValuesAggregateBoolExp>;
  user_group_user_roles?: InputMaybe<UserGroupUserRolesBoolExp>;
  user_group_user_roles_aggregate?: InputMaybe<UserGroupUserRolesAggregateBoolExp>;
  user_groups?: InputMaybe<UserGroupsBoolExp>;
  user_groups_aggregate?: InputMaybe<UserGroupsAggregateBoolExp>;
  user_process_values?: InputMaybe<UserProcessValuesBoolExp>;
  user_process_values_aggregate?: InputMaybe<UserProcessValuesAggregateBoolExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "email", "auth0_tenant_name" */
  UsersEmailAuth0TenantNameKey = 'users_email_auth0_tenant_name_key',
  /** unique or primary key constraint on columns "auth0_tenant_name", "phone_number" */
  UsersPhoneNumberAuth0TenantNameKey = 'users_phone_number_auth0_tenant_name_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  asset_bookings?: InputMaybe<AssetBookingsArrRelInsertInput>;
  asset_bookings_by_updated_by_id?: InputMaybe<AssetBookingsArrRelInsertInput>;
  avatar_url?: InputMaybe<Scalars['citext']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  end_date?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invites?: InputMaybe<InvitesArrRelInsertInput>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  raw_invites?: InputMaybe<RawInvitesArrRelInsertInput>;
  salutation?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_avatar_media?: InputMaybe<UserAvatarMediaArrRelInsertInput>;
  user_consents?: InputMaybe<UserConsentsArrRelInsertInput>;
  user_group_process_values?: InputMaybe<UserGroupProcessValuesArrRelInsertInput>;
  user_group_user_roles?: InputMaybe<UserGroupUserRolesArrRelInsertInput>;
  user_groups?: InputMaybe<UserGroupsArrRelInsertInput>;
  user_process_values?: InputMaybe<UserProcessValuesArrRelInsertInput>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'users_max_fields';
  avatar_url?: Maybe<Scalars['citext']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['citext']>;
  end_date?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'users_min_fields';
  avatar_url?: Maybe<Scalars['citext']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['citext']>;
  end_date?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UsersOnConflict>;
};

/** on_conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "users". */
export type UsersOrderBy = {
  active?: InputMaybe<OrderBy>;
  asset_bookings_aggregate?: InputMaybe<AssetBookingsAggregateOrderBy>;
  asset_bookings_by_updated_by_id_aggregate?: InputMaybe<AssetBookingsAggregateOrderBy>;
  avatar_url?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deactivated_at?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  external_group_process_values_aggregate?: InputMaybe<ExternalProcessValuesAggregateOrderBy>;
  first_name?: InputMaybe<OrderBy>;
  flattened_user_group_user_permissions_aggregate?: InputMaybe<FlattenedUserGroupUserPermissionsAggregateOrderBy>;
  flattened_user_group_users_aggregate?: InputMaybe<FlattenedUserGroupUsersAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  invites_aggregate?: InputMaybe<InvitesAggregateOrderBy>;
  last_name?: InputMaybe<OrderBy>;
  phone_number?: InputMaybe<OrderBy>;
  phone_number_verifications_aggregate?: InputMaybe<PhoneNumberVerificationsAggregateOrderBy>;
  processes_aggregate?: InputMaybe<ProcessesAggregateOrderBy>;
  profiles_aggregate?: InputMaybe<ProfilesAggregateOrderBy>;
  salutation?: InputMaybe<OrderBy>;
  sc_user_preference?: InputMaybe<ScUserPreferencesOrderBy>;
  start_date?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_avatar_media_aggregate?: InputMaybe<UserAvatarMediaAggregateOrderBy>;
  user_consents_aggregate?: InputMaybe<UserConsentsAggregateOrderBy>;
  user_group_process_values_aggregate?: InputMaybe<UserGroupProcessValuesAggregateOrderBy>;
  user_group_user_roles_aggregate?: InputMaybe<UserGroupUserRolesAggregateOrderBy>;
  user_groups_aggregate?: InputMaybe<UserGroupsAggregateOrderBy>;
  user_process_values_aggregate?: InputMaybe<UserProcessValuesAggregateOrderBy>;
};

/** primary key columns input for table: users */
export type UsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Salutation = 'salutation',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  avatar_url?: InputMaybe<Scalars['citext']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  end_date?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  avatar_url?: InputMaybe<Scalars['citext']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  end_date?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Salutation = 'salutation',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type UsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "wednesday_operating_hours" */
export type WednesdayOperatingHours = {
  __typename?: 'wednesday_operating_hours';
  day_of_week: Scalars['String'];
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id: Scalars['uuid'];
};

/** aggregated selection of "wednesday_operating_hours" */
export type WednesdayOperatingHoursAggregate = {
  __typename?: 'wednesday_operating_hours_aggregate';
  aggregate?: Maybe<WednesdayOperatingHoursAggregateFields>;
  nodes: Array<WednesdayOperatingHours>;
};

/** aggregate fields of "wednesday_operating_hours" */
export type WednesdayOperatingHoursAggregateFields = {
  __typename?: 'wednesday_operating_hours_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WednesdayOperatingHoursMaxFields>;
  min?: Maybe<WednesdayOperatingHoursMinFields>;
};


/** aggregate fields of "wednesday_operating_hours" */
export type WednesdayOperatingHoursAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WednesdayOperatingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "wednesday_operating_hours". All fields are combined with a logical 'AND'. */
export type WednesdayOperatingHoursBoolExp = {
  _and?: InputMaybe<Array<WednesdayOperatingHoursBoolExp>>;
  _not?: InputMaybe<WednesdayOperatingHoursBoolExp>;
  _or?: InputMaybe<Array<WednesdayOperatingHoursBoolExp>>;
  day_of_week?: InputMaybe<StringComparisonExp>;
  hour_end?: InputMaybe<TimetzComparisonExp>;
  hour_start?: InputMaybe<TimetzComparisonExp>;
  model_id?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type WednesdayOperatingHoursMaxFields = {
  __typename?: 'wednesday_operating_hours_max_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type WednesdayOperatingHoursMinFields = {
  __typename?: 'wednesday_operating_hours_min_fields';
  day_of_week?: Maybe<Scalars['String']>;
  hour_end?: Maybe<Scalars['timetz']>;
  hour_start?: Maybe<Scalars['timetz']>;
  model_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "wednesday_operating_hours". */
export type WednesdayOperatingHoursOrderBy = {
  day_of_week?: InputMaybe<OrderBy>;
  hour_end?: InputMaybe<OrderBy>;
  hour_start?: InputMaybe<OrderBy>;
  model_id?: InputMaybe<OrderBy>;
};

/** select columns of table "wednesday_operating_hours" */
export enum WednesdayOperatingHoursSelectColumn {
  /** column name */
  DayOfWeek = 'day_of_week',
  /** column name */
  HourEnd = 'hour_end',
  /** column name */
  HourStart = 'hour_start',
  /** column name */
  ModelId = 'model_id'
}

/** Streaming cursor of the table "wednesday_operating_hours" */
export type WednesdayOperatingHoursStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WednesdayOperatingHoursStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WednesdayOperatingHoursStreamCursorValueInput = {
  day_of_week?: InputMaybe<Scalars['String']>;
  hour_end?: InputMaybe<Scalars['timetz']>;
  hour_start?: InputMaybe<Scalars['timetz']>;
  model_id?: InputMaybe<Scalars['uuid']>;
};

export type CancelAssetBookingMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CancelAssetBookingMutation = { __typename?: 'mutation_root', cancel_asset_booking: { __typename?: 'CancelAssetBookingOutput', asset_booking?: { __typename?: 'asset_bookings', id: any } | undefined | null } };

export type ApproveAssetBookingMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ApproveAssetBookingMutation = { __typename?: 'mutation_root', approve_asset_booking: { __typename?: 'ApproveAssetBookingOutput', asset_booking?: { __typename?: 'asset_bookings', id: any } | undefined | null } };

export type RejectAssetBookingMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RejectAssetBookingMutation = { __typename?: 'mutation_root', reject_asset_booking: { __typename?: 'RejectAssetBookingOutput', asset_booking?: { __typename?: 'asset_bookings', id: any } | undefined | null } };

export type UpdateUserRolesInGroupsMutationVariables = Exact<{
  initialRoles: Array<Scalars['uuid']> | Scalars['uuid'];
  updatedRoles: Array<Scalars['uuid']> | Scalars['uuid'];
  userId: Scalars['uuid'];
  userGroupId: Scalars['uuid'];
}>;


export type UpdateUserRolesInGroupsMutation = { __typename?: 'mutation_root', update_user_roles_in_groups: { __typename?: 'UpdateUserRolesInGroupsOutput', message: string } };

export type DownloadCsvTemplateMutationVariables = Exact<{
  type: Scalars['String'];
  userGroupId: Scalars['uuid'];
}>;


export type DownloadCsvTemplateMutation = { __typename?: 'mutation_root', download_csv_template: { __typename?: 'DownloadCsvTemplateOutput', csv_data: Array<any> } };

export type MassEditUserProcessesMutationVariables = Exact<{
  csvJsonArray: Array<Scalars['json']> | Scalars['json'];
  userGroupId: Scalars['uuid'];
  type: Scalars['String'];
}>;


export type MassEditUserProcessesMutation = { __typename?: 'mutation_root', mass_edit_process_data: { __typename?: 'MassEditProcessDataOutput', message: string } };

export type DeactivateUserFromGroupMutationVariables = Exact<{
  userId: Scalars['uuid'];
  userGroupId: Scalars['uuid'];
}>;


export type DeactivateUserFromGroupMutation = { __typename?: 'mutation_root', deactivate_user_from_group: { __typename?: 'DeactivateUserFromGroupOutput', message: string } };

export type ActivateUserFromGroupMutationVariables = Exact<{
  userId: Scalars['uuid'];
  userGroupId: Scalars['uuid'];
}>;


export type ActivateUserFromGroupMutation = { __typename?: 'mutation_root', activate_user_from_group: { __typename?: 'ActivateUserFromGroupOutput', message: string } };

export type RemoveUserFromGroupMutationVariables = Exact<{
  groupId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type RemoveUserFromGroupMutation = { __typename?: 'mutation_root', remove_user_from_group: { __typename?: 'RemoveUserFromGroupOutput', user_id: string } };

export type AssetClassesForAutocompleteQueryVariables = Exact<{
  queryFilter?: InputMaybe<AssetClassesBoolExp>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type AssetClassesForAutocompleteQuery = { __typename?: 'query_root', asset_classes: Array<{ __typename?: 'asset_classes', id: any, name: string }> };

export type AssetGroupFieldsFragment = { __typename?: 'asset_groups', id: any, optional_name?: string | undefined | null, display_name: string, thumbnail_image_url?: any | undefined | null, floorplan_image_url?: any | undefined | null, description?: string | undefined | null, note?: string | undefined | null, created_at: any, updated_at: any, asset_class: { __typename?: 'asset_classes', id: any, name: string, description?: string | undefined | null }, asset_group?: { __typename?: 'asset_groups', id: any, display_name: string } | undefined | null, asset_group_media: Array<{ __typename?: 'asset_group_media', media: { __typename?: 'media', url: any } }> };

export type AssetGroupOperatingHoursFieldsFragment = { __typename?: 'asset_groups', asset_group_operating_hours: Array<{ __typename?: 'asset_group_operating_hours', id: any, day_of_week: string, hour_start: any, hour_end: any }> };

export type AssetGroupsForAutocompleteQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  queryFilter?: InputMaybe<AssetGroupsBoolExp>;
  orderBy?: InputMaybe<Array<AssetGroupsOrderBy> | AssetGroupsOrderBy>;
}>;


export type AssetGroupsForAutocompleteQuery = { __typename?: 'query_root', asset_groups: Array<{ __typename?: 'asset_groups', id: any, display_name: string }> };

export type AssetGroupsByUserGroupIdsQueryVariables = Exact<{
  assetGroupId?: InputMaybe<Scalars['uuid']>;
  userGroupIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  search?: InputMaybe<AssetGroupsBoolExp>;
  whereAssetClass?: InputMaybe<AssetGroupsBoolExp>;
  orderBy?: InputMaybe<Array<AssetGroupsOrderBy> | AssetGroupsOrderBy>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type AssetGroupsByUserGroupIdsQuery = { __typename?: 'query_root', asset_groups: Array<{ __typename?: 'asset_groups', id: any, optional_name?: string | undefined | null, display_name: string, thumbnail_image_url?: any | undefined | null, floorplan_image_url?: any | undefined | null, description?: string | undefined | null, note?: string | undefined | null, created_at: any, updated_at: any, flattened_asset_groups_by_child_id: Array<{ __typename?: 'flattened_asset_groups', asset_group_by_parent_id: { __typename?: 'asset_groups', id: any, display_name: string } }>, asset_class: { __typename?: 'asset_classes', id: any, name: string, description?: string | undefined | null, user_group: { __typename?: 'user_groups', id: any, display_name: string } }, asset_group?: { __typename?: 'asset_groups', id: any, display_name: string } | undefined | null, asset_group_media: Array<{ __typename?: 'asset_group_media', media: { __typename?: 'media', url: any } }> }>, asset_groups_aggregate: { __typename?: 'asset_groups_aggregate', aggregate?: { __typename?: 'asset_groups_aggregate_fields', count: number } | undefined | null } };

export type AssetGroupByIdQueryVariables = Exact<{
  assetGroupId: Scalars['uuid'];
}>;


export type AssetGroupByIdQuery = { __typename?: 'query_root', asset_groups_by_pk?: { __typename?: 'asset_groups', id: any, optional_name?: string | undefined | null, display_name: string, thumbnail_image_url?: any | undefined | null, floorplan_image_url?: any | undefined | null, description?: string | undefined | null, note?: string | undefined | null, created_at: any, updated_at: any, asset_class: { __typename?: 'asset_classes', id: any, name: string, description?: string | undefined | null }, asset_group?: { __typename?: 'asset_groups', id: any, display_name: string } | undefined | null, asset_group_media: Array<{ __typename?: 'asset_group_media', media: { __typename?: 'media', url: any } }>, asset_group_operating_hours: Array<{ __typename?: 'asset_group_operating_hours', id: any, day_of_week: string, hour_start: any, hour_end: any }> } | undefined | null };

export type AssetGroupOperatingHoursQueryVariables = Exact<{
  assetGroupId: Scalars['uuid'];
}>;


export type AssetGroupOperatingHoursQuery = { __typename?: 'query_root', asset_groups_by_pk?: { __typename?: 'asset_groups', asset_group_operating_hours: Array<{ __typename?: 'asset_group_operating_hours', id: any, day_of_week: string, hour_start: any, hour_end: any }> } | undefined | null };

export type UpdateAssetGroupMutationVariables = Exact<{
  assetGroupId: Scalars['uuid'];
  assetGroupData?: InputMaybe<AssetGroupsSetInput>;
}>;


export type UpdateAssetGroupMutation = { __typename?: 'mutation_root', update_asset_groups_by_pk?: { __typename?: 'asset_groups', id: any } | undefined | null };

export type CreateAssetGroupMediaMutationVariables = Exact<{
  assetGroupId: Scalars['uuid'];
  fileFormat: Scalars['String'];
  resourceKey: Scalars['String'];
  url: Scalars['citext'];
}>;


export type CreateAssetGroupMediaMutation = { __typename?: 'mutation_root', insert_media_one?: { __typename?: 'media', id: any } | undefined | null };

export type CreateAssetGroupOperatingHoursMutationVariables = Exact<{
  assetGroupId: Scalars['uuid'];
  dayOfWeek: Scalars['String'];
  hourStart: Scalars['timetz'];
  hourEnd: Scalars['timetz'];
}>;


export type CreateAssetGroupOperatingHoursMutation = { __typename?: 'mutation_root', insert_asset_group_operating_hours?: { __typename?: 'asset_group_operating_hours_mutation_response', affected_rows: number } | undefined | null };

export type CreateMultipleAssetGroupOperatingHoursMutationVariables = Exact<{
  input: Array<AssetGroupOperatingHoursInsertInput> | AssetGroupOperatingHoursInsertInput;
}>;


export type CreateMultipleAssetGroupOperatingHoursMutation = { __typename?: 'mutation_root', insert_asset_group_operating_hours?: { __typename?: 'asset_group_operating_hours_mutation_response', affected_rows: number } | undefined | null };

export type UpdateAssetGroupOperatingHoursMutationVariables = Exact<{
  assetGroupId: Scalars['uuid'];
  dayOfWeek: Scalars['String'];
  hourStart: Scalars['timetz'];
  hourEnd: Scalars['timetz'];
}>;


export type UpdateAssetGroupOperatingHoursMutation = { __typename?: 'mutation_root', update_asset_group_operating_hours?: { __typename?: 'asset_group_operating_hours_mutation_response', affected_rows: number } | undefined | null };

export type DeleteAssetGroupOperatingHoursMutationVariables = Exact<{
  assetGroupId: Scalars['uuid'];
  dayOfWeek: Scalars['String'];
}>;


export type DeleteAssetGroupOperatingHoursMutation = { __typename?: 'mutation_root', delete_asset_group_operating_hours?: { __typename?: 'asset_group_operating_hours_mutation_response', affected_rows: number } | undefined | null };

export type DeleteAssetGroupByIdMutationVariables = Exact<{
  assetGroupId: Scalars['uuid'];
}>;


export type DeleteAssetGroupByIdMutation = { __typename?: 'mutation_root', delete_asset_groups_by_pk?: { __typename?: 'asset_groups', id: any } | undefined | null };

export type CreateAssetGroupMutationVariables = Exact<{
  thumbnail_image_url?: InputMaybe<Scalars['String']>;
  floorplan_image_url?: InputMaybe<Scalars['String']>;
  asset_class_id: Scalars['String'];
  parent_id?: InputMaybe<Scalars['String']>;
  display_name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  asset_group_operating_hours: Array<AssetGroupOperatingHoursInsertCustomInput> | AssetGroupOperatingHoursInsertCustomInput;
}>;


export type CreateAssetGroupMutation = { __typename?: 'mutation_root', create_asset_group: { __typename?: 'CreateAssetGroupOutput', id: string } };

export type AssetFieldsFragment = { __typename?: 'assets', id: any, optional_name?: string | undefined | null, display_name: string, description?: string | undefined | null, thumbnail_image_url?: any | undefined | null, note?: string | undefined | null, active: boolean, created_at: any, updated_at: any, maximum_capacity: number };

export type AssetsByUserGroupIdsQueryVariables = Exact<{
  assetId?: InputMaybe<Scalars['uuid']>;
  userGroupIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  search?: InputMaybe<AssetsBoolExp>;
  orderBy?: InputMaybe<Array<AssetsOrderBy> | AssetsOrderBy>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type AssetsByUserGroupIdsQuery = { __typename?: 'query_root', assets: Array<{ __typename?: 'assets', id: any, display_name: string, description?: string | undefined | null, asset_class: { __typename?: 'asset_classes', name: string, user_group: { __typename?: 'user_groups', id: any, display_name: string } }, asset_group: { __typename?: 'asset_groups', display_name: string }, sc_asset?: { __typename?: 'sc_assets', is_draft: boolean } | undefined | null }>, assets_aggregate: { __typename?: 'assets_aggregate', aggregate?: { __typename?: 'assets_aggregate_fields', count: number } | undefined | null } };

export type AssetsByAssetClassQueryVariables = Exact<{
  assetClassName?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<AssetsBoolExp>;
  queryFilter?: InputMaybe<AssetsBoolExp>;
  whereDateAndTime?: InputMaybe<AssetsBoolExp>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetsOrderBy> | AssetsOrderBy>;
}>;


export type AssetsByAssetClassQuery = { __typename?: 'query_root', assets: Array<{ __typename?: 'assets', id: any, optional_name?: string | undefined | null, display_name: string, description?: string | undefined | null, thumbnail_image_url?: any | undefined | null, note?: string | undefined | null, active: boolean, created_at: any, updated_at: any, maximum_capacity: number }>, assets_aggregate: { __typename?: 'assets_aggregate', aggregate?: { __typename?: 'assets_aggregate_fields', count: number } | undefined | null } };

export type AssetByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AssetByIdQuery = { __typename?: 'query_root', assets_by_pk?: { __typename?: 'assets', id: any, optional_name?: string | undefined | null, display_name: string, description?: string | undefined | null, thumbnail_image_url?: any | undefined | null, note?: string | undefined | null, active: boolean, created_at: any, updated_at: any, maximum_capacity: number } | undefined | null };

export type CreateAssetMutationVariables = Exact<{
  object: AssetsInsertInput;
}>;


export type CreateAssetMutation = { __typename?: 'mutation_root', insert_assets_one?: { __typename?: 'assets', id: any } | undefined | null };

export type UpdateAssetMutationVariables = Exact<{
  assetId: Scalars['uuid'];
  assetData?: InputMaybe<AssetsSetInput>;
}>;


export type UpdateAssetMutation = { __typename?: 'mutation_root', update_assets_by_pk?: { __typename?: 'assets', id: any } | undefined | null };

export type CreateAssetMediaMutationVariables = Exact<{
  assetId: Scalars['uuid'];
  fileFormat: Scalars['String'];
  resourceKey: Scalars['String'];
  url: Scalars['citext'];
}>;


export type CreateAssetMediaMutation = { __typename?: 'mutation_root', insert_media_one?: { __typename?: 'media', id: any } | undefined | null };

export type DeleteAssetByIdMutationVariables = Exact<{
  assetId: Scalars['uuid'];
}>;


export type DeleteAssetByIdMutation = { __typename?: 'mutation_root', delete_assets_by_pk?: { __typename?: 'assets', id: any } | undefined | null };

export type GetAssetsByEventTypeWithGroupIdQueryVariables = Exact<{
  userGroupId: Scalars['uuid'];
}>;


export type GetAssetsByEventTypeWithGroupIdQuery = { __typename?: 'query_root', assets: Array<{ __typename?: 'assets', id: any, display_name: string }> };

export type AssetBookingByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AssetBookingByIdQuery = { __typename?: 'query_root', asset_bookings_by_pk?: { __typename?: 'asset_bookings', id: any, status: EnumAssetBookingStatusesEnum } | undefined | null };

export type BookingsByGroupIdsQueryVariables = Exact<{
  whereGroupId?: InputMaybe<AssetBookingsBoolExp>;
  whereDateAndTime?: InputMaybe<AssetBookingsBoolExp>;
  whereStatus?: InputMaybe<AssetBookingsBoolExp>;
  whereAssetClass?: InputMaybe<AssetBookingsBoolExp>;
  whereApproverList?: InputMaybe<AssetBookingsBoolExp>;
  whereAssetGroup?: InputMaybe<AssetBookingsBoolExp>;
  whereAsset?: InputMaybe<AssetBookingsBoolExp>;
  whereRequester?: InputMaybe<AssetBookingsBoolExp>;
  search?: InputMaybe<AssetBookingsBoolExp>;
  orderBy?: InputMaybe<Array<AssetBookingsOrderBy> | AssetBookingsOrderBy>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type BookingsByGroupIdsQuery = { __typename?: 'query_root', asset_bookings: Array<{ __typename?: 'asset_bookings', id: any, approver?: any | undefined | null, datetime_start: any, datetime_end: any, status: EnumAssetBookingStatusesEnum, created_at: any, asset: { __typename?: 'assets', display_name: string, asset_group: { __typename?: 'asset_groups', display_name: string }, asset_class: { __typename?: 'asset_classes', user_group: { __typename?: 'user_groups', id: any, display_name: string } } }, sc_booking?: { __typename?: 'sc_bookings', first_name: string, last_name: string } | undefined | null, booker?: { __typename?: 'bookers', first_name?: string | undefined | null, last_name?: string | undefined | null } | undefined | null }>, asset_bookings_aggregate: { __typename?: 'asset_bookings_aggregate', aggregate?: { __typename?: 'asset_bookings_aggregate_fields', count: number } | undefined | null } };

export type InviteStatusesForAutocompleteQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;


export type InviteStatusesForAutocompleteQuery = { __typename?: 'query_root', enum_invite_statuses: Array<{ __typename?: 'enum_invite_statuses', value: string }> };

export type EnumBookingStatusesQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;


export type EnumBookingStatusesQuery = { __typename?: 'query_root', enum_asset_booking_statuses: Array<{ __typename?: 'enum_asset_booking_statuses', value: string }> };

export type FilteredEnumBookingStatusesQueryVariables = Exact<{
  withOutStatuses?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type FilteredEnumBookingStatusesQuery = { __typename?: 'query_root', enum_asset_booking_statuses: Array<{ __typename?: 'enum_asset_booking_statuses', value: string }> };

export type GetExternalProcessValuesQueryVariables = Exact<{
  userId: Scalars['uuid'];
  processId: Scalars['uuid'];
}>;


export type GetExternalProcessValuesQuery = { __typename?: 'query_root', external_process_values: Array<{ __typename?: 'external_process_values', user_id: any, process_id: any, process_values: any }> };

export type GroupRolesForAutocompleteQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  queryFilter?: InputMaybe<UserGroupRolesBoolExp>;
}>;


export type GroupRolesForAutocompleteQuery = { __typename?: 'query_root', user_group_roles: Array<{ __typename?: 'user_group_roles', id: any, name: string }> };

export type InvitesByGroupIdsQueryVariables = Exact<{
  groupIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  search?: InputMaybe<InvitesBoolExp>;
  whereStatus?: InputMaybe<InvitesBoolExp>;
  orderBy?: InputMaybe<Array<InvitesOrderBy> | InvitesOrderBy>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type InvitesByGroupIdsQuery = { __typename?: 'query_root', invites: Array<{ __typename?: 'invites', id: any, receiver_email: any, status: EnumInviteStatusesEnum, expires_at: any, created_at: any, code: string, user_group_role?: { __typename?: 'user_group_roles', name: string } | undefined | null, user_group?: { __typename?: 'user_groups', id: any, display_name: string } | undefined | null }>, invites_aggregate: { __typename?: 'invites_aggregate', aggregate?: { __typename?: 'invites_aggregate_fields', count: number } | undefined | null } };

export type InviteByIdQueryVariables = Exact<{
  inviteId: Scalars['uuid'];
}>;


export type InviteByIdQuery = { __typename?: 'query_root', invites_by_pk?: { __typename?: 'invites', id: any, receiver_email: any, status: EnumInviteStatusesEnum, updated_at: any, created_at: any, expires_at: any, error?: any | undefined | null, user_group_id?: any | undefined | null, user_group?: { __typename?: 'user_groups', name: string } | undefined | null, user_group_role?: { __typename?: 'user_group_roles', id: any, name: string } | undefined | null } | undefined | null };

export type CancelInviteByIdMutationVariables = Exact<{
  inviteId: Scalars['uuid'];
}>;


export type CancelInviteByIdMutation = { __typename?: 'mutation_root', update_invites_by_pk?: { __typename?: 'invites', id: any, status: EnumInviteStatusesEnum } | undefined | null };

export type CompleteInviteByIdMutationVariables = Exact<{
  inviteId: Scalars['uuid'];
}>;


export type CompleteInviteByIdMutation = { __typename?: 'mutation_root', update_invites_by_pk?: { __typename?: 'invites', id: any, status: EnumInviteStatusesEnum } | undefined | null };

export type SendInviteForGroupMutationVariables = Exact<{
  email: Scalars['String'];
  groupId: Scalars['String'];
  groupRoleId: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;


export type SendInviteForGroupMutation = { __typename?: 'mutation_root', send_invite: { __typename?: 'SendInviteOutput', invite_id: string, receiver_email: string } };

export type InsertRawInvitesMutationVariables = Exact<{
  invites: Array<RawInvitesInsertInput> | RawInvitesInsertInput;
}>;


export type InsertRawInvitesMutation = { __typename?: 'mutation_root', insert_raw_invites?: { __typename?: 'raw_invites_mutation_response', affected_rows: number } | undefined | null };

export type MediaSignedUrlFieldsFragment = { __typename?: 'SignedMediaUrlsOutput', filename: string, resource_key: string, mime_type: string, get_url: string, put_url: string };

export type GetMediaSignedUrlsMutationVariables = Exact<{
  files: Array<SignedMediaUrlsInput> | SignedMediaUrlsInput;
}>;


export type GetMediaSignedUrlsMutation = { __typename?: 'mutation_root', get_media_signed_urls: Array<{ __typename?: 'SignedMediaUrlsOutput', filename: string, resource_key: string, mime_type: string, get_url: string, put_url: string }> };

export type GetProcessesByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;


export type GetProcessesByOrganizationIdQuery = { __typename?: 'query_root', processes: Array<{ __typename?: 'processes', id: any, user_group_id: any, schema: any, uischema?: any | undefined | null, type: EnumProcessTypesEnum, external_url?: string | undefined | null }> };

export type ProfileFieldsFragment = { __typename?: 'profiles', auth0_id: string, user_id?: any | undefined | null, data?: any | undefined | null, email?: any | undefined | null, created_at: any, updated_at: any };

export type GetProfileByAuth0IdQueryVariables = Exact<{
  auth0_id: Scalars['String'];
}>;


export type GetProfileByAuth0IdQuery = { __typename?: 'query_root', profiles: Array<{ __typename?: 'profiles', auth0_id: string, user_id?: any | undefined | null, data?: any | undefined | null, email?: any | undefined | null, created_at: any, updated_at: any }> };

export type GetProfilesByUserIdQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  connection_strategy?: InputMaybe<StringComparisonExp>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetProfilesByUserIdQuery = { __typename?: 'query_root', profiles: Array<{ __typename?: 'profiles', auth0_id: string, user_id?: any | undefined | null, data?: any | undefined | null, email?: any | undefined | null, created_at: any, updated_at: any }> };

export type UsersExampleQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Array<Scalars['Boolean']> | Scalars['Boolean']>;
  whereActive?: InputMaybe<UsersBoolExp>;
  orderBy?: InputMaybe<Array<UsersOrderBy> | UsersOrderBy>;
}>;


export type UsersExampleQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, first_name: string, last_name: string, email?: any | undefined | null, active: boolean, start_date?: any | undefined | null, invites_aggregate: { __typename?: 'invites_aggregate', aggregate?: { __typename?: 'invites_aggregate_fields', count: number } | undefined | null } }>, users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | undefined | null } };

export type GetUserConsentsByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
  organizationId: Scalars['uuid'];
}>;


export type GetUserConsentsByUserIdQuery = { __typename?: 'query_root', user_consents: Array<{ __typename?: 'user_consents', consent: boolean, updated_at: any, consent_by_consent_id: { __typename?: 'consents', name: string } }> };

export type InsertUserGroupProcessValuesMutationVariables = Exact<{
  object: UserGroupProcessValuesInsertInput;
}>;


export type InsertUserGroupProcessValuesMutation = { __typename?: 'mutation_root', insert_user_group_process_values_one?: { __typename?: 'user_group_process_values', id: any } | undefined | null };

export type EditUserGroupProcessValuesMutationVariables = Exact<{
  id: Scalars['uuid'];
  processValues: Scalars['jsonb'];
}>;


export type EditUserGroupProcessValuesMutation = { __typename?: 'mutation_root', update_user_group_process_values?: { __typename?: 'user_group_process_values_mutation_response', affected_rows: number } | undefined | null };

export type UserGroupProcessValuesQueryVariables = Exact<{
  userId: Scalars['uuid'];
  processId: Scalars['uuid'];
}>;


export type UserGroupProcessValuesQuery = { __typename?: 'query_root', user_group_process_values: Array<{ __typename?: 'user_group_process_values', id: any, user_id: any, process_id: any, process_values: any }> };

export type UserGroupRolesByGroupIdQueryVariables = Exact<{
  groupId: Scalars['uuid'];
}>;


export type UserGroupRolesByGroupIdQuery = { __typename?: 'query_root', user_group_roles: Array<{ __typename?: 'user_group_roles', id: any, name: string }> };

export type UpdateUserRoleDatesByEmailMutationVariables = Exact<{
  emails: Array<Scalars['citext']> | Scalars['citext'];
  groupId: Scalars['uuid'];
  start_date?: InputMaybe<Scalars['date']>;
  end_date?: InputMaybe<Scalars['date']>;
}>;


export type UpdateUserRoleDatesByEmailMutation = { __typename?: 'mutation_root', update_user_group_user_roles?: { __typename?: 'user_group_user_roles_mutation_response', affected_rows: number } | undefined | null };

export type UpdateUserRoleActiveByEmailMutationVariables = Exact<{
  emails: Array<Scalars['citext']> | Scalars['citext'];
  groupId: Scalars['uuid'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateUserRoleActiveByEmailMutation = { __typename?: 'mutation_root', update_user_group_user_roles?: { __typename?: 'user_group_user_roles_mutation_response', affected_rows: number } | undefined | null };

export type UsersFromUserGroupUserRoleByGroupIdsQueryVariables = Exact<{
  groupIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  search?: InputMaybe<UserGroupUserRolesBoolExp>;
  whereActive?: InputMaybe<UserGroupUserRolesBoolExp>;
  whereRole?: InputMaybe<UserGroupUserRolesBoolExp>;
  orderBy?: InputMaybe<Array<UserGroupUserRolesOrderBy> | UserGroupUserRolesOrderBy>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type UsersFromUserGroupUserRoleByGroupIdsQuery = { __typename?: 'query_root', user_group_user_roles: Array<{ __typename?: 'user_group_user_roles', is_active: boolean, created_at: any, user_id: any, user_group_role_id: any, user: { __typename?: 'users', id: any, salutation?: string | undefined | null, first_name: string, last_name: string, email?: any | undefined | null, phone_number?: string | undefined | null, active: boolean, created_at: any, start_date?: any | undefined | null, end_date?: any | undefined | null, avatar_url?: any | undefined | null }, user_group_role: { __typename?: 'user_group_roles', id: any, name: string, user_group: { __typename?: 'user_groups', id: any, display_name: string } } }>, user_group_user_roles_aggregate: { __typename?: 'user_group_user_roles_aggregate', aggregate?: { __typename?: 'user_group_user_roles_aggregate_fields', count: number } | undefined | null } };

export type ActiveRolesCountQueryVariables = Exact<{
  userId: Scalars['uuid'];
  userGroupId: Scalars['uuid'];
}>;


export type ActiveRolesCountQuery = { __typename?: 'query_root', user_group_user_roles_aggregate: { __typename?: 'user_group_user_roles_aggregate', aggregate?: { __typename?: 'user_group_user_roles_aggregate_fields', count: number } | undefined | null } };

export type UserGroupFieldsFragment = { __typename?: 'user_groups', id: any, name: string, display_name: string, active: boolean, deactivated_at?: any | undefined | null, parent_id?: any | undefined | null, logo_url?: any | undefined | null, tier: EnumUserGroupsTierEnum, user_group_configuration?: any | undefined | null, process_values?: any | undefined | null, created_at: any, updated_at: any };

export type UserGroupsQueryVariables = Exact<{
  search?: InputMaybe<UserGroupsBoolExp>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserGroupsOrderBy> | UserGroupsOrderBy>;
}>;


export type UserGroupsQuery = { __typename?: 'query_root', user_groups: Array<{ __typename?: 'user_groups', id: any, name: string, display_name: string, active: boolean, deactivated_at?: any | undefined | null, parent_id?: any | undefined | null, logo_url?: any | undefined | null, tier: EnumUserGroupsTierEnum, user_group_configuration?: any | undefined | null, process_values?: any | undefined | null, created_at: any, updated_at: any }>, user_groups_aggregate: { __typename?: 'user_groups_aggregate', aggregate?: { __typename?: 'user_groups_aggregate_fields', count: number } | undefined | null } };

export type UserGroupByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserGroupByIdQuery = { __typename?: 'query_root', user_groups_by_pk?: { __typename?: 'user_groups', id: any, name: string, display_name: string, active: boolean, deactivated_at?: any | undefined | null, parent_id?: any | undefined | null, logo_url?: any | undefined | null, tier: EnumUserGroupsTierEnum, user_group_configuration?: any | undefined | null, process_values?: any | undefined | null, created_at: any, updated_at: any, user_group?: { __typename?: 'user_groups', id: any, name: string, display_name: string, active: boolean, deactivated_at?: any | undefined | null, parent_id?: any | undefined | null, logo_url?: any | undefined | null, tier: EnumUserGroupsTierEnum, user_group_configuration?: any | undefined | null, process_values?: any | undefined | null, created_at: any, updated_at: any } | undefined | null } | undefined | null };

export type UserGroupByIdBasicQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserGroupByIdBasicQuery = { __typename?: 'query_root', user_groups_by_pk?: { __typename?: 'user_groups', id: any, name: string, display_name: string } | undefined | null };

export type UserGroupConfigurationQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserGroupConfigurationQuery = { __typename?: 'query_root', user_groups_by_pk?: { __typename?: 'user_groups', user_group_configuration?: any | undefined | null } | undefined | null };

export type UserGroupSchemaQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserGroupSchemaQuery = { __typename?: 'query_root', user_groups_by_pk?: { __typename?: 'user_groups', schema?: any | undefined | null, uischema?: any | undefined | null } | undefined | null };

export type UserGroupCompanyFieldsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserGroupCompanyFieldsQuery = { __typename?: 'query_root', user_groups_by_pk?: { __typename?: 'user_groups', process_values?: any | undefined | null } | undefined | null };

export type EditGroupProcessValuesMutationVariables = Exact<{
  id: Scalars['uuid'];
  processValues: Scalars['jsonb'];
}>;


export type EditGroupProcessValuesMutation = { __typename?: 'mutation_root', update_user_groups?: { __typename?: 'user_groups_mutation_response', affected_rows: number } | undefined | null };

export type UpdateUserGroupByIdMutationVariables = Exact<{
  groupId: Scalars['uuid'];
  groupData?: InputMaybe<UserGroupsSetInput>;
}>;


export type UpdateUserGroupByIdMutation = { __typename?: 'mutation_root', update_user_groups_by_pk?: { __typename?: 'user_groups', id: any, name: string, display_name: string, active: boolean, deactivated_at?: any | undefined | null, parent_id?: any | undefined | null, logo_url?: any | undefined | null, tier: EnumUserGroupsTierEnum, user_group_configuration?: any | undefined | null, process_values?: any | undefined | null, created_at: any, updated_at: any } | undefined | null };

export type CreateUserGroupMutationVariables = Exact<{
  object: UserGroupsInsertInput;
}>;


export type CreateUserGroupMutation = { __typename?: 'mutation_root', insert_user_groups_one?: { __typename?: 'user_groups', id: any } | undefined | null };

export type CreateUserGroupMediaMutationVariables = Exact<{
  userGroupId: Scalars['uuid'];
  fileFormat: Scalars['String'];
  resourceKey: Scalars['String'];
  url: Scalars['citext'];
}>;


export type CreateUserGroupMediaMutation = { __typename?: 'mutation_root', insert_media_one?: { __typename?: 'media', id: any } | undefined | null };

export type DeleteUserGroupByIdMutationVariables = Exact<{
  userGroupId: Scalars['uuid'];
}>;


export type DeleteUserGroupByIdMutation = { __typename?: 'mutation_root', delete_user_group: { __typename?: 'DeleteUserGroupOutput', message: string } };

export type ActivateUserGroupByIdMutationVariables = Exact<{
  userGroupId: Scalars['uuid'];
}>;


export type ActivateUserGroupByIdMutation = { __typename?: 'mutation_root', update_user_groups_by_pk?: { __typename?: 'user_groups', id: any } | undefined | null };

export type DeactivateUserGroupByIdMutationVariables = Exact<{
  userGroupId: Scalars['uuid'];
}>;


export type DeactivateUserGroupByIdMutation = { __typename?: 'mutation_root', update_user_groups_by_pk?: { __typename?: 'user_groups', id: any } | undefined | null };

export type InsertUserProcessValuesMutationVariables = Exact<{
  object: UserProcessValuesInsertInput;
}>;


export type InsertUserProcessValuesMutation = { __typename?: 'mutation_root', insert_user_process_values_one?: { __typename?: 'user_process_values', id: any } | undefined | null };

export type EditUserProcessValuesMutationVariables = Exact<{
  id: Scalars['uuid'];
  processValues: Scalars['jsonb'];
}>;


export type EditUserProcessValuesMutation = { __typename?: 'mutation_root', update_user_process_values?: { __typename?: 'user_process_values_mutation_response', affected_rows: number } | undefined | null };

export type UserProcessValuesQueryVariables = Exact<{
  userId: Scalars['uuid'];
  processId: Scalars['uuid'];
}>;


export type UserProcessValuesQuery = { __typename?: 'query_root', user_process_values: Array<{ __typename?: 'user_process_values', id: any, user_id: any, process_id: any, process_values: any }> };

export type UserFieldsFragment = { __typename?: 'users', id: any, salutation?: string | undefined | null, first_name: string, last_name: string, email?: any | undefined | null, active: boolean, created_at: any, start_date?: any | undefined | null, end_date?: any | undefined | null, avatar_url?: any | undefined | null, phone_number?: string | undefined | null };

export type UsersForAutocompleteQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  queryFilter?: InputMaybe<UsersBoolExp>;
}>;


export type UsersForAutocompleteQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, first_name: string, last_name: string }> };

export type UserByIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
  whereGroupIdForRole?: InputMaybe<UserGroupUserRolesBoolExp>;
}>;


export type UserByIdQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', phone_number?: string | undefined | null, id: any, salutation?: string | undefined | null, first_name: string, last_name: string, email?: any | undefined | null, active: boolean, created_at: any, start_date?: any | undefined | null, end_date?: any | undefined | null, avatar_url?: any | undefined | null, user_group_user_roles: Array<{ __typename?: 'user_group_user_roles', is_active: boolean, start_date?: any | undefined | null, end_date?: any | undefined | null, user_group_role: { __typename?: 'user_group_roles', id: any, name: string, user_group: { __typename?: 'user_groups', id: any, name: string } } }>, sc_user_preference?: { __typename?: 'sc_user_preferences', asset_group_description?: string | undefined | null, asset_type?: ScEnumUserPreferenceAssetTypesEnum | undefined | null, max_area?: any | undefined | null, max_budget?: any | undefined | null, min_area?: any | undefined | null, min_budget?: any | undefined | null, more_details?: string | undefined | null } | undefined | null } | undefined | null };

export type UserByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;


export type UserByOrganizationIdQuery = { __typename?: 'query_root', flattened_user_group_users: Array<{ __typename?: 'flattened_user_group_users', user_id: any, user: { __typename?: 'users', id: any, first_name: string, last_name: string, email?: any | undefined | null } }> };

export type UserByIdBasicQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type UserByIdBasicQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', first_name: string, last_name: string, phone_number?: string | undefined | null, email?: any | undefined | null, avatar_url?: any | undefined | null } | undefined | null };

export type UserByIdForPermissionsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type UserByIdForPermissionsQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', id: any, email?: any | undefined | null, flattened_user_group_user_permissions: Array<{ __typename?: 'flattened_user_group_user_permissions', user_group_id: any, permission_id: string }> } | undefined | null };

export type UpsertUserFieldByIdMutationVariables = Exact<{
  userFieldData: UserProcessValuesInsertInput;
}>;


export type UpsertUserFieldByIdMutation = { __typename?: 'mutation_root', insert_user_process_values_one?: { __typename?: 'user_process_values', id: any, process_id: any, user_id: any, process_values: any } | undefined | null };

export type UpdateUserByIdMutationVariables = Exact<{
  userId: Scalars['uuid'];
  userData?: InputMaybe<UsersSetInput>;
}>;


export type UpdateUserByIdMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: any, salutation?: string | undefined | null, first_name: string, last_name: string, email?: any | undefined | null, active: boolean, created_at: any, start_date?: any | undefined | null, end_date?: any | undefined | null, avatar_url?: any | undefined | null, phone_number?: string | undefined | null } | undefined | null };

export type CreateUserMediaMutationVariables = Exact<{
  userId: Scalars['uuid'];
  fileFormat: Scalars['String'];
  resourceKey: Scalars['String'];
  url: Scalars['citext'];
}>;


export type CreateUserMediaMutation = { __typename?: 'mutation_root', insert_media_one?: { __typename?: 'media', id: any } | undefined | null };

export const AssetGroupFieldsFragmentDoc = gql`
    fragment AssetGroupFields on asset_groups {
  id
  optional_name
  display_name
  thumbnail_image_url
  floorplan_image_url
  description
  note
  created_at
  updated_at
  asset_class {
    id
    name
    description
  }
  asset_group {
    id
    display_name
  }
  asset_group_media {
    media {
      url
    }
  }
}
    `;
export const AssetGroupOperatingHoursFieldsFragmentDoc = gql`
    fragment AssetGroupOperatingHoursFields on asset_groups {
  asset_group_operating_hours {
    id
    day_of_week
    hour_start
    hour_end
  }
}
    `;
export const AssetFieldsFragmentDoc = gql`
    fragment AssetFields on assets {
  id
  optional_name
  display_name
  description
  thumbnail_image_url
  note
  active
  created_at
  updated_at
  maximum_capacity
}
    `;
export const MediaSignedUrlFieldsFragmentDoc = gql`
    fragment MediaSignedUrlFields on SignedMediaUrlsOutput {
  filename
  resource_key
  mime_type
  get_url
  put_url
}
    `;
export const ProfileFieldsFragmentDoc = gql`
    fragment profileFields on profiles {
  auth0_id
  user_id
  data
  email
  created_at
  updated_at
}
    `;
export const UserGroupFieldsFragmentDoc = gql`
    fragment UserGroupFields on user_groups {
  id
  name
  display_name
  active
  deactivated_at
  parent_id
  logo_url
  tier
  user_group_configuration
  process_values
  created_at
  updated_at
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on users {
  id
  salutation
  first_name
  last_name
  email
  active
  created_at
  start_date
  end_date
  avatar_url
  phone_number
}
    `;
export const CancelAssetBookingDocument = gql`
    mutation CancelAssetBooking($id: String!) {
  cancel_asset_booking(asset_booking_id: $id) {
    asset_booking {
      id
    }
  }
}
    `;
export type CancelAssetBookingMutationFn = Apollo.MutationFunction<CancelAssetBookingMutation, CancelAssetBookingMutationVariables>;

/**
 * __useCancelAssetBookingMutation__
 *
 * To run a mutation, you first call `useCancelAssetBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAssetBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAssetBookingMutation, { data, loading, error }] = useCancelAssetBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelAssetBookingMutation(baseOptions?: Apollo.MutationHookOptions<CancelAssetBookingMutation, CancelAssetBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAssetBookingMutation, CancelAssetBookingMutationVariables>(CancelAssetBookingDocument, options);
      }
export type CancelAssetBookingMutationHookResult = ReturnType<typeof useCancelAssetBookingMutation>;
export type CancelAssetBookingMutationResult = Apollo.MutationResult<CancelAssetBookingMutation>;
export type CancelAssetBookingMutationOptions = Apollo.BaseMutationOptions<CancelAssetBookingMutation, CancelAssetBookingMutationVariables>;
export const ApproveAssetBookingDocument = gql`
    mutation ApproveAssetBooking($id: String!) {
  approve_asset_booking(asset_booking_id: $id) {
    asset_booking {
      id
    }
  }
}
    `;
export type ApproveAssetBookingMutationFn = Apollo.MutationFunction<ApproveAssetBookingMutation, ApproveAssetBookingMutationVariables>;

/**
 * __useApproveAssetBookingMutation__
 *
 * To run a mutation, you first call `useApproveAssetBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAssetBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAssetBookingMutation, { data, loading, error }] = useApproveAssetBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveAssetBookingMutation(baseOptions?: Apollo.MutationHookOptions<ApproveAssetBookingMutation, ApproveAssetBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveAssetBookingMutation, ApproveAssetBookingMutationVariables>(ApproveAssetBookingDocument, options);
      }
export type ApproveAssetBookingMutationHookResult = ReturnType<typeof useApproveAssetBookingMutation>;
export type ApproveAssetBookingMutationResult = Apollo.MutationResult<ApproveAssetBookingMutation>;
export type ApproveAssetBookingMutationOptions = Apollo.BaseMutationOptions<ApproveAssetBookingMutation, ApproveAssetBookingMutationVariables>;
export const RejectAssetBookingDocument = gql`
    mutation RejectAssetBooking($id: String!) {
  reject_asset_booking(asset_booking_id: $id) {
    asset_booking {
      id
    }
  }
}
    `;
export type RejectAssetBookingMutationFn = Apollo.MutationFunction<RejectAssetBookingMutation, RejectAssetBookingMutationVariables>;

/**
 * __useRejectAssetBookingMutation__
 *
 * To run a mutation, you first call `useRejectAssetBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAssetBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAssetBookingMutation, { data, loading, error }] = useRejectAssetBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectAssetBookingMutation(baseOptions?: Apollo.MutationHookOptions<RejectAssetBookingMutation, RejectAssetBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectAssetBookingMutation, RejectAssetBookingMutationVariables>(RejectAssetBookingDocument, options);
      }
export type RejectAssetBookingMutationHookResult = ReturnType<typeof useRejectAssetBookingMutation>;
export type RejectAssetBookingMutationResult = Apollo.MutationResult<RejectAssetBookingMutation>;
export type RejectAssetBookingMutationOptions = Apollo.BaseMutationOptions<RejectAssetBookingMutation, RejectAssetBookingMutationVariables>;
export const UpdateUserRolesInGroupsDocument = gql`
    mutation UpdateUserRolesInGroups($initialRoles: [uuid!]!, $updatedRoles: [uuid!]!, $userId: uuid!, $userGroupId: uuid!) {
  update_user_roles_in_groups(
    update_user_roles_in_groups_input: {initial_roles: $initialRoles, updated_roles: $updatedRoles, user_id: $userId, user_group_id: $userGroupId}
  ) {
    message
  }
}
    `;
export type UpdateUserRolesInGroupsMutationFn = Apollo.MutationFunction<UpdateUserRolesInGroupsMutation, UpdateUserRolesInGroupsMutationVariables>;

/**
 * __useUpdateUserRolesInGroupsMutation__
 *
 * To run a mutation, you first call `useUpdateUserRolesInGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRolesInGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRolesInGroupsMutation, { data, loading, error }] = useUpdateUserRolesInGroupsMutation({
 *   variables: {
 *      initialRoles: // value for 'initialRoles'
 *      updatedRoles: // value for 'updatedRoles'
 *      userId: // value for 'userId'
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useUpdateUserRolesInGroupsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRolesInGroupsMutation, UpdateUserRolesInGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRolesInGroupsMutation, UpdateUserRolesInGroupsMutationVariables>(UpdateUserRolesInGroupsDocument, options);
      }
export type UpdateUserRolesInGroupsMutationHookResult = ReturnType<typeof useUpdateUserRolesInGroupsMutation>;
export type UpdateUserRolesInGroupsMutationResult = Apollo.MutationResult<UpdateUserRolesInGroupsMutation>;
export type UpdateUserRolesInGroupsMutationOptions = Apollo.BaseMutationOptions<UpdateUserRolesInGroupsMutation, UpdateUserRolesInGroupsMutationVariables>;
export const DownloadCsvTemplateDocument = gql`
    mutation DownloadCsvTemplate($type: String!, $userGroupId: uuid!) {
  download_csv_template(object: {type: $type, user_group_id: $userGroupId}) {
    csv_data
  }
}
    `;
export type DownloadCsvTemplateMutationFn = Apollo.MutationFunction<DownloadCsvTemplateMutation, DownloadCsvTemplateMutationVariables>;

/**
 * __useDownloadCsvTemplateMutation__
 *
 * To run a mutation, you first call `useDownloadCsvTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadCsvTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadCsvTemplateMutation, { data, loading, error }] = useDownloadCsvTemplateMutation({
 *   variables: {
 *      type: // value for 'type'
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useDownloadCsvTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DownloadCsvTemplateMutation, DownloadCsvTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadCsvTemplateMutation, DownloadCsvTemplateMutationVariables>(DownloadCsvTemplateDocument, options);
      }
export type DownloadCsvTemplateMutationHookResult = ReturnType<typeof useDownloadCsvTemplateMutation>;
export type DownloadCsvTemplateMutationResult = Apollo.MutationResult<DownloadCsvTemplateMutation>;
export type DownloadCsvTemplateMutationOptions = Apollo.BaseMutationOptions<DownloadCsvTemplateMutation, DownloadCsvTemplateMutationVariables>;
export const MassEditUserProcessesDocument = gql`
    mutation MassEditUserProcesses($csvJsonArray: [json!]!, $userGroupId: uuid!, $type: String!) {
  mass_edit_process_data(
    object: {csv_json_array: $csvJsonArray, type: $type, user_group_id: $userGroupId}
  ) {
    message
  }
}
    `;
export type MassEditUserProcessesMutationFn = Apollo.MutationFunction<MassEditUserProcessesMutation, MassEditUserProcessesMutationVariables>;

/**
 * __useMassEditUserProcessesMutation__
 *
 * To run a mutation, you first call `useMassEditUserProcessesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassEditUserProcessesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massEditUserProcessesMutation, { data, loading, error }] = useMassEditUserProcessesMutation({
 *   variables: {
 *      csvJsonArray: // value for 'csvJsonArray'
 *      userGroupId: // value for 'userGroupId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMassEditUserProcessesMutation(baseOptions?: Apollo.MutationHookOptions<MassEditUserProcessesMutation, MassEditUserProcessesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassEditUserProcessesMutation, MassEditUserProcessesMutationVariables>(MassEditUserProcessesDocument, options);
      }
export type MassEditUserProcessesMutationHookResult = ReturnType<typeof useMassEditUserProcessesMutation>;
export type MassEditUserProcessesMutationResult = Apollo.MutationResult<MassEditUserProcessesMutation>;
export type MassEditUserProcessesMutationOptions = Apollo.BaseMutationOptions<MassEditUserProcessesMutation, MassEditUserProcessesMutationVariables>;
export const DeactivateUserFromGroupDocument = gql`
    mutation DeactivateUserFromGroup($userId: uuid!, $userGroupId: uuid!) {
  deactivate_user_from_group(
    object: {user_group_id: $userGroupId, user_id: $userId}
  ) {
    message
  }
}
    `;
export type DeactivateUserFromGroupMutationFn = Apollo.MutationFunction<DeactivateUserFromGroupMutation, DeactivateUserFromGroupMutationVariables>;

/**
 * __useDeactivateUserFromGroupMutation__
 *
 * To run a mutation, you first call `useDeactivateUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserFromGroupMutation, { data, loading, error }] = useDeactivateUserFromGroupMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useDeactivateUserFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateUserFromGroupMutation, DeactivateUserFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateUserFromGroupMutation, DeactivateUserFromGroupMutationVariables>(DeactivateUserFromGroupDocument, options);
      }
export type DeactivateUserFromGroupMutationHookResult = ReturnType<typeof useDeactivateUserFromGroupMutation>;
export type DeactivateUserFromGroupMutationResult = Apollo.MutationResult<DeactivateUserFromGroupMutation>;
export type DeactivateUserFromGroupMutationOptions = Apollo.BaseMutationOptions<DeactivateUserFromGroupMutation, DeactivateUserFromGroupMutationVariables>;
export const ActivateUserFromGroupDocument = gql`
    mutation ActivateUserFromGroup($userId: uuid!, $userGroupId: uuid!) {
  activate_user_from_group(
    object: {user_group_id: $userGroupId, user_id: $userId}
  ) {
    message
  }
}
    `;
export type ActivateUserFromGroupMutationFn = Apollo.MutationFunction<ActivateUserFromGroupMutation, ActivateUserFromGroupMutationVariables>;

/**
 * __useActivateUserFromGroupMutation__
 *
 * To run a mutation, you first call `useActivateUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserFromGroupMutation, { data, loading, error }] = useActivateUserFromGroupMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useActivateUserFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUserFromGroupMutation, ActivateUserFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateUserFromGroupMutation, ActivateUserFromGroupMutationVariables>(ActivateUserFromGroupDocument, options);
      }
export type ActivateUserFromGroupMutationHookResult = ReturnType<typeof useActivateUserFromGroupMutation>;
export type ActivateUserFromGroupMutationResult = Apollo.MutationResult<ActivateUserFromGroupMutation>;
export type ActivateUserFromGroupMutationOptions = Apollo.BaseMutationOptions<ActivateUserFromGroupMutation, ActivateUserFromGroupMutationVariables>;
export const RemoveUserFromGroupDocument = gql`
    mutation RemoveUserFromGroup($groupId: uuid!, $userId: uuid!) {
  remove_user_from_group(user_id: $userId, group_id: $groupId) {
    user_id
  }
}
    `;
export type RemoveUserFromGroupMutationFn = Apollo.MutationFunction<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;

/**
 * __useRemoveUserFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromGroupMutation, { data, loading, error }] = useRemoveUserFromGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>(RemoveUserFromGroupDocument, options);
      }
export type RemoveUserFromGroupMutationHookResult = ReturnType<typeof useRemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationResult = Apollo.MutationResult<RemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;
export const AssetClassesForAutocompleteDocument = gql`
    query AssetClassesForAutocomplete($queryFilter: asset_classes_bool_exp = {}, $search: String = "%%") {
  asset_classes(
    where: {_and: [{name: {_ilike: $search}}, $queryFilter]}
    distinct_on: name
  ) {
    id
    name
  }
}
    `;

/**
 * __useAssetClassesForAutocompleteQuery__
 *
 * To run a query within a React component, call `useAssetClassesForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetClassesForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetClassesForAutocompleteQuery({
 *   variables: {
 *      queryFilter: // value for 'queryFilter'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAssetClassesForAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<AssetClassesForAutocompleteQuery, AssetClassesForAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetClassesForAutocompleteQuery, AssetClassesForAutocompleteQueryVariables>(AssetClassesForAutocompleteDocument, options);
      }
export function useAssetClassesForAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetClassesForAutocompleteQuery, AssetClassesForAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetClassesForAutocompleteQuery, AssetClassesForAutocompleteQueryVariables>(AssetClassesForAutocompleteDocument, options);
        }
export type AssetClassesForAutocompleteQueryHookResult = ReturnType<typeof useAssetClassesForAutocompleteQuery>;
export type AssetClassesForAutocompleteLazyQueryHookResult = ReturnType<typeof useAssetClassesForAutocompleteLazyQuery>;
export type AssetClassesForAutocompleteQueryResult = Apollo.QueryResult<AssetClassesForAutocompleteQuery, AssetClassesForAutocompleteQueryVariables>;
export const AssetGroupsForAutocompleteDocument = gql`
    query AssetGroupsForAutocomplete($search: String = "%%", $queryFilter: asset_groups_bool_exp = {}, $orderBy: [asset_groups_order_by!] = {display_name: asc}) {
  asset_groups(
    offset: 0
    limit: 20
    order_by: $orderBy
    distinct_on: display_name
    where: {_and: [{display_name: {_ilike: $search}}, $queryFilter]}
  ) {
    id
    display_name
  }
}
    `;

/**
 * __useAssetGroupsForAutocompleteQuery__
 *
 * To run a query within a React component, call `useAssetGroupsForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetGroupsForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetGroupsForAutocompleteQuery({
 *   variables: {
 *      search: // value for 'search'
 *      queryFilter: // value for 'queryFilter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAssetGroupsForAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<AssetGroupsForAutocompleteQuery, AssetGroupsForAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetGroupsForAutocompleteQuery, AssetGroupsForAutocompleteQueryVariables>(AssetGroupsForAutocompleteDocument, options);
      }
export function useAssetGroupsForAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetGroupsForAutocompleteQuery, AssetGroupsForAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetGroupsForAutocompleteQuery, AssetGroupsForAutocompleteQueryVariables>(AssetGroupsForAutocompleteDocument, options);
        }
export type AssetGroupsForAutocompleteQueryHookResult = ReturnType<typeof useAssetGroupsForAutocompleteQuery>;
export type AssetGroupsForAutocompleteLazyQueryHookResult = ReturnType<typeof useAssetGroupsForAutocompleteLazyQuery>;
export type AssetGroupsForAutocompleteQueryResult = Apollo.QueryResult<AssetGroupsForAutocompleteQuery, AssetGroupsForAutocompleteQueryVariables>;
export const AssetGroupsByUserGroupIdsDocument = gql`
    query AssetGroupsByUserGroupIds($assetGroupId: uuid, $userGroupIds: [uuid!], $search: asset_groups_bool_exp = {}, $whereAssetClass: asset_groups_bool_exp = {}, $orderBy: [asset_groups_order_by!] = {display_name: asc}, $offset: Int = 0, $limit: Int = 20) {
  asset_groups(
    order_by: $orderBy
    offset: $offset
    limit: $limit
    where: {_and: [{id: {_eq: $assetGroupId}}, {asset_class: {user_group_id: {_in: $userGroupIds}}}, $search, $whereAssetClass]}
  ) {
    ...AssetGroupFields
    flattened_asset_groups_by_child_id {
      asset_group_by_parent_id {
        id
        display_name
      }
    }
    asset_class {
      user_group {
        id
        display_name
      }
    }
  }
  asset_groups_aggregate(
    where: {_and: [{id: {_eq: $assetGroupId}}, {asset_class: {user_group_id: {_in: $userGroupIds}}}, $search, $whereAssetClass]}
  ) {
    aggregate {
      count
    }
  }
}
    ${AssetGroupFieldsFragmentDoc}`;

/**
 * __useAssetGroupsByUserGroupIdsQuery__
 *
 * To run a query within a React component, call `useAssetGroupsByUserGroupIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetGroupsByUserGroupIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetGroupsByUserGroupIdsQuery({
 *   variables: {
 *      assetGroupId: // value for 'assetGroupId'
 *      userGroupIds: // value for 'userGroupIds'
 *      search: // value for 'search'
 *      whereAssetClass: // value for 'whereAssetClass'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAssetGroupsByUserGroupIdsQuery(baseOptions?: Apollo.QueryHookOptions<AssetGroupsByUserGroupIdsQuery, AssetGroupsByUserGroupIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetGroupsByUserGroupIdsQuery, AssetGroupsByUserGroupIdsQueryVariables>(AssetGroupsByUserGroupIdsDocument, options);
      }
export function useAssetGroupsByUserGroupIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetGroupsByUserGroupIdsQuery, AssetGroupsByUserGroupIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetGroupsByUserGroupIdsQuery, AssetGroupsByUserGroupIdsQueryVariables>(AssetGroupsByUserGroupIdsDocument, options);
        }
export type AssetGroupsByUserGroupIdsQueryHookResult = ReturnType<typeof useAssetGroupsByUserGroupIdsQuery>;
export type AssetGroupsByUserGroupIdsLazyQueryHookResult = ReturnType<typeof useAssetGroupsByUserGroupIdsLazyQuery>;
export type AssetGroupsByUserGroupIdsQueryResult = Apollo.QueryResult<AssetGroupsByUserGroupIdsQuery, AssetGroupsByUserGroupIdsQueryVariables>;
export const AssetGroupByIdDocument = gql`
    query AssetGroupById($assetGroupId: uuid!) {
  asset_groups_by_pk(id: $assetGroupId) {
    ...AssetGroupFields
    ...AssetGroupOperatingHoursFields
  }
}
    ${AssetGroupFieldsFragmentDoc}
${AssetGroupOperatingHoursFieldsFragmentDoc}`;

/**
 * __useAssetGroupByIdQuery__
 *
 * To run a query within a React component, call `useAssetGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetGroupByIdQuery({
 *   variables: {
 *      assetGroupId: // value for 'assetGroupId'
 *   },
 * });
 */
export function useAssetGroupByIdQuery(baseOptions: Apollo.QueryHookOptions<AssetGroupByIdQuery, AssetGroupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetGroupByIdQuery, AssetGroupByIdQueryVariables>(AssetGroupByIdDocument, options);
      }
export function useAssetGroupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetGroupByIdQuery, AssetGroupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetGroupByIdQuery, AssetGroupByIdQueryVariables>(AssetGroupByIdDocument, options);
        }
export type AssetGroupByIdQueryHookResult = ReturnType<typeof useAssetGroupByIdQuery>;
export type AssetGroupByIdLazyQueryHookResult = ReturnType<typeof useAssetGroupByIdLazyQuery>;
export type AssetGroupByIdQueryResult = Apollo.QueryResult<AssetGroupByIdQuery, AssetGroupByIdQueryVariables>;
export const AssetGroupOperatingHoursDocument = gql`
    query AssetGroupOperatingHours($assetGroupId: uuid!) {
  asset_groups_by_pk(id: $assetGroupId) {
    ...AssetGroupOperatingHoursFields
  }
}
    ${AssetGroupOperatingHoursFieldsFragmentDoc}`;

/**
 * __useAssetGroupOperatingHoursQuery__
 *
 * To run a query within a React component, call `useAssetGroupOperatingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetGroupOperatingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetGroupOperatingHoursQuery({
 *   variables: {
 *      assetGroupId: // value for 'assetGroupId'
 *   },
 * });
 */
export function useAssetGroupOperatingHoursQuery(baseOptions: Apollo.QueryHookOptions<AssetGroupOperatingHoursQuery, AssetGroupOperatingHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetGroupOperatingHoursQuery, AssetGroupOperatingHoursQueryVariables>(AssetGroupOperatingHoursDocument, options);
      }
export function useAssetGroupOperatingHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetGroupOperatingHoursQuery, AssetGroupOperatingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetGroupOperatingHoursQuery, AssetGroupOperatingHoursQueryVariables>(AssetGroupOperatingHoursDocument, options);
        }
export type AssetGroupOperatingHoursQueryHookResult = ReturnType<typeof useAssetGroupOperatingHoursQuery>;
export type AssetGroupOperatingHoursLazyQueryHookResult = ReturnType<typeof useAssetGroupOperatingHoursLazyQuery>;
export type AssetGroupOperatingHoursQueryResult = Apollo.QueryResult<AssetGroupOperatingHoursQuery, AssetGroupOperatingHoursQueryVariables>;
export const UpdateAssetGroupDocument = gql`
    mutation UpdateAssetGroup($assetGroupId: uuid!, $assetGroupData: asset_groups_set_input = {}) {
  update_asset_groups_by_pk(
    pk_columns: {id: $assetGroupId}
    _set: $assetGroupData
  ) {
    id
  }
}
    `;
export type UpdateAssetGroupMutationFn = Apollo.MutationFunction<UpdateAssetGroupMutation, UpdateAssetGroupMutationVariables>;

/**
 * __useUpdateAssetGroupMutation__
 *
 * To run a mutation, you first call `useUpdateAssetGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetGroupMutation, { data, loading, error }] = useUpdateAssetGroupMutation({
 *   variables: {
 *      assetGroupId: // value for 'assetGroupId'
 *      assetGroupData: // value for 'assetGroupData'
 *   },
 * });
 */
export function useUpdateAssetGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetGroupMutation, UpdateAssetGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetGroupMutation, UpdateAssetGroupMutationVariables>(UpdateAssetGroupDocument, options);
      }
export type UpdateAssetGroupMutationHookResult = ReturnType<typeof useUpdateAssetGroupMutation>;
export type UpdateAssetGroupMutationResult = Apollo.MutationResult<UpdateAssetGroupMutation>;
export type UpdateAssetGroupMutationOptions = Apollo.BaseMutationOptions<UpdateAssetGroupMutation, UpdateAssetGroupMutationVariables>;
export const CreateAssetGroupMediaDocument = gql`
    mutation CreateAssetGroupMedia($assetGroupId: uuid!, $fileFormat: String!, $resourceKey: String!, $url: citext!) {
  insert_media_one(
    object: {asset_group_media: {data: {asset_group_id: $assetGroupId}}, url: $url, resource_key: $resourceKey, file_format: $fileFormat}
  ) {
    id
  }
}
    `;
export type CreateAssetGroupMediaMutationFn = Apollo.MutationFunction<CreateAssetGroupMediaMutation, CreateAssetGroupMediaMutationVariables>;

/**
 * __useCreateAssetGroupMediaMutation__
 *
 * To run a mutation, you first call `useCreateAssetGroupMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetGroupMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetGroupMediaMutation, { data, loading, error }] = useCreateAssetGroupMediaMutation({
 *   variables: {
 *      assetGroupId: // value for 'assetGroupId'
 *      fileFormat: // value for 'fileFormat'
 *      resourceKey: // value for 'resourceKey'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreateAssetGroupMediaMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetGroupMediaMutation, CreateAssetGroupMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetGroupMediaMutation, CreateAssetGroupMediaMutationVariables>(CreateAssetGroupMediaDocument, options);
      }
export type CreateAssetGroupMediaMutationHookResult = ReturnType<typeof useCreateAssetGroupMediaMutation>;
export type CreateAssetGroupMediaMutationResult = Apollo.MutationResult<CreateAssetGroupMediaMutation>;
export type CreateAssetGroupMediaMutationOptions = Apollo.BaseMutationOptions<CreateAssetGroupMediaMutation, CreateAssetGroupMediaMutationVariables>;
export const CreateAssetGroupOperatingHoursDocument = gql`
    mutation CreateAssetGroupOperatingHours($assetGroupId: uuid!, $dayOfWeek: String!, $hourStart: timetz!, $hourEnd: timetz!) {
  insert_asset_group_operating_hours(
    objects: {asset_group_id: $assetGroupId, hour_start: $hourStart, hour_end: $hourEnd, day_of_week: $dayOfWeek}
  ) {
    affected_rows
  }
}
    `;
export type CreateAssetGroupOperatingHoursMutationFn = Apollo.MutationFunction<CreateAssetGroupOperatingHoursMutation, CreateAssetGroupOperatingHoursMutationVariables>;

/**
 * __useCreateAssetGroupOperatingHoursMutation__
 *
 * To run a mutation, you first call `useCreateAssetGroupOperatingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetGroupOperatingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetGroupOperatingHoursMutation, { data, loading, error }] = useCreateAssetGroupOperatingHoursMutation({
 *   variables: {
 *      assetGroupId: // value for 'assetGroupId'
 *      dayOfWeek: // value for 'dayOfWeek'
 *      hourStart: // value for 'hourStart'
 *      hourEnd: // value for 'hourEnd'
 *   },
 * });
 */
export function useCreateAssetGroupOperatingHoursMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetGroupOperatingHoursMutation, CreateAssetGroupOperatingHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetGroupOperatingHoursMutation, CreateAssetGroupOperatingHoursMutationVariables>(CreateAssetGroupOperatingHoursDocument, options);
      }
export type CreateAssetGroupOperatingHoursMutationHookResult = ReturnType<typeof useCreateAssetGroupOperatingHoursMutation>;
export type CreateAssetGroupOperatingHoursMutationResult = Apollo.MutationResult<CreateAssetGroupOperatingHoursMutation>;
export type CreateAssetGroupOperatingHoursMutationOptions = Apollo.BaseMutationOptions<CreateAssetGroupOperatingHoursMutation, CreateAssetGroupOperatingHoursMutationVariables>;
export const CreateMultipleAssetGroupOperatingHoursDocument = gql`
    mutation CreateMultipleAssetGroupOperatingHours($input: [asset_group_operating_hours_insert_input!]!) {
  insert_asset_group_operating_hours(objects: $input) {
    affected_rows
  }
}
    `;
export type CreateMultipleAssetGroupOperatingHoursMutationFn = Apollo.MutationFunction<CreateMultipleAssetGroupOperatingHoursMutation, CreateMultipleAssetGroupOperatingHoursMutationVariables>;

/**
 * __useCreateMultipleAssetGroupOperatingHoursMutation__
 *
 * To run a mutation, you first call `useCreateMultipleAssetGroupOperatingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipleAssetGroupOperatingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultipleAssetGroupOperatingHoursMutation, { data, loading, error }] = useCreateMultipleAssetGroupOperatingHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMultipleAssetGroupOperatingHoursMutation(baseOptions?: Apollo.MutationHookOptions<CreateMultipleAssetGroupOperatingHoursMutation, CreateMultipleAssetGroupOperatingHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMultipleAssetGroupOperatingHoursMutation, CreateMultipleAssetGroupOperatingHoursMutationVariables>(CreateMultipleAssetGroupOperatingHoursDocument, options);
      }
export type CreateMultipleAssetGroupOperatingHoursMutationHookResult = ReturnType<typeof useCreateMultipleAssetGroupOperatingHoursMutation>;
export type CreateMultipleAssetGroupOperatingHoursMutationResult = Apollo.MutationResult<CreateMultipleAssetGroupOperatingHoursMutation>;
export type CreateMultipleAssetGroupOperatingHoursMutationOptions = Apollo.BaseMutationOptions<CreateMultipleAssetGroupOperatingHoursMutation, CreateMultipleAssetGroupOperatingHoursMutationVariables>;
export const UpdateAssetGroupOperatingHoursDocument = gql`
    mutation UpdateAssetGroupOperatingHours($assetGroupId: uuid!, $dayOfWeek: String!, $hourStart: timetz!, $hourEnd: timetz!) {
  update_asset_group_operating_hours(
    where: {_and: [{asset_group_id: {_eq: $assetGroupId}}, {day_of_week: {_eq: $dayOfWeek}}]}
    _set: {hour_start: $hourStart, hour_end: $hourEnd}
  ) {
    affected_rows
  }
}
    `;
export type UpdateAssetGroupOperatingHoursMutationFn = Apollo.MutationFunction<UpdateAssetGroupOperatingHoursMutation, UpdateAssetGroupOperatingHoursMutationVariables>;

/**
 * __useUpdateAssetGroupOperatingHoursMutation__
 *
 * To run a mutation, you first call `useUpdateAssetGroupOperatingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetGroupOperatingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetGroupOperatingHoursMutation, { data, loading, error }] = useUpdateAssetGroupOperatingHoursMutation({
 *   variables: {
 *      assetGroupId: // value for 'assetGroupId'
 *      dayOfWeek: // value for 'dayOfWeek'
 *      hourStart: // value for 'hourStart'
 *      hourEnd: // value for 'hourEnd'
 *   },
 * });
 */
export function useUpdateAssetGroupOperatingHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetGroupOperatingHoursMutation, UpdateAssetGroupOperatingHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetGroupOperatingHoursMutation, UpdateAssetGroupOperatingHoursMutationVariables>(UpdateAssetGroupOperatingHoursDocument, options);
      }
export type UpdateAssetGroupOperatingHoursMutationHookResult = ReturnType<typeof useUpdateAssetGroupOperatingHoursMutation>;
export type UpdateAssetGroupOperatingHoursMutationResult = Apollo.MutationResult<UpdateAssetGroupOperatingHoursMutation>;
export type UpdateAssetGroupOperatingHoursMutationOptions = Apollo.BaseMutationOptions<UpdateAssetGroupOperatingHoursMutation, UpdateAssetGroupOperatingHoursMutationVariables>;
export const DeleteAssetGroupOperatingHoursDocument = gql`
    mutation DeleteAssetGroupOperatingHours($assetGroupId: uuid!, $dayOfWeek: String!) {
  delete_asset_group_operating_hours(
    where: {_and: [{asset_group_id: {_eq: $assetGroupId}}, {day_of_week: {_eq: $dayOfWeek}}]}
  ) {
    affected_rows
  }
}
    `;
export type DeleteAssetGroupOperatingHoursMutationFn = Apollo.MutationFunction<DeleteAssetGroupOperatingHoursMutation, DeleteAssetGroupOperatingHoursMutationVariables>;

/**
 * __useDeleteAssetGroupOperatingHoursMutation__
 *
 * To run a mutation, you first call `useDeleteAssetGroupOperatingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetGroupOperatingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetGroupOperatingHoursMutation, { data, loading, error }] = useDeleteAssetGroupOperatingHoursMutation({
 *   variables: {
 *      assetGroupId: // value for 'assetGroupId'
 *      dayOfWeek: // value for 'dayOfWeek'
 *   },
 * });
 */
export function useDeleteAssetGroupOperatingHoursMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssetGroupOperatingHoursMutation, DeleteAssetGroupOperatingHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssetGroupOperatingHoursMutation, DeleteAssetGroupOperatingHoursMutationVariables>(DeleteAssetGroupOperatingHoursDocument, options);
      }
export type DeleteAssetGroupOperatingHoursMutationHookResult = ReturnType<typeof useDeleteAssetGroupOperatingHoursMutation>;
export type DeleteAssetGroupOperatingHoursMutationResult = Apollo.MutationResult<DeleteAssetGroupOperatingHoursMutation>;
export type DeleteAssetGroupOperatingHoursMutationOptions = Apollo.BaseMutationOptions<DeleteAssetGroupOperatingHoursMutation, DeleteAssetGroupOperatingHoursMutationVariables>;
export const DeleteAssetGroupByIdDocument = gql`
    mutation DeleteAssetGroupById($assetGroupId: uuid!) {
  delete_asset_groups_by_pk(id: $assetGroupId) {
    id
  }
}
    `;
export type DeleteAssetGroupByIdMutationFn = Apollo.MutationFunction<DeleteAssetGroupByIdMutation, DeleteAssetGroupByIdMutationVariables>;

/**
 * __useDeleteAssetGroupByIdMutation__
 *
 * To run a mutation, you first call `useDeleteAssetGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetGroupByIdMutation, { data, loading, error }] = useDeleteAssetGroupByIdMutation({
 *   variables: {
 *      assetGroupId: // value for 'assetGroupId'
 *   },
 * });
 */
export function useDeleteAssetGroupByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssetGroupByIdMutation, DeleteAssetGroupByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssetGroupByIdMutation, DeleteAssetGroupByIdMutationVariables>(DeleteAssetGroupByIdDocument, options);
      }
export type DeleteAssetGroupByIdMutationHookResult = ReturnType<typeof useDeleteAssetGroupByIdMutation>;
export type DeleteAssetGroupByIdMutationResult = Apollo.MutationResult<DeleteAssetGroupByIdMutation>;
export type DeleteAssetGroupByIdMutationOptions = Apollo.BaseMutationOptions<DeleteAssetGroupByIdMutation, DeleteAssetGroupByIdMutationVariables>;
export const CreateAssetGroupDocument = gql`
    mutation CreateAssetGroup($thumbnail_image_url: String, $floorplan_image_url: String, $asset_class_id: String!, $parent_id: String, $display_name: String!, $description: String, $asset_group_operating_hours: [AssetGroupOperatingHoursInsertCustomInput!]!) {
  create_asset_group(
    asset_class_id: $asset_class_id
    parent_id: $parent_id
    display_name: $display_name
    description: $description
    thumbnail_image_url: $thumbnail_image_url
    floorplan_image_url: $floorplan_image_url
    asset_group_operating_hours: $asset_group_operating_hours
  ) {
    id
  }
}
    `;
export type CreateAssetGroupMutationFn = Apollo.MutationFunction<CreateAssetGroupMutation, CreateAssetGroupMutationVariables>;

/**
 * __useCreateAssetGroupMutation__
 *
 * To run a mutation, you first call `useCreateAssetGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetGroupMutation, { data, loading, error }] = useCreateAssetGroupMutation({
 *   variables: {
 *      thumbnail_image_url: // value for 'thumbnail_image_url'
 *      floorplan_image_url: // value for 'floorplan_image_url'
 *      asset_class_id: // value for 'asset_class_id'
 *      parent_id: // value for 'parent_id'
 *      display_name: // value for 'display_name'
 *      description: // value for 'description'
 *      asset_group_operating_hours: // value for 'asset_group_operating_hours'
 *   },
 * });
 */
export function useCreateAssetGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetGroupMutation, CreateAssetGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetGroupMutation, CreateAssetGroupMutationVariables>(CreateAssetGroupDocument, options);
      }
export type CreateAssetGroupMutationHookResult = ReturnType<typeof useCreateAssetGroupMutation>;
export type CreateAssetGroupMutationResult = Apollo.MutationResult<CreateAssetGroupMutation>;
export type CreateAssetGroupMutationOptions = Apollo.BaseMutationOptions<CreateAssetGroupMutation, CreateAssetGroupMutationVariables>;
export const AssetsByUserGroupIdsDocument = gql`
    query AssetsByUserGroupIds($assetId: uuid, $userGroupIds: [uuid!], $search: assets_bool_exp = {}, $orderBy: [assets_order_by!] = {display_name: asc}, $offset: Int = 0, $limit: Int = 20) {
  assets(
    order_by: $orderBy
    offset: $offset
    limit: $limit
    where: {_and: [{id: {_eq: $assetId}}, {asset_class: {user_group_id: {_in: $userGroupIds}}}, $search]}
  ) {
    id
    display_name
    description
    asset_class {
      name
      user_group {
        id
        display_name
      }
    }
    asset_group {
      display_name
    }
    sc_asset {
      is_draft
    }
  }
  assets_aggregate(
    where: {_and: [{id: {_eq: $assetId}}, {asset_class: {user_group_id: {_in: $userGroupIds}}}, $search]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAssetsByUserGroupIdsQuery__
 *
 * To run a query within a React component, call `useAssetsByUserGroupIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsByUserGroupIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsByUserGroupIdsQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      userGroupIds: // value for 'userGroupIds'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAssetsByUserGroupIdsQuery(baseOptions?: Apollo.QueryHookOptions<AssetsByUserGroupIdsQuery, AssetsByUserGroupIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetsByUserGroupIdsQuery, AssetsByUserGroupIdsQueryVariables>(AssetsByUserGroupIdsDocument, options);
      }
export function useAssetsByUserGroupIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetsByUserGroupIdsQuery, AssetsByUserGroupIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetsByUserGroupIdsQuery, AssetsByUserGroupIdsQueryVariables>(AssetsByUserGroupIdsDocument, options);
        }
export type AssetsByUserGroupIdsQueryHookResult = ReturnType<typeof useAssetsByUserGroupIdsQuery>;
export type AssetsByUserGroupIdsLazyQueryHookResult = ReturnType<typeof useAssetsByUserGroupIdsLazyQuery>;
export type AssetsByUserGroupIdsQueryResult = Apollo.QueryResult<AssetsByUserGroupIdsQuery, AssetsByUserGroupIdsQueryVariables>;
export const AssetsByAssetClassDocument = gql`
    query AssetsByAssetClass($assetClassName: String = "", $search: assets_bool_exp = {}, $queryFilter: assets_bool_exp = {}, $whereDateAndTime: assets_bool_exp = {}, $offset: Int = 0, $limit: Int = 20, $orderBy: [assets_order_by!] = {display_name: asc}) {
  assets(
    offset: $offset
    limit: $limit
    order_by: $orderBy
    where: {_and: [{asset_class: {name: {_eq: $assetClassName}}}, {active: {_eq: true}}, $search, $queryFilter, $whereDateAndTime]}
  ) {
    ...AssetFields
  }
  assets_aggregate(
    where: {_and: [{asset_class: {name: {_eq: $assetClassName}}}, {active: {_eq: true}}, $search, $queryFilter, $whereDateAndTime]}
  ) {
    aggregate {
      count
    }
  }
}
    ${AssetFieldsFragmentDoc}`;

/**
 * __useAssetsByAssetClassQuery__
 *
 * To run a query within a React component, call `useAssetsByAssetClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsByAssetClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsByAssetClassQuery({
 *   variables: {
 *      assetClassName: // value for 'assetClassName'
 *      search: // value for 'search'
 *      queryFilter: // value for 'queryFilter'
 *      whereDateAndTime: // value for 'whereDateAndTime'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAssetsByAssetClassQuery(baseOptions?: Apollo.QueryHookOptions<AssetsByAssetClassQuery, AssetsByAssetClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetsByAssetClassQuery, AssetsByAssetClassQueryVariables>(AssetsByAssetClassDocument, options);
      }
export function useAssetsByAssetClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetsByAssetClassQuery, AssetsByAssetClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetsByAssetClassQuery, AssetsByAssetClassQueryVariables>(AssetsByAssetClassDocument, options);
        }
export type AssetsByAssetClassQueryHookResult = ReturnType<typeof useAssetsByAssetClassQuery>;
export type AssetsByAssetClassLazyQueryHookResult = ReturnType<typeof useAssetsByAssetClassLazyQuery>;
export type AssetsByAssetClassQueryResult = Apollo.QueryResult<AssetsByAssetClassQuery, AssetsByAssetClassQueryVariables>;
export const AssetByIdDocument = gql`
    query AssetById($id: uuid!) {
  assets_by_pk(id: $id) {
    ...AssetFields
  }
}
    ${AssetFieldsFragmentDoc}`;

/**
 * __useAssetByIdQuery__
 *
 * To run a query within a React component, call `useAssetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetByIdQuery(baseOptions: Apollo.QueryHookOptions<AssetByIdQuery, AssetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetByIdQuery, AssetByIdQueryVariables>(AssetByIdDocument, options);
      }
export function useAssetByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetByIdQuery, AssetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetByIdQuery, AssetByIdQueryVariables>(AssetByIdDocument, options);
        }
export type AssetByIdQueryHookResult = ReturnType<typeof useAssetByIdQuery>;
export type AssetByIdLazyQueryHookResult = ReturnType<typeof useAssetByIdLazyQuery>;
export type AssetByIdQueryResult = Apollo.QueryResult<AssetByIdQuery, AssetByIdQueryVariables>;
export const CreateAssetDocument = gql`
    mutation CreateAsset($object: assets_insert_input!) {
  insert_assets_one(object: $object) {
    id
  }
}
    `;
export type CreateAssetMutationFn = Apollo.MutationFunction<CreateAssetMutation, CreateAssetMutationVariables>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(CreateAssetDocument, options);
      }
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<CreateAssetMutation, CreateAssetMutationVariables>;
export const UpdateAssetDocument = gql`
    mutation UpdateAsset($assetId: uuid!, $assetData: assets_set_input = {}) {
  update_assets_by_pk(pk_columns: {id: $assetId}, _set: $assetData) {
    id
  }
}
    `;
export type UpdateAssetMutationFn = Apollo.MutationFunction<UpdateAssetMutation, UpdateAssetMutationVariables>;

/**
 * __useUpdateAssetMutation__
 *
 * To run a mutation, you first call `useUpdateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetMutation, { data, loading, error }] = useUpdateAssetMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      assetData: // value for 'assetData'
 *   },
 * });
 */
export function useUpdateAssetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetMutation, UpdateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(UpdateAssetDocument, options);
      }
export type UpdateAssetMutationHookResult = ReturnType<typeof useUpdateAssetMutation>;
export type UpdateAssetMutationResult = Apollo.MutationResult<UpdateAssetMutation>;
export type UpdateAssetMutationOptions = Apollo.BaseMutationOptions<UpdateAssetMutation, UpdateAssetMutationVariables>;
export const CreateAssetMediaDocument = gql`
    mutation CreateAssetMedia($assetId: uuid!, $fileFormat: String!, $resourceKey: String!, $url: citext!) {
  insert_media_one(
    object: {asset_media: {data: {asset_id: $assetId}}, url: $url, resource_key: $resourceKey, file_format: $fileFormat}
  ) {
    id
  }
}
    `;
export type CreateAssetMediaMutationFn = Apollo.MutationFunction<CreateAssetMediaMutation, CreateAssetMediaMutationVariables>;

/**
 * __useCreateAssetMediaMutation__
 *
 * To run a mutation, you first call `useCreateAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMediaMutation, { data, loading, error }] = useCreateAssetMediaMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      fileFormat: // value for 'fileFormat'
 *      resourceKey: // value for 'resourceKey'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreateAssetMediaMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetMediaMutation, CreateAssetMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetMediaMutation, CreateAssetMediaMutationVariables>(CreateAssetMediaDocument, options);
      }
export type CreateAssetMediaMutationHookResult = ReturnType<typeof useCreateAssetMediaMutation>;
export type CreateAssetMediaMutationResult = Apollo.MutationResult<CreateAssetMediaMutation>;
export type CreateAssetMediaMutationOptions = Apollo.BaseMutationOptions<CreateAssetMediaMutation, CreateAssetMediaMutationVariables>;
export const DeleteAssetByIdDocument = gql`
    mutation DeleteAssetById($assetId: uuid!) {
  delete_assets_by_pk(id: $assetId) {
    id
  }
}
    `;
export type DeleteAssetByIdMutationFn = Apollo.MutationFunction<DeleteAssetByIdMutation, DeleteAssetByIdMutationVariables>;

/**
 * __useDeleteAssetByIdMutation__
 *
 * To run a mutation, you first call `useDeleteAssetByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetByIdMutation, { data, loading, error }] = useDeleteAssetByIdMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useDeleteAssetByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssetByIdMutation, DeleteAssetByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssetByIdMutation, DeleteAssetByIdMutationVariables>(DeleteAssetByIdDocument, options);
      }
export type DeleteAssetByIdMutationHookResult = ReturnType<typeof useDeleteAssetByIdMutation>;
export type DeleteAssetByIdMutationResult = Apollo.MutationResult<DeleteAssetByIdMutation>;
export type DeleteAssetByIdMutationOptions = Apollo.BaseMutationOptions<DeleteAssetByIdMutation, DeleteAssetByIdMutationVariables>;
export const GetAssetsByEventTypeWithGroupIdDocument = gql`
    query getAssetsByEventTypeWithGroupId($userGroupId: uuid!) {
  assets(
    where: {asset_class: {user_group_id: {_eq: $userGroupId}, name: {_eq: "event space"}}}
  ) {
    id
    display_name
  }
}
    `;

/**
 * __useGetAssetsByEventTypeWithGroupIdQuery__
 *
 * To run a query within a React component, call `useGetAssetsByEventTypeWithGroupIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsByEventTypeWithGroupIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsByEventTypeWithGroupIdQuery({
 *   variables: {
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useGetAssetsByEventTypeWithGroupIdQuery(baseOptions: Apollo.QueryHookOptions<GetAssetsByEventTypeWithGroupIdQuery, GetAssetsByEventTypeWithGroupIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetsByEventTypeWithGroupIdQuery, GetAssetsByEventTypeWithGroupIdQueryVariables>(GetAssetsByEventTypeWithGroupIdDocument, options);
      }
export function useGetAssetsByEventTypeWithGroupIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetsByEventTypeWithGroupIdQuery, GetAssetsByEventTypeWithGroupIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetsByEventTypeWithGroupIdQuery, GetAssetsByEventTypeWithGroupIdQueryVariables>(GetAssetsByEventTypeWithGroupIdDocument, options);
        }
export type GetAssetsByEventTypeWithGroupIdQueryHookResult = ReturnType<typeof useGetAssetsByEventTypeWithGroupIdQuery>;
export type GetAssetsByEventTypeWithGroupIdLazyQueryHookResult = ReturnType<typeof useGetAssetsByEventTypeWithGroupIdLazyQuery>;
export type GetAssetsByEventTypeWithGroupIdQueryResult = Apollo.QueryResult<GetAssetsByEventTypeWithGroupIdQuery, GetAssetsByEventTypeWithGroupIdQueryVariables>;
export const AssetBookingByIdDocument = gql`
    query AssetBookingById($id: uuid!) {
  asset_bookings_by_pk(id: $id) {
    id
    status
  }
}
    `;

/**
 * __useAssetBookingByIdQuery__
 *
 * To run a query within a React component, call `useAssetBookingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetBookingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetBookingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetBookingByIdQuery(baseOptions: Apollo.QueryHookOptions<AssetBookingByIdQuery, AssetBookingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetBookingByIdQuery, AssetBookingByIdQueryVariables>(AssetBookingByIdDocument, options);
      }
export function useAssetBookingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetBookingByIdQuery, AssetBookingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetBookingByIdQuery, AssetBookingByIdQueryVariables>(AssetBookingByIdDocument, options);
        }
export type AssetBookingByIdQueryHookResult = ReturnType<typeof useAssetBookingByIdQuery>;
export type AssetBookingByIdLazyQueryHookResult = ReturnType<typeof useAssetBookingByIdLazyQuery>;
export type AssetBookingByIdQueryResult = Apollo.QueryResult<AssetBookingByIdQuery, AssetBookingByIdQueryVariables>;
export const BookingsByGroupIdsDocument = gql`
    query BookingsByGroupIds($whereGroupId: asset_bookings_bool_exp = {}, $whereDateAndTime: asset_bookings_bool_exp = {}, $whereStatus: asset_bookings_bool_exp = {}, $whereAssetClass: asset_bookings_bool_exp = {}, $whereApproverList: asset_bookings_bool_exp = {}, $whereAssetGroup: asset_bookings_bool_exp = {}, $whereAsset: asset_bookings_bool_exp = {}, $whereRequester: asset_bookings_bool_exp = {}, $search: asset_bookings_bool_exp = {}, $orderBy: [asset_bookings_order_by!] = {}, $offset: Int = 0, $limit: Int = 20) {
  asset_bookings(
    order_by: $orderBy
    offset: $offset
    limit: $limit
    where: {_and: [$whereGroupId, $whereDateAndTime, $whereStatus, $whereAssetClass, $whereApproverList, $whereAssetGroup, $whereAsset, $whereRequester, $search]}
  ) {
    id
    approver
    datetime_start
    datetime_end
    status
    created_at
    asset {
      display_name
      asset_group {
        display_name
      }
      asset_class {
        user_group {
          id
          display_name
        }
      }
    }
    sc_booking {
      first_name
      last_name
    }
    booker {
      first_name
      last_name
    }
  }
  asset_bookings_aggregate(
    where: {_and: [$whereGroupId, $whereDateAndTime, $whereStatus, $whereAssetGroup, $whereAsset, $whereRequester, $whereAssetClass, $whereApproverList, $search]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useBookingsByGroupIdsQuery__
 *
 * To run a query within a React component, call `useBookingsByGroupIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsByGroupIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsByGroupIdsQuery({
 *   variables: {
 *      whereGroupId: // value for 'whereGroupId'
 *      whereDateAndTime: // value for 'whereDateAndTime'
 *      whereStatus: // value for 'whereStatus'
 *      whereAssetClass: // value for 'whereAssetClass'
 *      whereApproverList: // value for 'whereApproverList'
 *      whereAssetGroup: // value for 'whereAssetGroup'
 *      whereAsset: // value for 'whereAsset'
 *      whereRequester: // value for 'whereRequester'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBookingsByGroupIdsQuery(baseOptions?: Apollo.QueryHookOptions<BookingsByGroupIdsQuery, BookingsByGroupIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingsByGroupIdsQuery, BookingsByGroupIdsQueryVariables>(BookingsByGroupIdsDocument, options);
      }
export function useBookingsByGroupIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingsByGroupIdsQuery, BookingsByGroupIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingsByGroupIdsQuery, BookingsByGroupIdsQueryVariables>(BookingsByGroupIdsDocument, options);
        }
export type BookingsByGroupIdsQueryHookResult = ReturnType<typeof useBookingsByGroupIdsQuery>;
export type BookingsByGroupIdsLazyQueryHookResult = ReturnType<typeof useBookingsByGroupIdsLazyQuery>;
export type BookingsByGroupIdsQueryResult = Apollo.QueryResult<BookingsByGroupIdsQuery, BookingsByGroupIdsQueryVariables>;
export const InviteStatusesForAutocompleteDocument = gql`
    query InviteStatusesForAutocomplete($search: String = "%%") {
  enum_invite_statuses(where: {value: {_ilike: $search}}) {
    value
  }
}
    `;

/**
 * __useInviteStatusesForAutocompleteQuery__
 *
 * To run a query within a React component, call `useInviteStatusesForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteStatusesForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteStatusesForAutocompleteQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useInviteStatusesForAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<InviteStatusesForAutocompleteQuery, InviteStatusesForAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteStatusesForAutocompleteQuery, InviteStatusesForAutocompleteQueryVariables>(InviteStatusesForAutocompleteDocument, options);
      }
export function useInviteStatusesForAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteStatusesForAutocompleteQuery, InviteStatusesForAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteStatusesForAutocompleteQuery, InviteStatusesForAutocompleteQueryVariables>(InviteStatusesForAutocompleteDocument, options);
        }
export type InviteStatusesForAutocompleteQueryHookResult = ReturnType<typeof useInviteStatusesForAutocompleteQuery>;
export type InviteStatusesForAutocompleteLazyQueryHookResult = ReturnType<typeof useInviteStatusesForAutocompleteLazyQuery>;
export type InviteStatusesForAutocompleteQueryResult = Apollo.QueryResult<InviteStatusesForAutocompleteQuery, InviteStatusesForAutocompleteQueryVariables>;
export const EnumBookingStatusesDocument = gql`
    query EnumBookingStatuses($search: String = "%%") {
  enum_asset_booking_statuses(where: {value: {_ilike: $search}}) {
    value
  }
}
    `;

/**
 * __useEnumBookingStatusesQuery__
 *
 * To run a query within a React component, call `useEnumBookingStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnumBookingStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnumBookingStatusesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useEnumBookingStatusesQuery(baseOptions?: Apollo.QueryHookOptions<EnumBookingStatusesQuery, EnumBookingStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnumBookingStatusesQuery, EnumBookingStatusesQueryVariables>(EnumBookingStatusesDocument, options);
      }
export function useEnumBookingStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnumBookingStatusesQuery, EnumBookingStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnumBookingStatusesQuery, EnumBookingStatusesQueryVariables>(EnumBookingStatusesDocument, options);
        }
export type EnumBookingStatusesQueryHookResult = ReturnType<typeof useEnumBookingStatusesQuery>;
export type EnumBookingStatusesLazyQueryHookResult = ReturnType<typeof useEnumBookingStatusesLazyQuery>;
export type EnumBookingStatusesQueryResult = Apollo.QueryResult<EnumBookingStatusesQuery, EnumBookingStatusesQueryVariables>;
export const FilteredEnumBookingStatusesDocument = gql`
    query FilteredEnumBookingStatuses($withOutStatuses: [String!] = [""]) {
  enum_asset_booking_statuses(where: {value: {_nin: $withOutStatuses}}) {
    value
  }
}
    `;

/**
 * __useFilteredEnumBookingStatusesQuery__
 *
 * To run a query within a React component, call `useFilteredEnumBookingStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredEnumBookingStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredEnumBookingStatusesQuery({
 *   variables: {
 *      withOutStatuses: // value for 'withOutStatuses'
 *   },
 * });
 */
export function useFilteredEnumBookingStatusesQuery(baseOptions?: Apollo.QueryHookOptions<FilteredEnumBookingStatusesQuery, FilteredEnumBookingStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilteredEnumBookingStatusesQuery, FilteredEnumBookingStatusesQueryVariables>(FilteredEnumBookingStatusesDocument, options);
      }
export function useFilteredEnumBookingStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilteredEnumBookingStatusesQuery, FilteredEnumBookingStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilteredEnumBookingStatusesQuery, FilteredEnumBookingStatusesQueryVariables>(FilteredEnumBookingStatusesDocument, options);
        }
export type FilteredEnumBookingStatusesQueryHookResult = ReturnType<typeof useFilteredEnumBookingStatusesQuery>;
export type FilteredEnumBookingStatusesLazyQueryHookResult = ReturnType<typeof useFilteredEnumBookingStatusesLazyQuery>;
export type FilteredEnumBookingStatusesQueryResult = Apollo.QueryResult<FilteredEnumBookingStatusesQuery, FilteredEnumBookingStatusesQueryVariables>;
export const GetExternalProcessValuesDocument = gql`
    query GetExternalProcessValues($userId: uuid!, $processId: uuid!) {
  external_process_values(
    where: {process_id: {_eq: $processId}, user_id: {_eq: $userId}}
  ) {
    user_id
    process_id
    process_values
  }
}
    `;

/**
 * __useGetExternalProcessValuesQuery__
 *
 * To run a query within a React component, call `useGetExternalProcessValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalProcessValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalProcessValuesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      processId: // value for 'processId'
 *   },
 * });
 */
export function useGetExternalProcessValuesQuery(baseOptions: Apollo.QueryHookOptions<GetExternalProcessValuesQuery, GetExternalProcessValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalProcessValuesQuery, GetExternalProcessValuesQueryVariables>(GetExternalProcessValuesDocument, options);
      }
export function useGetExternalProcessValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalProcessValuesQuery, GetExternalProcessValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalProcessValuesQuery, GetExternalProcessValuesQueryVariables>(GetExternalProcessValuesDocument, options);
        }
export type GetExternalProcessValuesQueryHookResult = ReturnType<typeof useGetExternalProcessValuesQuery>;
export type GetExternalProcessValuesLazyQueryHookResult = ReturnType<typeof useGetExternalProcessValuesLazyQuery>;
export type GetExternalProcessValuesQueryResult = Apollo.QueryResult<GetExternalProcessValuesQuery, GetExternalProcessValuesQueryVariables>;
export const GroupRolesForAutocompleteDocument = gql`
    query GroupRolesForAutocomplete($search: String = "%%", $queryFilter: user_group_roles_bool_exp = {}) {
  user_group_roles(where: {_and: [{name: {_ilike: $search}}, $queryFilter]}) {
    id
    name
  }
}
    `;

/**
 * __useGroupRolesForAutocompleteQuery__
 *
 * To run a query within a React component, call `useGroupRolesForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupRolesForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupRolesForAutocompleteQuery({
 *   variables: {
 *      search: // value for 'search'
 *      queryFilter: // value for 'queryFilter'
 *   },
 * });
 */
export function useGroupRolesForAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<GroupRolesForAutocompleteQuery, GroupRolesForAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupRolesForAutocompleteQuery, GroupRolesForAutocompleteQueryVariables>(GroupRolesForAutocompleteDocument, options);
      }
export function useGroupRolesForAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupRolesForAutocompleteQuery, GroupRolesForAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupRolesForAutocompleteQuery, GroupRolesForAutocompleteQueryVariables>(GroupRolesForAutocompleteDocument, options);
        }
export type GroupRolesForAutocompleteQueryHookResult = ReturnType<typeof useGroupRolesForAutocompleteQuery>;
export type GroupRolesForAutocompleteLazyQueryHookResult = ReturnType<typeof useGroupRolesForAutocompleteLazyQuery>;
export type GroupRolesForAutocompleteQueryResult = Apollo.QueryResult<GroupRolesForAutocompleteQuery, GroupRolesForAutocompleteQueryVariables>;
export const InvitesByGroupIdsDocument = gql`
    query InvitesByGroupIds($groupIds: [uuid!], $search: invites_bool_exp = {}, $whereStatus: invites_bool_exp = {}, $orderBy: [invites_order_by!] = {receiver_email: asc}, $offset: Int = 0, $limit: Int = 20) {
  invites(
    order_by: $orderBy
    offset: $offset
    limit: $limit
    where: {_and: [{user_group_id: {_in: $groupIds}}, $search, $whereStatus]}
  ) {
    id
    receiver_email
    status
    expires_at
    created_at
    code
    user_group_role {
      name
    }
    user_group {
      id
      display_name
    }
  }
  invites_aggregate(
    where: {_and: [{user_group_id: {_in: $groupIds}}, $search, $whereStatus]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useInvitesByGroupIdsQuery__
 *
 * To run a query within a React component, call `useInvitesByGroupIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitesByGroupIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitesByGroupIdsQuery({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *      search: // value for 'search'
 *      whereStatus: // value for 'whereStatus'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useInvitesByGroupIdsQuery(baseOptions?: Apollo.QueryHookOptions<InvitesByGroupIdsQuery, InvitesByGroupIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitesByGroupIdsQuery, InvitesByGroupIdsQueryVariables>(InvitesByGroupIdsDocument, options);
      }
export function useInvitesByGroupIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitesByGroupIdsQuery, InvitesByGroupIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitesByGroupIdsQuery, InvitesByGroupIdsQueryVariables>(InvitesByGroupIdsDocument, options);
        }
export type InvitesByGroupIdsQueryHookResult = ReturnType<typeof useInvitesByGroupIdsQuery>;
export type InvitesByGroupIdsLazyQueryHookResult = ReturnType<typeof useInvitesByGroupIdsLazyQuery>;
export type InvitesByGroupIdsQueryResult = Apollo.QueryResult<InvitesByGroupIdsQuery, InvitesByGroupIdsQueryVariables>;
export const InviteByIdDocument = gql`
    query InviteById($inviteId: uuid!) {
  invites_by_pk(id: $inviteId) {
    id
    receiver_email
    status
    updated_at
    created_at
    expires_at
    error
    user_group_id
    user_group {
      name
    }
    user_group_role {
      id
      name
    }
  }
}
    `;

/**
 * __useInviteByIdQuery__
 *
 * To run a query within a React component, call `useInviteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteByIdQuery({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useInviteByIdQuery(baseOptions: Apollo.QueryHookOptions<InviteByIdQuery, InviteByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteByIdQuery, InviteByIdQueryVariables>(InviteByIdDocument, options);
      }
export function useInviteByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteByIdQuery, InviteByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteByIdQuery, InviteByIdQueryVariables>(InviteByIdDocument, options);
        }
export type InviteByIdQueryHookResult = ReturnType<typeof useInviteByIdQuery>;
export type InviteByIdLazyQueryHookResult = ReturnType<typeof useInviteByIdLazyQuery>;
export type InviteByIdQueryResult = Apollo.QueryResult<InviteByIdQuery, InviteByIdQueryVariables>;
export const CancelInviteByIdDocument = gql`
    mutation CancelInviteById($inviteId: uuid!) {
  update_invites_by_pk(pk_columns: {id: $inviteId}, _set: {status: cancelled}) {
    id
    status
  }
}
    `;
export type CancelInviteByIdMutationFn = Apollo.MutationFunction<CancelInviteByIdMutation, CancelInviteByIdMutationVariables>;

/**
 * __useCancelInviteByIdMutation__
 *
 * To run a mutation, you first call `useCancelInviteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInviteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInviteByIdMutation, { data, loading, error }] = useCancelInviteByIdMutation({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useCancelInviteByIdMutation(baseOptions?: Apollo.MutationHookOptions<CancelInviteByIdMutation, CancelInviteByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelInviteByIdMutation, CancelInviteByIdMutationVariables>(CancelInviteByIdDocument, options);
      }
export type CancelInviteByIdMutationHookResult = ReturnType<typeof useCancelInviteByIdMutation>;
export type CancelInviteByIdMutationResult = Apollo.MutationResult<CancelInviteByIdMutation>;
export type CancelInviteByIdMutationOptions = Apollo.BaseMutationOptions<CancelInviteByIdMutation, CancelInviteByIdMutationVariables>;
export const CompleteInviteByIdDocument = gql`
    mutation CompleteInviteById($inviteId: uuid!) {
  update_invites_by_pk(pk_columns: {id: $inviteId}, _set: {status: completed}) {
    id
    status
  }
}
    `;
export type CompleteInviteByIdMutationFn = Apollo.MutationFunction<CompleteInviteByIdMutation, CompleteInviteByIdMutationVariables>;

/**
 * __useCompleteInviteByIdMutation__
 *
 * To run a mutation, you first call `useCompleteInviteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteInviteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeInviteByIdMutation, { data, loading, error }] = useCompleteInviteByIdMutation({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useCompleteInviteByIdMutation(baseOptions?: Apollo.MutationHookOptions<CompleteInviteByIdMutation, CompleteInviteByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteInviteByIdMutation, CompleteInviteByIdMutationVariables>(CompleteInviteByIdDocument, options);
      }
export type CompleteInviteByIdMutationHookResult = ReturnType<typeof useCompleteInviteByIdMutation>;
export type CompleteInviteByIdMutationResult = Apollo.MutationResult<CompleteInviteByIdMutation>;
export type CompleteInviteByIdMutationOptions = Apollo.BaseMutationOptions<CompleteInviteByIdMutation, CompleteInviteByIdMutationVariables>;
export const SendInviteForGroupDocument = gql`
    mutation SendInviteForGroup($email: String!, $groupId: String!, $groupRoleId: String!, $startDate: String, $endDate: String) {
  send_invite(
    send_invite_inputs: {receiver_email: $email, user_group_id: $groupId, user_group_role_id: $groupRoleId, start_date: $startDate, end_date: $endDate}
  ) {
    invite_id
    receiver_email
  }
}
    `;
export type SendInviteForGroupMutationFn = Apollo.MutationFunction<SendInviteForGroupMutation, SendInviteForGroupMutationVariables>;

/**
 * __useSendInviteForGroupMutation__
 *
 * To run a mutation, you first call `useSendInviteForGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteForGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteForGroupMutation, { data, loading, error }] = useSendInviteForGroupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      groupId: // value for 'groupId'
 *      groupRoleId: // value for 'groupRoleId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useSendInviteForGroupMutation(baseOptions?: Apollo.MutationHookOptions<SendInviteForGroupMutation, SendInviteForGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInviteForGroupMutation, SendInviteForGroupMutationVariables>(SendInviteForGroupDocument, options);
      }
export type SendInviteForGroupMutationHookResult = ReturnType<typeof useSendInviteForGroupMutation>;
export type SendInviteForGroupMutationResult = Apollo.MutationResult<SendInviteForGroupMutation>;
export type SendInviteForGroupMutationOptions = Apollo.BaseMutationOptions<SendInviteForGroupMutation, SendInviteForGroupMutationVariables>;
export const InsertRawInvitesDocument = gql`
    mutation InsertRawInvites($invites: [raw_invites_insert_input!]!) {
  insert_raw_invites(objects: $invites) {
    affected_rows
  }
}
    `;
export type InsertRawInvitesMutationFn = Apollo.MutationFunction<InsertRawInvitesMutation, InsertRawInvitesMutationVariables>;

/**
 * __useInsertRawInvitesMutation__
 *
 * To run a mutation, you first call `useInsertRawInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRawInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRawInvitesMutation, { data, loading, error }] = useInsertRawInvitesMutation({
 *   variables: {
 *      invites: // value for 'invites'
 *   },
 * });
 */
export function useInsertRawInvitesMutation(baseOptions?: Apollo.MutationHookOptions<InsertRawInvitesMutation, InsertRawInvitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertRawInvitesMutation, InsertRawInvitesMutationVariables>(InsertRawInvitesDocument, options);
      }
export type InsertRawInvitesMutationHookResult = ReturnType<typeof useInsertRawInvitesMutation>;
export type InsertRawInvitesMutationResult = Apollo.MutationResult<InsertRawInvitesMutation>;
export type InsertRawInvitesMutationOptions = Apollo.BaseMutationOptions<InsertRawInvitesMutation, InsertRawInvitesMutationVariables>;
export const GetMediaSignedUrlsDocument = gql`
    mutation GetMediaSignedUrls($files: [SignedMediaUrlsInput!]!) {
  get_media_signed_urls(files: $files) {
    ...MediaSignedUrlFields
  }
}
    ${MediaSignedUrlFieldsFragmentDoc}`;
export type GetMediaSignedUrlsMutationFn = Apollo.MutationFunction<GetMediaSignedUrlsMutation, GetMediaSignedUrlsMutationVariables>;

/**
 * __useGetMediaSignedUrlsMutation__
 *
 * To run a mutation, you first call `useGetMediaSignedUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetMediaSignedUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getMediaSignedUrlsMutation, { data, loading, error }] = useGetMediaSignedUrlsMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useGetMediaSignedUrlsMutation(baseOptions?: Apollo.MutationHookOptions<GetMediaSignedUrlsMutation, GetMediaSignedUrlsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetMediaSignedUrlsMutation, GetMediaSignedUrlsMutationVariables>(GetMediaSignedUrlsDocument, options);
      }
export type GetMediaSignedUrlsMutationHookResult = ReturnType<typeof useGetMediaSignedUrlsMutation>;
export type GetMediaSignedUrlsMutationResult = Apollo.MutationResult<GetMediaSignedUrlsMutation>;
export type GetMediaSignedUrlsMutationOptions = Apollo.BaseMutationOptions<GetMediaSignedUrlsMutation, GetMediaSignedUrlsMutationVariables>;
export const GetProcessesByOrganizationIdDocument = gql`
    query GetProcessesByOrganizationId($organizationId: uuid!) {
  processes(where: {user_group_id: {_eq: $organizationId}}) {
    id
    user_group_id
    schema
    uischema
    type
    external_url
  }
}
    `;

/**
 * __useGetProcessesByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useGetProcessesByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessesByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcessesByOrganizationIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetProcessesByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<GetProcessesByOrganizationIdQuery, GetProcessesByOrganizationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProcessesByOrganizationIdQuery, GetProcessesByOrganizationIdQueryVariables>(GetProcessesByOrganizationIdDocument, options);
      }
export function useGetProcessesByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProcessesByOrganizationIdQuery, GetProcessesByOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProcessesByOrganizationIdQuery, GetProcessesByOrganizationIdQueryVariables>(GetProcessesByOrganizationIdDocument, options);
        }
export type GetProcessesByOrganizationIdQueryHookResult = ReturnType<typeof useGetProcessesByOrganizationIdQuery>;
export type GetProcessesByOrganizationIdLazyQueryHookResult = ReturnType<typeof useGetProcessesByOrganizationIdLazyQuery>;
export type GetProcessesByOrganizationIdQueryResult = Apollo.QueryResult<GetProcessesByOrganizationIdQuery, GetProcessesByOrganizationIdQueryVariables>;
export const GetProfileByAuth0IdDocument = gql`
    query getProfileByAuth0Id($auth0_id: String!) {
  profiles(where: {auth0_id: {_eq: $auth0_id}}, limit: 1) {
    ...profileFields
  }
}
    ${ProfileFieldsFragmentDoc}`;

/**
 * __useGetProfileByAuth0IdQuery__
 *
 * To run a query within a React component, call `useGetProfileByAuth0IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileByAuth0IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileByAuth0IdQuery({
 *   variables: {
 *      auth0_id: // value for 'auth0_id'
 *   },
 * });
 */
export function useGetProfileByAuth0IdQuery(baseOptions: Apollo.QueryHookOptions<GetProfileByAuth0IdQuery, GetProfileByAuth0IdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileByAuth0IdQuery, GetProfileByAuth0IdQueryVariables>(GetProfileByAuth0IdDocument, options);
      }
export function useGetProfileByAuth0IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileByAuth0IdQuery, GetProfileByAuth0IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileByAuth0IdQuery, GetProfileByAuth0IdQueryVariables>(GetProfileByAuth0IdDocument, options);
        }
export type GetProfileByAuth0IdQueryHookResult = ReturnType<typeof useGetProfileByAuth0IdQuery>;
export type GetProfileByAuth0IdLazyQueryHookResult = ReturnType<typeof useGetProfileByAuth0IdLazyQuery>;
export type GetProfileByAuth0IdQueryResult = Apollo.QueryResult<GetProfileByAuth0IdQuery, GetProfileByAuth0IdQueryVariables>;
export const GetProfilesByUserIdDocument = gql`
    query getProfilesByUserId($user_id: uuid!, $connection_strategy: String_comparison_exp = {}, $limit: Int) {
  profiles(
    where: {user_id: {_eq: $user_id}, connection_strategy: $connection_strategy}
    limit: $limit
  ) {
    ...profileFields
  }
}
    ${ProfileFieldsFragmentDoc}`;

/**
 * __useGetProfilesByUserIdQuery__
 *
 * To run a query within a React component, call `useGetProfilesByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilesByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilesByUserIdQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      connection_strategy: // value for 'connection_strategy'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProfilesByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetProfilesByUserIdQuery, GetProfilesByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfilesByUserIdQuery, GetProfilesByUserIdQueryVariables>(GetProfilesByUserIdDocument, options);
      }
export function useGetProfilesByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfilesByUserIdQuery, GetProfilesByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfilesByUserIdQuery, GetProfilesByUserIdQueryVariables>(GetProfilesByUserIdDocument, options);
        }
export type GetProfilesByUserIdQueryHookResult = ReturnType<typeof useGetProfilesByUserIdQuery>;
export type GetProfilesByUserIdLazyQueryHookResult = ReturnType<typeof useGetProfilesByUserIdLazyQuery>;
export type GetProfilesByUserIdQueryResult = Apollo.QueryResult<GetProfilesByUserIdQuery, GetProfilesByUserIdQueryVariables>;
export const UsersExampleDocument = gql`
    query UsersExample($search: String = "%%", $offset: Int = 0, $limit: Int = 20, $active: [Boolean!], $whereActive: users_bool_exp = {}, $orderBy: [users_order_by!] = {email: asc}) {
  users(
    order_by: $orderBy
    offset: $offset
    limit: $limit
    where: {_and: [{_or: [{first_name: {_ilike: $search}}, {last_name: {_ilike: $search}}]}, $whereActive]}
  ) {
    id
    first_name
    last_name
    email
    active
    start_date
    invites_aggregate {
      aggregate {
        count
      }
    }
  }
  users_aggregate(
    where: {_and: [{_or: [{first_name: {_ilike: $search}}, {last_name: {_ilike: $search}}]}, $whereActive]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsersExampleQuery__
 *
 * To run a query within a React component, call `useUsersExampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersExampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersExampleQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      active: // value for 'active'
 *      whereActive: // value for 'whereActive'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUsersExampleQuery(baseOptions?: Apollo.QueryHookOptions<UsersExampleQuery, UsersExampleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersExampleQuery, UsersExampleQueryVariables>(UsersExampleDocument, options);
      }
export function useUsersExampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersExampleQuery, UsersExampleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersExampleQuery, UsersExampleQueryVariables>(UsersExampleDocument, options);
        }
export type UsersExampleQueryHookResult = ReturnType<typeof useUsersExampleQuery>;
export type UsersExampleLazyQueryHookResult = ReturnType<typeof useUsersExampleLazyQuery>;
export type UsersExampleQueryResult = Apollo.QueryResult<UsersExampleQuery, UsersExampleQueryVariables>;
export const GetUserConsentsByUserIdDocument = gql`
    query GetUserConsentsByUserId($userId: uuid!, $organizationId: uuid!) {
  user_consents(
    where: {user_id: {_eq: $userId}, consent_by_consent_id: {organization_id: {_eq: $organizationId}}}
  ) {
    consent
    updated_at
    consent_by_consent_id {
      name
    }
  }
}
    `;

/**
 * __useGetUserConsentsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserConsentsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserConsentsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserConsentsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetUserConsentsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserConsentsByUserIdQuery, GetUserConsentsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserConsentsByUserIdQuery, GetUserConsentsByUserIdQueryVariables>(GetUserConsentsByUserIdDocument, options);
      }
export function useGetUserConsentsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserConsentsByUserIdQuery, GetUserConsentsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserConsentsByUserIdQuery, GetUserConsentsByUserIdQueryVariables>(GetUserConsentsByUserIdDocument, options);
        }
export type GetUserConsentsByUserIdQueryHookResult = ReturnType<typeof useGetUserConsentsByUserIdQuery>;
export type GetUserConsentsByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserConsentsByUserIdLazyQuery>;
export type GetUserConsentsByUserIdQueryResult = Apollo.QueryResult<GetUserConsentsByUserIdQuery, GetUserConsentsByUserIdQueryVariables>;
export const InsertUserGroupProcessValuesDocument = gql`
    mutation InsertUserGroupProcessValues($object: user_group_process_values_insert_input!) {
  insert_user_group_process_values_one(object: $object) {
    id
  }
}
    `;
export type InsertUserGroupProcessValuesMutationFn = Apollo.MutationFunction<InsertUserGroupProcessValuesMutation, InsertUserGroupProcessValuesMutationVariables>;

/**
 * __useInsertUserGroupProcessValuesMutation__
 *
 * To run a mutation, you first call `useInsertUserGroupProcessValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserGroupProcessValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserGroupProcessValuesMutation, { data, loading, error }] = useInsertUserGroupProcessValuesMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertUserGroupProcessValuesMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserGroupProcessValuesMutation, InsertUserGroupProcessValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUserGroupProcessValuesMutation, InsertUserGroupProcessValuesMutationVariables>(InsertUserGroupProcessValuesDocument, options);
      }
export type InsertUserGroupProcessValuesMutationHookResult = ReturnType<typeof useInsertUserGroupProcessValuesMutation>;
export type InsertUserGroupProcessValuesMutationResult = Apollo.MutationResult<InsertUserGroupProcessValuesMutation>;
export type InsertUserGroupProcessValuesMutationOptions = Apollo.BaseMutationOptions<InsertUserGroupProcessValuesMutation, InsertUserGroupProcessValuesMutationVariables>;
export const EditUserGroupProcessValuesDocument = gql`
    mutation EditUserGroupProcessValues($id: uuid!, $processValues: jsonb!) {
  update_user_group_process_values(
    where: {id: {_eq: $id}}
    _set: {process_values: $processValues}
  ) {
    affected_rows
  }
}
    `;
export type EditUserGroupProcessValuesMutationFn = Apollo.MutationFunction<EditUserGroupProcessValuesMutation, EditUserGroupProcessValuesMutationVariables>;

/**
 * __useEditUserGroupProcessValuesMutation__
 *
 * To run a mutation, you first call `useEditUserGroupProcessValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserGroupProcessValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserGroupProcessValuesMutation, { data, loading, error }] = useEditUserGroupProcessValuesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      processValues: // value for 'processValues'
 *   },
 * });
 */
export function useEditUserGroupProcessValuesMutation(baseOptions?: Apollo.MutationHookOptions<EditUserGroupProcessValuesMutation, EditUserGroupProcessValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserGroupProcessValuesMutation, EditUserGroupProcessValuesMutationVariables>(EditUserGroupProcessValuesDocument, options);
      }
export type EditUserGroupProcessValuesMutationHookResult = ReturnType<typeof useEditUserGroupProcessValuesMutation>;
export type EditUserGroupProcessValuesMutationResult = Apollo.MutationResult<EditUserGroupProcessValuesMutation>;
export type EditUserGroupProcessValuesMutationOptions = Apollo.BaseMutationOptions<EditUserGroupProcessValuesMutation, EditUserGroupProcessValuesMutationVariables>;
export const UserGroupProcessValuesDocument = gql`
    query UserGroupProcessValues($userId: uuid!, $processId: uuid!) {
  user_group_process_values(
    where: {process_id: {_eq: $processId}, user_id: {_eq: $userId}}
  ) {
    id
    user_id
    process_id
    process_values
  }
}
    `;

/**
 * __useUserGroupProcessValuesQuery__
 *
 * To run a query within a React component, call `useUserGroupProcessValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupProcessValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupProcessValuesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      processId: // value for 'processId'
 *   },
 * });
 */
export function useUserGroupProcessValuesQuery(baseOptions: Apollo.QueryHookOptions<UserGroupProcessValuesQuery, UserGroupProcessValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupProcessValuesQuery, UserGroupProcessValuesQueryVariables>(UserGroupProcessValuesDocument, options);
      }
export function useUserGroupProcessValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupProcessValuesQuery, UserGroupProcessValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupProcessValuesQuery, UserGroupProcessValuesQueryVariables>(UserGroupProcessValuesDocument, options);
        }
export type UserGroupProcessValuesQueryHookResult = ReturnType<typeof useUserGroupProcessValuesQuery>;
export type UserGroupProcessValuesLazyQueryHookResult = ReturnType<typeof useUserGroupProcessValuesLazyQuery>;
export type UserGroupProcessValuesQueryResult = Apollo.QueryResult<UserGroupProcessValuesQuery, UserGroupProcessValuesQueryVariables>;
export const UserGroupRolesByGroupIdDocument = gql`
    query UserGroupRolesByGroupId($groupId: uuid!) {
  user_group_roles(where: {user_group_id: {_eq: $groupId}}) {
    id
    name
  }
}
    `;

/**
 * __useUserGroupRolesByGroupIdQuery__
 *
 * To run a query within a React component, call `useUserGroupRolesByGroupIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupRolesByGroupIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupRolesByGroupIdQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useUserGroupRolesByGroupIdQuery(baseOptions: Apollo.QueryHookOptions<UserGroupRolesByGroupIdQuery, UserGroupRolesByGroupIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupRolesByGroupIdQuery, UserGroupRolesByGroupIdQueryVariables>(UserGroupRolesByGroupIdDocument, options);
      }
export function useUserGroupRolesByGroupIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupRolesByGroupIdQuery, UserGroupRolesByGroupIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupRolesByGroupIdQuery, UserGroupRolesByGroupIdQueryVariables>(UserGroupRolesByGroupIdDocument, options);
        }
export type UserGroupRolesByGroupIdQueryHookResult = ReturnType<typeof useUserGroupRolesByGroupIdQuery>;
export type UserGroupRolesByGroupIdLazyQueryHookResult = ReturnType<typeof useUserGroupRolesByGroupIdLazyQuery>;
export type UserGroupRolesByGroupIdQueryResult = Apollo.QueryResult<UserGroupRolesByGroupIdQuery, UserGroupRolesByGroupIdQueryVariables>;
export const UpdateUserRoleDatesByEmailDocument = gql`
    mutation UpdateUserRoleDatesByEmail($emails: [citext!]!, $groupId: uuid!, $start_date: date, $end_date: date) {
  update_user_group_user_roles(
    where: {_and: [{user: {email: {_in: $emails}}}, {user_group_role: {user_group_id: {_eq: $groupId}}}]}
    _set: {start_date: $start_date, end_date: $end_date}
  ) {
    affected_rows
  }
}
    `;
export type UpdateUserRoleDatesByEmailMutationFn = Apollo.MutationFunction<UpdateUserRoleDatesByEmailMutation, UpdateUserRoleDatesByEmailMutationVariables>;

/**
 * __useUpdateUserRoleDatesByEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleDatesByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleDatesByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleDatesByEmailMutation, { data, loading, error }] = useUpdateUserRoleDatesByEmailMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      groupId: // value for 'groupId'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useUpdateUserRoleDatesByEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRoleDatesByEmailMutation, UpdateUserRoleDatesByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRoleDatesByEmailMutation, UpdateUserRoleDatesByEmailMutationVariables>(UpdateUserRoleDatesByEmailDocument, options);
      }
export type UpdateUserRoleDatesByEmailMutationHookResult = ReturnType<typeof useUpdateUserRoleDatesByEmailMutation>;
export type UpdateUserRoleDatesByEmailMutationResult = Apollo.MutationResult<UpdateUserRoleDatesByEmailMutation>;
export type UpdateUserRoleDatesByEmailMutationOptions = Apollo.BaseMutationOptions<UpdateUserRoleDatesByEmailMutation, UpdateUserRoleDatesByEmailMutationVariables>;
export const UpdateUserRoleActiveByEmailDocument = gql`
    mutation UpdateUserRoleActiveByEmail($emails: [citext!]!, $groupId: uuid!, $isActive: Boolean!) {
  update_user_group_user_roles(
    where: {_and: [{user: {email: {_in: $emails}}}, {user_group_role: {user_group_id: {_eq: $groupId}}}]}
    _set: {is_active: $isActive}
  ) {
    affected_rows
  }
}
    `;
export type UpdateUserRoleActiveByEmailMutationFn = Apollo.MutationFunction<UpdateUserRoleActiveByEmailMutation, UpdateUserRoleActiveByEmailMutationVariables>;

/**
 * __useUpdateUserRoleActiveByEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleActiveByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleActiveByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleActiveByEmailMutation, { data, loading, error }] = useUpdateUserRoleActiveByEmailMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      groupId: // value for 'groupId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateUserRoleActiveByEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRoleActiveByEmailMutation, UpdateUserRoleActiveByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRoleActiveByEmailMutation, UpdateUserRoleActiveByEmailMutationVariables>(UpdateUserRoleActiveByEmailDocument, options);
      }
export type UpdateUserRoleActiveByEmailMutationHookResult = ReturnType<typeof useUpdateUserRoleActiveByEmailMutation>;
export type UpdateUserRoleActiveByEmailMutationResult = Apollo.MutationResult<UpdateUserRoleActiveByEmailMutation>;
export type UpdateUserRoleActiveByEmailMutationOptions = Apollo.BaseMutationOptions<UpdateUserRoleActiveByEmailMutation, UpdateUserRoleActiveByEmailMutationVariables>;
export const UsersFromUserGroupUserRoleByGroupIdsDocument = gql`
    query UsersFromUserGroupUserRoleByGroupIds($groupIds: [uuid!], $search: user_group_user_roles_bool_exp = {}, $whereActive: user_group_user_roles_bool_exp = {}, $whereRole: user_group_user_roles_bool_exp = {}, $orderBy: [user_group_user_roles_order_by!] = {}, $offset: Int = 0, $limit: Int = 20) {
  user_group_user_roles(
    order_by: $orderBy
    offset: $offset
    limit: $limit
    where: {_and: [{user_group_role: {user_group_id: {_in: $groupIds}}}, $search, $whereActive, $whereRole]}
  ) {
    is_active
    created_at
    user_id
    user_group_role_id
    user {
      id
      salutation
      first_name
      last_name
      email
      phone_number
      active
      created_at
      start_date
      end_date
      avatar_url
    }
    user_group_role {
      id
      name
      user_group {
        id
        display_name
      }
    }
  }
  user_group_user_roles_aggregate(
    where: {_and: [{user_group_role: {user_group_id: {_in: $groupIds}}}, $search, $whereActive, $whereRole]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsersFromUserGroupUserRoleByGroupIdsQuery__
 *
 * To run a query within a React component, call `useUsersFromUserGroupUserRoleByGroupIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersFromUserGroupUserRoleByGroupIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersFromUserGroupUserRoleByGroupIdsQuery({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *      search: // value for 'search'
 *      whereActive: // value for 'whereActive'
 *      whereRole: // value for 'whereRole'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUsersFromUserGroupUserRoleByGroupIdsQuery(baseOptions?: Apollo.QueryHookOptions<UsersFromUserGroupUserRoleByGroupIdsQuery, UsersFromUserGroupUserRoleByGroupIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersFromUserGroupUserRoleByGroupIdsQuery, UsersFromUserGroupUserRoleByGroupIdsQueryVariables>(UsersFromUserGroupUserRoleByGroupIdsDocument, options);
      }
export function useUsersFromUserGroupUserRoleByGroupIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersFromUserGroupUserRoleByGroupIdsQuery, UsersFromUserGroupUserRoleByGroupIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersFromUserGroupUserRoleByGroupIdsQuery, UsersFromUserGroupUserRoleByGroupIdsQueryVariables>(UsersFromUserGroupUserRoleByGroupIdsDocument, options);
        }
export type UsersFromUserGroupUserRoleByGroupIdsQueryHookResult = ReturnType<typeof useUsersFromUserGroupUserRoleByGroupIdsQuery>;
export type UsersFromUserGroupUserRoleByGroupIdsLazyQueryHookResult = ReturnType<typeof useUsersFromUserGroupUserRoleByGroupIdsLazyQuery>;
export type UsersFromUserGroupUserRoleByGroupIdsQueryResult = Apollo.QueryResult<UsersFromUserGroupUserRoleByGroupIdsQuery, UsersFromUserGroupUserRoleByGroupIdsQueryVariables>;
export const ActiveRolesCountDocument = gql`
    query ActiveRolesCount($userId: uuid!, $userGroupId: uuid!) {
  user_group_user_roles_aggregate(
    where: {is_active: {_eq: true}, user_id: {_eq: $userId}, user_group_role: {user_group_id: {_eq: $userGroupId}}}
    limit: 1
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useActiveRolesCountQuery__
 *
 * To run a query within a React component, call `useActiveRolesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveRolesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveRolesCountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useActiveRolesCountQuery(baseOptions: Apollo.QueryHookOptions<ActiveRolesCountQuery, ActiveRolesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveRolesCountQuery, ActiveRolesCountQueryVariables>(ActiveRolesCountDocument, options);
      }
export function useActiveRolesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveRolesCountQuery, ActiveRolesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveRolesCountQuery, ActiveRolesCountQueryVariables>(ActiveRolesCountDocument, options);
        }
export type ActiveRolesCountQueryHookResult = ReturnType<typeof useActiveRolesCountQuery>;
export type ActiveRolesCountLazyQueryHookResult = ReturnType<typeof useActiveRolesCountLazyQuery>;
export type ActiveRolesCountQueryResult = Apollo.QueryResult<ActiveRolesCountQuery, ActiveRolesCountQueryVariables>;
export const UserGroupsDocument = gql`
    query UserGroups($search: user_groups_bool_exp, $offset: Int = 0, $limit: Int, $orderBy: [user_groups_order_by!]) {
  user_groups(offset: $offset, limit: $limit, where: $search, order_by: $orderBy) {
    ...UserGroupFields
  }
  user_groups_aggregate(where: $search) {
    aggregate {
      count
    }
  }
}
    ${UserGroupFieldsFragmentDoc}`;

/**
 * __useUserGroupsQuery__
 *
 * To run a query within a React component, call `useUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUserGroupsQuery(baseOptions?: Apollo.QueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
      }
export function useUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
        }
export type UserGroupsQueryHookResult = ReturnType<typeof useUserGroupsQuery>;
export type UserGroupsLazyQueryHookResult = ReturnType<typeof useUserGroupsLazyQuery>;
export type UserGroupsQueryResult = Apollo.QueryResult<UserGroupsQuery, UserGroupsQueryVariables>;
export const UserGroupByIdDocument = gql`
    query UserGroupById($id: uuid!) {
  user_groups_by_pk(id: $id) {
    ...UserGroupFields
    user_group {
      ...UserGroupFields
    }
  }
}
    ${UserGroupFieldsFragmentDoc}`;

/**
 * __useUserGroupByIdQuery__
 *
 * To run a query within a React component, call `useUserGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupByIdQuery(baseOptions: Apollo.QueryHookOptions<UserGroupByIdQuery, UserGroupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupByIdQuery, UserGroupByIdQueryVariables>(UserGroupByIdDocument, options);
      }
export function useUserGroupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupByIdQuery, UserGroupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupByIdQuery, UserGroupByIdQueryVariables>(UserGroupByIdDocument, options);
        }
export type UserGroupByIdQueryHookResult = ReturnType<typeof useUserGroupByIdQuery>;
export type UserGroupByIdLazyQueryHookResult = ReturnType<typeof useUserGroupByIdLazyQuery>;
export type UserGroupByIdQueryResult = Apollo.QueryResult<UserGroupByIdQuery, UserGroupByIdQueryVariables>;
export const UserGroupByIdBasicDocument = gql`
    query UserGroupByIdBasic($id: uuid!) {
  user_groups_by_pk(id: $id) {
    id
    name
    display_name
  }
}
    `;

/**
 * __useUserGroupByIdBasicQuery__
 *
 * To run a query within a React component, call `useUserGroupByIdBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupByIdBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupByIdBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupByIdBasicQuery(baseOptions: Apollo.QueryHookOptions<UserGroupByIdBasicQuery, UserGroupByIdBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupByIdBasicQuery, UserGroupByIdBasicQueryVariables>(UserGroupByIdBasicDocument, options);
      }
export function useUserGroupByIdBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupByIdBasicQuery, UserGroupByIdBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupByIdBasicQuery, UserGroupByIdBasicQueryVariables>(UserGroupByIdBasicDocument, options);
        }
export type UserGroupByIdBasicQueryHookResult = ReturnType<typeof useUserGroupByIdBasicQuery>;
export type UserGroupByIdBasicLazyQueryHookResult = ReturnType<typeof useUserGroupByIdBasicLazyQuery>;
export type UserGroupByIdBasicQueryResult = Apollo.QueryResult<UserGroupByIdBasicQuery, UserGroupByIdBasicQueryVariables>;
export const UserGroupConfigurationDocument = gql`
    query UserGroupConfiguration($id: uuid!) {
  user_groups_by_pk(id: $id) {
    user_group_configuration
  }
}
    `;

/**
 * __useUserGroupConfigurationQuery__
 *
 * To run a query within a React component, call `useUserGroupConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupConfigurationQuery(baseOptions: Apollo.QueryHookOptions<UserGroupConfigurationQuery, UserGroupConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupConfigurationQuery, UserGroupConfigurationQueryVariables>(UserGroupConfigurationDocument, options);
      }
export function useUserGroupConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupConfigurationQuery, UserGroupConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupConfigurationQuery, UserGroupConfigurationQueryVariables>(UserGroupConfigurationDocument, options);
        }
export type UserGroupConfigurationQueryHookResult = ReturnType<typeof useUserGroupConfigurationQuery>;
export type UserGroupConfigurationLazyQueryHookResult = ReturnType<typeof useUserGroupConfigurationLazyQuery>;
export type UserGroupConfigurationQueryResult = Apollo.QueryResult<UserGroupConfigurationQuery, UserGroupConfigurationQueryVariables>;
export const UserGroupSchemaDocument = gql`
    query UserGroupSchema($id: uuid!) {
  user_groups_by_pk(id: $id) {
    schema
    uischema
  }
}
    `;

/**
 * __useUserGroupSchemaQuery__
 *
 * To run a query within a React component, call `useUserGroupSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupSchemaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupSchemaQuery(baseOptions: Apollo.QueryHookOptions<UserGroupSchemaQuery, UserGroupSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupSchemaQuery, UserGroupSchemaQueryVariables>(UserGroupSchemaDocument, options);
      }
export function useUserGroupSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupSchemaQuery, UserGroupSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupSchemaQuery, UserGroupSchemaQueryVariables>(UserGroupSchemaDocument, options);
        }
export type UserGroupSchemaQueryHookResult = ReturnType<typeof useUserGroupSchemaQuery>;
export type UserGroupSchemaLazyQueryHookResult = ReturnType<typeof useUserGroupSchemaLazyQuery>;
export type UserGroupSchemaQueryResult = Apollo.QueryResult<UserGroupSchemaQuery, UserGroupSchemaQueryVariables>;
export const UserGroupCompanyFieldsDocument = gql`
    query UserGroupCompanyFields($id: uuid!) {
  user_groups_by_pk(id: $id) {
    process_values
  }
}
    `;

/**
 * __useUserGroupCompanyFieldsQuery__
 *
 * To run a query within a React component, call `useUserGroupCompanyFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupCompanyFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupCompanyFieldsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupCompanyFieldsQuery(baseOptions: Apollo.QueryHookOptions<UserGroupCompanyFieldsQuery, UserGroupCompanyFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupCompanyFieldsQuery, UserGroupCompanyFieldsQueryVariables>(UserGroupCompanyFieldsDocument, options);
      }
export function useUserGroupCompanyFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupCompanyFieldsQuery, UserGroupCompanyFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupCompanyFieldsQuery, UserGroupCompanyFieldsQueryVariables>(UserGroupCompanyFieldsDocument, options);
        }
export type UserGroupCompanyFieldsQueryHookResult = ReturnType<typeof useUserGroupCompanyFieldsQuery>;
export type UserGroupCompanyFieldsLazyQueryHookResult = ReturnType<typeof useUserGroupCompanyFieldsLazyQuery>;
export type UserGroupCompanyFieldsQueryResult = Apollo.QueryResult<UserGroupCompanyFieldsQuery, UserGroupCompanyFieldsQueryVariables>;
export const EditGroupProcessValuesDocument = gql`
    mutation EditGroupProcessValues($id: uuid!, $processValues: jsonb!) {
  update_user_groups(
    where: {id: {_eq: $id}}
    _set: {process_values: $processValues}
  ) {
    affected_rows
  }
}
    `;
export type EditGroupProcessValuesMutationFn = Apollo.MutationFunction<EditGroupProcessValuesMutation, EditGroupProcessValuesMutationVariables>;

/**
 * __useEditGroupProcessValuesMutation__
 *
 * To run a mutation, you first call `useEditGroupProcessValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupProcessValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupProcessValuesMutation, { data, loading, error }] = useEditGroupProcessValuesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      processValues: // value for 'processValues'
 *   },
 * });
 */
export function useEditGroupProcessValuesMutation(baseOptions?: Apollo.MutationHookOptions<EditGroupProcessValuesMutation, EditGroupProcessValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGroupProcessValuesMutation, EditGroupProcessValuesMutationVariables>(EditGroupProcessValuesDocument, options);
      }
export type EditGroupProcessValuesMutationHookResult = ReturnType<typeof useEditGroupProcessValuesMutation>;
export type EditGroupProcessValuesMutationResult = Apollo.MutationResult<EditGroupProcessValuesMutation>;
export type EditGroupProcessValuesMutationOptions = Apollo.BaseMutationOptions<EditGroupProcessValuesMutation, EditGroupProcessValuesMutationVariables>;
export const UpdateUserGroupByIdDocument = gql`
    mutation UpdateUserGroupById($groupId: uuid!, $groupData: user_groups_set_input = {}) {
  update_user_groups_by_pk(pk_columns: {id: $groupId}, _set: $groupData) {
    ...UserGroupFields
  }
}
    ${UserGroupFieldsFragmentDoc}`;
export type UpdateUserGroupByIdMutationFn = Apollo.MutationFunction<UpdateUserGroupByIdMutation, UpdateUserGroupByIdMutationVariables>;

/**
 * __useUpdateUserGroupByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupByIdMutation, { data, loading, error }] = useUpdateUserGroupByIdMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      groupData: // value for 'groupData'
 *   },
 * });
 */
export function useUpdateUserGroupByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserGroupByIdMutation, UpdateUserGroupByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserGroupByIdMutation, UpdateUserGroupByIdMutationVariables>(UpdateUserGroupByIdDocument, options);
      }
export type UpdateUserGroupByIdMutationHookResult = ReturnType<typeof useUpdateUserGroupByIdMutation>;
export type UpdateUserGroupByIdMutationResult = Apollo.MutationResult<UpdateUserGroupByIdMutation>;
export type UpdateUserGroupByIdMutationOptions = Apollo.BaseMutationOptions<UpdateUserGroupByIdMutation, UpdateUserGroupByIdMutationVariables>;
export const CreateUserGroupDocument = gql`
    mutation CreateUserGroup($object: user_groups_insert_input!) {
  insert_user_groups_one(object: $object) {
    id
  }
}
    `;
export type CreateUserGroupMutationFn = Apollo.MutationFunction<CreateUserGroupMutation, CreateUserGroupMutationVariables>;

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(CreateUserGroupDocument, options);
      }
export type CreateUserGroupMutationHookResult = ReturnType<typeof useCreateUserGroupMutation>;
export type CreateUserGroupMutationResult = Apollo.MutationResult<CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = Apollo.BaseMutationOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>;
export const CreateUserGroupMediaDocument = gql`
    mutation CreateUserGroupMedia($userGroupId: uuid!, $fileFormat: String!, $resourceKey: String!, $url: citext!) {
  insert_media_one(
    object: {user_group_logo_media: {data: {user_group_id: $userGroupId}}, url: $url, resource_key: $resourceKey, file_format: $fileFormat}
  ) {
    id
  }
}
    `;
export type CreateUserGroupMediaMutationFn = Apollo.MutationFunction<CreateUserGroupMediaMutation, CreateUserGroupMediaMutationVariables>;

/**
 * __useCreateUserGroupMediaMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMediaMutation, { data, loading, error }] = useCreateUserGroupMediaMutation({
 *   variables: {
 *      userGroupId: // value for 'userGroupId'
 *      fileFormat: // value for 'fileFormat'
 *      resourceKey: // value for 'resourceKey'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreateUserGroupMediaMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserGroupMediaMutation, CreateUserGroupMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserGroupMediaMutation, CreateUserGroupMediaMutationVariables>(CreateUserGroupMediaDocument, options);
      }
export type CreateUserGroupMediaMutationHookResult = ReturnType<typeof useCreateUserGroupMediaMutation>;
export type CreateUserGroupMediaMutationResult = Apollo.MutationResult<CreateUserGroupMediaMutation>;
export type CreateUserGroupMediaMutationOptions = Apollo.BaseMutationOptions<CreateUserGroupMediaMutation, CreateUserGroupMediaMutationVariables>;
export const DeleteUserGroupByIdDocument = gql`
    mutation deleteUserGroupById($userGroupId: uuid!) {
  delete_user_group(object: {user_group_id: $userGroupId}) {
    message
  }
}
    `;
export type DeleteUserGroupByIdMutationFn = Apollo.MutationFunction<DeleteUserGroupByIdMutation, DeleteUserGroupByIdMutationVariables>;

/**
 * __useDeleteUserGroupByIdMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupByIdMutation, { data, loading, error }] = useDeleteUserGroupByIdMutation({
 *   variables: {
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useDeleteUserGroupByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserGroupByIdMutation, DeleteUserGroupByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserGroupByIdMutation, DeleteUserGroupByIdMutationVariables>(DeleteUserGroupByIdDocument, options);
      }
export type DeleteUserGroupByIdMutationHookResult = ReturnType<typeof useDeleteUserGroupByIdMutation>;
export type DeleteUserGroupByIdMutationResult = Apollo.MutationResult<DeleteUserGroupByIdMutation>;
export type DeleteUserGroupByIdMutationOptions = Apollo.BaseMutationOptions<DeleteUserGroupByIdMutation, DeleteUserGroupByIdMutationVariables>;
export const ActivateUserGroupByIdDocument = gql`
    mutation activateUserGroupById($userGroupId: uuid!) {
  update_user_groups_by_pk(pk_columns: {id: $userGroupId}, _set: {active: true}) {
    id
  }
}
    `;
export type ActivateUserGroupByIdMutationFn = Apollo.MutationFunction<ActivateUserGroupByIdMutation, ActivateUserGroupByIdMutationVariables>;

/**
 * __useActivateUserGroupByIdMutation__
 *
 * To run a mutation, you first call `useActivateUserGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserGroupByIdMutation, { data, loading, error }] = useActivateUserGroupByIdMutation({
 *   variables: {
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useActivateUserGroupByIdMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUserGroupByIdMutation, ActivateUserGroupByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateUserGroupByIdMutation, ActivateUserGroupByIdMutationVariables>(ActivateUserGroupByIdDocument, options);
      }
export type ActivateUserGroupByIdMutationHookResult = ReturnType<typeof useActivateUserGroupByIdMutation>;
export type ActivateUserGroupByIdMutationResult = Apollo.MutationResult<ActivateUserGroupByIdMutation>;
export type ActivateUserGroupByIdMutationOptions = Apollo.BaseMutationOptions<ActivateUserGroupByIdMutation, ActivateUserGroupByIdMutationVariables>;
export const DeactivateUserGroupByIdDocument = gql`
    mutation deactivateUserGroupById($userGroupId: uuid!) {
  update_user_groups_by_pk(pk_columns: {id: $userGroupId}, _set: {active: false}) {
    id
  }
}
    `;
export type DeactivateUserGroupByIdMutationFn = Apollo.MutationFunction<DeactivateUserGroupByIdMutation, DeactivateUserGroupByIdMutationVariables>;

/**
 * __useDeactivateUserGroupByIdMutation__
 *
 * To run a mutation, you first call `useDeactivateUserGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserGroupByIdMutation, { data, loading, error }] = useDeactivateUserGroupByIdMutation({
 *   variables: {
 *      userGroupId: // value for 'userGroupId'
 *   },
 * });
 */
export function useDeactivateUserGroupByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateUserGroupByIdMutation, DeactivateUserGroupByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateUserGroupByIdMutation, DeactivateUserGroupByIdMutationVariables>(DeactivateUserGroupByIdDocument, options);
      }
export type DeactivateUserGroupByIdMutationHookResult = ReturnType<typeof useDeactivateUserGroupByIdMutation>;
export type DeactivateUserGroupByIdMutationResult = Apollo.MutationResult<DeactivateUserGroupByIdMutation>;
export type DeactivateUserGroupByIdMutationOptions = Apollo.BaseMutationOptions<DeactivateUserGroupByIdMutation, DeactivateUserGroupByIdMutationVariables>;
export const InsertUserProcessValuesDocument = gql`
    mutation InsertUserProcessValues($object: user_process_values_insert_input!) {
  insert_user_process_values_one(object: $object) {
    id
  }
}
    `;
export type InsertUserProcessValuesMutationFn = Apollo.MutationFunction<InsertUserProcessValuesMutation, InsertUserProcessValuesMutationVariables>;

/**
 * __useInsertUserProcessValuesMutation__
 *
 * To run a mutation, you first call `useInsertUserProcessValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserProcessValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserProcessValuesMutation, { data, loading, error }] = useInsertUserProcessValuesMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertUserProcessValuesMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserProcessValuesMutation, InsertUserProcessValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUserProcessValuesMutation, InsertUserProcessValuesMutationVariables>(InsertUserProcessValuesDocument, options);
      }
export type InsertUserProcessValuesMutationHookResult = ReturnType<typeof useInsertUserProcessValuesMutation>;
export type InsertUserProcessValuesMutationResult = Apollo.MutationResult<InsertUserProcessValuesMutation>;
export type InsertUserProcessValuesMutationOptions = Apollo.BaseMutationOptions<InsertUserProcessValuesMutation, InsertUserProcessValuesMutationVariables>;
export const EditUserProcessValuesDocument = gql`
    mutation EditUserProcessValues($id: uuid!, $processValues: jsonb!) {
  update_user_process_values(
    where: {id: {_eq: $id}}
    _set: {process_values: $processValues}
  ) {
    affected_rows
  }
}
    `;
export type EditUserProcessValuesMutationFn = Apollo.MutationFunction<EditUserProcessValuesMutation, EditUserProcessValuesMutationVariables>;

/**
 * __useEditUserProcessValuesMutation__
 *
 * To run a mutation, you first call `useEditUserProcessValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserProcessValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserProcessValuesMutation, { data, loading, error }] = useEditUserProcessValuesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      processValues: // value for 'processValues'
 *   },
 * });
 */
export function useEditUserProcessValuesMutation(baseOptions?: Apollo.MutationHookOptions<EditUserProcessValuesMutation, EditUserProcessValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserProcessValuesMutation, EditUserProcessValuesMutationVariables>(EditUserProcessValuesDocument, options);
      }
export type EditUserProcessValuesMutationHookResult = ReturnType<typeof useEditUserProcessValuesMutation>;
export type EditUserProcessValuesMutationResult = Apollo.MutationResult<EditUserProcessValuesMutation>;
export type EditUserProcessValuesMutationOptions = Apollo.BaseMutationOptions<EditUserProcessValuesMutation, EditUserProcessValuesMutationVariables>;
export const UserProcessValuesDocument = gql`
    query UserProcessValues($userId: uuid!, $processId: uuid!) {
  user_process_values(
    where: {process_id: {_eq: $processId}, user_id: {_eq: $userId}}
  ) {
    id
    user_id
    process_id
    process_values
  }
}
    `;

/**
 * __useUserProcessValuesQuery__
 *
 * To run a query within a React component, call `useUserProcessValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProcessValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProcessValuesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      processId: // value for 'processId'
 *   },
 * });
 */
export function useUserProcessValuesQuery(baseOptions: Apollo.QueryHookOptions<UserProcessValuesQuery, UserProcessValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProcessValuesQuery, UserProcessValuesQueryVariables>(UserProcessValuesDocument, options);
      }
export function useUserProcessValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProcessValuesQuery, UserProcessValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProcessValuesQuery, UserProcessValuesQueryVariables>(UserProcessValuesDocument, options);
        }
export type UserProcessValuesQueryHookResult = ReturnType<typeof useUserProcessValuesQuery>;
export type UserProcessValuesLazyQueryHookResult = ReturnType<typeof useUserProcessValuesLazyQuery>;
export type UserProcessValuesQueryResult = Apollo.QueryResult<UserProcessValuesQuery, UserProcessValuesQueryVariables>;
export const UsersForAutocompleteDocument = gql`
    query UsersForAutocomplete($search: String = "%%", $queryFilter: users_bool_exp = {}) {
  users(
    offset: 0
    limit: 20
    where: {_and: [{_or: [{first_name: {_ilike: $search}}, {last_name: {_ilike: $search}}]}, $queryFilter]}
  ) {
    id
    first_name
    last_name
  }
}
    `;

/**
 * __useUsersForAutocompleteQuery__
 *
 * To run a query within a React component, call `useUsersForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersForAutocompleteQuery({
 *   variables: {
 *      search: // value for 'search'
 *      queryFilter: // value for 'queryFilter'
 *   },
 * });
 */
export function useUsersForAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<UsersForAutocompleteQuery, UsersForAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersForAutocompleteQuery, UsersForAutocompleteQueryVariables>(UsersForAutocompleteDocument, options);
      }
export function useUsersForAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersForAutocompleteQuery, UsersForAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersForAutocompleteQuery, UsersForAutocompleteQueryVariables>(UsersForAutocompleteDocument, options);
        }
export type UsersForAutocompleteQueryHookResult = ReturnType<typeof useUsersForAutocompleteQuery>;
export type UsersForAutocompleteLazyQueryHookResult = ReturnType<typeof useUsersForAutocompleteLazyQuery>;
export type UsersForAutocompleteQueryResult = Apollo.QueryResult<UsersForAutocompleteQuery, UsersForAutocompleteQueryVariables>;
export const UserByIdDocument = gql`
    query UserById($userId: uuid!, $whereGroupIdForRole: user_group_user_roles_bool_exp = {}) {
  users_by_pk(id: $userId) {
    ...UserFields
    phone_number
    user_group_user_roles(where: $whereGroupIdForRole) {
      is_active
      start_date
      end_date
      user_group_role {
        id
        name
        user_group {
          id
          name
        }
      }
    }
    sc_user_preference {
      asset_group_description
      asset_type
      max_area
      max_budget
      min_area
      min_budget
      more_details
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      whereGroupIdForRole: // value for 'whereGroupIdForRole'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const UserByOrganizationIdDocument = gql`
    query UserByOrganizationId($organizationId: uuid!) {
  flattened_user_group_users(
    where: {organization_id: {_eq: $organizationId}}
    distinct_on: user_id
  ) {
    user_id
    user {
      id
      first_name
      last_name
      email
    }
  }
}
    `;

/**
 * __useUserByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useUserByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByOrganizationIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUserByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<UserByOrganizationIdQuery, UserByOrganizationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByOrganizationIdQuery, UserByOrganizationIdQueryVariables>(UserByOrganizationIdDocument, options);
      }
export function useUserByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByOrganizationIdQuery, UserByOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByOrganizationIdQuery, UserByOrganizationIdQueryVariables>(UserByOrganizationIdDocument, options);
        }
export type UserByOrganizationIdQueryHookResult = ReturnType<typeof useUserByOrganizationIdQuery>;
export type UserByOrganizationIdLazyQueryHookResult = ReturnType<typeof useUserByOrganizationIdLazyQuery>;
export type UserByOrganizationIdQueryResult = Apollo.QueryResult<UserByOrganizationIdQuery, UserByOrganizationIdQueryVariables>;
export const UserByIdBasicDocument = gql`
    query UserByIdBasic($userId: uuid!) {
  users_by_pk(id: $userId) {
    first_name
    last_name
    phone_number
    email
    avatar_url
  }
}
    `;

/**
 * __useUserByIdBasicQuery__
 *
 * To run a query within a React component, call `useUserByIdBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdBasicQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserByIdBasicQuery(baseOptions: Apollo.QueryHookOptions<UserByIdBasicQuery, UserByIdBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdBasicQuery, UserByIdBasicQueryVariables>(UserByIdBasicDocument, options);
      }
export function useUserByIdBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdBasicQuery, UserByIdBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdBasicQuery, UserByIdBasicQueryVariables>(UserByIdBasicDocument, options);
        }
export type UserByIdBasicQueryHookResult = ReturnType<typeof useUserByIdBasicQuery>;
export type UserByIdBasicLazyQueryHookResult = ReturnType<typeof useUserByIdBasicLazyQuery>;
export type UserByIdBasicQueryResult = Apollo.QueryResult<UserByIdBasicQuery, UserByIdBasicQueryVariables>;
export const UserByIdForPermissionsDocument = gql`
    query UserByIdForPermissions($userId: uuid!) {
  users_by_pk(id: $userId) {
    id
    email
    flattened_user_group_user_permissions {
      user_group_id
      permission_id
    }
  }
}
    `;

/**
 * __useUserByIdForPermissionsQuery__
 *
 * To run a query within a React component, call `useUserByIdForPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdForPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdForPermissionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserByIdForPermissionsQuery(baseOptions: Apollo.QueryHookOptions<UserByIdForPermissionsQuery, UserByIdForPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdForPermissionsQuery, UserByIdForPermissionsQueryVariables>(UserByIdForPermissionsDocument, options);
      }
export function useUserByIdForPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdForPermissionsQuery, UserByIdForPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdForPermissionsQuery, UserByIdForPermissionsQueryVariables>(UserByIdForPermissionsDocument, options);
        }
export type UserByIdForPermissionsQueryHookResult = ReturnType<typeof useUserByIdForPermissionsQuery>;
export type UserByIdForPermissionsLazyQueryHookResult = ReturnType<typeof useUserByIdForPermissionsLazyQuery>;
export type UserByIdForPermissionsQueryResult = Apollo.QueryResult<UserByIdForPermissionsQuery, UserByIdForPermissionsQueryVariables>;
export const UpsertUserFieldByIdDocument = gql`
    mutation UpsertUserFieldById($userFieldData: user_process_values_insert_input!) {
  insert_user_process_values_one(
    object: $userFieldData
    on_conflict: {constraint: user_process_values_process_id_user_id_key, update_columns: process_values}
  ) {
    id
    process_id
    user_id
    process_values
  }
}
    `;
export type UpsertUserFieldByIdMutationFn = Apollo.MutationFunction<UpsertUserFieldByIdMutation, UpsertUserFieldByIdMutationVariables>;

/**
 * __useUpsertUserFieldByIdMutation__
 *
 * To run a mutation, you first call `useUpsertUserFieldByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserFieldByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserFieldByIdMutation, { data, loading, error }] = useUpsertUserFieldByIdMutation({
 *   variables: {
 *      userFieldData: // value for 'userFieldData'
 *   },
 * });
 */
export function useUpsertUserFieldByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpsertUserFieldByIdMutation, UpsertUserFieldByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertUserFieldByIdMutation, UpsertUserFieldByIdMutationVariables>(UpsertUserFieldByIdDocument, options);
      }
export type UpsertUserFieldByIdMutationHookResult = ReturnType<typeof useUpsertUserFieldByIdMutation>;
export type UpsertUserFieldByIdMutationResult = Apollo.MutationResult<UpsertUserFieldByIdMutation>;
export type UpsertUserFieldByIdMutationOptions = Apollo.BaseMutationOptions<UpsertUserFieldByIdMutation, UpsertUserFieldByIdMutationVariables>;
export const UpdateUserByIdDocument = gql`
    mutation UpdateUserById($userId: uuid!, $userData: users_set_input = {}) {
  update_users_by_pk(pk_columns: {id: $userId}, _set: $userData) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserByIdMutationFn = Apollo.MutationFunction<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;

/**
 * __useUpdateUserByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByIdMutation, { data, loading, error }] = useUpdateUserByIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userData: // value for 'userData'
 *   },
 * });
 */
export function useUpdateUserByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>(UpdateUserByIdDocument, options);
      }
export type UpdateUserByIdMutationHookResult = ReturnType<typeof useUpdateUserByIdMutation>;
export type UpdateUserByIdMutationResult = Apollo.MutationResult<UpdateUserByIdMutation>;
export type UpdateUserByIdMutationOptions = Apollo.BaseMutationOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;
export const CreateUserMediaDocument = gql`
    mutation CreateUserMedia($userId: uuid!, $fileFormat: String!, $resourceKey: String!, $url: citext!) {
  insert_media_one(
    object: {user_avatar_media: {data: {user_id: $userId}}, url: $url, resource_key: $resourceKey, file_format: $fileFormat}
  ) {
    id
  }
}
    `;
export type CreateUserMediaMutationFn = Apollo.MutationFunction<CreateUserMediaMutation, CreateUserMediaMutationVariables>;

/**
 * __useCreateUserMediaMutation__
 *
 * To run a mutation, you first call `useCreateUserMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMediaMutation, { data, loading, error }] = useCreateUserMediaMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      fileFormat: // value for 'fileFormat'
 *      resourceKey: // value for 'resourceKey'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreateUserMediaMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMediaMutation, CreateUserMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMediaMutation, CreateUserMediaMutationVariables>(CreateUserMediaDocument, options);
      }
export type CreateUserMediaMutationHookResult = ReturnType<typeof useCreateUserMediaMutation>;
export type CreateUserMediaMutationResult = Apollo.MutationResult<CreateUserMediaMutation>;
export type CreateUserMediaMutationOptions = Apollo.BaseMutationOptions<CreateUserMediaMutation, CreateUserMediaMutationVariables>;