import {
  generators as assetGroupsGenerators,
  paths as assetGroupsPaths,
} from "./assetGroups";
import { generators as assetsGenerators, paths as assetsPaths } from "./assets";
import {
  generators as bookingsGenerators,
  paths as bookingsPaths,
} from "./bookings";
import {
  generators as campaignsGenerators,
  paths as campaignsPaths,
} from "./campaigns";
import { generators as groupsGenerators, paths as groupsPaths } from "./groups";
import {
  generators as helpCenterGenerators,
  paths as helpCenterPaths,
} from "./helpCenter";
import {
  generators as invitesGenerators,
  paths as invitesPaths,
} from "./invites";
import { generators as usersGenerators, paths as usersPaths } from "./users";

export const paths = {
  home: "/",
  groups: groupsPaths,
  assetGroups: assetGroupsPaths,
  assets: assetsPaths,
  users: usersPaths,
  invites: invitesPaths,
  bookings: bookingsPaths,
  campaigns: campaignsPaths,
  helpCenter: helpCenterPaths,
};

export const generators = {
  home: () => "/",
  groups: groupsGenerators,
  assetGroups: assetGroupsGenerators,
  assets: assetsGenerators,
  users: usersGenerators,
  invites: invitesGenerators,
  bookings: bookingsGenerators,
  campaigns: campaignsGenerators,
  helpCenter: helpCenterGenerators,
};
