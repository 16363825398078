import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

import { paths } from "../paths/invites";

export const routes: RouteType[] = [
  {
    path: paths.listAll,
    component: lazy(() => import("../pages/invites/List/AllGroups")),
  },
  {
    path: paths.list,
    component: lazy(() => import("../pages/invites/List")),
  },
  {
    path: paths.create,
    component: lazy(() => import("../pages/invites/Create")),
  },
  {
    path: paths.view,
    component: lazy(() => import("../pages/invites/View")),
  },
];
