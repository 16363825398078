import {
  APP_TOP_BAR_HEIGHT,
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Logo,
  PerfectScrollbar,
  React,
  RouterLink,
  makeStyles,
  useEffect,
  useLocation,
} from "ui-kit";

import { useUserByIdBasicQuery } from "../../../graphql/__generated__";
import { useAdminPermissions } from "../../../hooks/useAdminPermissions";
import { generators } from "../../../paths";
import NavContent from "./NavContent";

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const NAV_BAR_WIDTH = 256;
const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: NAV_BAR_WIDTH,
  },
  desktopDrawer: {
    width: NAV_BAR_WIDTH,
    top: 64,
    height: `calc(100% - ${APP_TOP_BAR_HEIGHT}px)`,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  name: {
    fontWeight: 600,
  },
}));

const NavBar = ({ onMobileClose, openMobile }: NavBarProps): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();
  const { userId } = useAdminPermissions();
  const { data: userData } = useUserByIdBasicQuery({
    variables: {
      userId,
    },
  });

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const getUserName = () => {
    if (!userData?.users_by_pk) return "Unknown";
    const { users_by_pk: userByPk } = userData;
    return `${userByPk?.first_name} ${userByPk.last_name}`;
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box display="flex" justifyContent="center">
            <RouterLink to={generators.home()}>
              <Logo color="#000" height={70} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Avatar
              alt="User"
              className={classes.avatar}
              src={userData?.users_by_pk?.avatar_url}
            />
          </Box>
          <Box mt={2} textAlign="center">
            {getUserName()}
          </Box>
        </Box>
        <Divider />
        <NavContent />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
