import { generatePath } from "ui-kit";

export const paths = {
  view: "/:groupId/get-started",
};

export const generators = {
  view: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.view, { groupId }),
};
