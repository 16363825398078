import { React } from "ui-kit";

const defaultValue: {
  isVisitTutorial: boolean;
  setIsVisitTutorial: (v: boolean) => void;
} = {
  isVisitTutorial: false,
  setIsVisitTutorial: () => [],
};

export const TutorialVisitedContext = React.createContext(defaultValue);
